import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../../modals';

export const extendTrialModalActions = modalsActionsFactory(ModalName.EXTEND_TRIAL_MODAL_NAME);
export const extendTrialModalSelectors = modalsSelectorsFactory(ModalName.EXTEND_TRIAL_MODAL_NAME);

export const expireTrialModalActions = modalsActionsFactory(ModalName.EXPIRE_TRIAL_MODAL_NAME);
export const expireTrialModalSelectors = modalsSelectorsFactory(ModalName.EXPIRE_TRIAL_MODAL_NAME);

export const convertTrialModalActions = modalsActionsFactory(ModalName.CONVERT_TRIAL_MODAL_NAME);
export const convertTrialModalSelectors = modalsSelectorsFactory(ModalName.CONVERT_TRIAL_MODAL_NAME);

export const deleteOrgModalActions = modalsActionsFactory(ModalName.DELETE_ORG_MODAL_NAME);
export const deleteOrgModalSelectors = modalsSelectorsFactory(ModalName.DELETE_ORG_MODAL_NAME);

export const payLaterModalActions = modalsActionsFactory(ModalName.PAY_LATER_MODAL_NAME);
export const payLaterModalSelectors = modalsSelectorsFactory(ModalName.PAY_LATER_MODAL_NAME);
