import { CreateUserEventInput } from '../../API';

export enum SortMethod {
  UPCOMING = 'UPCOMING',
  PAST = 'PAST',
  DATE_RANGE = 'DATE_RANGE',
}

export enum MeetingStatus {
  BOOKED = 'BOOKED',
  CANCELED = 'CANCELED',
}

export type MeetingFilterType = {
  workspaceIds: string[];
  bookingPageIds: string[];
  statuses: string[];
  sortMethod: SortMethod;
  dateRange: string[];
};

export type UpdateBookedMeeting = CreateUserEventInput & { editMeetingNotes?: boolean };

export type GetBookedMeetingsPayload = {
  eventId?: string;
};

export type GetBookedMeetingsResponse = {
  events: CreateUserEventInput[];
};

export type UpdateInternalFieldsUserEventRequest = {
  eventId: string;
  internalNotes?: string;
  isNoShow?: boolean;
};
