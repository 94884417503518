import { CreateActionLogInput } from '../../../API';
import {
  ExpireTrialRequest,
  ExtendLicenseRequest,
  OPSConsoleLicense,
  OPSConsoleTenant,
  OrgDetailsType,
  OrgFilters,
  ORGS_CHART_VALUES,
  OrgsDataTableType,
  PayLaterDetails,
  PayLaterDetailsAddress,
} from './types';

export enum OrgsActionTypes {
  GET_ORGS_REQUEST = 'orgs/GET_ORGS_REQUEST',
  GET_ORGS_SUCCESS = 'orgs/GET_ORGS_SUCCESS',
  GET_ORGS_FAIL = 'orgs/GET_ORGS_FAIL',
  CHANGE_FILTER = 'orgs/CHANGE_FILTER',
  GET_ORG_DETAILS_REQUEST = 'orgs/GET_ORG_DETAILS_REQUEST',
  GET_ORG_DETAILS_SUCCESS = 'orgs/GET_ORG_DETAILS_SUCCESS',
  GET_ORG_DETAILS_FAIL = 'orgs/GET_ORG_DETAILS_FAIL',
  SAVE_ORG_DETAILS_REQUEST = 'orgs/SAVE_ORG_DETAILS_REQUEST',
  SAVE_ORG_DETAILS_SUCCESS = 'orgs/SAVE_ORG_DETAILS_SUCCESS',
  SAVE_ORG_DETAILS_FAIL = 'orgs/SAVE_ORG_DETAILS_FAIL',
  DELETE_ORG_REQUEST = 'orgs/DELETE_ORG_REQUEST',
  DELETE_ORG_SUCCESS = 'orgs/DELETE_ORG_SUCCESS',
  DELETE_ORG_FAIL = 'orgs/DELETE_ORG_FAIL',
  EXTEND_LICENSE_REQUEST = 'orgs/EXTEND_LICENSE_REQUEST',
  EXTEND_LICENSE_SUCCESS = 'orgs/EXTEND_LICENSE_SUCCESS',
  EXTEND_LICENSE_FAIL = 'orgs/EXTEND_LICENSE_FAIL',
  EXPIRE_TRIAL_REQUEST = 'orgs/EXPIRE_TRIAL_REQUEST',
  EXPIRE_TRIAL_SUCCESS = 'orgs/EXPIRE_TRIAL_SUCCESS',
  EXPIRE_TRIAL_FAIL = 'orgs/EXPIRE_TRIAL_FAIL',
  CONVERT_TO_TEST_REQUEST = 'orgs/CONVERT_TO_TEST_REQUEST',
  CONVERT_TO_TEST_SUCCESS = 'orgs/CONVERT_TO_TEST_SUCCESS',
  CONVERT_TO_TEST_FAIL = 'orgs/CONVERT_TO_TEST_FAIL',
  CONVERT_TO_PAY_LATER_REQUEST = 'orgs/CONVERT_TO_PAY_LATER_REQUEST',
  CONVERT_TO_PAY_LATER_SUCCESS = 'orgs/CONVERT_TO_PAY_LATER_SUCCESS',
  CONVERT_TO_PAY_LATER_FAIL = 'orgs/CONVERT_TO_PAY_LATER_FAIL',
  UPDATE_LICENSE = 'orgs/UPDATE_LICENSE',
  UPDATE_TENANT = 'orgs/UPDATE_TENANT',
  CHANGE_ACCOUNT = 'orgs/CHANGE_ACCOUNT',
  UPDATE_PAY_LATER = 'orgs/UPDATE_PAY_LATER',
  UPDATE_PAY_LATER_ADDRESS = 'orgs/UPDATE_PAY_LATER_ADDRESS',
  GET_ORGS_LOGS_REQUEST = 'orgs/GET_ORGS_LOGS_REQUEST',
  GET_ORGS_LOGS_SUCCESS = 'orgs/GET_ORGS_LOGS_SUCCESS',
  GET_ORGS_LOGS_FAIL = 'orgs/GET_ORGS_LOGS_FAIL',
  GET_ORG_LOGS_REQUEST = 'orgs/GET_ORG_LOGS_REQUEST',
  GET_ORG_LOGS_SUCCESS = 'orgs/GET_ORG_LOGS_SUCCESS',
  GET_ORG_LOGS_FAIL = 'orgs/GET_ORG_LOGS_FAIL',
}

export type OrgsAction =
  | { type: OrgsActionTypes.GET_ORGS_REQUEST }
  | { type: OrgsActionTypes.GET_ORGS_SUCCESS; payload: OrgsDataTableType[] }
  | { type: OrgsActionTypes.GET_ORGS_FAIL; error: unknown }
  | { type: OrgsActionTypes.CHANGE_FILTER; payload: Partial<OrgFilters> }
  | { type: OrgsActionTypes.GET_ORG_DETAILS_REQUEST; payload: string }
  | { type: OrgsActionTypes.GET_ORG_DETAILS_SUCCESS; payload: OrgDetailsType }
  | { type: OrgsActionTypes.GET_ORG_DETAILS_FAIL; error: unknown }
  | { type: OrgsActionTypes.SAVE_ORG_DETAILS_REQUEST }
  | { type: OrgsActionTypes.SAVE_ORG_DETAILS_SUCCESS }
  | { type: OrgsActionTypes.SAVE_ORG_DETAILS_FAIL; error: unknown }
  | { type: OrgsActionTypes.DELETE_ORG_REQUEST }
  | { type: OrgsActionTypes.DELETE_ORG_SUCCESS }
  | { type: OrgsActionTypes.DELETE_ORG_FAIL; error: unknown }
  | { type: OrgsActionTypes.EXTEND_LICENSE_REQUEST; payload: ExtendLicenseRequest }
  | { type: OrgsActionTypes.EXTEND_LICENSE_SUCCESS }
  | { type: OrgsActionTypes.EXTEND_LICENSE_FAIL; error: unknown }
  | { type: OrgsActionTypes.EXPIRE_TRIAL_REQUEST; payload: ExpireTrialRequest }
  | { type: OrgsActionTypes.EXPIRE_TRIAL_SUCCESS }
  | { type: OrgsActionTypes.EXPIRE_TRIAL_FAIL; error: unknown }
  | { type: OrgsActionTypes.CONVERT_TO_TEST_REQUEST }
  | { type: OrgsActionTypes.CONVERT_TO_TEST_SUCCESS }
  | { type: OrgsActionTypes.CONVERT_TO_TEST_FAIL; error: unknown }
  | { type: OrgsActionTypes.CONVERT_TO_PAY_LATER_REQUEST }
  | { type: OrgsActionTypes.CONVERT_TO_PAY_LATER_SUCCESS }
  | { type: OrgsActionTypes.CONVERT_TO_PAY_LATER_FAIL; error: unknown }
  | { type: OrgsActionTypes.UPDATE_LICENSE; payload: Partial<OPSConsoleLicense> }
  | { type: OrgsActionTypes.UPDATE_TENANT; payload: Partial<OPSConsoleTenant> }
  | { type: OrgsActionTypes.CHANGE_ACCOUNT; payload: string }
  | { type: OrgsActionTypes.UPDATE_PAY_LATER; payload: Partial<PayLaterDetails> }
  | { type: OrgsActionTypes.UPDATE_PAY_LATER_ADDRESS; payload: Partial<PayLaterDetailsAddress> }
  | { type: OrgsActionTypes.GET_ORGS_LOGS_REQUEST; payload: ORGS_CHART_VALUES }
  | { type: OrgsActionTypes.GET_ORGS_LOGS_SUCCESS; payload: CreateActionLogInput[] }
  | { type: OrgsActionTypes.GET_ORGS_LOGS_FAIL; error: unknown }
  | { type: OrgsActionTypes.GET_ORG_LOGS_REQUEST; payload: ORGS_CHART_VALUES }
  | { type: OrgsActionTypes.GET_ORG_LOGS_SUCCESS; payload: CreateActionLogInput[] }
  | { type: OrgsActionTypes.GET_ORG_LOGS_FAIL; error: unknown };

const getOrgsRequest = (): OrgsAction => ({ type: OrgsActionTypes.GET_ORGS_REQUEST });
const getOrgsSuccess = (payload: OrgsDataTableType[]): OrgsAction => ({
  type: OrgsActionTypes.GET_ORGS_SUCCESS,
  payload,
});
const getOrgsFail = (error: unknown): OrgsAction => ({ type: OrgsActionTypes.GET_ORGS_FAIL, error });

const changeFilter = (payload: Partial<OrgFilters>): OrgsAction => ({
  type: OrgsActionTypes.CHANGE_FILTER,
  payload,
});

const getOrgDetailsRequest = (payload: string): OrgsAction => ({
  type: OrgsActionTypes.GET_ORG_DETAILS_REQUEST,
  payload,
});
const getOrgDetailsSuccess = (payload: OrgDetailsType): OrgsAction => ({
  type: OrgsActionTypes.GET_ORG_DETAILS_SUCCESS,
  payload,
});
const getOrgDetailsFail = (error: unknown): OrgsAction => ({ type: OrgsActionTypes.GET_ORG_DETAILS_FAIL, error });

const saveOrgDetailsRequest = (): OrgsAction => ({
  type: OrgsActionTypes.SAVE_ORG_DETAILS_REQUEST,
});
const saveOrgDetailsSuccess = (): OrgsAction => ({
  type: OrgsActionTypes.SAVE_ORG_DETAILS_SUCCESS,
});
const saveOrgDetailsFail = (error: unknown): OrgsAction => ({ type: OrgsActionTypes.SAVE_ORG_DETAILS_FAIL, error });

const deleteOrgRequest = (): OrgsAction => ({
  type: OrgsActionTypes.DELETE_ORG_REQUEST,
});
const deleteOrgSuccess = (): OrgsAction => ({
  type: OrgsActionTypes.DELETE_ORG_SUCCESS,
});
const deleteOrgFail = (error: unknown): OrgsAction => ({ type: OrgsActionTypes.DELETE_ORG_FAIL, error });

const extendLicenseRequest = (payload: ExtendLicenseRequest): OrgsAction => ({
  type: OrgsActionTypes.EXTEND_LICENSE_REQUEST,
  payload,
});
const extendLicenseSuccess = (): OrgsAction => ({
  type: OrgsActionTypes.EXTEND_LICENSE_SUCCESS,
});
const extendLicenseFail = (error: unknown): OrgsAction => ({
  type: OrgsActionTypes.EXTEND_LICENSE_FAIL,
  error,
});

const expireTrialRequest = (payload: ExpireTrialRequest): OrgsAction => ({
  type: OrgsActionTypes.EXPIRE_TRIAL_REQUEST,
  payload,
});
const expireTrialSuccess = (): OrgsAction => ({
  type: OrgsActionTypes.EXPIRE_TRIAL_SUCCESS,
});
const expireTrialFail = (error: unknown): OrgsAction => ({
  type: OrgsActionTypes.EXPIRE_TRIAL_FAIL,
  error,
});

const convertToTestRequest = (): OrgsAction => ({
  type: OrgsActionTypes.CONVERT_TO_TEST_REQUEST,
});
const convertToTestSuccess = (): OrgsAction => ({
  type: OrgsActionTypes.CONVERT_TO_TEST_SUCCESS,
});
const convertToTestFail = (error: unknown): OrgsAction => ({
  type: OrgsActionTypes.CONVERT_TO_TEST_FAIL,
  error,
});

const convertToPayLaterRequest = (): OrgsAction => ({
  type: OrgsActionTypes.CONVERT_TO_PAY_LATER_REQUEST,
});
const convertToPayLaterSuccess = (): OrgsAction => ({
  type: OrgsActionTypes.CONVERT_TO_PAY_LATER_SUCCESS,
});
const convertToPayLaterFail = (error: unknown): OrgsAction => ({
  type: OrgsActionTypes.CONVERT_TO_PAY_LATER_FAIL,
  error,
});

const updateLicense = (payload: Partial<OPSConsoleLicense>): OrgsAction => ({
  type: OrgsActionTypes.UPDATE_LICENSE,
  payload,
});

const updateTenant = (payload: Partial<OPSConsoleTenant>): OrgsAction => ({
  type: OrgsActionTypes.UPDATE_TENANT,
  payload,
});

const changeAccount = (payload: string): OrgsAction => ({
  type: OrgsActionTypes.CHANGE_ACCOUNT,
  payload,
});

const updatePayLater = (payload: Partial<PayLaterDetails>): OrgsAction => ({
  type: OrgsActionTypes.UPDATE_PAY_LATER,
  payload,
});
const updatePayLaterAddress = (payload: Partial<PayLaterDetailsAddress>): OrgsAction => ({
  type: OrgsActionTypes.UPDATE_PAY_LATER_ADDRESS,
  payload,
});

const getOrgsLogsRequest = (payload: ORGS_CHART_VALUES): OrgsAction => ({
  type: OrgsActionTypes.GET_ORGS_LOGS_REQUEST,
  payload,
});
const getOrgsLogsSuccess = (payload: CreateActionLogInput[]): OrgsAction => ({
  type: OrgsActionTypes.GET_ORGS_LOGS_SUCCESS,
  payload,
});
const getOrgsLogsFail = (error: unknown): OrgsAction => ({
  type: OrgsActionTypes.GET_ORGS_LOGS_FAIL,
  error,
});

const getOrgLogsRequest = (payload: ORGS_CHART_VALUES): OrgsAction => ({
  type: OrgsActionTypes.GET_ORG_LOGS_REQUEST,
  payload,
});
const getOrgLogsSuccess = (payload: CreateActionLogInput[]): OrgsAction => ({
  type: OrgsActionTypes.GET_ORG_LOGS_SUCCESS,
  payload,
});
const getOrgLogsFail = (error: unknown): OrgsAction => ({
  type: OrgsActionTypes.GET_ORG_LOGS_FAIL,
  error,
});

export const orgsActions = {
  getOrgsRequest,
  getOrgsSuccess,
  getOrgsFail,
  changeFilter,
  getOrgDetailsRequest,
  getOrgDetailsSuccess,
  getOrgDetailsFail,
  saveOrgDetailsRequest,
  saveOrgDetailsSuccess,
  saveOrgDetailsFail,
  deleteOrgRequest,
  deleteOrgSuccess,
  deleteOrgFail,
  extendLicenseRequest,
  extendLicenseSuccess,
  extendLicenseFail,
  expireTrialRequest,
  expireTrialSuccess,
  expireTrialFail,
  convertToTestRequest,
  convertToTestSuccess,
  convertToTestFail,
  convertToPayLaterRequest,
  convertToPayLaterSuccess,
  convertToPayLaterFail,
  updateLicense,
  updateTenant,
  changeAccount,
  updatePayLater,
  updatePayLaterAddress,

  getOrgsLogsRequest,
  getOrgsLogsSuccess,
  getOrgsLogsFail,

  getOrgLogsRequest,
  getOrgLogsSuccess,
  getOrgLogsFail,
};
