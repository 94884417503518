import { t } from '../../../i18n/i18n';

export default {
  manageTitle: t('CookieSettings:MANAGE_TITLE'),
  description: t('CookieSettings:DESCRIPTION'),
  descriptionFirst: t('CookieSettings:DESCRIPTION_FIRST_PART'),
  descriptionSecond: t('CookieSettings:DESCRIPTION_SECOND_PART'),
  descriptionThird: t('CookieSettings:DESCRIPTION_THIRD_PART'),
  strictlyName: t('CookieSettings:STRICTLY_NAME'),
  performanceName: t('CookieSettings:PERFORMANCE_NAME'),
  functionalName: t('CookieSettings:FUNCTIONAL_NAME'),
  targetingName: t('CookieSettings:TARGETING_NAME'),
  necessaryOnly: t('CookieSettings:NECESSARY_ONLY'),
  agreeClose: t('CookieSettings:AGREE_CLOSE'),
  showAll: t('CookieSettings:SHOW_ALL'),
  hideAll: t('CookieSettings:HIDE_ALL'),
};
