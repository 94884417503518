import { APIProvider as GoogleAPIMapsProvider } from '@vis.gl/react-google-maps';
import i18n from '../../i18n/i18n';

export const GoogleMapsProvider: React.FC<{ children: React.ReactNode; apiKey: string; onLoad?: () => void }> = ({
  children,
  apiKey,
  onLoad,
}) => {
  return (
    <GoogleAPIMapsProvider apiKey={apiKey} language={i18n.language} onLoad={onLoad}>
      {children}
    </GoogleAPIMapsProvider>
  );
};
