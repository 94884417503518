import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useSelector } from 'react-redux';
import { orgsSelectors } from '../../../../store/opsConsole/orgs';
import { ScheduledChangesInput } from '../../../../API';
import { formatScheduledChanges, formatDateTimeThroughGap } from '../../../../services/DateService';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import labels from './labels';

export const OrgScheduledChanges = () => {
  const changes = useSelector(orgsSelectors.selectScheduledChanges);
  const license = useSelector(orgsSelectors.selectLicense);
  const isPositiveChanges = useSelector(orgsSelectors.selectIsScheduledPositive);
  const scheduledSeats = useSelector(orgsSelectors.selectScheduledSeats);
  const IconComponent = isPositiveChanges ? ArrowUpIcon : ArrowDownIcon;

  const columnClassName = 'border-none m-0 p-0 mt-8px';

  const getChangeBody = (data: ScheduledChangesInput) => {
    if (data.plan) {
      const isMonthly = data.plan.endsWith('Monthly');
      const prevTerm = isMonthly ? labels.annualy : labels.monthly;
      const nextTerm = isMonthly ? labels.monthly : labels.annualy;
      return `${labels.changedLabel} ${prevTerm} ${labels.to} ${nextTerm}`;
    }
  
    const seatLabel = data.seats === 1 ? labels.license : labels.licenses;
    return `${labels.reduce} ${data.seats || 0} ${seatLabel}`;
  };
  
  return (
    <div className="w-full sumo-card p-20px flex-column mb-24px">
      <div className="flex-left-center gap-8px">
        <div className="text-title-s-med ">{`${labels.changesScheduledFor} ${formatScheduledChanges(
          license.endDate
        )}`}</div>
        <div
          className={`text-label-s-med flex-center w-fit ${
            isPositiveChanges ? 'ors-changes-up' : 'ors-changes-down'
          } gap-4px`}
        >
          {scheduledSeats || ''}
          <IconComponent className="icon-8px" />
        </div>
      </div>

      <DataTable value={changes} size="small" className="mt-16px">
        <Column header={labels.scheduledColumnLabel} body={getChangeBody} className={columnClassName} />
        <Column header={labels.scheduledColumnChangeBy} field="changeBy" className={columnClassName} />
        <Column
          header={labels.scheduledColumnDate}
          field="requestDate"
          body={(data: ScheduledChangesInput) => (data.requestDate ? formatDateTimeThroughGap(data.requestDate) : '')}
          className={columnClassName}
        />
      </DataTable>
    </div>
  );
};
