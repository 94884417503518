import { combineReducers } from 'redux';
import { BillingAction, BillingActionTypes } from './actions';
import {
  CALCULATION_TAXES_DEFAULT,
  CHARGEBEE_MONTHLY_TERM,
  DEFAULT_CUSTOMER,
  DEFAULT_SEATS_AMOUNT,
  DEFAULT_SUBSCRIPTION,
  ITEM_PRICES_DEFAULT,
} from './constants';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { BillingAddress, Contact, PaymentSource, SubscriptionInvoice } from './types';

const subscription = (state = DEFAULT_SUBSCRIPTION, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.subscription;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_SUBSCRIPTION;
    default:
      return state;
  }
};

const customer = (state = DEFAULT_CUSTOMER, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.customer || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_CUSTOMER;
    default:
      return state;
  }
};

const contacts = (state: Contact[] = [], action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.contacts || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const paymentSources = (state: PaymentSource[] = [], action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.paymentSources || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const invoices = (state: SubscriptionInvoice[] = [], action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.invoices || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const billingAddress = (state: BillingAddress = {}, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.customer?.billing_address || state;
    case BillingActionTypes.SET_BILLING_ADDRESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return {};
    default:
      return state;
  }
};

const sendBillingEmail = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload.contacts
        ? !action.payload.contacts.length ||
            action.payload.contacts.every((contact) => Boolean(contact.send_billing_email))
        : state;
    case BillingActionTypes.SET_SEND_BILLING_EMAILS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const contactEmails = (state: string[] = [], action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return (action.payload.contacts?.map((contact) => contact.email) || state) as string[];
    case BillingActionTypes.SET_CONTACT_EMAILS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const addOrRemoveSeats = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.SET_ADD_OR_REMOVE_SEATS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const changeSeats = (state = DEFAULT_SEATS_AMOUNT, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.SET_CHANGE_SEATS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_SEATS_AMOUNT;
    default:
      return state;
  }
};

const term = (state = CHARGEBEE_MONTHLY_TERM, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.SET_TERM:
      return action.payload;
    case BillingActionTypes.GET_BILLING_SUCCESS:
      return action.payload?.subscription.subscription_items?.[0]?.item_price_id || state;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return CHARGEBEE_MONTHLY_TERM;
    default:
      return state;
  }
};

const itemPrices = (state = ITEM_PRICES_DEFAULT, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_ITEM_PRICES_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return ITEM_PRICES_DEFAULT;
    default:
      return state;
  }
};

const calculationTaxes = (state = CALCULATION_TAXES_DEFAULT, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.CALCULATE_TAXES_SUCCESS:
      return { ...action.payload };
    case BillingActionTypes.CALCULATE_TAXES_RESET:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return CALCULATION_TAXES_DEFAULT;
    default:
      return state;
  }
};

const isFetching = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_REQUEST:
    case BillingActionTypes.CHANGE_REQUEST:
    case BillingActionTypes.CANCEL_SUBSCRIPTION_REQUEST:
    case BillingActionTypes.UPDATE_CONTACTS_REQUEST:
    case BillingActionTypes.UPDATE_BILLING_ADDRESS_REQUEST:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST:
    case BillingActionTypes.PAY_LATER_REQUEST:
    case BillingActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST:
      return true;
    case BillingActionTypes.GET_BILLING_SUCCESS:
    case BillingActionTypes.GET_BILLING_FAIL:
    case BillingActionTypes.CHANGE_SUCCESS:
    case BillingActionTypes.CHANGE_FAIL:
    case BillingActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
    case BillingActionTypes.CANCEL_SUBSCRIPTION_FAIL:
    case BillingActionTypes.UPDATE_CONTACTS_SUCCESS:
    case BillingActionTypes.UPDATE_CONTACTS_FAIL:
    case BillingActionTypes.UPDATE_BILLING_ADDRESS_SUCCESS:
    case BillingActionTypes.UPDATE_BILLING_ADDRESS_FAIL:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL:
    case BillingActionTypes.PAY_LATER_FAIL:
    case BillingActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS:
    case BillingActionTypes.REACTIVATE_SUBSCRIPTION_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isRefreshLicencesFetching = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST:
      return true;
    case BillingActionTypes.REFRESH_TRIAL_LICENSE_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isRefreshTenantFetching = (state = false, action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.REFRESH_TENANT_REQUEST:
      return true;
    case BillingActionTypes.REFRESH_TENANT_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state = '', action: BillingAction | GlobalAction) => {
  switch (action.type) {
    case BillingActionTypes.GET_BILLING_REQUEST:
    case BillingActionTypes.CHANGE_REQUEST:
    case BillingActionTypes.UPDATE_CONTACTS_REQUEST:
    case BillingActionTypes.UPDATE_BILLING_ADDRESS_REQUEST:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST:
    case BillingActionTypes.GET_ITEM_PRICES_REQUEST:
      return '';
    case BillingActionTypes.GET_BILLING_FAIL:
    case BillingActionTypes.CHANGE_FAIL:
    case BillingActionTypes.UPDATE_CONTACTS_FAIL:
    case BillingActionTypes.UPDATE_BILLING_ADDRESS_FAIL:
    case BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL:
    case BillingActionTypes.GET_ITEM_PRICES_FAIL:
    case BillingActionTypes.PAY_LATER_FAIL:
    case BillingActionTypes.CALCULATE_TAXES_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

export default combineReducers({
  subscription,
  customer,
  contacts,
  paymentSources,
  invoices,
  billingAddress,
  sendBillingEmail,
  contactEmails,
  changeSeats,
  term,
  itemPrices,
  addOrRemoveSeats,
  calculationTaxes,
  isFetching,
  isRefreshLicencesFetching,
  isRefreshTenantFetching,
  error,
});
