export const BookingPages = Object.freeze({
  NEW_BOOKING_PAGE: `Nueva Página de Reserva`,
  DELETE_BOOKING_PAGE_DIALOG_TEXT: `¿Está seguro de que desea eliminar esta página de reserva?`,
  DELETE_BOOKING_PAGES_DIALOG_TEXT: `¿Está seguro de que desea eliminar estas páginas de reserva?`,
  TITLE: `Páginas de Reserva`,
  ADD_TO_SITE: `Agregar al Sitio Web`,
  CLONE_MODAL_TITLE: `Clonar Página de Reserva`,
  DEFAULT_FILTER: `Mis Páginas de Reserva`,
  SHARED_LABEL: `Compartido`,
  CLONE_NOTIFICATION: `Tenga en cuenta que la propiedad "Solo Admin" está desactivada por defecto para un registro clonado.`,
});

export const BookingPagesToastsNotifications = Object.freeze({
  CLONE_BOOKING_PAGES_ERROR_MESSAGE: `Error en la solicitud de clonación de la página de reserva`,
  CLONE_BOOKING_PAGES_SUCCESS_MESSAGE: `Página de reserva clonada con éxito`,
  DELETE_BOOKING_PAGES_ERROR_MESSAGE: `Error en la solicitud de eliminación de páginas de reserva`,
  DELETE_BOOKING_PAGES_SUCCESS_MESSAGE: `Página de reserva eliminada con éxito`,
  GET_BOOKING_PAGE_ERROR_MESSAGE: `No se pudo recuperar la página de reserva, podría estar eliminada`,
  GET_BOOKING_PAGES_ERROR_MESSAGE: `Error en la solicitud de obtención de páginas de reserva`,
  SAVE_BOOKING_PAGE_ERROR_MESSAGE: `Error en la solicitud de guardado de la página de reserva`,
  SAVE_BOOKING_PAGE_SUCCESS_MESSAGE: `Página de reserva guardada con éxito`,
});

export const DefaultBookingPage = Object.freeze({
  NAME: `Reunión de 30 minutos`,
  BOOK_A_MEETING: `Reservar una reunión`,
  MEETING_DETAILS: `Detalles de la reunión`,
  GUESTS_INFO: `Información del invitado`,
  BOOK_MEETING_BUTTON: `Confirmar`,
  BOOK_ANOTHER_MEETING_BUTTON: `Reservar otra reunión`,
  CANCEL: `Cancelar`,
  CANCEL_MEETING_BUTTON: `Cancelar reunión`,
  CONFIRM_CANCEL_BUTTON: `Confirmar`,
  SAVE_MEETING_BUTTON: `Reprogramar`,
  MEETING_BOOKED_TITLE: `Estás reservado`,
  MEETING_BOOKED_DESCRIPTION: `Se ha enviado una confirmación por correo electrónico a su bandeja de entrada.`,
  MEETING_RESCHEDULED_TITLE: `Estás reprogramado`,
  MEETING_RESCHEDULED_DESCRIPTION: `Se ha enviado una confirmación por correo electrónico a su bandeja de entrada.`,
  MEETING_CANCELED_TITLE: `Estás cancelado`,
  MEETING_CANCELED_DESCRIPTION: `Se ha enviado una confirmación por correo electrónico a su bandeja de entrada.`,
});

export const EditBookingPage = Object.freeze({
  WHAT_TITLE: `Qué`,
  WHAT_DESCRIPTION: `Plantillas y detalles de reserva`,
  WHO_TITLE: `Quién`,
  WHO_DESCRIPTION: `Anfitrión de la reunión`,
  WHERE_TITLE: `Dónde`,
  WHERE_DESCRIPTION: `Detalles y ubicaciones del evento`,
  WHEN_TITLE: `Cuándo`,
  WHEN_DESCRIPTION: `Duración y disponibilidad`,
  HOW_TITLE: `¿Cómo debe verse?`,
  HOW_DESCRIPTION: `Estilos de marca y exhibiciones`,
  ALERTS_TITLE: `Alertas`,
  ALERTS_DESCRIPTION: `Correos electrónicos y recordatorios`,
  INVITEE_TITLE: `Configuración del Invitado`,
  INVITEE_DESCRIPTION: `Formulario de reserva y permiso del invitado`,
  AFTER_TITLE: `Después de la reserva`,
  AFTER_DESCRIPTION: `Página de confirmación y Política de Cancelación`,
  ADDITIONAL_CONFIGURATIONS: `Configuraciones Adicionales`,
  VIEW_BOOKING_PAGE: `Ver Página de Reserva`,
  COPY_LINK: `Copiar Enlace`,
  ADD_TO_WEBSITE: `Agregar al Sitio Web`,
  ACTIVE: `Activo`,
  BOOKING_TEMPLATE_LINK_TOOLTIP: `Registro Bloqueado. Estos ajustes son de la plantilla de reserva titulada: `,
  BOOKING_TEMPLATE_LINK_TOOLTIP_OVERRIDE: `Haga clic aquí para sobrescribir estos ajustes`,
  LOCKED_ADMIN_ONLY: `Solo un Admin puede modificar estos ajustes. Última Modificación por:`,
  LOCKED_NO_PERMISSIONS: `No tiene permisos para editar esta Página de Reserva. Última Modificación por:`,
});

export const BookingPageWhatStep = Object.freeze({
  NAME: `Nombre`,
  NAME_PLACEHOLDER: `Nombre personalizado para esta página de reserva`,
  NAME_TOOLTIP: `Este texto se mostrará a los asistentes a la reunión en su calendario y en correos electrónicos. Por ejemplo, "Reunión de 30 min" o "Examen dental".`,
  COLOR: `Color`,
  COLOR_TOOLTIP: `Elija un color para esta página de reserva y la reunión creada.`,
  BOOKING_TEMPLATE: `Plantilla de reserva`,
  BOOKING_TEMPLATE_PLACEHOLDER: `Seleccione plantilla de reserva`,
  BOOKING_TEMPLATE_TOOLTIP: `Seleccione una plantilla de reserva para aplicar automáticamente todos los ajustes para la página de reserva actual.`,
  ADMIN_ONLY: `Solo Administrador`,
  ADMIN_ONLY_TOOLTIP: `Solo un administrador puede modificar estos ajustes`,
  MEETING_INSTRUCTIONS: `Instrucciones de la reunión`,
  MEETING_INSTRUCTIONS_PLACEHOLDER: `Ingrese instrucciones de la reunión`,
  MEETING_INSTRUCTIONS_TOOLTIP: `Escriba un resumen y cualquier detalle que su invitado deba conocer sobre el evento.`,
});

export const EditBookingPageWhoStep = Object.freeze({
  HOSTS_AND_TEAMS: `Anfitriones y equipos`,
  HOSTS_TOOLTIP: `Elige una persona, varias personas o un equipo (donde se seleccionará a un miembro mediante turnos) para asistir a esta reunión.`,
  SEARCH_PLACEHOLDER: `Buscar anfitriones y equipos`,
  UNASSIGNED: `No asignado`,
  WRONG_HOST: `Debe ser el anfitrión principal para guardar la página de reservas`,
});

export const EditBookingPageDurationStep = Object.freeze({
  DURATION: `Duración`,
  DURATION_DESCRIPTION: `Establezca la duración de su reunión. No debe exceder las 12 horas.`,
  BUFFER_TIME: `Tiempo de Espera`,
  BUFFER_TIME_TOOLTIP: `Establecer tiempos de espera antes y después`,
  AFTER_CHECKBOX_LABEL: `después del evento`,
  BEFORE_CHECKBOX_LABEL: `antes del evento`,
});

export const EditBookingPageDateRangeStep = Object.freeze({
  SCHEDULE_AVAILABILITY: `Disponibilidad de Horarios`,
  MINIMUM_NOTICE_PERIOD: `Periodo de Aviso Mínimo`,
  MINIMUM_NOTICE_PERIOD_TOOLTIP: `Esta configuración determina la cantidad mínima de tiempo requerida antes de que se pueda programar una reunión. Ayuda a garantizar que tenga suficiente aviso para prepararse para las reuniones próximas.`,
  OPTION_DAYS_INTO_THE_FUTURE: `Días en el futuro`,
  OPTION_DATE_RANGE: `Dentro de un rango de fechas`,
  OPTION_INDEFINITELY: `Indefinidamente en el futuro`,
});

export const EditBookingPageInviteeStep = Object.freeze({
  PERSONALIZED_TITLE: `Página de reserva personalizada`,
  PERSONALIZED_DESCRIPTION: `Puede rellenar previamente información sobre el invitado para personalizar la página para una persona específica`,
  PERSONAL_INFO: `información personal del invitado`,
  QUESTIONS: `Preguntas del Formulario de Reserva`,
  QUESTIONS_DESCRIPTION: `Mejore el formulario de reserva con preguntas de invitación`,
  NEW_QUESTION: `Nueva pregunta`,
  EDIT_QUESTION: `Editar pregunta`,
  PERMISSIONS: `Permisos del Invitado`,
  PERMISSIONS_DESCRIPTION: `Definir las acciones disponibles para sus invitados`,
  OPTION_INVITE_OTHERS: `Los invitados pueden invitar a otros`,
  OPTION_INVITE_OTHERS_TOOLTIP: `Habilitar esta función mostrará un campo "Invitar a Otros" en la Página de Reserva, permitiendo al invitado agregar otras personas a la lista de participantes.`,
  OPTION_ENTER_NOTE: `Los invitados pueden ingresar una nota para todos los invitados`,
  OPTION_ENTER_NOTE_TOOLTIP: `Habilitar esta función mostrará un campo "Nota para los Asistentes", permitiendo al invitado ingresar una nota que se mostrará en la cita reservada en el calendario para que todos los participantes la vean.`,
});

export const EditBookingPageAfterStep = Object.freeze({
  CONFIRMATION_PAGE: `Página de Confirmación`,
  OPTION_DISAPLY_CONFIRMATION_PAGE: `Mostrar página de confirmación de SUMO`,
  OPTION_REDIRECT_TO_EXTERNAL_PAGE: `Redirigir a una página externa específica`,
  EXTERNAL_LINK_PLACEHOLDER: `URL de redirección externa`,
  AVAILABLE_ACTIONS: `Acciones disponibles`,
  AVAILABLE_ACTIONS_DESCRIPTION: `Acciones accesibles para los invitados desde esta página de confirmación.`,
  OPTION_RESCHEDULE: `Reprogramar`,
  OPTION_CANCEL: `Cancelar reunión`,
  OPTION_BOOK_ANOTHER: `Reservar otra reunión`,
  CUSTOM_LINKS: `Enlaces personalizados adicionales`,
  CUSTOM_LINKS_DESCRIPTION: `Agregar enlaces personalizados en la página de confirmación, como una encuesta o recurso adicional.`,
  ADD_CUSTOM_LINK: `Agregar enlace personalizado`,
  CHANGE_CUSTOM_LINK: `Cambiar enlace personalizado`,
  LINK_URL: `URL del Enlace`,
  CUSTOM_LINK_LABEL: `Etiqueta del Enlace Personalizado`,
  CUSTOM_LINK_LABEL_PLACEHOLDER: `Acuerdo de reunión`,
  CANCELATION_POLICY: `Política de Cancelación`,
  CANCELATION_POLICY_DESCRIPTION: `Proporcione detalles sobre su política de cancelación para aclarar las consecuencias de los cambios.`,
  CANCELATION_POLICY_PLACEHOLDER: `Texto de la política de cancelación`,
});

export const BookingPageHowStep = Object.freeze({
  BRANDING_TITLE: `Personalización de la página de reservas`,
  BRANDING_DESCRIPTION_BOOKING_PAGE: `Personalice la apariencia, los estilos y las etiquetas de esta página de reservas.`,
  BRANDING_DESCRIPTION_BOOKING_TEMPLATE: `Personalice la apariencia, los estilos y las etiquetas de esta plantilla de reservas.`,
  CALENDAR_TITLE: `Configuración del calendario`,
  CALENDAR_DESCRIPTION: `Personalice el formato de visualización de calendario, fecha y zona horaria.`,
});

export const BookingPageBrandingModal = Object.freeze({
  TITLE: `Personalización de la página de reservas`,
  DESCRIPTION_BOOKING_PAGE: `Esta página de reservas utiliza la configuración de marca predeterminada del espacio de trabajo, pero puede personalizarlas a continuación.`,
  DESCRIPTION_BOOKING_TEMPLATE: `Esta plantilla de reservas utiliza la configuración de marca predeterminada del espacio de trabajo, pero puede personalizarlas a continuación.`,
  LOGO: `Logo`,
  BACKGROUND: `Fondo`,
  BACKGROUND_OPTION_BLANK: `En blanco`,
  BACKGROUND_OPTION_WALLPAPER: `Fondo de pantalla`,
  BACKGROUND_OPTION_Color: `Color`,
  BACKGROUND_TYPE: `Tipo de fondo`,
  BACKGROUND_TYPE_TOP: `Banner`,
  BACKGROUND_TYPE_FULL: `Página completa`,
  BACKGROUND_TYPE_LEFT: `Lado izquierdo`,
  BACKGROUND_TYPE_RIGHT: `Lado derecho`,
  BACKGROUND_COLOR: `Color de fondo`,
  MAIN_COLOR: `Color principal`,
  FONT_COLOR: `Color de fuente`,
  CUSTOM_CSS: `CSS personalizado`,
  CUSTOM_CSS_PLACEHOLDER: `Puede agregar código CSS personalizado`,
  FOOTER_HTML: `HTML del pie de página`,
  FOOTER_HTML_PLACEHOLDER: `Puede agregar HTML de pie de página o texto simple`,
  RESET_DEFAULT_STYLES: `Restablecer a los estilos predeterminados`,
  STEP_JOURNEY: `Viaje`,
  STEP_BOOKING_PAGE: `Página de reservas`,
  STEP_BOOKING_FORM: `Formulario de reserva`,
  STEP_BOOKED: `Reservado`,
  STEP_RESCHEDULED: `Reprogramado`,
  STEP_CANCELED: `Cancelado`,
  STEP_CANCEL: `Cancelar`,
});

export const BookingPageCalendarModal = Object.freeze({
  TITLE: `Configuración del calendario`,
  TIME_FORMAT: `Formato de hora`,
  HOUR_12: `12 horas`,
  HOUR_12_EXAMPLE: `1:00 p. m.`,
  HOUR_24: `24 horas`,
  HOUR_24_EXAMPLE: `13:00`,
  START_TIME_INTERVALS: `Intervalos de tiempo`,
  START_TIME_INTERVALS_DESCRIPTION: `Seleccione la duración del intervalo que desea mostrar al programar una cita.`,
  START_TIME_INTERVALS_TOOLTIP: `Elija la duración de los intervalos que desea mostrar al programar una cita. De forma predeterminada, tenemos seleccionados 15 minutos para maximizar la disponibilidad de espacios. Esta opción mostrará los espacios disponibles en este intervalo.`,
  INTERVALS_15_MIN: `15 min`,
  INTERVALS_20_MIN: `20 min`,
  INTERVALS_30_MIN: `30 min`,
  INTERVALS_60_MIN: `60 min`,
  TOP_OF_THE_INTERVAL: `Comience al principio del intervalo`,
  TOP_OF_THE_INTERVAL_DESCRIPTION: `Habilite esto para comenzar todas las citas al principio de cada intervalo (por ejemplo, 8 a. m., 9 a. m.)`,
  TOP_OF_THE_INTERVAL_TOOLTIP: `Habilite "Comience al principio del intervalo" si necesita que todas las citas comiencen siempre al principio del intervalo, independientemente de la duración de la plantilla de reserva. Por ejemplo, las duraciones de los espacios estarían disponibles a las 8:00 a. m., 9:00 a. m., 10:00 a. m., etc. NOTA: Habilitar esta función no permitirá al usuario maximizar la disponibilidad.`,
  TIME_ZONES: `Zonas horarias`,
  TIME_ZONES_TOOLTIP: `Las siguientes zonas horarias estarán disponibles en la página de reservas orientada al cliente. Si solo elige una zona horaria, se fijará, por lo que el campo de zona horaria se desactivará y el usuario no podrá cambiarlo.`,
  TIME_ZONE_DETECT: `Detectar automáticamente y mostrar las franjas horarias en la zona horaria del invitado.`,
  TIME_ZONE_DISPLAY: `Muestre solo las siguientes zonas horarias (ideal para eventos en persona)`,
  DEFAULT_TIME_ZONE: `Zona horaria predeterminada`,
});

export const BookingPageAddToWebsite = Object.freeze({
  BOTTOM_LEFT: `Abajo a la izquierda`,
  BOTTOM_RIGHT: `Abajo a la derecha`,
  BUTTON_POPUP_TITLE: `Botón`,
  BUTTON_POPUP_LABEL: `Programar una reunión`,
  COPY_CODE_EMBED_DESCRIPTION: `Incruste su widget SUMO en su sitio web pegando el código HTML en el lugar deseado.`,
  COPY_CODE_LINK_DESCRIPTION: `Muestre un botón de reserva flotante en las páginas que desee colocando este código en su HTML.`,
  COPY_CODE_POPUP_DESCRIPTION: `Muestre su enlace de reserva en cualquier lugar colocando este código HTML en sus páginas, firmas de correo electrónico y más.`,
  COPY_CODE_TITLE: `Copiar Código`,
  CUSTOMIZE_DESCRIPTION: `Personalice la apariencia para que se ajuste perfectamente a su sitio web.`,
  CUSTOMIZE_TITLE: `Personalizar`,
  EMBED_DESCRIPTION: `Muestre su página de reserva SUMO1 en un iFrame en su sitio web.`,
  EMBED_TITLE: `Incrustar`,
  FLOATING_BUTTON_DESCRIPTION: `Botón flotante`,
  HIDE_PAGE_DETAILS: `Ocultar detalles de la página`,
  HIDE_COOKIE_BANNER: `Ocultar banner de cookies`,
  LABEL: `Etiqueta`,
  LINK_DESCRIPTION: `Agregue un enlace de texto a su sitio que lanzará su página de reserva SUMO1 en un popup.`,
  LINK_TITLE: `Enlace de Texto`,
  POSITION: `Posición`,
  POPUP_DESCRIPTION: `Muestre su página de reserva SUMO1 en un popup cuando se haga clic en un botón.`,
  POPUP_TITLE: `Popup`,
  TITLE: `¿Cómo agregará SUMO a su sitio web?`,
  TOP_LEFT: `Arriba a la izquierda`,
  TOP_RIGHT: `Arriba a la derecha`,
});

export const BookingPagesOverrideModal = Object.freeze({
  TITLE: `Confirmar Sobrescritura`,
  DESCRIPTION: `Al sobrescribir los ajustes de una plantilla de reserva, técnicamente ya no está utilizando esta plantilla de reserva y, en su lugar, está creando una Página de Reserva Personalizada, que requiere un nombre.`,
});
