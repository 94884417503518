import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './i18n/i18n';
import './i18n/calendarLocales';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { store } from './store/rootStore';
import awsConfig from './aws-exports';

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './design/rainbow.css';
import './design/rainbowCustom.css';
import './design/rainbowSpacing.css';
import './design/rainbowUtils.css';
import './design/rainbowMobile.css';
import './design/rainbowOPSConsole.css';
import { SessionProvider } from './hooks/useSession';

const publicApi = awsConfig.aws_cloud_logic_custom.find((api) => api.name === 'publicApi');
if (publicApi && (process.env.REACT_APP_API_URL || '').trim()) {
  publicApi.endpoint = process.env.REACT_APP_API_URL || '';
}

Amplify.configure({ ...awsConfig, aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS' });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AmplifyProvider>
        <Authenticator.Provider>
          <ReduxProvider store={store}>
            <SessionProvider>
              <App />
            </SessionProvider>
          </ReduxProvider>
        </Authenticator.Provider>
      </AmplifyProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
