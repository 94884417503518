import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import labels from './labels';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useRef, useState } from 'react';
import { inviteUsersModalActions, inviteUsersModalSelectors } from '../../../store/users/modal';
import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions } from '../../../store/notifications';
import {
  DEFAULT_INVITE_DATA,
  INVITE_SEND_STATUS,
  INVITE_USERS_EMAIL_INVALID,
  InviteUsersTemplateLabels,
  potentialExpirationDays,
  defaultExpirationDays,
} from '../../../store/users/constants';
import * as Papa from 'papaparse';
import { FileUpload } from 'primereact/fileupload';
import { UserRecordTemplate } from '../../../store/users/types';
import { InviteDialogOptions } from './InviteDialogOptions';
import { validateEmails } from '../../../services/EmailService';
import { usersActions, usersSelectors } from '../../../store/users';
import { CreateAdminDataInput } from '../../../API';
import InviteUsersTemplate from '../../../templates/InviteUsersTemplate.csv';
import { authenticationSelectors } from '../../../store/authentication';
import { userSettingsSelectors } from '../../../store/userSettings/selectors';
import { rolesSelectors } from '../../../store/roles';
import { MAX_LENGTH_ABOUT_150_WORDS } from '../../../types/constants';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { FileCard } from '../../common';

export const InviteDialog = () => {
  const dispatch = useDispatch();
  const open = useSelector(inviteUsersModalSelectors.selectIsModalOpen);
  const emailList = useSelector(usersSelectors.selectUsersEmails);
  const userWorkspace = useSelector(authenticationSelectors.selectWorkspaceId);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const standardUserRoleId = useSelector(rolesSelectors.selectStandardUserId);
  const roles = useSelector(userSettingsSelectors.selectSupportedRoles);
  const supportedRolesName = useSelector(userSettingsSelectors.selectSupportedRolesName);
  const remainingLicenses = useSelector(authenticationSelectors.selectRemainingLicenses);

  const wasOpen = useRef<boolean>();
  const csvRef = useRef<FileUpload>(null);

  const [formData, setFormData] = useState(DEFAULT_INVITE_DATA);
  const [inviteUsersData, setInviteUsersData] = useState([DEFAULT_INVITE_DATA]);
  const [expirationInviteDays, setExpirationInviteDays] = useState(defaultExpirationDays);
  const [activeTabIndex, setActiveTabIndex] = useState(0); // index of selected Tab: 0 - Emails, 1 - CSV
  const [isConfirm, setIsConfirm] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [alreadyInvited, setAlreadyInvited] = useState<string[]>([]);

  useEffect(() => {
    setIsConfirm(false); // TODO: if initial state is false why we need this?
  }, []);

  useEffect(() => {
    if (open && wasOpen.current !== open) {
      setIsConfirm(false);
      setFormData({ ...DEFAULT_INVITE_DATA, roleId: standardUserRoleId });
      handleFileRemove();
    }
    wasOpen.current = open;
  }, [open]);

  const getRoleIdByName = (name: string) => {
    return roles.find((role) => role.name === name)?.id || '';
  };

  const getRoleNameById = (id: string) => {
    return roles.find((role) => role.id === id)?.name || '';
  };

  const filterUniqueEmails = (email: string, uniqueEmailsList: Set<string>) => {
    if (email.length > 0 && !uniqueEmailsList.has(email)) {
      if (emailList.includes(email)) {
        setAlreadyInvited((prev) => (prev.includes(email) ? prev : [...prev, email]));
      } else {
        uniqueEmailsList.add(email);
        return true;
      }
    }
    return false;
  };

  const createUserInviteRecordsFormData = () => {
    const uniqueEmailsList = new Set<string>();
    setAlreadyInvited([]);
    const emailArr = formData.email
      .split(',')
      .map((el) => el.trim().toLowerCase())
      .filter((el) => filterUniqueEmails(el, uniqueEmailsList));

    if (validateEmails(emailArr)) {
      const records = emailArr.map(
        (email) =>
          ({
            ...formData,
            email,
            status: INVITE_SEND_STATUS,
          } as CreateAdminDataInput)
      );
      setInviteUsersData(records);
      setIsConfirm(true);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch<any>(notificationsActions.showToast(INVITE_USERS_EMAIL_INVALID)); // TODO: replace any
    }
  };

  const createUserInviteRecordsCSVFile = () => {
    if (uploadedFile) {
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string, index: number) => InviteUsersTemplateLabels[index],
        complete: function (results: Papa.ParseResult<UserRecordTemplate>) {
          const emails = results.data.map((el) => el.email);
          if (validateEmails(emails)) {
            const uniqueEmailsList = new Set<string>();
            setAlreadyInvited([]);
            const uniqueData = results.data.filter(
              (el) =>
                filterUniqueEmails(el.email.toLowerCase(), uniqueEmailsList) && supportedRolesName.includes(el.role)
            );
            const records = uniqueData.map(
              (record) =>
                ({
                  ...record,
                  email: record.email.toLowerCase(),
                  team: record.team ? record.team.split(';') : [],
                  workspaceIds: [userWorkspace],
                  bookingTemplateIds: [],
                  tenantId: '',
                  status: INVITE_SEND_STATUS,
                  roleId: getRoleIdByName(record.role),
                } as CreateAdminDataInput)
            );
            setInviteUsersData(records);
            setIsConfirm(true);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            dispatch<any>(notificationsActions.showToast(INVITE_USERS_EMAIL_INVALID)); // TODO: replace any
          }
        },
        error: (error: Error, file: File) => {
          console.error(`Parsing file ${file.name} failed. Error: ${error.message}`);
        },
      });
    }
  };

  const handleFileRemove = () => {
    setUploadedFile(null);
  };

  const handleFileupload = (file: File | null) => {
    setUploadedFile(file);
    csvRef.current && csvRef.current.clear();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleExpirationDateChange = (event: React.ChangeEvent<HTMLTextAreaElement> | DropdownChangeEvent) => {
    const { value } = event.target;
    setExpirationInviteDays(value);
  };

  const handleClose = () => {
    dispatch(inviteUsersModalActions.closeModal());
  };

  const handleNext = () => {
    activeTabIndex === 0 ? createUserInviteRecordsFormData() : createUserInviteRecordsCSVFile();
  };

  const sendInvite = () => {
    dispatch(usersActions.inviteUsersRequest(inviteUsersData, expirationInviteDays));
    dispatch(inviteUsersModalActions.closeModal());
  };

  const dialogFooter = () => {
    return (
      <>
        <Button
          label={labels.next}
          className="min-w-120px"
          disabled={
            activeTabIndex === 0
              ? formData.email.length === 0 || !formData.workspaceIds?.length || !formData.roleId
              : !uploadedFile
          }
          onClick={handleNext}
        />
        <Button label={labels.cancel} text onClick={handleClose} />
      </>
    );
  };

  const confirmFooter = () => {
    const userNum = inviteUsersData.length;
    return (
      <>
        <Button
          label={`${labels.invite} ${userNum} ${userNum === 1 ? labels.user : labels.users}`}
          disabled={userNum < 1 || remainingLicenses - userNum < 0}
          onClick={sendInvite}
          tooltip={remainingLicenses - userNum < 0 ? labels.remainingMessage : ''}
          tooltipOptions={{ position: 'top', showOnDisabled: true }}
        />
        <Button label={labels.back} text onClick={() => setIsConfirm(false)} />
      </>
    );
  };

  return (
    <>
      {isConfirm ? (
        <Dialog
          header={labels.inviteTitle}
          footer={confirmFooter}
          visible={open}
          onHide={handleClose}
          className="w-840px"
          focusOnShow={false}
          draggable={false}
        >
          <div className="flex flex-column gap-32px">
            <div className="flex flex-column gap-16px">
              <div className="text-title-xs-med">{labels.confirmDialogDesc}</div>
              <div className="flex-bottom gap-40px">
                <div className="flex flex-column gap-24px">
                  <div className="flex flex-column gap-8px">
                    <div className="text-body-lg-reg text-heavy-60">{labels.userCount}:</div>
                    <div className="text-title-lg-med">{inviteUsersData.length}</div>
                  </div>
                  {activeTabIndex === 0 && (
                    <div className="flex flex-column gap-8px">
                      <div className="text-body-lg-reg text-heavy-60">{labels.role}:</div>
                      <div className="text-title-lg-med">{getRoleNameById(formData.roleId)}</div>
                    </div>
                  )}
                </div>
                <div className="flex flex-column gap-24px">
                  <div className="flex flex-column gap-8px">
                    <div className="text-body-lg-reg text-heavy-60">{labels.alreadyInvitedCount}:</div>
                    <div className="text-title-lg-med">{alreadyInvited.length}</div>
                  </div>
                  {activeTabIndex === 0 && (
                    <div className="flex flex-column gap-8px">
                      <div className="text-body-lg-reg text-heavy-60">{labels.team}:</div>
                      <div className="text-title-lg-med">{formData.team?.length ? formData.team?.length : 0}</div>
                    </div>
                  )}
                </div>
                {activeTabIndex === 0 && userWorkspaces?.length > 1 && (
                  <div className="flex flex-column gap-8px">
                    <div className="text-body-lg-reg text-heavy-60">{labels.assignWorkspace}:</div>
                    <div className="text-title-lg-med">{formData.workspaceIds?.length}</div>
                  </div>
                )}
                {activeTabIndex === 0 && (
                  <div className="flex flex-column gap-8px">
                    <div className="text-body-lg-reg text-heavy-60">{labels.bookingTemplate}:</div>
                    <div className="text-title-lg-med">
                      {formData.bookingTemplateIds?.length ? formData.bookingTemplateIds?.length : 0}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="border-top-1 border-heavy-20" />

            <div className="flex flex-column gap-10px">
              <div className="text-title-xs-med mt-10px">{labels.expirationDate}:</div>
              <Dropdown
                id="expirationDate"
                name="expirationDate"
                value={expirationInviteDays}
                options={[
                  { name: labels.days_30, id: potentialExpirationDays.days_30 },
                  { name: labels.days_20, id: potentialExpirationDays.days_20 },
                  { name: labels.days_15, id: potentialExpirationDays.days_15 },
                  { name: labels.days_10, id: potentialExpirationDays.days_10 },
                ]}
                optionLabel="name"
                optionValue="id"
                onChange={handleExpirationDateChange}
                className={`w-200px ${!expirationInviteDays && 'p-invalid'}`}
              />
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          header={labels.inviteTitle}
          footer={dialogFooter}
          visible={open}
          onHide={handleClose}
          className="w-840px"
          focusOnShow={false}
          draggable={false}
        >
          <TabView
            className="-mt-14px -mx-20px"
            panelContainerClassName="px-20px pt-20px"
            activeIndex={activeTabIndex}
            onTabChange={(e) => setActiveTabIndex(e.index)}
          >
            <TabPanel header={labels.pasteEmailsTitle}>
              <div className="flex gap-40px">
                <div className="flex-1 flex flex-column gap-16px">
                  <div className="flex flex-column gap-4px">
                    <div className="text-title-xs-med">{labels.inviteMessageTitle}</div>
                    <div className="text-body-s-reg text-heavy-60">{labels.inviteMessageDesc}</div>
                  </div>
                  <InputTextarea
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    rows={10}
                    cols={30}
                    className={`${formData.email.length === 0 && 'p-invalid'}`}
                    maxLength={MAX_LENGTH_ABOUT_150_WORDS}
                    autoResize
                  />
                  {formData.email.length === 0 && (
                    <div className="text-body-s-reg text-tomato-main">{labels.noEmail}</div>
                  )}
                </div>
                {activeTabIndex === 0 && (
                  <>
                    <div className="flex-none w-1px bg-heavy-20" />
                    <div className="w-280px">
                      <InviteDialogOptions formData={formData} setFormData={setFormData} handleChange={handleChange} />
                    </div>
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel header={labels.CSVUploadTitle}>
              <div className="flex flex-column gap-24px">
                <div className="flex flex-column gap-4px">
                  <div className="text-title-xs-med">{labels.CSVInviteMessage}: </div>
                  <div className="text-body-s-reg text-heavy-60">
                    <a href={InviteUsersTemplate} download="InviteUsersTemplate">
                      {labels.CSVTemplateTitle}
                    </a>
                    <span>{` ${labels.CSVTemplateDesc}`}</span>
                  </div>
                </div>

                <div className="flex flex-column gap-16px">
                  <div
                    className="flex-center py-60px border-radius-10px bg-heavy-1 hover-bg-heavy-10 cursor-pointer"
                    onClick={() => {
                      csvRef.current?.getInput().click();
                    }}
                  >
                    <FileUpload
                      ref={csvRef}
                      mode="basic"
                      accept=".csv"
                      auto
                      chooseLabel={labels.upload}
                      customUpload
                      uploadHandler={(e) => handleFileupload(e.files[0])}
                      className="hidden"
                    />
                    <div className="flex-left-center gap-8px">
                      <CloudArrowUpIcon className="icon-48px text-heavy-60" />
                      <div className="w-fit text-label-xs-reg text-blue-main">{labels.CSVUploadDesc}</div>
                    </div>
                  </div>

                  {uploadedFile && (
                    <div className="w-6 pr-20px">
                      <FileCard file={uploadedFile} onRemove={handleFileRemove} />
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
          </TabView>
        </Dialog>
      )}
    </>
  );
};
