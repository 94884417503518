import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scheduledMeetingsActions } from '../../../store/bookedMeetings/actions';
import { editMeetingNotesModalActions } from '../../../store/bookedMeetings/modal';
import { scheduledMeetingsSelectors } from '../../../store/bookedMeetings/selectors';
import { ModalName } from '../../../store/modals';
import { Modal, TextEditor } from '../../common';
import labels from './labels';

export const EditBookedMeetingNotesModal = () => {
  const dispatch = useDispatch();
  const event = useSelector(scheduledMeetingsSelectors.selectSelectedEvent);
  const [localNotes, setLocalNotes] = useState(event?.internalNotes || '');

  useEffect(() => {
    setLocalNotes(event?.internalNotes || '');
  }, [event]);

  const closeEditBookedMeetingNotesModal = () => {
    dispatch(editMeetingNotesModalActions.closeModal());
  };

  const saveEditMeetingNotes = () => {
    dispatch(
      scheduledMeetingsActions.updateBookedMeetingRequest({
        ...event,
        internalNotes: localNotes,
        editMeetingNotes: true,
      })
    );
  };

  return (
    <Modal.Container name={ModalName.EDIT_MEETING_NOTES_MODAL_NAME} closable className="w-700px">
      <Modal.Header>{labels.internalNote}</Modal.Header>

      <div className="text-body-s-reg pb-20px">{labels.internalNoteDescription}</div>
      <TextEditor value={localNotes} setValue={setLocalNotes} style={{ height: '400px' }} />

      <Modal.Buttons>
        <Button label={labels.save} onClick={saveEditMeetingNotes} />
        <Button label={labels.close} text onClick={closeEditBookedMeetingNotesModal} />
      </Modal.Buttons>
    </Modal.Container>
  );
};
