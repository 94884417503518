import { useDispatch, useSelector } from 'react-redux';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Search } from '../../common';
import { eventActions, eventSelectors } from '../../../store/publicBookingPage';
import { globalActions } from '../../../store/global';
import { getFullAddress, isEqualAddressNoMap } from '../../../store/locations';
import { Path } from '../../../routing';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { LocationType, PhysicalLocation } from '../../../API';
import labels from './labels';
import { ScrollPanel } from 'primereact/scrollpanel';

export const PublicBookingPageLocationsNoMap = () => {
  const dispatch = useDispatch();
  const locationSettings = useSelector(eventSelectors.selectLocationSettings);
  const address = useSelector(eventSelectors.selectLocationAddress);
  const defaultLocationType = useSelector(eventSelectors.selectDefaultLocationType);

  const [localLocationSettings, setLocalLocationSettings] = useState(locationSettings);
  const [localAddress, setLocalAddress] = useState(address);
  const locations = useSelector(eventSelectors.selectSearchedLocations);

  const handleSelectLocation = (location: PhysicalLocation) => {
    // save location name if street name is empty
    setLocalAddress({
      ...location.address,
      name: location.address?.name?.trim() ? location.address?.name : location.name,
    });
    setLocalLocationSettings(location.id);
  };

  const handleSave = () => {
    dispatch(eventActions.updateLocation({
      address: localAddress,
      settings: localLocationSettings
    }));
    dispatch(eventActions.getAgendaRequest());
    dispatch(eventActions.setIsLocationOpened(false));
    dispatch(globalActions.resetSearch());
  };

  const handleCancel = () => {
    if (!address && defaultLocationType && defaultLocationType !== LocationType.IN_PERSON) {
      dispatch(eventActions.updateLocation({ type: defaultLocationType }));
      dispatch(eventActions.getAgendaRequest());
    }
    dispatch(eventActions.setIsLocationOpened(false));
    dispatch(globalActions.resetSearch());
  };

  return (
    <div className="flex flex-column h-full">
      <div className="location-search-container">
        <Search
          placeholder={labels.locationsPlaceholder}
          maxTextLength={MAX_LENGTH_NAME}
          paths={[Path.PublicBookingPage, Path.PublicJourneyPage, Path.PublicGroupBookingPage]}
          openWidth="w-full"
        />
      </div>
      <div className="flex-1 overflow-hidden">
        <ScrollPanel>
          <div className="location-cards-container">
            <div className="flex flex-wrap -m-8px">
              {locations.map((location) => (
                <div key={location.id} className="w-12 md:w-4 p-8px h-auto">
                  <div
                    className={`location-card ${
                      isEqualAddressNoMap(location, localAddress) ? 'location-card-selected' : ''
                    }`}
                    onClick={() => handleSelectLocation(location)}
                  >
                    <div className="flex flex-column gap-4px">
                      <div className="text-label-s-med text-heavy-80">{location.name || ''}</div>
                      <div className="text-label-xs-reg text-heavy-60">{getFullAddress(location.address)}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="footer-space" />
        </ScrollPanel>
      </div>
      <div className="locations-buttons">
        <Button label={labels.save} className="min-w-120px button-xl" onClick={handleSave} disabled={!localAddress} />
        <Button label={labels.cancel} className="button-xl" onClick={handleCancel} text />
      </div>
    </div>
  );
};
