import { CHARGEBEE_MONTHLY_TERM, SubscribeCustomerInfo } from '../store/billing';
import { handleAPIRequest } from '../store/utils/reduxUtils';
import { API_PUBLIC } from '../types/constants';

declare global {
  const Chargebee: any;
}

const getCBInstance = () => {
  return Chargebee.inited
    ? Chargebee.getInstance()
    : Chargebee.init({
        site: process.env.REACT_APP_CHARGEBEE_SITE,
        publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLIC_KEY,
        isItemsModel: true,
      });
};

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const openPortal = async (chargeBeeCheckoutClose: () => void, openPaymentMethods = false) => {
  const cbInstance = getCBInstance();
  const cbPortal = cbInstance.createChargebeePortal();

  cbInstance.setPortalSession(() => handleAPIRequest(`${API_PUBLIC}/billing/createPortalSession`, {}));

  if (openPaymentMethods) {
    cbPortal.openSection(
      {
        sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
      },
      {
        close: () => {
          chargeBeeCheckoutClose();
        },
      }
    );
  } else {
    cbInstance.setPortalCallbacks({
      close: () => {
        chargeBeeCheckoutClose();
      },
    });
    cbPortal.open();
  }
};

export const subscribe = (
  quantity = 10,
  term = CHARGEBEE_MONTHLY_TERM,
  customerInfo: SubscribeCustomerInfo,
  tenantId: string,
  chargeBeeCheckoutSuccess: () => void
) => {
  const cbInstance = getCBInstance();
  const cart = cbInstance.getCart();
  const product = cbInstance.initializeProduct(term);
  product.setPlanQuantity(quantity);
  product.setCustomData({
    cf_tenantId: tenantId,
  });
  cart.replaceProduct(product);
  if (customerInfo) cart.setCustomer(customerInfo);

  // Callback API for ChargeBee Checkout Popup: https://www.chargebee.com/checkout-portal-docs/cbinstanceobj-api-ref.html#checkout-and-portal-integration
  cbInstance.setCheckoutCallbacks(function () {
    return {
      success: function () {
        chargeBeeCheckoutSuccess();
      },
    };
  });

  cart.proceedToCheckout();
};

/**
 * @see <a href="https://www.chargebee.com/checkout-portal-docs/feature-embedded-checkout.html">ChargeBee method documentation</a>
 */
export const checkoutInIframe = async ({
  quantity,
  term,
  customerInfo,
  tenantId,
  iframeId = 'checkoutIframe',
  abortController,
  successCallback,
  loadedCallback,
  resizeCallback,
  stepChangedCallback,
}: {
  quantity: number;
  term: string;
  customerInfo: SubscribeCustomerInfo;
  tenantId: string;
  iframeId?: string;
  abortController?: AbortController;
  successCallback: () => void;
  loadedCallback: () => void;
  resizeCallback: (height: number) => void;
  stepChangedCallback: (stepName: string) => void;
}) => {
  try {
    const cbInstance = getCBInstance();
    const cart = cbInstance.getCart();

    if (customerInfo) {
      cart.setCustomer(customerInfo);
    }

    const product = cbInstance.initializeProduct(term, quantity);
    product.isItemsModel = true;
    product.setLayout('full_page');
    product.setCustomData({
      cf_tenantId: tenantId,
    });

    cart.replaceProduct(product);

    const checkout = await getCBInstance().createCheckout({
      cart,
      callbacks: {
        loaded: () => {
          loadedCallback();
        },
        error: (error: any) => {
          console.error('Error during checkout:', error);
        },
        success: () => {
          successCallback();
        },
        resize: (value: number) => {
          resizeCallback(value);
        },
        step: (stepName: string) => {
          stepChangedCallback(stepName);
        },
      },
    });

    checkout.mount(`#${iframeId}`);

    if (abortController) {
      abortController.signal.addEventListener('abort', () => {
        checkout.close();
      });
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};
