import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { CanceledType, LocationType } from '../../API';
import { MeetingFilterType, SortMethod, MeetingStatus } from './types';
import { SelectItemOptionsType } from 'primereact/selectitem';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';

export const editorTextRegex = /(<([^>]+)>)/gi;
export const statusList = [
  { label: t('BookedMeetings:BOOKED'), value: MeetingStatus.BOOKED },
  { label: t('BookedMeetings:CANCELED'), value: MeetingStatus.CANCELED },
];

export const SORT_OPTIONS = [
  { value: SortMethod.UPCOMING, label: t('BookedMeetings:UPCOMING') },
  { value: SortMethod.PAST, label: t('BookedMeetings:PAST') },
  { value: SortMethod.DATE_RANGE, label: t('Common:DATE_RANGE') },
];

export const DEFAULT_FILTER: MeetingFilterType = {
  workspaceIds: [],
  bookingPageIds: [],
  statuses: [MeetingStatus.BOOKED, MeetingStatus.CANCELED],
  sortMethod: SortMethod.UPCOMING,
  dateRange: [],
};

export const locationLabels: { [key: string]: string } = {
  [LocationType.IN_PERSON]: t('BookedMeetings:IN_PERSON'),
  [LocationType.PHONE_CALL]: t('BookedMeetings:PHONE_CALL'),
  [LocationType.VIDEO_CONFERENCE]: t('BookedMeetings:VIDEO_CONFERENCE'),
};

export const cancelReasons = {
  [CanceledType.UNEXPECTED]: t('BookedMeetings:REASON_UNEXPECTED'),
  [CanceledType.INVITE_OTHERS]: t('BookedMeetings:REASON_INVITE'),
  [CanceledType.TIME]: t('BookedMeetings:REASON_TIME'),
  [CanceledType.OTHER]: t('BookedMeetings:REASON_OTHER'),
};

export const CANCEL_TYPE_OPTIONS: SelectItemOptionsType = [
  { value: CanceledType.UNEXPECTED, label: t('BookedMeetings:REASON_UNEXPECTED') },
  { value: CanceledType.INVITE_OTHERS, label: t('BookedMeetings:REASON_INVITE') },
  { value: CanceledType.TIME, label: t('BookedMeetings:REASON_TIME') },
  { value: CanceledType.OTHER, label: t('BookedMeetings:REASON_OTHER') },
];

export const GET_SCHEDULED_MEETINGS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookedMeetingsToastsNotifications:GET_SCHEDULED_MEETINGS_ERROR_TOAST'),
});

export const CANCEL_EVENT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BookedMeetingsToastsNotifications:CANCEL_SCHEDULED_MEETING_SUCCESS_MESSAGE'),
});
export const CANCEL_EVENT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookedMeetingsToastsNotifications:CANCEL_SCHEDULED_MEETING_ERROR_MESSAGE'),
});

export const UPDATE_SCHEDULED_MEETING_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BookedMeetingsToastsNotifications:UPDATE_SCHEDULED_MEETING_ERROR_TOAST'),
});
