import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../../../store/modals';
import { expireTrialModalActions, orgsActions } from '../../../../store/opsConsole/orgs';
import { OPSConsoleUsersSelectors } from '../../../../store/opsConsole/users';
import { Modal } from '../../../common';
import labels from './labels';

type OrgExpireTrialModalProps = {
  isFromDetails?: boolean;
};

export const OrgExpireTrialModal = ({ isFromDetails }: OrgExpireTrialModalProps) => {
  const dispatch = useDispatch();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const superAdminsEmails = useSelector(OPSConsoleUsersSelectors.selectSuperAdminsEmails);

  const handleClose = () => {
    dispatch(expireTrialModalActions.closeModal());
    setIsConfirmed(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = () => {
    dispatch(orgsActions.expireTrialRequest({ isFromDetails, superAdminsEmails }));
    handleClose();
  };

  return (
    <Modal.Container name={ModalName.EXPIRE_TRIAL_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem">
        <div className="w-full py-2 px-3 mb-2 uppercase sumo-header-bg-dark">{labels.rules}</div>
        <ul>
          {labels.rulesDesc.map((desc, index) => (
            <li key={`rules-${index}`}>{desc}</li>
          ))}
        </ul>
        <div className="w-full py-2 px-3 mb-2 uppercase sumo-header-bg-dark">{labels.expire}</div>
        <div className="flex flex-row align-items-center justify-content-center gap-3">
          <div className="text-lg font-bold">{labels.expireDesc}</div>
          <InputSwitch checked={isConfirmed} onChange={(e) => setIsConfirmed(!!e.value)} />
        </div>
      </div>
      <Modal.Buttons>
        <Button className="ml-auto" label={labels.cancel} onClick={handleCancel} outlined autoFocus />
        <Button label={labels.expireNow} onClick={handleSave} disabled={!isConfirmed} />
      </Modal.Buttons>
    </Modal.Container>
  );
};
