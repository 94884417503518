import { SelectItem } from 'primereact/selectitem';
import { AssignmentMode } from '../../API';
import { t } from '../../i18n/i18n';
import labels from '../../pages/teams/labels';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';
import { TeamFilterType, TeamRecord } from './types';

export const GET_TEAMS_FAIL_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('TeamsToastsNotifications:GET_TEAMS_FAIL_MESSAGE'),
});

export const SAVE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('TeamsToastsNotifications:SAVE_TEAM_SUCCESS_MESSAGE'),
});
export const SAVE_TEAM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('TeamsToastsNotifications:SAVE_TEAM_ERROR_MESSAGE'),
});

export const DELETE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('TeamsToastsNotifications:DELETE_TEAM_SUCCESS_MESSAGE'),
});
export const DELETE_TEAM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('TeamsToastsNotifications:DELETE_TEAM_ERROR_MESSAGE'),
});

export const ACTIVATE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('TeamsToastsNotifications:ACTIVATE_TEAM_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_TEAM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('TeamsToastsNotifications:DEACTIVATE_TEAM_SUCCESS_MESSAGE'),
});
export const DEACTIVATE_ACTIVATE_TEAM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('TeamsToastsNotifications:DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE'),
});

export const DEFAULT_TEAM: TeamRecord = {
  active: true,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  assignmentMode: AssignmentMode.ROUND_ROBIN,
};

export const StatusOptions: SelectItem[] = [
  { value: labels.active, label: labels.active },
  { value: labels.inactive, label: labels.inactive },
];

export const DEFAULT_FILTER: TeamFilterType = {
  workspaceIds: [],
  createdBy: [],
  active: StatusOptions.map((option) => option.value),
};
