import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { MeetingStatus, SortMethod } from './types';
import dayjs from 'dayjs';
import { BookingPageOption, bookingPageSelectors } from '../bookingPages';
import { CreateUserEventInput, CustomFieldType } from '../../API';
import { isOldData } from '../../services/utils';
import { DEFAULT_FILTER } from './constants';
import { workspacesSelectors } from '../workspaces';

const scheduledMeetingsState = (state: State) => state.scheduledMeetings;

const selectEvents = createSelector(scheduledMeetingsState, (state) => state.bookedMeetings);
const selectIsFetching = createSelector(scheduledMeetingsState, (state) => state.isFetching);
const selectError = createSelector(scheduledMeetingsState, (state) => state.error);

const selectFilter = createSelector(scheduledMeetingsState, (state) => state.filter);
const selectSortMethod = createSelector(selectFilter, (state) => state.sortMethod);
const selectDateRange = createSelector(selectFilter, (state) => state.dateRange);
const selectStatuses = createSelector(selectFilter, (state) => state.statuses);
const selectSelectedBookingPages = createSelector(selectFilter, (state) => state.bookingPageIds);
const selectSelectedWorkspaces = createSelector(selectFilter, (state) => state.workspaceIds);

const selectIsDefaultFilter = createSelector(selectFilter, (filter) => filter === DEFAULT_FILTER);

const selectLastLoadTime = createSelector(scheduledMeetingsState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);
const selectIsEventsLoaded = createSelector(selectLastLoadTime, (loadTime) => loadTime > 0);

const selectFilteredEvents = createSelector(
  selectEvents,
  selectStatuses,
  selectSelectedBookingPages,
  selectSelectedWorkspaces,
  selectSortMethod,
  selectDateRange,
  (events, statuses, bookingPagesIds, workspaceIds, method, dateRange) =>
    events.filter((event) => {
      if (!bookingPagesIds?.includes(event.bookingPageId)) {
        return false;
      }
      if (!workspaceIds?.includes(event.workspaceId || '')) {
        return false;
      }
      if (
        (!statuses.includes(MeetingStatus.BOOKED) && !event.canceled?.isCanceled) ||
        (!statuses.includes(MeetingStatus.CANCELED) && !!event.canceled?.isCanceled)
      ) {
        return false;
      }

      if (method === SortMethod.UPCOMING || method === SortMethod.PAST) {
        const today = dayjs().format('YYYY-MM-DD');
        return method === SortMethod.UPCOMING ? event.eventDate >= today : event.eventDate < today;
      } else if (dateRange && dateRange.length === 2) {
        return (
          event.eventDate >= dayjs(dateRange[0]).format('YYYY-MM-DD') &&
          event.eventDate <= dayjs(dateRange[1]).format('YYYY-MM-DD')
        );
      }
      // return false;
    })
);

const selectEventsByDay = createSelector(selectFilteredEvents, (events) => {
  const eventsByDayMap: { [key: string]: CreateUserEventInput[] } = {};

  events.forEach((event) => {
    if (!eventsByDayMap[event.eventDate]) {
      eventsByDayMap[event.eventDate] = [];
    }
    eventsByDayMap[event.eventDate].push(event);
  });

  for (const day in eventsByDayMap) {
    eventsByDayMap[day].sort((aEvent, bEvent) => (aEvent.startTime > bEvent.startTime ? 1 : -1));
  }

  return eventsByDayMap;
});

const selectSelectedEvent = createSelector(scheduledMeetingsState, (state) => state.selectedBookedMeeting);
const selectPreviewBookingPage = createSelector(
  selectSelectedEvent,
  bookingPageSelectors.selectBookingPages,
  (event, bookingPages) => bookingPages.find((page) => page.id === event.bookingPageId)
);

const selectEventId = createSelector(selectSelectedEvent, (event) => event.eventId);
const selectEventCanceled = createSelector(selectSelectedEvent, (event) => event.canceled);
const selectEventCancelType = createSelector(selectEventCanceled, (canceled) => canceled?.type);
const selectEventCancelNote = createSelector(selectEventCanceled, (canceled) => canceled?.note);
const selectEventAttendeeName = createSelector(selectSelectedEvent, (event) => {
  const attendeeName = event.inputFields?.find((field) => field?.fieldType === CustomFieldType.NAME);
  return attendeeName?.value ? attendeeName.value : '';
});
const selectEventAttendeeEmail = createSelector(selectSelectedEvent, (event) => {
  const attendeeEmail = event.inputFields?.find((field) => field?.fieldType === CustomFieldType.EMAIL);
  return attendeeEmail?.value ? attendeeEmail.value : '';
});

const selectEventAttendeeInfo = createSelector(
  selectEventAttendeeEmail,
  selectEventAttendeeName,
  (email, name) => `${name} (${email})`
);

const selectHostId = createSelector(selectSelectedEvent, (event) => event.host);
const selectGuestsIds = createSelector(
  selectSelectedEvent,
  (event) => event.cohostsMembers?.map((member) => member?.id) || [] //TODO work with other members, not only SUMO
);

const selectBookingPageOptions = createSelector(
  selectEvents,
  selectIsDefaultFilter,
  selectSelectedWorkspaces,
  bookingPageSelectors.selectBookingPages,
  (events, isDefaultFilter, workspaceIds, bookingPages) => {
    const optionsMap = new Map<string, BookingPageOption>();

    bookingPages.forEach((page) => {
      if (isDefaultFilter || workspaceIds.includes(page.workspaceId || '')) {
        optionsMap.set(page.id, {
          label: page.what?.customName || '',
          value: page.id,
          displayId: page.displayId,
        } as BookingPageOption);
      }
    });

    const bookingPageOptions = Array.from(optionsMap.values()).sort((a, b) =>
      (a.label || '').localeCompare(b.label || '')
    );

    const existingIds = new Set(optionsMap.keys());

    events.forEach((event) => {
      if (
        (isDefaultFilter || workspaceIds.includes(event.workspaceId || '')) &&
        !existingIds.has(event.bookingPageId)
      ) {
        bookingPageOptions.push({
          label: event.bookingPageName,
          value: event.bookingPageId,
        });
        existingIds.add(event.bookingPageId);
      }
    });

    return bookingPageOptions;
  }
);

const selectFilteredSelectedBookingPages = createSelector(
  selectSelectedBookingPages,
  selectBookingPageOptions,
  (bookingPageIds, options) =>
    bookingPageIds.filter((bookingPageId) => options.find((option) => option.value === bookingPageId))
);

const selectIsFilterInUse = createSelector(
  workspacesSelectors.selectWorkspaceOptions,
  selectBookingPageOptions,
  selectSortMethod,
  selectDateRange,
  selectStatuses,
  selectSelectedBookingPages,
  selectSelectedWorkspaces,
  (workspaceOptions, bookingPageOptions, sortMethod, dateRange, statuses, bookingPageIds, workspaceIds) =>
    sortMethod !== DEFAULT_FILTER.sortMethod ||
    Boolean(dateRange.length) ||
    statuses.length < DEFAULT_FILTER.statuses.length ||
    workspaceIds.length < workspaceOptions.length ||
    bookingPageIds.length < bookingPageOptions.length
);

export const scheduledMeetingsSelectors = {
  selectEvents,
  selectIsFetching,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectIsEventsLoaded,
  selectError,
  selectSortMethod,
  selectDateRange,
  selectStatuses,
  selectSelectedBookingPages,
  selectSelectedWorkspaces,
  selectFilteredEvents,
  selectEventsByDay,
  selectSelectedEvent,
  selectPreviewBookingPage,
  selectEventId,
  selectEventCanceled,
  selectEventCancelType,
  selectEventCancelNote,
  selectEventAttendeeName,
  selectEventAttendeeEmail,
  selectEventAttendeeInfo,
  selectHostId,
  selectGuestsIds,
  selectBookingPageOptions,
  selectIsDefaultFilter,
  selectFilteredSelectedBookingPages,
  selectIsFilterInUse,
};
