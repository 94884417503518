export const SmartAlerts = Object.freeze({
  TITLE: `Alertas inteligentes`,
  CREATE_ALERT: `Crear alerta`,
  CREATE_CUSTOM_ALERT: `Crear alerta personalizada`,
  CLONE_ALERT_TITLE: `Nombre de la alerta inteligente clonada`,
  CLONE_TEMPLATE_TITLE: `Nombre de la plantilla de alerta inteligente clonada`,
  DIALOG_PLACEHOLDER: `Recordatorio al invitado, Enviar agradecimiento, etc...`,
  EMPTY_LIST_MESSAGE: `Cree una alerta o ajuste su búsqueda o filtros.`,
  PERSONAL_LABEL: `Personal`,
  SHARED_LABEL: `Compartido`,
  TEMPLATES_LABEL: `Plantillas`,
  HIDDEN_LABEL: `Oculto`,
  UNHIDDEN_LABEL: `Visible`,
  BASIC_ALERTS: `Alertas básicas`,
  ADD_SMART_ALERT: `+ Agregar alerta inteligente`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  BULK_DELETE_DESCRIPTION: `las alertas inteligentes seleccionadas`,
  DELETE_WARNING_ONE: `Advertencia: Eliminar esta Alerta Inteligente también la eliminará de las páginas de reserva y plantillas de reserva donde se usa.`,
  DELETE_WARNING_MULTIPLE: `Advertencia: Eliminar estas Alertas Inteligentes también las eliminará de las páginas de reserva y plantillas de reserva donde se usan.`,
  HIDE_SYSTEM_TEMPLATES: `Ocultar plantillas del sistema`,
});

export const SmartAlertsToastsNotifications = Object.freeze({
  GET_SMART_TYPES_ERROR_MESSAGE: `Error al obtener las alertas inteligentes`,
  CLONE_SMART_TYPE_ERROR_MESSAGE: `Error al clonar las alertas inteligentes`,
  CLONE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente clonada con éxito`,
  CLONE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de alerta inteligente clonada con éxito`,
  DELETE_SMART_TYPES_SUCCESS_MESSAGE: `Alertas inteligentes eliminadas con éxito`,
  DELETE_SMART_TYPES_ERROR_MESSAGE: `Error al eliminar las alertas inteligentes`,
  GET_SMART_ALERT_ERROR_MESSAGE: `Error al obtener la alerta inteligente`,
  GET_SMART_TEMPLATE_ERROR_MESSAGE: `Error al obtener la plantilla de alerta inteligente`,
  UPSERT_SMART_TYPE_ERROR_MESSAGE: `Error al insertar o actualizar las alertas inteligentes`,
  CREATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente creada con éxito`,
  CREATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de alerta inteligente creada con éxito`,
  UPDATE_SMART_ALERT_SUCCESS_MESSAGE: `Alerta inteligente actualizada con éxito`,
  UPDATE_SMART_TEMPLATE_SUCCESS_MESSAGE: `Plantilla de alerta inteligente actualizada con éxito`,
});

export const EditSmartAlert = Object.freeze({
  LOCKED_SYSTEM_TEMPLATE: `Bloqueado - Plantilla del sistema`,
  CREATE_ALERT_TITLE: `Crear alerta`,
  CREATE_TEMPLATE_TITLE: `Crear plantilla`,
  CREATE_CUSTOM_TITLE: `+ Crear su alerta personalizada`,
  DESCRIPTION: `Cree su alerta personalizada o use plantillas para una rápida creación de alertas`,
  EDIT_ALERT_TITLE: `Editar alerta`,
  EDIT_TEMPLATE_TITLE: `Editar plantilla`,
  ICON: `Icono`,
  NAME: `Nombre`,
  NAME_PLACEHOLDER: `Ingrese el nombre de la alerta`,
  HIDE: `Ocultar`,
  HIDE_DESCRIPTION: `No mostrar en la lista de Smart Alerts`,
  ALERT: `Alerta`,
  TEMPLATE: `Plantilla`,
  APPLY_TO: `Aplicar a`,
  SHARE_WITH: `Compartir con`,
  WORKSPACES: `Espacios de trabajo`,
  WHEN_TITLE: `Cuando esto suceda`,
  DO_THIS: `Hacer esto`,
  PERSONALIZE: `Personalizar`,
  ADD_VARIABLE: `Agregar variable`,
  SUBJECT: `Asunto`,
  BODY: `Cuerpo`,
  USE_TEMPLATE: `Usar plantilla`,
  HOW_LONG_AFTER: `Cuánto tiempo después`,
  IMMEDIATELY: `Inmediatamente`,
  CUSTOM_NAME: `Nombre personalizado`,
  TEMPLATE_DESCRIPTION_PLACEHOLDER: `Ingrese la descripción de la plantilla inteligente`,
  USE_ALERT: `Usar alerta`,
  SELECT_SMART_TITLE: `Seleccionar alerta`,
  SELECT_ALERT_DESCRIPTION: `Seleccione una alerta inteligente o cree la suya propia`,
  BOOKING_PAGES: `Páginas de reserva`,
  BOOKING_TEMPLATES: `Plantillas de reserva`,
  SMS_TO_HOST_TOOLTIP: `Si el anfitrión no tiene el número de teléfono, el recordatorio de texto no será enviado`,
  NEEDS_YOUR_ATTENTION: `Necesita su atención`,
});

export const SmartAlertsPersonalize = Object.freeze({
  EMAIL_TO_INVITE_SUBJECT: `Recordatorio: &ltMeeting Name&gt con &ltHost Name&gt a las &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_INVITE_BODY: `Hola &ltInvitee Name&gt,<br><br>Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltHost Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Ubicación</b><br>&ltMeeting Location&gt<br><br>&ltVer o editar enlace de la reunión&gt`,
  EMAIL_TO_HOST_SUBJECT: `Recordatorio: &ltMeeting Name&gt con &ltInvitee Name&gt a las &ltMeeting Time&gt, &ltMeeting Date&gt`,
  EMAIL_TO_HOST_BODY: `Hola &ltHost Name&gt,<br><br>Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltInvitee Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt.<br><br><b>Ubicación</b><br>&ltMeeting Location&gt<br><br>&ltVer o editar enlace de la reunión&gt`,
  SMS_TO_INVITE: `Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltHost Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt. Ver o editar reunión &ltMeeting Link&gt`,
  SMS_TO_HOST: `Este es un recordatorio amistoso de que su &ltMeeting Name&gt con &ltInvitee Name&gt es a las &ltMeeting Time&gt, &ltMeeting Date&gt. Ver o editar reunión &ltMeeting Link&gt`,
  TITLE_EMAIL_TO_HOST: `Personalizar correo electrónico al anfitrión`,
  TITLE_EMAIL_TO_INVITE: `Personalizar correo electrónico al invitado`,
  TITLE_SMS_TO_HOST: `Personalizar recordatorio por texto al anfitrión`,
  TITLE_SMS_TO_INVITE: `Personalizar recordatorio por texto al invitado`,
});

export const SmartAlertsWhen = Object.freeze({
  HOW_LONG_MEETING_BOOKED: `¿Cuánto tiempo después de reservar la nueva reunión?`,
  MEETING_ENDS: `La reunión termina`,
  MEETING_BEFORE_START: `Antes de que comience la reunión`,
  MEETING_CANCELLED: `La reunión está cancelada`,
  MEETINGS_STARTS: `La reunión comienza`,
  MEETING_RESCHEDULED: `La reunión ha sido reprogramada`,
  HOW_LONG_MEETING_ENDS: `¿Cuánto tiempo después de que termine la reunión?`,
  HOW_LONG_MEETING_BEFORE_START: `¿Cuánto tiempo antes de que comience la reunión?`,
  HOW_LONG_MEETING_CANCELLED: `¿Cuánto tiempo después de que se cancele la reunión?`,
  HOW_LONG_MEETINGS_STARTS: `¿Cuánto tiempo después de que comience la reunión?`,
  HOW_LONG_MEETING_RESCHEDULED: `¿Cuánto tiempo después de que se reprograma la reunión?`,
  MINUTE: `Minuto`,
  MINUTES: `Minutos`,
  HOUR: `Hora`,
  HOURS: `Horas`,
  DAY: `Día`,
  DAYS: `Días`,
  WHEN_MEETING_BOOKED: `Cuando se reserva una nueva reunión`,
  WHEN_MEETING_STARTS: `Cuando la reunión comienza`,
  WHEN_MEETING_ENDS: `Cuando la reunión termina`,
  WHEN_MEETING_CANCELED: `Cuando la reunión es cancelada`,
  WHEN_MEETING_RESCHEDULED: `Cuando la reunión es reprogramada`,
  AFTER: `después `,
});

export const SmartAlertsWhom = Object.freeze({
  EMAIL_TO_INVITEE: `Enviar correo electrónico al invitado`,
  EMAIL_TO_HOST: `Enviar correo electrónico al anfitrión`,
  SMS_TO_INVITEE: `Enviar recordatorio por SMS al invitado`,
  SMS_TO_HOST: `Enviar recordatorio por SMS al anfitrión`,
  SEND_ALL: `Enviar correo electrónico y recordatorio por SMS al invitado y al anfitrión`,
  SEND_EMAIL_SMS_INVITEE: `Enviar correo electrónico y recordatorio por SMS al invitado`,
  SEND_EMAIL_SMS_HOST: `Enviar correo electrónico y recordatorio por SMS al anfitrión`,
});

export const SmartAlertsWarningModal = Object.freeze({
  WARNING_BP_AND_BT: `Advertencia: este cambio afectará a las próximas páginas y plantillas de reserva:`,
  WARNING_BP: `Advertencia: este cambio afectará a las próximas páginas de reserva:`,
  WARNING_BT: `Este cambio afectará a otros registros, ya que esta alerta inteligente se utiliza en los siguientes:`,
  WARNING_TITLE: `Advertencia`,
  CREATE_NEW: `Crear nueva alerta inteligente`,
  HOW_TO_APPLY_CHANGE: `¿Cómo desea aplicar este cambio?`,
  UPDATE_ALL_TITLE: `Actualizar todo`,
  UPDATE_ALL_SUB_TITLE: `Este cambio se aplicará a todas las páginas y plantillas de reserva enumeradas anteriormente.`,
  CREATE_NEW_TITLE: `Actualizar solo en`,
  CREATE_NEW_SUB_TITLE_FOR_BOOKING_PAGE: `Este cambio se aplicará solo a esta página de reserva.`,
  CREATE_NEW_SUB_TITLE_FOR_BOOKING_TEMPLATE: `Este cambio se aplicará solo a esta plantilla de reserva.`,
  CREATE_NEW_LABEL_TITLE: `Nombre de la alerta inteligente`,
  CREATE_NEW_INPUT_PLACEHOLDER: `Ingrese el nombre de la alerta inteligente`,
});
