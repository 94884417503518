export const EditRole = Object.freeze({
  ACCESS_TO_ALL_WORKSPACES: `Accès à tous les espaces de travail et rôles`,
  ACTIVE: `Actif`,
  ADMIN_TITLE: `Administrateur`,
  MY_BOOKING_PAGE: `Mes pages de réservation`,
  ALL_BOOKING_PAGE: `Toutes les pages de réservation`,
  BOOKING_TEMPLATES: `Modèles de réservation`,
  CHROME_EXTENSION_TITLE: `SUMO Meeting Booker`,
  CREATE: `Créer`,
  DELETE: `Supprimer`,
  EDIT: `Modifier`,
  FEATURE_ACCESS_TITLE: `Accès aux fonctionnalités`,
  JOURNEY: `Constructeur de Parcours`,
  LOCATIONS: `Emplacements`,
  PERMISSIONS_TITLE: `Permissions`,
  PRIMARY_TITLE: `Principal`,
  READ: `Lire`,
  RESOURCES_TITLE: `Ressources (Emplacements, Salles)`,
  ROLE_NAME: `Nom du rôle`,
  SEARCH_ADD_WORKSPACE: `Rechercher et ajouter un espace de travail`,
  SMART_ALERT_TEMPLATES: `Modèles d'alertes intelligentes`,
  STANDARD_USER: `Utilisateur standard`,
  SUPER_ADMIN: `Super Administrateur`,
  TEAMS: `Équipes`,
  EDIT_TITLE: `Modifier le rôle`,
  NEW_TITLE: `Nouveau rôle`,
  WORKSPACE_ADMIN: `Administrateur de l'espace de travail`,
  WORKSPACE_ADMIN_DESCRIPTION: `Par défaut, tout administrateur avec ce rôle peut administrer tous les espaces de travail, sauf si vous spécifiez ci-dessous les espaces de travail spécifiques qu'il peut uniquement administrer :`,
});

export const RoleToastsNotifications = Object.freeze({
  ACTIVATE_ROLE_SUCCESS_MESSAGE: `Rôle activé`,
  DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE: `Échec de la demande d'activation/désactivation du rôle`,
  DEACTIVATE_ROLE_SUCCESS_MESSAGE: `Rôle désactivé`,
  DELETE_ROLE_ASSIGNED_ERROR_MESSAGE: `Ne peut pas être supprimé. Des utilisateurs sont assignés à ce rôle.`,
  DELETE_ROLE_ERROR_MESSAGE: `Échec de la demande de suppression du rôle`,
  DELETE_ROLE_SUCCESS_MESSAGE: `Rôle supprimé avec succès`,
  GET_ROLES_FAIL_MESSAGE: `Échec de la demande de récupération des rôles`,
  GET_ROLE_FAIL_MESSAGE: `Échec de la demande d'obtention d'un rôle`,
  SAVE_ROLE_ERROR_MESSAGE: `Échec de la demande d'enregistrement du rôle`,
  SAVE_ROLE_SUCCESS_MESSAGE: `Rôle enregistré avec succès`,
});

export const Roles = Object.freeze({
  ACTIVE: `Actif`,
  CUSTOM: `Personnalisé`,
  DEACTIVATE_DESCRIPTION: `Êtes-vous sûr de vouloir désactiver`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  DELETE_MESSAGE: `Les utilisateurs ne seront pas supprimés, mais peuvent perdre l'accès, nécessitant une attribution de rôle.`,
  LAST_MODIFIED: `Dernière modification`,
  LAST_MODIFIED_BY: `Dernière modification par`,
  NEW_ROLE: `Nouveau rôle`,
  ROLE: `Rôle`,
  TOOLTIP: `Gérer les rôles pour donner aux utilisateurs des permissions pour voir, créer/modifier ou supprimer diverses fonctionnalités`,
});
