import { CreateAdminDataInput } from '../../API';
import { UserAdminDataFullRecord } from './types';

import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { USER_CONTEXT_PARAM } from '../authentication';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getUsers = async (tenantId: string): Promise<{ users: UserAdminDataFullRecord[] }> => {
  return handleAPIRequest<{ users: UserAdminDataFullRecord[] }>(`${API_PUBLIC}/getAdminDataByTenant`, { tenantId });
};

export const inviteUsers = async (data: CreateAdminDataInput[], expirationInviteDays: number): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/sendUsersInviteLinks`, { data, expirationInviteDays });
};

export const updateUser = async (data: UserAdminDataFullRecord): Promise<void> => {
  const response = await handleAPIRequest<void>(`${API_PUBLIC}/updateAdminData`, { data });
  localStorage.removeItem(USER_CONTEXT_PARAM);
  return response;
};
