export enum PromoActionTypes {
  SHOW_GUIDE = 'promo/SHOW_GUIDE',
  HIDE_GUIDE = 'promo/HIDE_GUIDE',
  RUN_GUIDE = 'promo/RUN_GUIDE',
  STOP_GUIDE = 'promo/STOP_GUIDE',
  SET_GUIDE_STEP_INDEX = 'promo/SET_GUIDE_STEP_INDEX',
}

export type PromoAction =
  | { type: PromoActionTypes.SHOW_GUIDE }
  | { type: PromoActionTypes.HIDE_GUIDE }
  | { type: PromoActionTypes.RUN_GUIDE }
  | { type: PromoActionTypes.STOP_GUIDE }
  | { type: PromoActionTypes.SET_GUIDE_STEP_INDEX; payload: number };

const showGuide = (): PromoAction => ({
  type: PromoActionTypes.SHOW_GUIDE,
});

const hideGuide = (): PromoAction => ({
  type: PromoActionTypes.HIDE_GUIDE,
});

const runGuide = (): PromoAction => ({
  type: PromoActionTypes.RUN_GUIDE,
});

const stopGuide = (): PromoAction => ({
  type: PromoActionTypes.STOP_GUIDE,
});

const setGuideStepIndex = (step: number): PromoAction => ({
  type: PromoActionTypes.SET_GUIDE_STEP_INDEX,
  payload: step,
});

export const promoActions = {
  showGuide,
  hideGuide,
  runGuide,
  stopGuide,
  setGuideStepIndex,
};
