import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors, confirmOverrideModalActions } from '../../../store/bookingPages';
import { ModalName } from '../../../store/modals';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Modal } from '../../common';
import labels from './labels';

export const BookingPageConfirmOverrideModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingPageSelectors.selectCloneName);
  const isNameDuplicate = useSelector(bookingPageSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(bookingPageSelectors.selectIsCloneNameValid);

  const handleNameChange = (value: string) => {
    dispatch(bookingPagesActions.setCloneName(value));
  };

  const handleCancel = () => {
    dispatch(confirmOverrideModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(bookingPagesActions.updateWhatStep({ customName: name, bookingTemplateId: null }));
    dispatch(confirmOverrideModalActions.closeModal());
  };

  return (
    <Modal.Container name={ModalName.CONFIRM_OVERRIDE_MODAL_NAME} className="w-440px">
      <Modal.Header>{labels.title}</Modal.Header>
      <div className="flex flex-column gap-24px">
        <div className="flex gap-8px py-12px px-16px w-full border-1 border-blue-light border-radius-8px bg-blue-soft">
          <InformationCircleIcon className="icon-20px text-blue-main flex-none" />
          <div className="flex-1 text-label-xs-reg">{labels.description}</div>
        </div>

        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.name}</div>
          <InputText
            type="text"
            placeholder={labels.namePlaceholder}
            value={name}
            onChange={(e) => handleNameChange(e.target.value.trimStart())}
            className={(!isNameValid && 'p-invalid') || ''}
            maxLength={MAX_LENGTH_NAME}
          />
          {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicate}</div>}
        </div>
      </div>
      <Modal.Buttons>
        <Button label={labels.save} className="min-w-120px" onClick={handleSave} autoFocus disabled={!isNameValid} />
        <Button label={labels.cancel} onClick={handleCancel} text />
      </Modal.Buttons>
    </Modal.Container>
  );
};
