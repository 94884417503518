import { t } from '../../../i18n/i18n';

export default {
  deleteAccount: t('DeleteAccount:TITLE'),
  warningDelete: t('DeleteAccount:WARNING_DELETE'),
  deleteAccountAndOrg: t('DeleteAccount:DELETE_ACCOUNT_AND_ORG'),
  yesDelete: t('DeleteAccount:YES_DELETE'),
  next: t('Common:NEXT'),
  noCancel: t('Common:NO_CANCEL'),
  deleteToConfirm: t('DeleteAccount:DELETE_TO_CONFIRM'),
  deleteConfirmWord: t('DeleteAccount:DELETE_CONFIRM_WORD'),
  deleteAccountModalDescription: t('DeleteAccount:DELETE_ACCOUNT_MODAL_DESCRIPTION'),
  deleteAccountAndOrgModalDescription: t('DeleteAccount:DELETE_ACCOUNT_AND_ORG_MODAL_DESCRIPTION'),
  listDeletingEntitiesTitle: t('DeleteAccount:LIST_DELETING_ENTITIES_TITLE'),
  listDeletingAccountAndOrgEntitiesTitle: t('DeleteAccount:LIST_DELETING_ACCOUNT_AND_ORG_ENTITIES_TITLE'),
  listDeletingEntities: t('DeleteAccount:LIST_DELETING_ENTITIES'),
  additionalListDeletingOrgEntities: t('DeleteAccount:ADDITIONAL_LIST_DELETING_ORG_ENTITIES'),
  transferOwnershipTitle: t('DeleteAccount:TRANSFER_OWNERSHIP_TITLE'),
  transferOwnershipDescription: t('DeleteAccount:TRANSFER_OWNERSHIP_DESCRIPTION'),
  deleteAccountAndOrgTitle: t('DeleteAccount:DELETE_ACCOUNT_AND_ORG_TITLE'),
  deleteAccountAndOrgDescription: t('DeleteAccount:DELETE_ACCOUNT_AND_ORG_DESCRIPTION'),
  transferOwnership: t('DeleteAccount:TRANSFER_OWNERSHIP'),
  needInviteNewUserToOrg: t('DeleteAccount:NEED_INVITE_NEW_USER_TO_ORG'),
};
