import { Column } from 'primereact/column';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Account, UpdateAccountInput } from '../../../../API';
import { Path } from '../../../../routing';
import { DateFormat } from '../../../../store/userSettings';
import { accountsActions, accountsSelectors } from '../../../../store/opsConsole/accounts';
import { AccountsTableRowActions } from '../accountsTableRowActions/AccountsTableRowActions';
import labels from './labels';
import { authenticationSelectors } from '../../../../store/authentication';
import { navigationService } from '../../../../services/NavigationService';
import { Table } from '../../table/Table';
import { DataTableRowClickEvent } from 'primereact/datatable';

export const AccountsTable = () => {
  const dispatch = useDispatch();
  const accounts = useSelector(accountsSelectors.selectFilteredAccounts);
  const isPaginatorEnabled = useSelector(accountsSelectors.selectIsPaginatorEnabled);
  const isMainAdmin = useSelector(authenticationSelectors.selectIsMainAdmin);
  const isMainAdminOperationsOrSupport = useSelector(authenticationSelectors.selectIsMainAdminOperationsOrSupport);

  const openOPSConcoleAccount = (e: DataTableRowClickEvent) => {
    // prevent action a rowClick when clicking on expanded row menu
    if (e.originalEvent.target instanceof HTMLElement && e.originalEvent.target.tagName === 'TD') {
      navigationService.navigateTo(Path.OPSConsoleAccount.replace(':accountId', e.data.id));
      dispatch(accountsActions.setAccount(e.data as Partial<UpdateAccountInput>));
    }
  };

  return (
    <Table
      className="cursor-pointer"
      rowHover
      value={accounts}
      paginator={isPaginatorEnabled}
      onRowClick={openOPSConcoleAccount}
      paths={[Path.OPSConsoleAccounts, Path.OPSConsoleAccount]}
    >
      <Column field="name" header={labels.accountName} sortable />

      <Column field="createdBy" header={labels.createdBy} sortable />

      <Column
        field="createdAt"
        sortable
        header={labels.createdDate}
        body={(data: Account) => dayjs(data.createdAt).format(DateFormat.default)}
      />

      <Column
        className="w-3rem"
        align="center"
        frozen
        body={(data: Account) =>
          AccountsTableRowActions({
            accountId: data.id,
            disableDelete: !!data.tenantIds?.length,
            showDelete: isMainAdmin,
            showEdit: isMainAdminOperationsOrSupport,
            showView: !isMainAdminOperationsOrSupport,
          })
        }
      />
    </Table>
  );
};
