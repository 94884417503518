import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const groupCloneModalActions = modalsActionsFactory(ModalName.GROUP_CLONE_MODAL_NAME);
export const groupCloneModalSelectors = modalsSelectorsFactory(ModalName.GROUP_CLONE_MODAL_NAME);

export const groupDeleteModalActions = modalsActionsFactory(ModalName.GROUP_DELETE_MODAL_NAME);
export const groupDeleteModalSelectors = modalsSelectorsFactory(ModalName.GROUP_DELETE_MODAL_NAME);

export const groupDeactivateModalActions = modalsActionsFactory(ModalName.GROUP_DEACTIVATE_MODAL_NAME);
export const groupDeactivateModalSelectors = modalsSelectorsFactory(ModalName.GROUP_DEACTIVATE_MODAL_NAME);
