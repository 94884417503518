import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useDispatch, useSelector } from 'react-redux';
import { SmartAlertTemplate } from '../../API';
import { SmartAlertPreSelect } from '../../components/smartAlerts/smartAlertPreSelect/SmartAlertPreSelect';
import { Path } from '../../routing';
import { navigationService } from '../../services/NavigationService';
import { AppDispatch } from '../../store/rootStore';
import { smartAlertsActions, smartAlertsSelectors, SmartTypes } from '../../store/smartAlerts';
import labels from './labels';

export const PreSelectSmartAlert = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(smartAlertsSelectors.selectIsFetching);

  const handleBack = () => {
    navigationService.navigateTo(Path.SmartAlerts);
  };

  const handleUseTemplate = (template: SmartAlertTemplate, smartAlertId: string) => {
    dispatch(
      smartAlertsActions.updateSmartAlert({
        name: template.name,
        icon: template.icon,
        isHide: template.isHide,
        shareWith: template.shareWith,
        whenSend: template.whenSend,
        whomSend: template.whomSend,
      })
    );
    navigationService.navigateTo(
      Path.EditSmartAlert.replace(':smartType', SmartTypes.TEMPLATE).replace(':smartAlertId', smartAlertId)
    );
  };

  return (
    <>
      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column p-24px">
        <div className="flex-left-center gap-8px">
          <div className="text-title-xl-med">{labels.createAlertTitle}</div>
          {isFetching && <ProgressSpinner className="w-32px h-32px mx-0 -my-4px" strokeWidth="5" />}
        </div>

        <SmartAlertPreSelect handleUseTemplate={handleUseTemplate} />
      </div>
    </>
  );
};
