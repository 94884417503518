export const ConnectIntegrationPage = Object.freeze({
  LEADS: `Leads`,
  CONTACTS: `Contatos`,
  CREATE: `Criar`,
  BENEFITS_TITLE: `Benefícios`,
  DISCONNECT_INTEGRATION: `Desconectar Integração`,
  DISCONNECT_GOOGLE_CALENDAR_DESCRIPTION: `Tem certeza de que deseja desconectar o Google Calendar?`,
  DISCONNECT_GOOGLE_MEET_DESCRIPTION: `Tem certeza de que deseja desconectar o Google Meet?

  Isso pode impactar as páginas de reservas criadas anteriormente usando esta integração.`,
  DISCONNECT_MICROSOFT_CALENDAR_DESCRIPTION: `Tem certeza de que deseja desconectar o Microsoft Office365?`,
  DISCONNECT_TEAMS_DESCRIPTION: `Tem certeza de que deseja desconectar o Microsoft Teams?

  Isso pode afetar as páginas de reserva que foram criadas anteriormente usando esta integração.`,
  DISCONNECT_ZOOM_DESCRIPTION: `Tem certeza de que deseja desconectar o Zoom Meeting?

  Isso pode afetar as páginas de reserva que foram criadas anteriormente usando esta integração.`,
  DISCONNECT_HUBSPOT_DESCRIPTION: `Tem certeza de que deseja desconectar o HubSpot?`,
  DISCONNECT_SALESFORCE_DESCRIPTION: `Tem certeza de que deseja desconectar o Salesforce?`,
  DISCONNECT_GOOGLE_ANALYTICS_DESCRIPTION: `Tem certeza de que deseja desconectar o Google Analytics?`,
  DISCONNECT_GOOGLE_MAPS_DESCRIPTION: `Tem certeza de que deseja desconectar o Google Maps?`,
  GOOGLE_ANALYTICS_BENEFITS: `Obtenha informações valiosas sobre quem visita suas páginas de reserva públicas. \\p Meça as taxas de conversão e quantas pessoas reservam eventos. \\p Integre sua conta SUMO com o Google Analytics`,
  GOOGLE_ANALYTICS_REQUIREMENTS: `Conta do Google Analytics`,
  GOOGLE_ANALYTICS_SETUP_DESC: `Insira um ID de acompanhamento do Universal Analytics ou um ID de medição do Google Analytics 4 para conectar o SUMO à sua propriedade. Se você inserir ambos, rastrearemos as conversões em ambas as suas propriedades do Google Analytics.`,
  GOOGLE_ANALYTICS_PLACEHOLDER_TRACKING_ID: `UA-12345678-9`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_TRACKING_ID: `ID de acompanhamento do Universal Analytics`,
  GOOGLE_ANALYTICS_PLACEHOLDER_MEASUREMENT_ID: `G-1234567890`,
  GOOGLE_ANALYTICS_VISIBLE_NAME_MEASUREMENT_ID: `ID de medição do Google Analytics 4`,
  GOOGLE_CALENDAR_BENEFITS: `Reserve e re-programe automaticamente reuniões no seu Google Calendar.`,
  GOOGLE_CALENDAR_REQUIREMENTS: `Você precisa conceder acesso ao seu Google Calendar.`,
  GOOGLE_MAPS_BENEFITS: `\\p Forneça aos seus clientes um mapa com seus locais \\p Integre sua conta SUMO com o Google Maps`,
  GOOGLE_MAPS_REQUIREMENTS: `Conta do Google Cloud \\p Chave de API com Geocoding API, Maps JavaScript API, Places API`,
  GOOGLE_MAPS_SETUP_DESC: `Os produtos do Google Cloud estão protegidos contra uso não autorizado ao restringir chamadas de API para aquelas que fornecem as credenciais de autenticação adequadas. Essas credenciais estão na forma de uma chave API - uma string alfanumérica única. Certifique-se de que sua chave API esteja conectada à API de Geocodificação, API JavaScript do Maps e API de Lugares. Para mais informações sobre como obter e usar as chaves da API, consulte a documentação da plataforma Google Maps.\nUma vez que a API do Google Maps esteja conectada com sucesso, todos os endereços inseridos manualmente serão automaticamente convertidos em formatos padronizados usando esta API.`,
  GOOGLE_MAPS_NAME_API_KEY: `Chave API`,
  GOOGLE_MAPS_NAME_MAP_ID: `ID do Mapa`,
  GOOGLE_MEET_BENEFITS: `Crie automaticamente reuniões do Google Meet no momento em que um evento é agendado. Compartilhe instantaneamente os detalhes da conferência exclusiva após a confirmação.`,
  GOOGLE_MEET_REQUIREMENTS: `Dependendo da sua configuração, seu administrador do Google Meet pode precisar pré-aprovar o SUMO1 no Google Marketplace.`,
  HUBSPOT_BENEFITS: `Crie automaticamente eventos do HubSpot e reserve com clientes como leads ou contatos do HubSpot.`,
  HUBSPOT_REQUIREMENTS: `Conta do HubSpot \n Privilégios de administrador do HubSpot`,
  HUBSPOT_CREATE_EVENTS: `Criar eventos Hubspot`,
  HUBSPOT_BOOK_USERS: `Permitir reservar com registros de Hubspot a partir do SUMO Meeting Booker`,
  INBOX_BENEFITS: `A melhor experiência SUMO1 é usar SUMO no seu ambiente, instalando a Extensão do Chrome / Complemento do Edge. Use Sumo em:\n\u2022 Google Gmail no navegador\n\u2022 Microsoft Office Web no navegador`,
  INBOX_REQUIREMENTS: `Um dos seguintes deve estar conectado ao SUMO1:\n\u2022 Conta do Google\n\u2022 Conta do Microsoft Office365`,
  INBOX_INSTALL_CHROME_EXTENSION: `Instalar Extensão do Chrome`,
  INBOX_INSTALL_EDGE_ADD_ON: `Instalar Complemento do Edge`,
  MICROSOFT_CALENDAR_BENEFITS: `Reserve e re-programe automaticamente reuniões no seu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_REQUIREMENTS: `Você precisa conceder acesso ao seu Microsoft Calendar.`,
  MICROSOFT_TEAMS_BENEFITS: `Crie automaticamente reuniões do Microsoft Teams no momento em que um evento é agendado. Compartilhe instantaneamente os detalhes da conferência exclusiva após a confirmação.`,
  MICROSOFT_TEAMS_REQUIREMENTS: `Dependendo da sua configuração, seu administrador do Microsoft Teams pode precisar pré-aprovar o SUMO1 no Microsoft Marketplace.`,
  NO_CANCEL: `Não, cancelar`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SALESFORCE_BENEFITS: `Crie automaticamente eventos do Salesforce e reserve com clientes como leads, contatos ou contas de pessoa do Salesforce.`,
  SALESFORCE_REQUIREMENTS: `Conta do Salesforce \n Privilégios de administrador do Salesforce`,
  SALESFORCE_CREATE_EVENTS: `Criar eventos Salesforce`,
  SALESFORCE_BOOK_USERS: `Permitir reservar com registros de Salesforce a partir do SUMO Meeting Booker`,
  SALESFORCE_BOOKING_WITH_SF_SUMO_INBOX: `Permitir agendamentos com registros do Salesforce a partir do SUMO Meeting Booker`,
  PERSON_ACCOUNT: `Contas pessoais`,
  YES_DISCONNECT: `Sim, desconectar`,
  ZOOM_BENEFITS: `Crie automaticamente reuniões do Zoom no momento em que um evento é agendado. Compartilhe instantaneamente os detalhes da conferência exclusiva após a confirmação.`,
  ZOOM_REQUIREMENTS: `Dependendo da sua configuração, seu administrador do Zoom pode precisar pré-aprovar o SUMO1 no Zoom Marketplace.`,
});

export const IntegrationPage = Object.freeze({
  DESCRIPTION: `Economize mais tempo automatizando videoconferências, calendários e muito mais.`,
  FILTER_ALL: `Todos`,
  FILTER_CALENDARS: `Calendários`,
  FILTER_VIDEO_CONFERENCE: `Videoconferência`,
  FILTER_WORKSPACE_INTEGRATIONS: `Integrações de Espaço de Trabalho`,
  FILTER_EXTENSIONS: `Extensões`,
  GOOGLE_ANALYTICS_DESC: `Acompanhe o engajamento com suas páginas de reservas`,
  GOOGLE_ANALYTICS_TITLE: `Google Analytics`,
  GOOGLE_CALENDAR_DESC: `Reserve e reagende automaticamente reuniões em seu Google Calendar.`,
  GOOGLE_CALENDAR_TITLE: `Google Calendar`,
  GOOGLE_MAPS_DESC: `Adicione locais ao mapa para proporcionar uma melhor experiência de reunião presencial`,
  GOOGLE_MAPS_TITLE: `Google Maps`,
  GOOGLE_MEET_DESC: `Adicione automaticamente detalhes do Google Meet às suas reuniões.`,
  GOOGLE_MEET_TITLE: `Google Meet`,
  HUBSPOT_DESC: `Adicione automaticamente reuniões criadas pelo SUMO1 ao HubSpot CRM`,
  HUBSPOT_TITLE: `HubSpot`,
  INBOX_TITLE: `SUMO Meeting Booker`,
  INBOX_DESC: `Instale a extensão do Chrome ou o complemento do Edge para enviar links de convite do Gmail ou Outlook Web.`,
  MICROSOFT_CALENDAR_DESC: `Reserve e reagende automaticamente reuniões em seu Microsoft Calendar.`,
  MICROSOFT_CALENDAR_TITLE: `Microsoft Office365`,
  MICROSOFT_TEAMS_DESC: `Adicione automaticamente detalhes do Microsoft Teams às suas reuniões.`,
  MICROSOFT_TEAMS_TITLE: `Microsoft Teams`,
  NO_ITEMS: `Nenhum item encontrado.`,
  REQUIREMENTS_TITLE: `Requisitos`,
  SALESFORCE_DESC: `Reserve usuários do Salesforce, registros de CRM e adicione automaticamente reuniões do Salesforce`,
  SALESFORCE_TITLE: `Salesforce`,
  TITLE: `Integração`,
  ZOOM_DESC: `Adicione automaticamente detalhes do Zoom às suas reuniões do SUMO.`,
  ZOOM_TITLE: `Zoom Meeting`,
  FILL_ONE_FIELD: `Preencha pelo menos um campo`,
});

export const IntegrationToastsNotifications = Object.freeze({
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS_MESSAGE: `Integração de espaço de trabalho conectada com sucesso`,
  CONNECT_WORKSPACE_INTEGRATION_ERROR_MESSAGE: `Falha na solicitação de integração de espaço de trabalho`,
  INVALID_GOOGLE_MAP_API_KEY: `Chave API do Google inválida ou serviços necessários não estão ativados.`,
});
