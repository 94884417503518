import { ConfirmationModal, SectionHeader, SumoTable } from '../../components/common';
import labels from './labels';
import { Column } from 'primereact/column';
import { Menu } from 'primereact/menu';
import { useDispatch, useSelector } from 'react-redux';
import { createRef, useEffect, useState } from 'react';
import { rolesActions, rolesSelectors } from '../../store/roles';
import { userSettingsSelectors } from '../../store/userSettings';
import { formatDateUTC } from '../../services/DateService';
import { EditRoleModal } from '../../components/roles';
import { editRoleModalActions } from '../../store/roles/modal';
import { DEFAULT_ROLE_DATA, StatusOptions } from '../../store/roles/constants';
import { Role } from '../../API';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { usersSelectors } from '../../store/users';
import { DEFAULT_FILTER } from '../../store/roles';
import { Path } from '../../routing';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { AppDispatch } from '../../store/rootStore';
import { MenuItemCommandEvent } from 'primereact/menuitem';

export const Roles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isSpinnerFetching = useSelector(rolesSelectors.selectIsSpinnerFetching);
  const isSkeletonFetching = useSelector(rolesSelectors.selectIsSkeletonFetching);
  const roles = useSelector(rolesSelectors.selectRoles);
  const filteredRoles = useSelector(rolesSelectors.selectFilteredRoles);
  const selectedRole = useSelector(rolesSelectors.selectSelectedRole);
  const superAdminIds = useSelector(rolesSelectors.selectSuperAdminIds);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const currentUserRoleId = useSelector(userSettingsSelectors.selectUserRoleId);
  const usersOptions = useSelector(usersSelectors.selectLoggedUsersInCurrentWorkspaceOptions);

  const isDefaultFilter = useSelector(rolesSelectors.selectIsDefaultFilter);
  const filterStatuses = useSelector(rolesSelectors.selectFilterStatuses);
  const isFilterActive = useSelector(rolesSelectors.selectIsFilterInUse);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(rolesActions.getRolesRequest());
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  useEffect(() => {
    usersOptions.length && isDefaultFilter && resetFilters();
  }, [usersOptions]);

  const resetFilters = () => {
    dispatch(rolesActions.setFilter(DEFAULT_FILTER));
  };

  const handleSelectStatuses = (e: MultiSelectChangeEvent) => {
    dispatch(rolesActions.setFilter({ statuses: e.target.value }));
  };

  const handleEditRole = () => {
    dispatch(editRoleModalActions.openModal());
    dispatch(rolesActions.getRoleRequest(selectedRole.id));
  };

  const handleDiactivateRole = () => {
    if (selectedRole.isActive) {
      setIsDelete(false);
      setIsModalOpen(true);
    } else {
      dispatch(rolesActions.deactivateRoleRequest());
    }
  };

  const handleCloneRole = () => {
    dispatch(rolesActions.cloneRoleThunk());
  };

  const handleDeteleRole = () => {
    setIsDelete(true);
    setIsModalOpen(true);
  };

  const handleMenuActions = (action: () => void) => (e: MenuItemCommandEvent) => {
    e.originalEvent.stopPropagation();
    action();
  };

  const menuItems = [
    {
      visible: selectedRole.isCustom,
      label: labels.edit,
      command: handleMenuActions(handleEditRole),
    },
    {
      visible:
        (selectedRole.isCustom || !superAdminIds.includes(selectedRole.id)) && selectedRole.id !== currentUserRoleId,
      label: selectedRole.isActive ? labels.deactivate : labels.activate,
      command: handleMenuActions(handleDiactivateRole),
    },
    {
      label: labels.clone,
      command: handleMenuActions(handleCloneRole),
    },
    {
      visible: selectedRole.isCustom && selectedRole.id !== currentUserRoleId,
      label: labels.delete,
      command: handleMenuActions(handleDeteleRole),
    },
  ];

  const handleConfirmDelete = () => {
    dispatch(rolesActions.deleteRoleRequest());
    setIsModalOpen(false);
  };

  const handleConfirmInactivate = () => {
    dispatch(rolesActions.deactivateRoleRequest());
    setIsModalOpen(false);
  };

  const handleAddRole = () => {
    dispatch(rolesActions.setSelectedRole(DEFAULT_ROLE_DATA));
    dispatch(editRoleModalActions.openModal());
  };

  const renderActions = (data: Role) => {
    const menu = createRef<Menu>();
    return (
      <div
        className="action-button"
        onClick={(e) => {
          dispatch(rolesActions.setSelectedRole(data));
          menu.current?.toggle(e);
        }}
      >
        <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
        <EllipsisVerticalIcon className="icon-20px" />
      </div>
    );
  };

  return (
    <div>
      <EditRoleModal />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isSpinnerFetching}
          title={labels.title}
          itemsLength={roles.length}
          searchPaths={[Path.Roles]}
          buttonLabel={labels.newRole}
          onButtonClick={handleAddRole}
          filters={[
            { label: labels.status, value: filterStatuses, options: StatusOptions, onChange: handleSelectStatuses },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterActive}
        />

        <SumoTable isCard loading={isSkeletonFetching} value={filteredRoles} actionsBody={renderActions}>
          <Column field="name" header={labels.role} sortable bodyClassName="text-overflow-ellipsis max-w-150px" />
          <Column
            field="isCustom"
            header={labels.custom}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-90px"
            body={(data: Role) => (data.isCustom ? labels.yes : labels.no)}
          />
          <Column
            field="isActive"
            header={labels.active}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-90px"
            body={(data: Role) => (data.isActive ? labels.yes : labels.no)}
          />
          <Column
            field="updatedAt"
            header={labels.lastModified}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-90px"
            body={(data: Role) => formatDateUTC(data.updatedAt, dateFormat)}
          />
          <Column
            field="updatedBy"
            header={labels.lastModifiedBy}
            sortable
            bodyClassName="text-overflow-ellipsis max-w-150px"
            frozen
          />
        </SumoTable>
      </div>

      <ConfirmationModal
        visible={isModalOpen}
        title={isDelete ? labels.delete : labels.deactivate}
        description={`${isDelete ? labels.deleteDesc : labels.deactivateDesc} "${
          selectedRole.name
        }" ${labels.role.toLowerCase()}?`}
        additionalText={isDelete ? [labels.deleteMessage] : undefined}
        confirmButtonLabel={labels.yes}
        cancelButtonLabel={labels.no}
        onConfirm={isDelete ? handleConfirmDelete : handleConfirmInactivate}
        onCancel={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};
