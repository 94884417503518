import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch, useSelector } from 'react-redux';
import {
  billingActions,
  billingChangeModalActions,
  billingSelectors,
  CHARGEBEE_MONTHLY_TERM,
  CHARGEBEE_YEARLY_TERM,
  DEFAULT_SEATS_AMOUNT,
  getPriceByQuantityTiersOneLicense,
  MAX_SEATS_AMOUNT,
} from '../../../store/billing';
import { ModalName } from '../../../store/modals';
import { Modal } from '../../common';
import labels from './labels';

export const BillingChangeModal = () => {
  const dispatch = useDispatch();
  const removeSeatsMax = useSelector(billingSelectors.selectRemoveSeatsMax);
  const changeSeats = useSelector(billingSelectors.selectChangeSeats);
  const changeSeatsPrice = useSelector(billingSelectors.selectChangeSeatsPrice);
  const addSeatsProratedPrice = useSelector(billingSelectors.selectAddSeatsProratedPrice);
  const nextBillingDate = useSelector(billingSelectors.selectNextBillingDate);
  const currencyLabel = useSelector(billingSelectors.selectCurrencyLabel);
  const termsDaysLeft = useSelector(billingSelectors.selectTermsDaysLeft);
  const nextTerm = useSelector(billingSelectors.selectNextTerm);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const scheduledLicenses = useSelector(billingSelectors.selectScheduledLicenses);
  const term = useSelector(billingSelectors.selectTerm);
  const itemPrices = useSelector(billingSelectors.selectItemPrices);
  const hasScheduledChanges = useSelector(billingSelectors.selectHasScheduledChanges);

  const constructDescription = () => {
    const termLabel = nextTerm === CHARGEBEE_YEARLY_TERM ? labels.annual : labels.monthly;
    const price = currencyLabel + changeSeatsPrice.toFixed(2);
    if (changeSeats > 0) {
      return `${labels.addDescriptionPart1} ${changeSeats} ${labels.addDescriptionPart2} ${price} ${labels.addDescriptionPart3} ${termLabel} ${labels.addDescriptionPart4} ${nextBillingDate}.`;
    } else {
      return `${labels.removeDescriptionPart1} ${changeSeats} ${labels.removeDescriptionPart2} ${termLabel} ${labels.removeDescriptionPart3} ${price} ${labels.removeDescriptionPart4} ${nextBillingDate}.`;
    }
  };

  const constructProratedDescription = () => {
    const price = currencyLabel + addSeatsProratedPrice.toFixed(2);
    return `${labels.addProratedPart1} ${termsDaysLeft} ${labels.addProratedPart2} ${price} ${labels.addProratedPart3}`;
  };

  const handleSeatsChange = (seats: number) => {
    const validCountSeats = Math.max(-removeSeatsMax, Math.min(seats, MAX_SEATS_AMOUNT));
    dispatch(billingActions.setChangeSeats(validCountSeats));
  };

  const handleTermValueChange = (value: string) => {
    dispatch(billingActions.setTerm(value));
  };

  const handleCancel = () => {
    dispatch(billingChangeModalActions.closeModal());
    dispatch(billingActions.setChangeSeats(DEFAULT_SEATS_AMOUNT));
  };

  const handleSave = () => {
    dispatch(billingActions.changeRequest());
  };

  const priceOneLicenseForMonth = () =>
    getPriceByQuantityTiersOneLicense(itemPrices, CHARGEBEE_MONTHLY_TERM, scheduledLicenses + changeSeats);
  const priceOneLicenseForYear = () =>
    getPriceByQuantityTiersOneLicense(itemPrices, CHARGEBEE_YEARLY_TERM, scheduledLicenses + changeSeats);
  const discountOnPaymentForYear = () =>
    (priceOneLicenseForMonth() - priceOneLicenseForYear()) * (scheduledLicenses + changeSeats);

  const isTermChangeDisabled = () => hasScheduledChanges || changeSeats !== 0;

  return (
    <Modal.Container name={ModalName.CHANGE_SEATS_AND_TERM_MODAL} className="w-460px">
      <Modal.Header>{labels.changeSubscription}</Modal.Header>
      <div className="flex flex-column gap-24px">
        <div className="flex flex-column gap-12px">
          <div className="text-body-s-reg text-heavy-80">1. {labels.licenses}</div>
          <div className="flex-left-center gap-16px">
            <Button
              onClick={() => handleSeatsChange(changeSeats - 1)}
              className="h-40px"
              icon={<MinusIcon className="icon-18px" />}
              outlined
              disabled={changeSeats <= -removeSeatsMax || term !== nextTerm}
            />
            <InputNumber
              inputClassName="w-60px"
              value={changeSeats}
              onChange={(e) => handleSeatsChange(e.value || 0)}
              min={-removeSeatsMax}
              max={MAX_SEATS_AMOUNT}
              disabled={term !== nextTerm}
            />
            <Button
              onClick={() => handleSeatsChange(changeSeats + 1)}
              className="h-40px"
              icon={<PlusIcon className="icon-18px" />}
              outlined
              disabled={changeSeats >= MAX_SEATS_AMOUNT || term !== nextTerm}
            />
          </div>
        </div>

        <div className="flex flex-column gap-12px">
          <div className="text-body-s-reg text-heavy-80">2. {labels.term}</div>
          <div
            className={`sumo-card action-card flex gap-10px p-12px border-1 border-heavy-20 w-335px ${
              isTermChangeDisabled() ? 'p-disabled' : ''
            }`}
            onClick={() => handleTermValueChange(CHARGEBEE_MONTHLY_TERM)}
          >
            <div className="flex-none flex pt-2px">
              <RadioButton inputId={CHARGEBEE_MONTHLY_TERM} checked={term === CHARGEBEE_MONTHLY_TERM} />
            </div>
            <div className="flex flex-column gap-8px pt-2px">
              <div className="text-title-xs-med">{labels.monthly}</div>
              <div className="text-body-s-reg text-heavy-60">
                {currencyLabel}
                {priceOneLicenseForMonth()} {labels.berUserMonth}
              </div>
            </div>
          </div>
          <div
            className={`sumo-card action-card flex-between-center p-12px border-1 border-heavy-20 w-335px ${
              isTermChangeDisabled() ? 'p-disabled' : ''
            }`}
            onClick={() => handleTermValueChange(CHARGEBEE_YEARLY_TERM)}
          >
            <div className="flex gap-10px pt-2px">
              <div className="flex-none flex">
                <RadioButton inputId={CHARGEBEE_YEARLY_TERM} checked={term === CHARGEBEE_YEARLY_TERM} />
              </div>
              <div className="flex flex-column gap-8px">
                <div className="text-title-xs-med">{labels.annual}</div>
                <div className="text-body-s-reg text-heavy-60">
                  {currencyLabel}
                  {priceOneLicenseForYear()} {labels.berUserMonth}
                </div>
              </div>
            </div>
            <div className="flex-center h-30px bg-cyan-main text-primary-white text-label-s-med border-radius-4px px-10px">
              {labels.save} {currencyLabel}
              {discountOnPaymentForYear()}
            </div>
          </div>
        </div>

        {changeSeats < 0 && (
          <div className="text-body-s-reg text-heavy-60">{`${labels.removeNotePart1} ${removeSeatsMax} ${labels.removeNotePart2}`}</div>
        )}

        {changeSeats !== 0 && <div className="text-body-s-reg">{constructDescription()}</div>}

        {changeSeats > 0 && <div className="text-body-s-reg">{constructProratedDescription()}</div>}
      </div>
      <Modal.Buttons>
        <Button
          label={labels.save}
          onClick={handleSave}
          disabled={(changeSeats === 0 && term === nextTerm) || isFetching}
        />
        <Button label={labels.cancel} onClick={handleCancel} outlined />
      </Modal.Buttons>
    </Modal.Container>
  );
};
