import { t } from '../../i18n/i18n';

export default {
  title: t('Billing:TITLE'),
  billingDetailsTitle: t('Billing:BILLING_DETAILS_TITLE'),
  billingDetailsDescription: t('Billing:BILLING_DETAILS_DESCRIPTION'),
  notificationsTitle: t('Billing:NOTIFICATION_TITLE'),
  notificationsDescription: t('Billing:NOTIFICATION_DESCRIPTION'),
  invoiceTitle: t('Billing:INVOICE_TITLE'),
  invoiceDescription: t('Billing:INVOICE_DESCRIPTION'),
  accountSuspendedPart1: t('Billing:ACCOUNT_SUSPENDED_PART1'),
  accountSuspendedPart2: t('Billing:ACCOUNT_SUSPENDED_PART2'),
  accountSuspendedPart3: t('Billing:ACCOUNT_SUSPENDED_PART3'),
};
