import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../../../store/modals';
import { accountsActions, accountsSelectors, deleteAccountModalActions } from '../../../../store/opsConsole/accounts';
import { Modal } from '../../../common';
import labels from './labels';

export const AccountsDeleteModal = () => {
  const dispatch = useDispatch();
  const accountName = useSelector(accountsSelectors.selectSelectedAccountName);

  const handleCancel = () => {
    dispatch(deleteAccountModalActions.closeModal());
  };

  const handleDelete = () => {
    dispatch(accountsActions.deleteAccountRequest());
  };

  return (
    <Modal.Container name={ModalName.DELETE_ACCOUNT_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem">
        <div className="mb-3">{`${labels.title} "${accountName}" ?`}</div>
        <div className="mb-3">{labels.description}</div>
      </div>
      <Modal.Buttons>
        <Button className="ml-auto" label={labels.cancel} onClick={handleCancel} outlined autoFocus />
        <Button label={labels.title} onClick={handleDelete} severity="danger" />
      </Modal.Buttons>
    </Modal.Container>
  );
};
