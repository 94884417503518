export const Billing = Object.freeze({
  ACCOUNT_SUSPENDED_PART1: `Ihr Konto ist gesperrt. Sie müssen`,
  ACCOUNT_SUSPENDED_PART2: `Ihre ausstehende Rechnung bezahlen`,
  ACCOUNT_SUSPENDED_PART3: `um den Service wieder zu aktivieren.`,
  ADD_SEATS: `Sitze hinzufügen`,
  ADD_SEATS_DESCRIPTION_PART_1: `Hinzufügen`,
  ADD_SEATS_DESCRIPTION_PART_2: `Sitz(e) wird hinzufügen`,
  ADD_SEATS_DESCRIPTION_PART_3: `zu Ihrer`,
  ADD_SEATS_DESCRIPTION_PART_4: `Rechnung, beginnend`,
  ADD_SEATS_PRORATED_PART_1: `Für die verbleibenden`,
  ADD_SEATS_PRORATED_PART_2: `Tage bis zur nächsten Zahlung wird Ihrer Kreditkarte ein anteiliger Betrag von`,
  ADD_SEATS_PRORATED_PART_3: `heute berechnet.`,
  AMOUNT: `Betrag`,
  ANNUAL_TITLE: `Jährlich`,
  ANNUALLY_TITLE: `jährlich`,
  BILL_IS_LATE_PART_1: `Rechnung ist derzeit`,
  BILL_IS_LATE_PART_2_MULTIPLE: `Tage verspätet.`,
  BILL_IS_LATE_PART_2_SINGLE: `Tag verspätet.`,
  BILLING_ADDRESS: `Rechnungsadresse`,
  BILLING_DETAILS_DESCRIPTION: `Verwalten Sie Ihre Zahlungsmethode und Rechnungsadresse`,
  BILLING_DETAILS_TITLE: `Rechnungsdetails`,
  BUY_LICENSES_MESSAGE: `Ihre Rechnung wird sein`,
  BY_INVOICE: `Per Rechnung`,
  CANCEL_SUBSCRIPTION: `Abonnement stornieren`,
  CANCELLATION_TEXT: `Ihr Abonnement wird zum`,
  CANCELLATION_DESCRIPTION: `Möchten Sie die geplante Änderung wirklich stornieren?`,
  CHANGE: `Ändern`,
  CHANGE_SUBSCRIPTION: `Abonnement ändern`,
  CARD: `Karte`,
  CHANGE_TERM: `Laufzeit ändern`,
  CHECKOUT: `Kasse`,
  DAYS: `Tage`,
  DATE_ISSUE: `Ausstellungsdatum`,
  DOWNLOAD: `Herunterladen`,
  EMAIL: `E-Mail`,
  EXPIRE_MESSAGE_PART_1: `Rechnung ist`,
  EXPIRE_MESSAGE_PART_2: `Tage verspätet. Ihre Organisation wird inoperabel in`,
  EXPIRE_MESSAGE_PART_3: `Tagen.`,
  INVOICE: `Rechnung`,
  INVOICE_DESCRIPTION: `Anzeigen und herunterladen`,
  INVOICE_TITLE: `Rechnungshistorie`,
  LICENSE_MESSAGE_PART_1: `Sie haben bereits`,
  LICENSE_MESSAGE_PART_2: `Lizenzen zu Benutzern zugewiesen. Bitte geben Sie mehr`,
  LICENSE_MESSAGE_PART_3: `oder deaktivieren/löschen Sie Benutzer, um Lizenzen freizugeben`,
  LICENSES_PLACEHOLDER: `Geben Sie eine Nummer ein`,
  LICENSES_TITLE: `Lizenzen`,
  LOADING_LABEL_1: `Verbinden…`,
  LOADING_LABEL_2: `Lizenzen hinzufügen`,
  MONTH_TITLE: `Monat`,
  MONTHLY_TITLE: `Monatlich`,
  NEXT_INVOICE_DUE: `Nächste Rechnung fällig`,
  NOTIFICATION_DESCRIPTION: `E-Mail-Benachrichtigungen für neue Rechnungen erhalten`,
  NOTIFICATION_TITLE: `Benachrichtigungen`,
  NUMBER_OF_LICENSES: `# Lizenzen`,
  ORDER_SUMMARY: `Bestellübersicht`,
  PAY_NOW_TITLE: `Jetzt bezahlen`,
  PAYMENT_METHOD: `Zahlungsmethode`,
  PER_USER_MONTH: `pro Benutzer / Monat`,
  PLAN_SILVER: `Silber`,
  PLAN_TRIAL: `Test`,
  RECEIVE_NOTIFICATIONS: `E-Mail-Benachrichtigungen für neue Rechnungen erhalten`,
  REMOVE_SEATS: `Sitze entfernen`,
  REMOVE_SEATS_DESCRIPTION_PART_1: `Entfernen`,
  REMOVE_SEATS_DESCRIPTION_PART_2: `Sitz(e) wird Ihre`,
  REMOVE_SEATS_DESCRIPTION_PART_3: `Rechnung reduzieren um`,
  REMOVE_SEATS_DESCRIPTION_PART_4: `beginnend`,
  REMOVE_SEATS_NOTE_PART1: `Um mehr als`,
  REMOVE_SEATS_NOTE_PART2: `Sitz(e) zu entfernen, können Sie Benutzer deaktivieren oder löschen, um Lizenzen freizugeben.`,
  REACTIVATE: `Wieder aktivieren`,
  SCHEDULED_CHANGES: `Änderungen geplant für`,
  SCHEDULED_COLUMN_CHANGE_BY: `Änderung von`,
  SCHEDULED_COLUMN_DATE: `Anfragedatum`,
  SCHEDULED_COLUMN_LABEL: `Bestelländerung`,
  SCHEDULED_SEATS_CHANGE_PART_1: `Reduzieren`,
  SCHEDULED_SEATS_CHANGE_PART_2: `Lizenzen`,
  SCHEDULED_TERM_CHANGE: `Laufzeit geändert zu`,
  SEATS: `Sitze`,
  STATUS: `Status`,
  STATUS_DUE: `Fällig`,
  STATUS_PAID: `Bezahlt`,
  SUBSCRIPTION: `Abonnement`,
  TERM: `Laufzeit`,
  TERM_ANNUAL: `Jährlich`,
  TERM_MONTHLY: `Monatlich`,
  TITLE: `Abrechnung`,
  TRIAL_LICENSE_EXPIRED: `Ihre SUMO1-Lizenz ist abgelaufen. Um SUMO1 weiterhin ohne Unterbrechungen zu nutzen, kaufen Sie bitte eine neue Lizenz.`,
  USERS: `Benutzer`,
  USERS_OF: `von`,
  YOUR_PLAN: `Ihr Plan`,
  TAX: `Steuer`,
});

export const BillingToastsNotifications = Object.freeze({
  ADD_SEATS_ERROR_TOAST: `Sitze konnten nicht hinzugefügt werden`,
  ADD_SEATS_SUCCESS_TOAST: `Bitte warten Sie bis zu 1 Minute, bevor wir Ihre Zahlung erhalten`,
  ADD_SEATS_SUCCESS_TOAST_TITLE: `Sitze hinzugefügt.`,
  BUY_LICENSE_SUCCESS_TOAST: `Abonnement erfolgreich erstellt`,
  CHANGE_TERM_ERROR_TOAST: `Laufzeit konnte nicht geändert werden.`,
  CHANGE_TERM_SUCCESS_TOAST: `Laufzeit erfolgreich geändert.`,
  CHARGEBEE_PORTAL_CLOSE_TOAST: `Bitte warten Sie bis zu 1 Minute, bevor wir es erhalten, wenn Sie Änderungen vorgenommen haben, und öffnen Sie dann die Abrechnungsseite erneut`,
  DELETE_CHANGE_ERROR_TOAST: `Geplante Änderung konnte nicht gelöscht werden.`,
  DELETE_CHANGE_SUCCESS_TOAST: `Geplante Änderung erfolgreich gelöscht.`,
  GET_BILLING_ERROR_TOAST: `ChargeBee Verbindungsfehler`,
  GET_ITEM_TIERS_ERROR_TOAST: `Konnte die Artikelpreise für Lizenzen nicht erhalten`,
  PAY_LATER_ERROR_TOAST: `Die Zahlung konnte nicht geplant werden.`,
  REMOVE_SEATS_ERROR_TOAST: `Sitze konnten nicht entfernt werden`,
  REMOVE_SEATS_SUCCESS_TOAST: `Änderungen geplant`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1: `Reduzieren`,
  REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2: `Sitze`,
  CALCULATE_TAXES_ERROR_TOAST: `Fehler bei der Steuerberechnung`,
  REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST: `Abonnement erfolgreich wieder aktiviert`,
  REACTIVATE_SUBSCRIPTION_ERROR_TOAST: `Aktivierung des Abonnements fehlgeschlagen. Bitte aktualisieren Sie Ihre Zahlungsmethode.`,
});
