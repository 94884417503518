import { UpdateBookingPageInput } from '../../API';
import { timeZoneItem } from '../../services/TimeZoneService';

export type TimeZoneList = timeZoneItem & { value: string };

export type UpsertBookingPage = {
  id: string;
  displayId: string;
  workspace: string;
};

export type BookingPageOption = {
  label: string;
  value: string;
  displayId?: string;
};

export type BookingPageAndTemplateOption = {
  label: string;
  code: string;
  items: {
    label: string;
    value: string;
    displayId?: string;
  }[];
};

export type AccordionIndexes = {
  what: boolean;
  who: boolean;
  where: boolean;
  when: boolean;
  how: boolean;
  alerts: boolean;
  invitee: boolean;
  after: boolean;
};

export type GetBookingPagePayload = {
  id: string;
  isInitialVisit: boolean;
};

export type GetBookingPagesResponse = {
  bookingPages: UpdateBookingPageInput[];
};

export type PostBookingPageResponse = {
  bookingPage: UpdateBookingPageInput;
};

export type BookingPagesByIdType = {
  [key: string]: UpdateBookingPageInput;
};

export enum BackgroundOption {
  BLANK = 'BLANK',
  WALLPAPER = 'WALLPAPER',
  COLOR = 'COLOR',
}
