import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { TooltipRenderProps } from 'react-joyride';

export const Tooltip: React.FC<TooltipRenderProps> = (props) => {
  const { backProps, closeProps, continuous, index, primaryProps, skipProps, step, tooltipProps, size } = props;

  return (
    <div
      className={classNames(
        'tooltip__body relative bg-primary-white border-radius-14px border-none',
        step.data?.className ? step.data.className : 'w-460px'
      )}
      {...tooltipProps}
    >
      <div className="flex justify-content-between p-20px border-none border-bottom-1 border-solid border-heavy-20">
        {step.title && <p className="tooltip__title text-title-lg-med m-0">{step.title}</p>}
        <button
          className="tooltip__close action-button absolute top-15px right-15px ml-auto bg-primary-white"
          {...closeProps}
          onClick={closeProps.onClick}
        >
          <XMarkIcon className="icon-20px" />
        </button>
      </div>
      <div className="tooltip__content text-body-lg-reg p-20px">
        {!step.data?.hideStepCounter && (
          <div>
            <span className="text-heavy-60 bg-heavy-10 py-6px px-8px border-radius-6px text-label-s-med">
              {index + 1}/{size}
            </span>
          </div>
        )}
        {step.content}
      </div>
      {!step.data?.hideBackButton && !step.data?.hideContinuousButton && !step.data?.hideSkipButton && (
        <div className="tooltip__footer flex flex-row justify-content-between p-20px border-none border-top-1 border-solid border-heavy-20">
          {!step.data?.hideSkipButton && (
            <Button link className="tooltip__button text-label-md-med cursor-pointer" {...skipProps}>
              {skipProps.title}
            </Button>
          )}
          <div className="tooltip__spacer flex flex-row justify-content-between gap-2">
            {!step.data?.hideBackButton && index > 0 && (
              <Button
                link
                className="tooltip__button px-3 text-heavy-80 text-label-md-med cursor-pointer"
                {...backProps}
              >
                {backProps.title}
              </Button>
            )}
            {!step.data?.hideContinuousButton && continuous && (
              <Button
                className="tooltip__button tooltip__button--primary text-label-md-med py-16px px-45px"
                {...primaryProps}
              >
                {primaryProps.title}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
