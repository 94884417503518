import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as EmbedIcon } from '../../../assets/icons/website-embed.svg';
import { ReactComponent as PopupIcon } from '../../../assets/icons/website-popup.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/website-textLink.svg';
import { getUrlOrigin } from '../../../services/URLService';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { ModalName } from '../../../store/modals';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME } from '../../../types/constants';
import { Modal } from '../../common';
import {
  ADD_TO_WEBSITE_OPTIONS,
  ADD_TO_WEBSITE_TYPE,
  BUTTON_POSITION,
  DEFAULT_BUTTON_POSITION,
  DEFAULT_LABEL,
} from './constants';
import labels from './labels';

export const BookingPageAddToWebsiteModal = () => {
  const style = useSelector(bookingPageSelectors.selectStyle);
  const link = useSelector(bookingPageSelectors.selectAddToWebsiteLink);

  const [selectedType, setSelectedType] = useState<ADD_TO_WEBSITE_TYPE | null>(null);
  const [linkLabel, setLinkLabel] = useState(DEFAULT_LABEL);
  const [isFloatingButton, setIsFloatingButton] = useState(true);
  const [buttonPosition, setButtonPosition] = useState(DEFAULT_BUTTON_POSITION);
  const [hidePageDetails, setHidePageDetails] = useState(false);
  const [hideCookieBar, setHideCookieBar] = useState(true);

  const baseUrl = getUrlOrigin();

  const clearState = () => {
    setSelectedType(null);
    setLinkLabel(DEFAULT_LABEL);
    setIsFloatingButton(false);
    setButtonPosition(DEFAULT_BUTTON_POSITION);
    setHidePageDetails(false);
    setHideCookieBar(true);
  };

  const handleBack = () => {
    clearState();
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(getEmbedText());
  };

  const getTitleByType = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return labels.embedTitle;
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return labels.popupTitle;
      case ADD_TO_WEBSITE_TYPE.LINK:
        return labels.linkTitle;
      default:
        return '';
    }
  };

  const getButonLabelByType = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return labels.buttonToPopup;
      case ADD_TO_WEBSITE_TYPE.LINK:
        return labels.linkTitle;
      default:
        return '';
    }
  };

  const getDescriptionByType = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return labels.copyCodeDescEmbed;
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return labels.copyCodeDescPopup;
      case ADD_TO_WEBSITE_TYPE.LINK:
        return labels.copyCodeDescLink;
      default:
        return '';
    }
  };

  const getIconByType = (type: ADD_TO_WEBSITE_TYPE) => {
    switch (type) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return <EmbedIcon />;
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return <PopupIcon />;
      case ADD_TO_WEBSITE_TYPE.LINK:
        return <LinkIcon />;
      default:
        return '';
    }
  };

  const getLink = () => {
    return `${baseUrl}${link}?${[
      'iframe=true',
      `hideCookie=${hideCookieBar}`,
      `hidePageDetails=${hidePageDetails}`,
      `hideHeader=${selectedType === ADD_TO_WEBSITE_TYPE.EMBED}`,
    ].join('&')}`;
  };

  const getEmbedTypeData = () => {
    return `<div id="sumo1-widget" data-url=${getLink()} id="sumo1-widget" style="min-width:320px;height:700px;"></div>`;
  };

  const getFloatingButtonTypeData = () => {
    return `<script type="text/javascript">window.onload = function() { Sumo1Widgets.renderFloatingButton({url:'${getLink()}', background:'${
      style?.primaryColor
    }', text:'${linkLabel}', position: '${buttonPosition}'}); }</script>`;
  };

  const getButtonTypeData = () => {
    return `<button data-url=${getLink()} id="sumo1-widget" style="color:#ffffff;background:${
      style?.primaryColor
    };border-color: ${style?.primaryColor};">${linkLabel}</button>`;
  };

  const getLinkTypeData = () => {
    return `<a id="sumo1-widget" data-url=${getLink()}>${linkLabel}</a>`;
  };

  const getAdditionalData = () => {
    switch (selectedType) {
      case ADD_TO_WEBSITE_TYPE.EMBED:
        return getEmbedTypeData();
      case ADD_TO_WEBSITE_TYPE.POPUP:
        return isFloatingButton ? getFloatingButtonTypeData() : getButtonTypeData();
      case ADD_TO_WEBSITE_TYPE.LINK:
        return getLinkTypeData();
      default:
        return '';
    }
  };

  const getEmbedText = () => {
    return (
      `<!-- SUMO1 ${getTitleByType()} widget begin -->\n` +
      `<link href="${baseUrl}/addToWebsite/addToWebsite.css" rel="stylesheet">\n` +
      `<script src="${baseUrl}/addToWebsite/addToWebsite.js" type="text/javascript" id="sumo1-widget" async></script>\n` +
      `${getAdditionalData()}\n` +
      `<!-- SUMO1 ${getTitleByType()} widget end -->`
    );
  };

  const EmbedOptions = () => {
    return (
      <>
        <div className="border-left-1 border-heavy-20" />
        <div className="flex flex-column gap-28px w-280px">
          <div className="flex flex-column gap-4px">
            <div className="text-title-xs-med">{labels.customizeTitle}</div>
            <div className="text-body-s-reg text-heavy-60">{labels.customizeDesc}</div>
          </div>

          <div className="flex flex-row align-items-center gap-10px py-2">
            <InputSwitch
              checked={hideCookieBar}
              inputId="hideCookieBar"
              className="mr-2"
              onChange={(e) => setHideCookieBar(!!e.value)}
            />
            <label htmlFor="hideCookieBar">{labels.hideCookieBanner}</label>
          </div>
        </div>
      </>
    );
  };
  const PopupOptions = () => {
    return (
      <>
        <div className="border-left-1 border-heavy-20" />
        <div className="flex flex-column gap-28px w-280px">
          <div className="flex flex-column gap-4px">
            <div className="text-title-xs-med">{labels.customizeTitle}</div>
            <div className="text-body-s-reg text-heavy-60">{labels.customizeDesc}</div>
          </div>

          <div className="flex flex-row align-items-center gap-10px py-2">
            <InputSwitch
              checked={hideCookieBar}
              inputId="hideCookieBar"
              className="mr-2"
              onChange={(e) => setHideCookieBar(!!e.value)}
            />
            <label htmlFor="hideCookieBar">{labels.hideCookieBanner}</label>
          </div>

          <div className="flex flex-column gap-10px">
            <div className="text-title-xs-med">
              {getButonLabelByType()} {labels.label}
            </div>
            <InputText
              className="w-full"
              value={linkLabel}
              onChange={(e) => setLinkLabel(e.target.value)}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>

          <div className="flex-left-center gap-10px">
            <InputSwitch checked={isFloatingButton} onChange={(e) => setIsFloatingButton(!!e.value)} />
            <div className="text-title-xs-med text-heavy-80">{labels.floatingButtonDesc}</div>
          </div>

          <div className="flex flex-column gap-10px">
            <div className="text-title-xs-med">{labels.position}</div>
            <Dropdown
              className="w-full"
              disabled={!isFloatingButton}
              value={buttonPosition}
              options={BUTTON_POSITION}
              onChange={(e) => setButtonPosition(e.target.value)}
            />
          </div>
        </div>
      </>
    );
  };
  const LinkOptions = () => {
    return (
      <>
        <div className="border-left-1 border-heavy-20" />
        <div className="flex flex-column gap-28px w-280px">
          <div className="flex flex-column gap-4px">
            <div className="text-title-xs-med">{labels.customizeTitle}</div>
            <div className="text-body-s-reg text-heavy-60">{labels.customizeDesc}</div>
          </div>

          <div className="flex flex-row align-items-center gap-10px py-2">
            <InputSwitch
              checked={hideCookieBar}
              inputId="hideCookieBar"
              className="mr-2"
              onChange={(e) => setHideCookieBar(!!e.value)}
            />
            <label htmlFor="hideCookieBar">{labels.hideCookieBanner}</label>
          </div>

          <div className="flex flex-column gap-10px">
            <div className="text-title-xs-med">
              {getButonLabelByType()} {labels.label}
            </div>
            <InputText
              className="w-full"
              value={linkLabel}
              onChange={(e) => setLinkLabel(e.target.value)}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal.Container name={ModalName.ADD_TO_WEBSITE_MODAL} className="w-840px" closable>
      <Modal.Header>{labels.title}</Modal.Header>

      {!selectedType ? (
        <div className="flex-center-top gap-24px py-32px">
          {ADD_TO_WEBSITE_OPTIONS.map((item, index) => (
            <div
              className="sumo-card action-card flex flex-column gap-4px w-240px py-20px px-16px"
              key={index}
              onClick={() => setSelectedType(item.type)}
            >
              {getIconByType(item.type)}
              <div className="text-title-s-med mt-4px">{item.label}</div>
              <div className="text-body-lg-reg">{item.desc}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex gap-40px">
          <div className="flex-1 flex flex-column gap-4px">
            <div className="text-title-xs-med">{getTitleByType()}</div>
            <div className="text-body-s-reg text-heavy-60">{getDescriptionByType()}</div>
            <InputTextarea
              className="mt-12px"
              readOnly={true}
              rows={9}
              autoResize
              value={getEmbedText()}
              maxLength={MAX_LENGTH_DESCRIPTION}
            />
          </div>
          {selectedType === ADD_TO_WEBSITE_TYPE.EMBED ? <EmbedOptions /> : null}
          {selectedType === ADD_TO_WEBSITE_TYPE.POPUP ? <PopupOptions /> : null}
          {selectedType === ADD_TO_WEBSITE_TYPE.LINK ? <LinkOptions /> : null}
        </div>
      )}

      {selectedType && (
        <Modal.Buttons>
          <Button label={labels.copyCodeTitle} onClick={handleCopy} />
          <Button label={labels.back} text onClick={handleBack} />
        </Modal.Buttons>
      )}
    </Modal.Container>
  );
};
