import { NotificationTypes } from '../../../../store/bookingTemplates';
import labels from './labels';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';

type NotificationItemProps = {
  type: NotificationTypes;
  onOpen: () => void;
  handleEnabledChange: (value: boolean) => void;
  disabled?: boolean;
  isEnabled: boolean
};

export const NotificationItem = ({ type, disabled, onOpen, handleEnabledChange, isEnabled }: NotificationItemProps) => {
  const getTitle = () => {
    switch (type) {
      case NotificationTypes.CONFIRMATION:
        return labels.confirmationTitle;
      case NotificationTypes.RESCHEDULE:
        return labels.rescheduleTitle;
      case NotificationTypes.REMINDER:
        return labels.reminderTitle;
      case NotificationTypes.CANCELATION:
        return labels.cancelationTitle;
      case NotificationTypes.FOLLOW_UP:
        return labels.followUpTitle;
      case NotificationTypes.SMS:
        return labels.smsTitle;
    }
  };

  const getDescription = () => {
    switch (type) {
      case NotificationTypes.CONFIRMATION:
        return labels.confirmationDescription;
      case NotificationTypes.RESCHEDULE:
        return labels.rescheduleDescription;
      case NotificationTypes.REMINDER:
        return labels.reminderDescription;
      case NotificationTypes.CANCELATION:
        return labels.cancelationDescription;
      case NotificationTypes.FOLLOW_UP:
        return labels.followUpDescription;
      case NotificationTypes.SMS:
        return labels.smsDescription;
    }
  };

  return (
    <div className="flex gap-10px">
      <InputSwitch
        checked={isEnabled}
        onChange={(e) => handleEnabledChange(Boolean(e.value))}
        disabled={disabled}
      />
      <div className="flex-1 pt-4px">
        <div className="text-title-xs-med text-heavy-100">{getTitle()}</div>
        <div className="text-body-s-reg text-heavy-60" style={{ whiteSpace: 'pre-wrap' }}>
          {getDescription()}
        </div>
        <Button
          text
          className="mt-12px button-text-line button-blue"
          label={labels.personalize}
          onClick={onOpen}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
