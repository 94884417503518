import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';
import { authenticationActions } from '../../../store/authentication';
import { UserSettingsKeys } from '../../../store/userSettings';
import { CookieConsent } from '../../common';
import labels from './labels';

type BookingPageCookieModalProps = {
  visible: boolean;
};

export const BookingPageCookieModal: React.FC<BookingPageCookieModalProps> = ({ visible }) => {
  const dispatch = useDispatch();

  const handleSave = () => {
    localStorage.setItem(UserSettingsKeys.COOKIE_SAVED, 'true');
    dispatch(authenticationActions.setCookieOpen(false));
  };

  return (
    <Dialog
      visible={visible}
      className="cookie-consent-modal w-820px"
      showHeader={false}
      contentClassName="p-20px border-round"
      onHide={handleSave}
      resizable={false}
      focusOnShow={false}
      closable={false}
      modal={false}
      position="bottom-left"
    >
      <CookieConsent title={labels.cookieTitle} />
      <Button
        size="large"
        label={labels.saveAndClose}
        className="h-48px w-164px min-w-120px mt-20px"
        onClick={handleSave}
        autoFocus
      />
    </Dialog>
  );
};
