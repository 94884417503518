import { combineReducers } from 'redux';
import { PromoAction, PromoActionTypes } from './actions';

const selectIsGuideVisible = (state = true, action: PromoAction) => {
  switch (action.type) {
    case PromoActionTypes.SHOW_GUIDE:
      return true;
    case PromoActionTypes.HIDE_GUIDE:
      return false;
    default:
      return state;
  }
};

const selectIsGuideRunning = (state = false, action: PromoAction) => {
  switch (action.type) {
    case PromoActionTypes.RUN_GUIDE:
      return true;
    case PromoActionTypes.STOP_GUIDE:
      return false;
    default:
      return state;
  }
};

const selectGuideStepIndex = (state = 0, action: PromoAction) => {
  switch (action.type) {
    case PromoActionTypes.SET_GUIDE_STEP_INDEX:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({ selectIsGuideVisible, selectIsGuideRunning, selectGuideStepIndex });
