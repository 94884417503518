import { useDispatch } from 'react-redux';
import YouTube, { YouTubeEvent } from 'react-youtube';
import { ModalName } from '../../../store/modals';
import { installModalActions } from '../../../store/promo';
import { AppDispatch } from '../../../store/rootStore';
import { Modal } from '../../common/modal/Modal';
import labels from './labels';

export const InstallModal = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleCancel = () => {
    dispatch(installModalActions.closeModal());
  };

  return (
    <Modal.Container name={ModalName.INSTALL_MODAL} closable={true} onClose={handleCancel} className="w-680px">
      <Modal.Header>
        <div>{labels.sumoInboxTopTitle}</div>
      </Modal.Header>
      <YouTube
        opts={{
          width: '640',
          height: '480',
          playerVars: {
            autoplay: 1,
          },
        }}
        videoId="3JUBWokK4yc"
        title="SUMO Scheduler - Salesforce AppExchange 60 Seconds"
        onEnd={(event: YouTubeEvent<number>) => {
          event.target.pauseVideo();
        }}
      />
    </Modal.Container>
  );
};
