import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import labels from './labels';
import { useEffect, useState } from 'react';
import { CSVFileUpload, Preloader } from '../../components/common';
import Papa from 'papaparse';
import {
  getAddressComponent,
  ImportLocationsLabels,
  LocationImportType,
  locationsActions,
  locationsSelectors,
  VALIDATE_LOCATIONS_ERROR_TOAST,
} from '../../store/locations';
import { useSelector } from 'react-redux';
import { AddressInput, CreatePhysicalLocationInput, WorkspaceIntegrationType } from '../../API';
import { authenticationSelectors } from '../../store/authentication';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/rootStore';
import { notificationsActions } from '../../store/notifications';
import ImportLocations from '../../templates/ImportLocations.csv';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { userSettingsSelectors } from '../../store/userSettings';

export const LocationsImport = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(locationsSelectors.selectIsFetching);
  const locations = useSelector(locationsSelectors.selectLocations);
  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const isCreate = useSelector(userSettingsSelectors.selectLocationsCreate);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [localFething, setLocalFetching] = useState(false);
  const isMapsConnected = useSelector(
    authenticationSelectors.selectIsWorkspaceIntegrationConnected(WorkspaceIntegrationType.GOOGLE_MAPS)
  );

  const geocoder = isMapsConnected ? new google.maps.Geocoder() : null;

  useEffect(() => {
    if (!isCreate) {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, []);

  const handleBack = () => {
    navigationService.navigateTo(Path.Locations);
  };

  const validateData = (data: LocationImportType[]) => {
    return data.every(
      (record) =>
        !!record.name && !!record.address && !!record.country && !!record.state && !!record.zip && !!record.city
    );
  };

  const isUniqueImportLocation = (record: LocationImportType) => {
    return !locations.find(
      (location) =>
        location.name === record.name &&
        location.address?.name === record.address &&
        location.address.city === record.city &&
        location.address.country === record.country &&
        location.address.state === record.state &&
        location.address.zip === record.zip
    );
  };

  const isUniqueConvertedLocation = (record: AddressInput | null | undefined) => {
    return !locations.find(
      (location) =>
        location.address?.name === record?.name &&
        location.address?.city === record?.city &&
        location.address?.country === record?.country &&
        location.address?.state === record?.state &&
        location.address?.zip === record?.zip
    );
  };

  const convertAndUploadRecords = async (data: LocationImportType[]) => {
    const results: CreatePhysicalLocationInput[] = [];
    const uniqueRecords = data.filter((record) => isUniqueImportLocation(record));
    await Promise.all(
      uniqueRecords.map(async (record) => {
        const item = {
          workspaceId,
          name: record.name,
          active: true,
          address: {
            name: record.address,
            city: record.city,
            country: record.country,
            state: record.state,
            zip: record.zip,
          },
        } as CreatePhysicalLocationInput;
        if (geocoder) {
          const response: google.maps.GeocoderResponse = await geocoder.geocode({
            address:
              (record.address || '') +
              ' ' +
              (record.city || '') +
              ' ' +
              (record.country || '') +
              ' ' +
              (record.state || '') +
              ' ' +
              (record.zip || ''),
          });

          if (response.results && response.results[0]) {
            const place = response.results[0];
            const addressComponents = place.address_components;
            if (item.address) {
              item.address = {
                name:
                  getAddressComponent(addressComponents, 'street_number') +
                  ' ' +
                  getAddressComponent(addressComponents, 'route'),
                city: getAddressComponent(addressComponents, 'locality'),
                state: getAddressComponent(addressComponents, 'administrative_area_level_1'),
                country: getAddressComponent(addressComponents, 'country'),
                zip: getAddressComponent(addressComponents, 'postal_code'),
                lat: place.geometry?.location?.lat().toString(),
                lng: place.geometry?.location?.lng().toString(),
              };
            }
          }
        }
        isUniqueConvertedLocation(item.address) && results.push(item);
      })
    );
    dispatch(locationsActions.importLocationsRequest(results));
  };

  const handleImport = () => {
    if (uploadedFile) {
      setLocalFetching(true);
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header: string, index: number) => ImportLocationsLabels[index],
        complete: function (results: Papa.ParseResult<LocationImportType>) {
          if (validateData(results.data)) {
            convertAndUploadRecords(results.data);
          } else {
            setLocalFetching(false);
            dispatch(notificationsActions.showToast(VALIDATE_LOCATIONS_ERROR_TOAST));
          }
        },
        error: (error: Error, file: File) => {
          setLocalFetching(false);
          console.error(`Parsing file ${file.name} failed. Error: ${error.message}`);
        },
      });
    }
  };

  return (
    <div>
      {(isFetching || localFething) && <Preloader />}
      <div className="flex-left-center -mt-24px mb-4px">
        <Button className="button-blue gap-8px" text onClick={handleBack}>
          <ArrowLeftIcon className="icon-16px" />
          <div>{labels.back}</div>
        </Button>
      </div>
      <div className="sumo-card-bg flex flex-column p-24px">
        <div className="flex-1 text-title-xl-med">{labels.importCSV}</div>
        <div className="text-body-lg-reg text-heavy-60 pt-8px">
          {labels.importDescriptionPart1}
          <a href={ImportLocations} download="ImportLocations">
            {labels.importDescriptionPart2}
          </a>
          {labels.importDescriptionPart3}
        </div>

        <div className="sumo-card p-20px pt-24px mt-16px">
          <CSVFileUpload setUploadedFile={setUploadedFile} uploadedFile={uploadedFile} />

          <div className="border-top-1 border-heavy-20 mt-32px flex-left-center gap-6px pt-16px -mb-4px -mx-22px px-22px">
            <Button
              className="button-xl flex-center"
              style={{ minWidth: '120px' }}
              onClick={handleImport}
              disabled={!uploadedFile}
            >
              {labels.importLabel}
            </Button>
            <Button className="button-xl" text onClick={handleBack}>
              {labels.cancel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
