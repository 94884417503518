export const Locations = Object.freeze({
  TITLE: `Standorte`,
  CLONE_TITLE: `Geben Sie den Namen des geklonten Standorts ein`,
  ADD_LABEL: `Neuer Standort`,
  DEACTIVATE_TITLE: `Standort deaktivieren`,
  DEACTIVATE_DESCRIPTION: `Möchten Sie wirklich deaktivieren`,
  DELETE_TITLE: `Standort löschen`,
  DELETE_DESCRIPTION: `Möchten Sie wirklich löschen`,
  IMPORT_CSV: `CSV importieren`,
  IMPORT_LABEL: `Importieren`,
  STATUS: `Status`,
  ACTIVE: `Aktiv`,
  INACTIVE: `Inaktiv`,
  UPLOAD_DESCRIPTION_PART1: `Wählen Sie eine CSV-Datei auf Ihrem Computer aus`,
  UPLOAD_DESCRIPTION_PART2: `oder ziehen Sie sie in diesen Upload-Bereich`,
  IMPORT_DESCRIPTION_PART1: `Hier finden Sie eine `,
  IMPORT_DESCRIPTION_PART2: `Beispieldatei`,
  IMPORT_DESCRIPTION_PART3: ``,
  EMPTY_LIST_DESCRIPTION: `Erstellen Sie einen Standort oder passen Sie Ihre Suche oder Filter an.`,
  SELECTED_LOCATIONS: `ausgewählte Standorte`,
  NO_MAPS_INTEGRATION_PART1: `Um die Standortanzeige auf Google Maps für die öffentliche Buchungsseite zu aktivieren, richten Sie das Google Maps Platform-Konto und den API-Schlüssel im `,
  NO_MAPS_INTEGRATION_PART2: `Integrationen`,
  NO_MAPS_INTEGRATION_PART3: ` Abschnitt ein.`,
  LOCATION: `Standort`,
  SELECT_LOCATION: `Standort auswählen`,
});

export const EditLocation = Object.freeze({
  ADD_LOCATION: `Standort hinzufügen`,
  NAME_PLACEHOLDER: `Standortnamen eingeben`,
  ADDRESS: `Adresse`,
});

export const LocationsToastsNotifications = Object.freeze({
  GET_LOCATIONS_ERROR_MESSAGE: `Abrufen der Standorte fehlgeschlagen`,
  DEACTIVATE_LOCATION_SUCCESS_MESSAGE: `Standort erfolgreich deaktiviert`,
  ACTIVATE_LOCATION_SUCCESS_MESSAGE: `Standort erfolgreich aktiviert`,
  DEACTIVATE_LOCATION_ERROR_MESSAGE: `Aktivieren/Deaktivieren des Standorts fehlgeschlagen`,
  CLONE_LOCATION_SUCCESS_MESSAGE: `Standort erfolgreich geklont`,
  CLONE_LOCATION_ERROR_MESSAGE: `Klonen des Standorts fehlgeschlagen`,
  DELETE_LOCATION_SUCCESS_MESSAGE: `Standort erfolgreich gelöscht`,
  DELETE_LOCATION_ERROR_MESSAGE: `Löschen des Standorts fehlgeschlagen`,
  VALIDATE_LOCATIONS_ERROR_MESSAGE: `Ein oder mehrere Datensätze enthalten leere Felder. Alle Felder sind erforderlich.`,
  IMPORT_LOCATIONS_SUCCESS_MESSAGE: `Standorte erfolgreich importiert`,
  IMPORT_LOCATIONS_ERROR_MESSAGE: `Importieren der Standorte fehlgeschlagen`,
  GET_LOCATION_ERROR_MESSAGE: `Fehler beim Abrufen des Standorts`,
  SAVE_LOCATION_ERROR_MESSAGE: `Fehler beim Speichern des Standorts`,
  CREATE_LOCATION_SUCCESS_MESSAGE: `Standort erfolgreich erstellt`,
  UPDATE_LOCATION_SUCCESS_MESSAGE: `Standort erfolgreich aktualisiert`,
});
