import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { UserSettingsKeys } from '../../../store/userSettings';
import labels from './labels';

export type CookieConsentProps = {
  title?: string;
};

export const CookieConsent: React.FC<CookieConsentProps> = ({ title = labels.manageTitle }) => {
  const [showAll, setShowAll] = useState(false);

  const [cookies, setCookies] = useState({
    necessary: true,
    performance: true,
    functional: true,
    targeting: true,
  });

  const handleChange = (event: CheckboxChangeEvent) => {
    setCookies((prev) => {
      const newCookies = { ...prev, [event.target.value]: event.target.checked };
      localStorage.setItem(UserSettingsKeys.COOKIES, JSON.stringify(newCookies));
      return newCookies;
    });
  };

  useEffect(() => {
    const localCookies = localStorage.getItem(UserSettingsKeys.COOKIES);
    if (localCookies) {
      setCookies(JSON.parse(localCookies));
    }
  }, []);

  // do not open cookie automatically in v1, used only in account
  // useEffect(() => {
  //   const storedCookies = localStorage.getItem(UserSettingsKeys.COOKIES);
  //   if (!storedCookies) {
  //     dispatch(authenticationActions.setCookieOpen(true));
  //   } else {
  //     setCookies(JSON.parse(storedCookies));
  //   }
  // }, []);

  return (
    <div className="cookie-consent text-heavy-80">
      <div className="text-title-xs-med text-heavy-100 mb-12px">{title}</div>

      <div className="cookie-consent-body flex gap-20px">
        <div className="flex-1 text-body-s-reg">
          <p className="mt-0">{labels.descriptionFirst}</p>
          <p className={classNames('hidden md:block', showAll ? 'block' : 'hidden')}>{labels.descriptionSecond}</p>
          <p className={classNames('hidden md:block', showAll ? 'block' : 'hidden')}>{labels.descriptionThird}</p>
          <Button link={true} onClick={() => setShowAll((prev) => !prev)} className="md:hidden px-0">
            {showAll ? labels.hideAll : labels.showAll}
          </Button>
        </div>
        <div className="flex flex-column w-280px gap-12px md:px-40px px-0 text-body-lg-reg">
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="necessary"
              name="option"
              value="necessary"
              checked={cookies.necessary}
              onChange={handleChange}
              disabled={true}
            />
            <label htmlFor="necessary">{labels.strictlyName}</label>
          </div>
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="performance"
              name="option"
              value="performance"
              checked={cookies.performance}
              onChange={handleChange}
            />
            <label htmlFor="performance">{labels.performanceName}</label>
          </div>
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="functional"
              name="option"
              value="functional"
              checked={cookies.functional}
              onChange={handleChange}
            />
            <label htmlFor="functional">{labels.functionalName}</label>
          </div>
          <div className="flex-left-center gap-8px">
            <Checkbox
              inputId="targeting"
              name="option"
              value="targeting"
              checked={cookies.targeting}
              onChange={handleChange}
            />
            <label htmlFor="targeting">{labels.targetingName}</label>
          </div>
        </div>
      </div>
    </div>
  );
};
