import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { WorkspaceIntegrationType } from '../../../API';
import { ConnectFormDataType, integrationIcon, integrationLabels } from '../../../store/integration';
import labels from './labels';

type IConnectFormIntegrationProps = {
  integrationType: WorkspaceIntegrationType;
  IntegrationIcon?: React.ReactElement;
  handleSave: (formData: ConnectFormDataType) => void;
  handleCancel: () => void;
  contentForm: {
    type: 'input';
    uniqueName: string;
    placeholder: string;
    visibleName: string;
    value?: string;
  }[];
  validate?: (formData: ConnectFormDataType) => boolean;
  ValidateError?: React.ReactElement;
};

export const ConnectFormIntegration: React.FC<IConnectFormIntegrationProps> = ({
  integrationType,
  IntegrationIcon,
  handleSave,
  handleCancel,
  contentForm,
  validate,
  ValidateError,
}) => {
  const initialFormDataState: ConnectFormDataType = {};
  contentForm.forEach((item) => {
    initialFormDataState[item.uniqueName] = item.value || '';
  });

  const [formData, setFormData] = useState<ConnectFormDataType>(initialFormDataState);
  const [isValidForm, setIsValidForm] = useState<boolean>(true);

  const setNewValueFormData = (uniqueName: string, value: string) => {
    setFormData((prevState) => {
      const newData = { ...prevState, [uniqueName]: value };
      if (!isValidForm && validate) {
        setIsValidForm(validate(newData));
      }
      return newData;
    });
  };

  const handlerSave = () => {
    if (validate) {
      const isValid = validate(formData);
      setIsValidForm(isValid);
      if (!isValid) return;
    }
    handleSave(formData);
  };

  return (
    <div className="sumo-card border-none flex gap-16px h-full py-24px px-20px">
      <div className="flex-initial gap-20px">
        {IntegrationIcon || (
          <img src={integrationIcon[integrationType as keyof typeof integrationIcon]} className="w-56px h-56px" />
        )}
      </div>
      <div className="flex-1">
        <div className="text-title-xs-med mb-4px">
          {labels.setUp} {integrationLabels[integrationType as keyof typeof integrationLabels]}
        </div>
        <div className="text-body-s-reg text-heavy-60 mb-2">
          {labels.setUpDesc[integrationType]?.split('\n').map((line, index) => (
            <div key={index} className="w-12 lg:w-8 mb-6px">
              {line}
            </div>
          ))}
          <div className="flex flex-column p-fluid formgrid mt-18px">
            {contentForm.map((item) => {
              return (
                <div key={item.uniqueName} className="w-12 lg:w-6 field">
                  <label htmlFor={item.uniqueName} className="text-800 font-semibold">
                    {item.visibleName}
                  </label>
                  <InputText
                    id={item.uniqueName}
                    type="text"
                    aria-required={true}
                    placeholder={item.placeholder}
                    value={formData[item.uniqueName] || ''}
                    onChange={(e) => setNewValueFormData(e.target.id, e.target.value)}
                    className={`${!isValidForm && !formData[item.uniqueName] ? 'p-invalid' : ''}`}
                  />
                </div>
              );
            })}
          </div>
          {!isValidForm && ValidateError}
        </div>
        <div>
          <Button label={labels.save} className="button-xl mr-20px" onClick={handlerSave} disabled={!isValidForm} />
          <Button label={labels.cancel} className="button-xl" hidden outlined onClick={handleCancel} />
        </div>
      </div>
    </div>
  );
};
