export const EditTeam = Object.freeze({
  AVAILABLE_FOR_USE: `Available for Use`,
  AVAILABLE_FOR_USE_TOOLTIP: `This will activate or deactivate this team from being available in all booking tools, including Booking Pages, Booking Templates, and more.`,
  EDIT_TEAM: `Edit Team`,
  MEMBERS_LABEL: `Add team members`,
  MEMBERS_PLACEHOLDER: `Search & Add Team Member`,
  ROUND_ROBIN_DESC_PART1: `Round-Robin`,
  ROUND_ROBIN_DESC_PART2: `In order for SUMO1 to auto-assign a Host for a meeting, you must create a team (aka a resource pool) of SUMO1 users. Then users and admins may add Teams to meetings via Booking Pages or SUMO Meeting Booker (Chrome Extension).`,
  TEAM_NAME: `Display Name`,
  TEAM_NAME_TOOLTIP: `This text will be displayed to invitees on the Public Booking Page. For example: “Sales Engineer Team” or “Dentist Team” would be good names.`,
  TEAM_NAME_PLACEHOLDER: `Enter a team name`,
  WORKSPACES: `Workspaces`,
  WORKSPACES_LABEL: `Default Workspace`,
  WORKSPACES_PLACEHOLDER: `Search & Add Workspace`,
  DEFAULT_WORKSPACE_TOOLTIP: `Workspace where the team was created and can be modified or deleted.`,
});

export const TeamsToastsNotifications = Object.freeze({
  ACTIVATE_TEAM_SUCCESS_MESSAGE: `Team successfully activated`,
  DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE: `Deactivate/Activate Team request fail`,
  DEACTIVATE_TEAM_SUCCESS_MESSAGE: `Team successfully deactivated`,
  DELETE_TEAM_ERROR_MESSAGE: `Delete Team request fail`,
  DELETE_TEAM_SUCCESS_MESSAGE: `Team successfully deleted`,
  GET_TEAMS_FAIL_MESSAGE: `Get Teams request fail`,
  SAVE_TEAM_ERROR_MESSAGE: `Save Team request fail`,
  SAVE_TEAM_SUCCESS_MESSAGE: `Team successfully saved`,
});

export const Teams = Object.freeze({
  CREATE_TEAM: `Create Team`,
  CREATED_BY: `Created By`,
  CREATED_DATE: `Created Date`,
  STATUS: `Status`,
  ACTIVE: `Active`,
  INACTIVE: `Inactive`,
  DEACTIVATE_TEAM_DESCRIPTION: `Are you sure you would like to deactivate`,
  DEACTIVATE_TEAM_TITLE: `Deactivate Team`,
  DELETE_TEAM_DESCRIPTION: `Are you sure you would like to delete`,
  DELETE_TEAM_TITLE: `Delete Team`,
  MEMBERS: `Members`,
  NAME: `Name`,
  TITLE: `Teams`,
  TOOLTIP: `Teams are important to define if you want SUMO to automatically assign a meeting host or co-host, based on assignment logic (round-robin, etc).`,
  WORKSPACE: `Workspace`,
});
