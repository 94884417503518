import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ConfirmationModal } from '../../components/common';
import { Button } from 'primereact/button';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CodeBracketIcon,
  EyeIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import labels from './labels';
import { Fragment, useEffect, useState } from 'react';
import { useSession } from '../../hooks/useSession';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { GroupBookingPageBasicSettings } from '../../components/groupBookingPages/groupBookingPageBasicSettings/GroupBookingPageBasicSettings';
import { GroupBookingPageHowStep } from '../../components/groupBookingPages/groupBookingPageHowStep/GroupBookingPageHowStep';
import { groupBookingPagesActions, groupBookingPagesSelectors } from '../../store/groupBookingPages';
import { addToWebsiteModalActions, bookingPagesActions, bookingPageSelectors } from '../../store/bookingPages';
import { InputSwitch } from 'primereact/inputswitch';
import { BookingPageAddToWebsiteModal } from '../../components/bookingPages';
import { ProgressSpinner } from 'primereact/progressspinner';

export const EditGroupBookingPage = () => {
  const { groupBookingPageId } = useParams();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const dispatch = useDispatch();
  const isFetching = useSelector(groupBookingPagesSelectors.selectIsFetching);
  const isEdited = useSelector(groupBookingPagesSelectors.selectIsEdited);
  const groupBookingPage = useSelector(groupBookingPagesSelectors.selectGroupBookingPage);
  const linkedBookingPages = useSelector(bookingPageSelectors.selectLinkedBookingPages(groupBookingPage));
  const name = useSelector(groupBookingPagesSelectors.selectGroupBookingPageName);
  const link = useSelector(groupBookingPagesSelectors.selectGroupBookignPageLink);
  const isGroupPageValid = useSelector(groupBookingPagesSelectors.selectIsGroupBookingPageValid);

  const [index1, setIndex1] = useState(true);
  const [index2, setIndex2] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const headerTemplate = (title: string, description: string, index: boolean) => (
    <div className="flex-left-center px-20px py-8px">
      <div className="flex-1 flex flex-column gap-2px text-title-xs-med pb-4px">
        <div className="text-title-s-med text-heavy-100">{title}</div>
        <div className="text-body-s-reg">{description}</div>
      </div>
      <div className="flex-none">
        {index ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
      </div>
    </div>
  );

  useEffect(() => {
    setIndex1(true);
    setIndex2(false);
    dispatch(
      groupBookingPagesActions.getGroupBookingPageRequest({
        id: !groupBookingPageId || groupBookingPageId === 'new' ? '' : groupBookingPageId,
        isInitialVisit,
      })
    );
  }, []);

  const handleViewGroupBookingPage = () => {
    window.open(link, '_blank');
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
  };

  const handleActiveChange = (active: boolean) => {
    dispatch(groupBookingPagesActions.updateGroupBookingPage({ active }));
  };

  const handleAddToWebsite = () => {
    dispatch(
      bookingPagesActions.setAddToWebsiteLink(
        Path.PublicGroupBookingPage.replace(':groupBookingPageId', groupBookingPage.id)
      )
    );
    dispatch(addToWebsiteModalActions.openModal());
  };

  const handleBack = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      handleConfirmCancel();
    }
  };

  const handleConfirmCancel = () => {
    navigationService.navigateTo(Path.BookingPages);
    dispatch(groupBookingPagesActions.resetGroupBookingPage());
  };

  const handleSave = () => {
    dispatch(groupBookingPagesActions.upsertGroupBookingPageRequest());
  };

  const generateHeader = () => (
    <div className="sumo-card border-none">
      <div className="flex-between px-20px py-16px">
        <div className="flex-center gap-6px">
          <div className="text-title-lg-med">{name}</div>
          {isFetching && <ProgressSpinner className="w-32px h-32px" strokeWidth="5" />}
        </div>
        <div className="flex-center gap-8px">
          <Button
            outlined
            className="button-icon"
            onClick={handleViewGroupBookingPage}
            disabled={!groupBookingPage.id || !groupBookingPage.active}
          >
            <EyeIcon className="icon-18px" />
          </Button>
          <Button
            outlined
            className="button-icon"
            onClick={handleAddToWebsite}
            disabled={!groupBookingPage.id || !groupBookingPage.active}
          >
            <CodeBracketIcon className="icon-18px" />
          </Button>
          <Button
            outlined
            className="button-icon"
            onClick={handleCopyLink}
            disabled={!groupBookingPage.id || !groupBookingPage.active}
          >
            <LinkIcon className="icon-18px" />
          </Button>
        </div>
      </div>
      <div className="flex-left-center gap-6px text-body-s-reg text-heavy-60 pt-12px pb-18px px-20px border-top-1 border-heavy-20">
        {linkedBookingPages.map((page, index) => (
          <Fragment key={`name_${page.id}`}>
            {index > 0 && <div className="w-4px h-4px border-radius-2px bg-heavy-50" />}
            <div>{page.what?.customName}</div>
          </Fragment>
        ))}
      </div>
      <div className="flex-between-center gap-6px pb-18px px-20px">
        <div className="flex-left-center gap-6px">
          <Button className="min-w-90px" label={labels.save} onClick={handleSave} disabled={!isGroupPageValid} />
          <Button label={labels.cancel} text onClick={handleBack} />
        </div>
        <div className="flex align-items-center gap-10px">
          <InputSwitch
            inputId="active"
            checked={groupBookingPage.active || false}
            onChange={(e) => handleActiveChange(!!e.value)}
          />
          <label htmlFor="active" className="text-label-lg-med cursor-pointer">
            {labels.active}
          </label>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <BookingPageAddToWebsiteModal />
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        onConfirm={handleConfirmCancel}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column gap-16px">
        {generateHeader()}

        <Accordion
          className="sumo-card border-none"
          activeIndex={index1 ? 0 : null}
          onTabChange={() => setIndex1((prev) => !prev)}
        >
          <AccordionTab
            contentClassName="px-20px pt-12px pb-24px"
            header={headerTemplate(labels.basicSettings, labels.basicSettingsDesc, index1)}
          >
            <GroupBookingPageBasicSettings />
          </AccordionTab>
        </Accordion>
        <Accordion
          className="sumo-card border-none"
          activeIndex={index2 ? 0 : null}
          onTabChange={() => setIndex2((prev) => !prev)}
        >
          <AccordionTab
            contentClassName="px-20px pt-12px pb-24px"
            header={headerTemplate(labels.branding, labels.brandingDesc, index2)}
          >
            <GroupBookingPageHowStep />
          </AccordionTab>
        </Accordion>
      </div>
    </>
  );
};
