export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Você pode conectar apenas 1 calendário`, // Para aumentar esse limite, entre em contato com vendas para atualizar seu nível de licença.
  DESCRIPTION: `Conecte seu calendário para que o SUMO saiba quando você está disponível e atualize seu calendário à medida que os compromissos são agendados.`,
  DISCONNECT_MESSAGE: `Você tem certeza de que deseja desconectar seu calendário?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Os compromissos não serão excluídos ou cancelados.`,
  DISCONNECT_TITLE: `Desconectar Calendário`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 ou 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendário Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendário Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com ou Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Calendário Office 365`,
  SELECT_CALENDAR_TITLE: `Selecionar Calendário`,
  TITLE: `Conexões de Calendário`,
  TOOLTIP: `Conecte seu calendário Google ou Microsoft ao SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Concordar e Fechar`,
  DESCRIPTION: `Quando você visita um site, ele tem a capacidade de armazenar ou recuperar certas informações no seu navegador. Essas informações, muitas vezes na forma de cookies, podem ser sobre suas preferências, dispositivo ou outros detalhes. Seu principal objetivo é garantir que o site funcione como você espera. Embora essas informações não o identifiquem diretamente, elas podem melhorar sua experiência de navegação personalizando o conteúdo. Entendemos e valorizamos seu direito à privacidade, por isso oferecemos a você a opção de controlar e personalizar suas preferências de cookies. Ao clicar nos vários títulos de categoria, você pode aprender mais sobre cada tipo de cookie e modificar nossas configurações padrão. Observe que bloquear certos tipos de cookies pode afetar sua experiência geral no site e limitar os serviços que podemos oferecer.`,
  DESCRIPTION_FIRST_PART: `Quando você visita um site, ele tem a capacidade de armazenar ou recuperar certas informações no seu navegador. Essas informações, muitas vezes na forma de cookies, podem ser sobre suas preferências, dispositivo ou outros detalhes. Seu principal objetivo é garantir que o site funcione como você espera.`,
  DESCRIPTION_SECOND_PART: `Embora essas informações não o identifiquem diretamente, elas podem melhorar sua experiência de navegação personalizando o conteúdo. Entendemos e valorizamos seu direito à privacidade, por isso oferecemos a você a opção de controlar e personalizar suas preferências de cookies.`,
  DESCRIPTION_THIRD_PART: `Ao clicar nos vários títulos de categoria, você pode aprender mais sobre cada tipo de cookie e modificar nossas configurações padrão. Observe que bloquear certos tipos de cookies pode afetar sua experiência geral no site e limitar os serviços que podemos oferecer.`,
  FUNCTIONAL_NAME: `Cookies Funcionais`,
  MANAGE_TITLE: `Gerenciar Preferências de Consentimento`,
  NECESSARY_ONLY: `Apenas Estritamente Necessários`,
  PERFORMANCE_NAME: `Cookies de Desempenho`,
  STRICTLY_NAME: `Cookies Estritamente Necessários`,
  TARGETING_NAME: `Cookies de Segmentação`,
  TITLE: `Configurações de Cookies`,
  SHOW_ALL: `Mostrar Todos`,
  HIDE_ALL: `Ocultar Todos`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Conta do Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Trocar Contas do Google`,
  GOOGLE_SWITCH: `Entrar com Google`,
  GOOGLE_SWITCH_DESC: `Mudar da autenticação da Microsoft para Google`,
  MICROSOFT_ACCOUNT: `Conta da Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Trocar Contas da Microsoft`,
  MICROSOFT_SWITCH: `Entrar com Microsoft`,
  MICROSOFT_SWITCH_DESC: `Mudar da autenticação do Google para Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Sua conta SUMO1 perderá acesso a dados disponíveis apenas através do seu provedor original. Você também pode precisar reautorizar o acesso a integrações e calendários.`,
  SWITCH_DESCRIPTION_PART1: `Mudar de `,
  SWITCH_DESCRIPTION_PART2: ` autenticação para `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Seu papel atual`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Formato de Data`,
  LANGUAGE: `Idioma`,
  NAME: `Nome`,
  WELCOME_MESSAGE: `Mensagem de Boas-Vindas`,
  PHONE_NUMBER: `Número de Telefone`,
  PROFILE_IMAGE: `Imagem de Perfil`,
  PHONE_DETAILS: `Detalhes do Telefone`,
  PHONE_DETAILS_PLACEHOLDER: `Adicione informações adicionais ou instruções aqui.\r\nEx: Estou ansioso para te conhecer. Envie-me uma mensagem de texto a qualquer momento.`,
  TIME_FORMAT: `Formato de Hora`,
  TIME_ZONE: `Fuso Horário`,
  COUNTRY: `País`,
});

export const Profile = Object.freeze({
  TITLE: `Perfil`,
  ACCOUNT_SETTINGS: `Configurações da Conta`,
  ACCOUNT_DESCRIPTION: `Informações básicas e configurações`,
  LOGIN: `Entrar`,
  LOGIN_DESCRIPTION: `Configurar conta conectada`,
  MY_ROLE: `Meu Papel`,
  MY_ROLE_DESCRIPTION: `Seu papel e permissões atuais`,
  COOKIE_SETTINGS: `Configurações de Cookies`,
  COOKIE_DESCRIPTION: `Cookies Necessários, de Desempenho, Funcionais, de Segmentação`,
  DELETE_ACCOUNT_DESCRIPTION: `Exclua sua conta e todos os dados relacionados da sua organização`,
});

export const DeleteAccount = Object.freeze({
  TITLE: `Excluir Conta`,
  WARNING_DELETE: `Cuidado, esta ação é permanente e não pode ser desfeita.`,
  DELETE_ACCOUNT_AND_ORG: `Excluir Conta e Organização`,
  YES_DELETE: `Sim, excluir`,
  DELETE_TO_CONFIRM: `Digite <b>Excluir</b> para confirmar.`,
  DELETE_CONFIRM_WORD: `Excluir`,
  DELETE_ACCOUNT_MODAL_DESCRIPTION: `Exclua seus dados pessoais e todos os dados relacionados da sua organização. Esta ação é permanente e não pode ser desfeita.`,
  DELETE_ACCOUNT_AND_ORG_MODAL_DESCRIPTION: `Exclua sua conta e organização. Esta ação é permanente e não pode ser desfeita.`,
  LIST_DELETING_ENTITIES_TITLE: `A exclusão da sua conta fará o seguinte`,
  LIST_DELETING_ACCOUNT_AND_ORG_ENTITIES_TITLE: `A exclusão da sua conta e organização fará o seguinte`,
  LIST_DELETING_ENTITIES: `Desconectá-lo de todos os dispositivos \\p Excluir todas as informações pessoais \\p Excluir todas as integrações pessoais`,
  ADDITIONAL_LIST_DELETING_ORG_ENTITIES: `\\p Excluir todos os dados da organização`,
  TRANSFER_OWNERSHIP_TITLE: `Transferir propriedade e excluir minha conta`,
  TRANSFER_OWNERSHIP_DESCRIPTION: `Antes de excluir sua conta, você deve transferir a propriedade para outro membro da equipe para que ele possa gerenciar a organização.`,
  DELETE_ACCOUNT_AND_ORG_TITLE: `Excluir minha conta e dissolver a organização`,
  DELETE_ACCOUNT_AND_ORG_DESCRIPTION: `Se você não quiser transferir a propriedade, poderá excluir sua conta e dissolver a organização.`,
  TRANSFER_OWNERSHIP: `Transferir propriedade`,
  NEED_INVITE_NEW_USER_TO_ORG: `Convide alguém para a sua organização e aceite o convite`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `Falha na solicitação de novo calendário`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `Novo calendário conectado com sucesso!`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Falha na solicitação de conexão do Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Zoom conectado com sucesso!`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Falha na solicitação de salvamento das configurações`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Suas configurações foram salvas com sucesso!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `Conta trocada com sucesso`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Falha ao trocar de conta. Por favor, tente novamente mais tarde`,
  DELETE_ACCOUNT_ERROR_TOAST_MESSAGE: `Falha ao excluir a conta`,
});

export const Guide = Object.freeze({
  GET_STARTED: `Começar com SUMO1`,
  START: `Iniciar`,

  BACK: `Voltar`,
  CLOSE: `Fechar`,
  LAST: `Finalizar`,
  NEXT: `Próximo`,
  NEXT_LABEL_WITH_PROGRESS: `Próximo (Passo {step} de {steps})`,
  OPEN_THE_DIALOG: `Abrir a caixa de diálogo`,
  SKIP: `Terminar tour`,
  COMPLETE_TOUR: `Completar Tour`,
  GO_TO: `Ir para`,

  PROFILE_TITLE: `Gerenciar seu perfil`,
  PROFILE_DESCRIPTION: `Você pode gerenciar suas configurações de perfil de usuário, incluindo idioma, fuso horário, seu papel e suas permissões, etc.`,
  INTEGRATIONS_TITLE: `Conecte seu calendário de reuniões de vídeo`,
  INTEGRATIONS_DESCRIPTION: `SUMO 1 tem integrações prontas para uso para CRM, reuniões de vídeo, processadores de pagamento e mais.`,
  AVAILABILITY_TITLE: `Modifique suas horas de disponibilidade`,
  AVAILABILITY_DESCRIPTION: `Você pode modificar sua disponibilidade ou criar novos horários de disponibilidade como "Após-horas" aqui.`,
  SUMO_INBOX_TITLE: `Instalar SUMO Inbox`,
  SUMO_INBOX_DESCRIPTION: `SUMO Meeting Booker é uma extensão de navegador que 90% dos usuários usam diariamente para trazer funcionalidades avançadas de agendamento de reuniões de SUMO1 para Gmail e Outlook.`,
  SUMO_INBOX_LINK: `Instalar a extensão do navegador do SUMO1`,
  BOOKING_PAGES_TITLE: `Crie sua página de reserva`,
  BOOKING_PAGES_DESCRIPTION: `Você pode criar páginas de reserva que serão usadas mais duradouramente. Por exemplo, um link de página de reserva para sua assinatura eletrônica, seu site, etc.`,
  BOOKED_MEETINGS_TITLE: `Como ver as reuniões agendadas`,
  BOOKED_MEETINGS_DESCRIPTION: `Veja uma lista de compromissos agendados, incluindo a opção de cancelamento, de marcar como não apresente, de reagendar e mais.`,
  SMART_ALERTS_TITLE: `Otimize seu agendamento com alertas inteligentes`,
  SMART_ALERTS_DESCRIPTION: `As páginas de reserva incluem alertas básicos, como enviar um lembrete por SMS 1 dia antes do compromisso. Os alertas inteligentes ajudam os usuários a configurar alertas de compromisso mais sofisticados.`,
  BOOKING_TEMPLATES_TITLE: `Modelo de Reserva`,
  BOOKING_TEMPLATES_DESCRIPTION: `Você pode criar modelos de reserva (também conhecidos como tipos de reuniões) para facilitar a reserva rápida de um compromisso específico com a pessoa adequada, no local adequado e no momento adequado.`,
  TEAMS_TITLE: `Gerenciar suas equipes`,
  TEAMS_DESCRIPTION: `Você pode criar equipes (também conhecidas como grupos de recursos) para se atribuir automaticamente a uma reunião com base em um modo de atribuição, como Round-Robin.`,
  JOURNEY_BUILDER_TITLE: `Agende reuniões instantaneamente`,
  JOURNEY_BUILDER_DESCRIPTION: `Journey Builder é uma ferramenta que você pode usar para criar uma experiência de engajamento do usuário além de uma página de reserva simples.`,
  USERS_TITLE: `Convidar e gerenciar seus usuários`,
  USERS_DESCRIPTION: `Você pode convidar usuários para usar SUMO1 e ativar ou desativar usuários.`,
  ROLES_TITLE: `Gerenciar as permissões de seus colegas de equipe`,
  ROLES_DESCRIPTION: `Você pode gerenciar as permissões de seus colegas de equipe para garantir que eles tenham os acessos CRUD (criar, ler, atualizar, deletar) necessários.`,
  BILLING_TITLE: `Escolha seu plano e configure seu pagamento`,
  BILLING_DESCRIPTION: `Você pode gerenciar sua assinatura SUMO1 você mesmo, incluindo a capacidade de adicionar mais licenças conforme necessário.`,
});
