import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import {
  extendTrialModalActions,
  orgsActions,
  orgsSelectors,
  expireTrialModalActions,
  deleteOrgModalActions,
  payLaterModalActions,
} from '../../../store/opsConsole/orgs';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Path } from '../../../routing';
import { ConfirmationModal, Preloader, SectionHeader } from '../../../components/common';
import { Menu } from 'primereact/menu';
import {
  OrgAdoptionChart,
  OrgConvertTrialModal,
  OrgDeleteModal,
  OrgDetailsBasicInfo,
  OrgDetailsLicenses,
  OrgDetailsUsers,
  OrgExpireTrialModal,
  OrgExtendTrialModal,
  OrgPayLaterModal,
  OrgScheduledChanges,
} from '../../../components/opsConsole';
import labels from './labels';
import { OrgType } from '../../../API';
import { authenticationSelectors } from '../../../store/authentication';
import { navigationService } from '../../../services/NavigationService';
import { billingActions } from '../../../store/billing';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { globalActions } from '../../../store/global';

export const OrgDetails = () => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const isFetching = useSelector(orgsSelectors.selectIsFetching);
  const orgType = useSelector(orgsSelectors.selectTenantType);
  const tenantId = useSelector(orgsSelectors.selectTenantId);
  const tenantName = useSelector(orgsSelectors.selectTenantName);
  const tenantIsActive = useSelector(orgsSelectors.selectTenantIsActive);
  const isEdited = useSelector(orgsSelectors.selectIsEdited);
  const isMainAdmin = useSelector(authenticationSelectors.selectIsMainAdmin);
  const isMainAdminOrOperations = useSelector(authenticationSelectors.selectIsMainAdminOrOperations);
  const isMainAdminOperationsOrSupport = useSelector(authenticationSelectors.selectIsMainAdminOperationsOrSupport);
  const isOrgValid = useSelector(orgsSelectors.selectOrgValid);
  const isScheduledChanges = useSelector(orgsSelectors.selectIsScheduledChanges);

  const menu = useRef<Menu>(null);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  useEffect(() => {
    if (orgId) {
      dispatch(orgsActions.getOrgDetailsRequest(orgId));
      dispatch(billingActions.getItemPricesRequest());
    }
    dispatch(globalActions.setFullScreen(true));
    return () => {
      dispatch(globalActions.setFullScreen(false));
    };
  }, []);

  const navigateBack = () => {
    navigationService.navigateTo(Path.OPSConsoleOrgs);
  };

  const handleBack = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      navigateBack();
    }
  };

  const handleSave = () => {
    dispatch(orgsActions.saveOrgDetailsRequest());
  };

  const menuItems = [
    {
      label: labels.extendTrial,
      command: () => {
        dispatch(extendTrialModalActions.openModal());
      },
      visible: orgType === OrgType.TRIAL,
    },
    {
      label: labels.expireTrial,
      command: () => {
        dispatch(expireTrialModalActions.openModal());
      },
      visible: orgType === OrgType.TRIAL && tenantIsActive,
    },
    {
      label: labels.convertToTest,
      command: () => {
        dispatch(orgsActions.convertToTestRequest());
      },
      visible: orgType === OrgType.TRIAL,
    },
    {
      label: labels.convertToCustomer,
      command: () => {
        dispatch(payLaterModalActions.openModal());
      },
      visible: orgType === OrgType.TRIAL,
    },
    {
      label: labels.delete,
      command: () => {
        dispatch(deleteOrgModalActions.openModal());
      },
      visible: isMainAdmin,
    },
  ];

  return (
    <div>
      {isFetching && <Preloader />}
      <OrgExtendTrialModal isFromDetails={true} />
      <OrgExpireTrialModal isFromDetails={true} />
      <OrgConvertTrialModal />
      <OrgDeleteModal />
      <OrgPayLaterModal />
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        confirmButtonProps={{ className: 'min-w-120px' }}
        onConfirm={navigateBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />

      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader title={labels.title} hideButton />

        <div className="card">
          <div className="flex justify-content-between align-items-center mb-3">
            <div className="text-xl">
              {tenantName} &gt; {labels.orgTitle}: {tenantId}
            </div>
            <div className="flex align-items-center">
              {isMainAdminOperationsOrSupport && (
                <Button
                  label={labels.save}
                  onClick={handleSave}
                  className="w-6rem"
                  disabled={!isEdited || !isOrgValid}
                />
              )}
              {((orgType === OrgType.TRIAL && isMainAdminOrOperations) || isMainAdmin) && (
                <>
                  <Menu ref={menu} model={menuItems} popup popupAlignment="right" />
                  <Button
                    text
                    icon="pi pi-cog text-xl"
                    rounded
                    onClick={(event) => menu.current?.toggle(event)}
                    className="ml-3"
                  />
                </>
              )}
            </div>
          </div>

          {isScheduledChanges && <OrgScheduledChanges />}
          <OrgAdoptionChart />
          <OrgDetailsBasicInfo />
          <OrgDetailsLicenses />
          <OrgDetailsUsers />
        </div>
      </div>
    </div>
  );
};
