import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatDateHHMM, formatDateUTC } from '../../../services/DateService';
import { rolesSelectors } from '../../../store/roles';
import { usersSelectors } from '../../../store/users';
import { StatusLabels } from '../../../store/users/constants';
import { UserAdminDataFullRecord } from '../../../store/users/types';
import { TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import { SumoTable, UserAvatar } from '../../common';
import { ConfirmDialog } from '../confirmDialog/ConfirmDialog';
import { EditUser } from '../editUser/EditUser';
import { InviteDialog } from '../inviteDialog/InviteDialog';
import { UserRowActions } from '../userRowActions/UserRowActions';
import labels from './labels';
import { AdminDataStatus } from '../../../API';
import { workspacesSelectors } from '../../../store/workspaces';
import { teamsSelectors } from '../../../store/teams';

export const UsersList = () => {
  const isSkeletonFetching = useSelector(usersSelectors.selectIsSkeletonFetching);
  const filteredUsers = useSelector(usersSelectors.selectFilteredUsers);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat);
  const roles = useSelector(rolesSelectors.selectRoles);
  const workspacesNamesById = useSelector(workspacesSelectors.selectWorkspacesNamesById);
  const teamNamesByUserId = useSelector(teamsSelectors.selectTeamNamesByUserId);

  const [isDisableUser, setIsDisableUser] = useState(false);
  const [isEditConfirm, setIsEditConfirm] = useState(false);

  const setConfirmDialogProps = (isEdit: boolean, isDisable: boolean) => {
    setIsEditConfirm(isEdit);
    setIsDisableUser(isDisable);
  };

  const getRoleNameById = (id: string) => {
    return roles.find((role) => role.id === id)?.name || 'None';
  };

  const nameBody = (data: UserAdminDataFullRecord) => (
    <div className="flex-left-center gap-8px">
      <UserAvatar name={data.fullName} avatarLink={data.avatar} size="large" className="flex-none" />
      <div title={data.fullName} className="overflow-hidden text-overflow-ellipsis">
        {data.fullName}
      </div>
    </div>
  );

  const emailBody = (data: UserAdminDataFullRecord) => {
    const email = data.email;
    return (
      <div title={email} className="overflow-hidden text-overflow-ellipsis">
        {email}
      </div>
    );
  };

  const teamBody = (data: UserAdminDataFullRecord) => {
    const teamNames = teamNamesByUserId[data.userId || ''];
    return (
      <div title={teamNames} className="overflow-hidden text-overflow-ellipsis">
        {teamNames}
      </div>
    );
  };

  const workspaceBody = (data: UserAdminDataFullRecord) => {
    const workspaceNames = data.workspaceIds?.map((id) => workspacesNamesById[id || '']).join(', ');
    return (
      <div title={workspaceNames} className="overflow-hidden text-overflow-ellipsis">
        {workspaceNames}
      </div>
    );
  };

  const statusBody = (data: UserAdminDataFullRecord) => (
    <div
      className={classNames('w-fit border-radius-6px py-6px px-8px text-label-s-med', {
        'bg-egg-blue-light text-egg-blue-dark': data.status === AdminDataStatus.ACTIVE,
        'bg-heavy-10 text-heavy-60':
          data.status === AdminDataStatus.INACTIVE || data.status === AdminDataStatus.INVITE_SENT,
      })}
    >
      {data.status ? (data.status in StatusLabels ? StatusLabels[data.status] : data.status) : ''}
    </div>
  );

  const lastUpdateBody = (data: UserAdminDataFullRecord) => {
    const lastUpdate = `${formatDateUTC(data.updatedAt, dateFormat)} ${formatDateHHMM(
      new Date(data.updatedAt),
      timeFormat
    )}`;
    return (
      <div title={lastUpdate} className="overflow-hidden text-overflow-ellipsis">
        {lastUpdate}
      </div>
    );
  };

  return (
    <>
      <InviteDialog key="InviteDialog" />
      <EditUser key="EditUserDialog" />
      <ConfirmDialog key="DisableUserDialog" isDisableUser={isDisableUser} isEditConfirm={isEditConfirm} />

      <SumoTable
        isCard
        loading={isSkeletonFetching}
        actionsBody={(data) => UserRowActions({ data, setConfirmDialogProps })}
        value={filteredUsers.sort((userA, userB) => (userB.createdAt > userA.createdAt ? 1 : -1))}
      >
        <Column field="fullName" header={labels.name} sortable bodyClassName="max-w-150px" body={nameBody} />
        <Column field="email" header={labels.email} sortable bodyClassName="max-w-150px" body={emailBody} />
        <Column
          body={(data: UserAdminDataFullRecord) => getRoleNameById(data.roleId)}
          field="roleId"
          header={labels.role}
          sortable
          bodyClassName="text-overflow-ellipsis max-w-90px"
          sortFunction={(e) =>
            [...e.data].sort((a: UserAdminDataFullRecord, b: UserAdminDataFullRecord) =>
              getRoleNameById(a.roleId) > getRoleNameById(b.roleId) ? e.order || 0 : -(e.order || 0)
            )
          }
        />
        <Column
          field="team"
          header={'Team'}
          sortable
          bodyClassName="text-overflow-ellipsis max-w-80px"
          body={teamBody}
          sortFunction={(e) =>
            [...e.data].sort((a: UserAdminDataFullRecord, b: UserAdminDataFullRecord) =>
              teamNamesByUserId[a.userId || ''] > teamNamesByUserId[b.userId || ''] ? e.order || 0 : -(e.order || 0)
            )
          }
        />
        <Column
          field="workspaceIds"
          header={'Workspace'}
          sortable
          bodyClassName="max-w-90px"
          body={workspaceBody}
          sortFunction={(e) =>
            [...e.data].sort((a: UserAdminDataFullRecord, b: UserAdminDataFullRecord) =>
              workspacesNamesById[a.workspaceIds?.[0] || ''] > workspacesNamesById[b.workspaceIds?.[0] || '']
                ? e.order || 0
                : -(e.order || 0)
            )
          }
        />
        <Column
          field="status"
          header={labels.status}
          sortable
          bodyClassName="text-overflow-ellipsis max-w-70px"
          body={statusBody}
        />
        <Column
          field="updatedAt"
          header={labels.lastActive}
          body={lastUpdateBody}
          sortable
          bodyClassName="text-overflow-ellipsis max-w-80px"
          frozen
        />
      </SumoTable>
    </>
  );
};
