import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SmartAlertEdit } from '../../components/smartAlerts';
import { Path } from '../../routing';
import { navigationService } from '../../services/NavigationService';
import { AppDispatch } from '../../store/rootStore';
import {
  DEFAULT_SMART_ALERT_TEMPLATE,
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
  UpsertSmartTypeFrom,
} from '../../store/smartAlerts';
import { userSettingsSelectors } from '../../store/userSettings';
import { TEMPLATES } from '../smartAlerts/constants';
import labels from './labels';

export const EditSmartAlert = () => {
  const { smartType, smartAlertId = '' } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const personalSmartAlerts = useSelector(smartAlertsSelectors.selectPersonalSearched);
  const smartAlert = useSelector(smartAlertsSelectors.selectSmartAlert);

  const isReadOnly = useSelector(
    smartAlertsSelectors.selectIsAlertReadOnly(
      smartAlertId === 'new' ? '' : smartAlertId,
      smartType === SmartTypes.ALERT ? SmartTypes.ALERT : SmartTypes.TEMPLATE
    )
  );
  const isTemplateCreate = useSelector(userSettingsSelectors.selectSmartAlertTemplatesCreate);

  useEffect(() => {
    dispatch(smartAlertsActions.setSmartType(smartAlertId === 'new' ? SmartTypes.ALERT : (smartType as SmartTypes)));
    if (!smartAlert.id && smartAlertId === 'new') {
      dispatch(
        smartAlertsActions.updateSmartAlert({
          name: DEFAULT_SMART_ALERT_TEMPLATE.name,
          icon: DEFAULT_SMART_ALERT_TEMPLATE.icon,
          isHide: DEFAULT_SMART_ALERT_TEMPLATE.isHide,
          shareWith: DEFAULT_SMART_ALERT_TEMPLATE.shareWith,
          whenSend: DEFAULT_SMART_ALERT_TEMPLATE.whenSend,
          whomSend: DEFAULT_SMART_ALERT_TEMPLATE.whomSend,
        })
      );
    } else if (smartType) {
      const template = TEMPLATES.find((template) => template.id === smartAlertId);
      if (!template) {
        if (smartType === SmartTypes.ALERT) {
          dispatch(smartAlertsActions.getSmartAlertRequest(smartAlertId));
        } else {
          dispatch(smartAlertsActions.getSmartAlertTemplateRequest(smartAlertId));
        }
      } else {
        dispatch(
          smartAlertsActions.updateSmartAlert({
            name: template.name,
            icon: template.icon,
            isHide: template.isHide,
            shareWith: template.shareWith,
            whenSend: template.whenSend,
            whomSend: template.whomSend,
          })
        );
      }
    }
  }, []);

  const handleBack = () => {
    if (smartAlertId === 'new' && personalSmartAlerts.length) {
      navigationService.navigateTo(Path.PreSelectSmartAlert.replace(':smartType', SmartTypes.ALERT));
    } else {
      navigationService.navigateTo(Path.SmartAlerts);
    }
  };

  const handleCancel = () => {
    navigationService.navigateTo(Path.SmartAlerts);
  };

  const handleSave = () => {
    dispatch(smartAlertsActions.upsertSmartTypeRequest(UpsertSmartTypeFrom.SMART_ALERT));
  };

  return (
    <>
      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <SmartAlertEdit
        handleCancel={handleCancel}
        handleSave={handleSave}
        isReadOnly={isReadOnly}
        hideTypeChange={!isTemplateCreate}
        isCreate={smartAlertId === 'new'}
      />
    </>
  );
};
