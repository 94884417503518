import { useEffect } from 'react';
import { OrgExpireTrialModal, OrgsChart, OrgsTable } from '../../../components/opsConsole';
import labels from './labels';
import { useDispatch } from 'react-redux';
import {
  CHANGES_FILTER_OPTIONS,
  CHANGES_FILTER_VALUES,
  DEFAULT_FILTER,
  ORG_STATUS_OPTIONS,
  ORG_STATUS_VALUES,
  ORG_TYPE_OPTIONS,
  ORGS_CSV_HEADERS,
  orgsActions,
  orgsSelectors,
  TERM_FILTER_OPTIONS,
} from '../../../store/opsConsole/orgs';
import { SectionHeader } from '../../../components/common';
import { useSelector } from 'react-redux';
import { OrgExtendTrialModal } from '../../../components/opsConsole/orgs/orgExtendTrialModal/OrgExtendTrialModal';
import { Button } from 'primereact/button';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { formatDateUTC } from '../../../services/DateService';
import { DateFormat } from '../../../store/userSettings';
import { Path } from '../../../routing';
import { downloadCSV } from '../../../services/utils';
import { globalActions } from '../../../store/global';
import { LicenseType } from '../../../API';

export const Orgs = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(orgsSelectors.selectIsFetching);
  const orgsRecords = useSelector(orgsSelectors.selectOrgs);
  const isFilterActive = useSelector(orgsSelectors.selectIsFilterActive);
  const status = useSelector(orgsSelectors.selectFilterStatus);
  const term = useSelector(orgsSelectors.selectFilterTerm);
  const changes = useSelector(orgsSelectors.selectFilterChanges);

  useEffect(() => {
    dispatch(orgsActions.getOrgsRequest());
    dispatch(globalActions.setFullScreen(true));
    return () => {
      dispatch(globalActions.setFullScreen(false));
    };
  }, []);

  // utility to Convert Orgs Array to CSV
  const convertArrayToCSV = (): string => {
    const rows = orgsRecords.map((org) =>
      [
        org.accountName,
        org.tenantId,
        ORG_STATUS_OPTIONS.find((status) => status.value === org.status)?.label,
        ORG_TYPE_OPTIONS.find((type) => type.value === org.type)?.label,
        TERM_FILTER_OPTIONS.find((term) => term.value === org.term)?.label,
        org.amount,
        org.amountPerUser,
        org.owned,
        org.assigned,
        org.percentOfWeekAdoption,
        org.percentOfMonthAdoption,
        org.startDate,
        org.endDate,
        org.daysLeft,
        org.bookedMeetings,
        org.note,
        formatDateUTC(org.updatedAt, DateFormat.default),
      ].join(',')
    );

    return [ORGS_CSV_HEADERS.join(',').toUpperCase(), ...rows].join('\n');
  };

  const handleStatusChange = (status: ORG_STATUS_VALUES[]) => {
    dispatch(orgsActions.changeFilter({ status }));
  };

  const handleTermChange = (term: LicenseType[]) => {
    dispatch(orgsActions.changeFilter({ term }));
  };

  const handleChangesChange = (changes: CHANGES_FILTER_VALUES[]) => {
    dispatch(orgsActions.changeFilter({ changes }));
  };

  const handleFilterReset = () => {
    dispatch(orgsActions.changeFilter(DEFAULT_FILTER));
  };

  return (
    <>
      <OrgExtendTrialModal />
      <OrgExpireTrialModal />

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader
          loading={isFetching}
          title={labels.title}
          itemsLength={orgsRecords.length}
          searchPaths={[Path.OPSConsoleOrgs, Path.OPSConsoleOrgDetails]}
          hideButton
          extraContent={
            <Button
              className="flex-none gap-8px button-xl button-white"
              onClick={() => downloadCSV(convertArrayToCSV(), 'orgs.csv')}
            >
              <CloudArrowDownIcon className="icon-18px" />
              <div>{labels.export}</div>
            </Button>
          }
          filters={[
            {
              label: labels.term,
              value: term,
              options: TERM_FILTER_OPTIONS,
              onChange: (e) => handleTermChange(e.target.value),
            },
            {
              label: labels.status,
              value: status,
              options: ORG_STATUS_OPTIONS,
              onChange: (e) => handleStatusChange(e.target.value),
            },
            {
              label: labels.changes,
              value: changes,
              options: CHANGES_FILTER_OPTIONS,
              onChange: (e) => handleChangesChange(e.target.value),
            },
          ]}
          isFilterActive={isFilterActive}
          onFilterReset={handleFilterReset}
        />

        <OrgsChart />
        <OrgsTable />
      </div>
    </>
  );
};
