import { Button } from 'primereact/button';
import { BuyLicensePageButton } from '..';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationSelectors } from '../../../store/authentication';
import { billingChangeModalActions, billingActions, billingSelectors } from '../../../store/billing';
import labels from './labels';

export const BillingSubscriptionButtons = () => {
  const dispatch = useDispatch();
  const isTrialLicense = useSelector(authenticationSelectors.selectIsTrialLicense);
  const isSubscriptionNonRenewing = useSelector(billingSelectors.selectIsStatusNonRenewing);
  const isSubscriptionCancelled = useSelector(billingSelectors.selectIsStatusCancelled);
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const hasScheduledChangeTheTerm = useSelector(billingSelectors.selectHasScheduledChangeTheTerm);
  const commonWrapperClass = 'flex gap-20px mt-16px';

  const handleChange = () => {
    dispatch(billingChangeModalActions.openModal());
  };

  const handleCancel = () => {
    dispatch(billingActions.cancelSubscriptionRequest());
  };

  const handleReactivate = () => {
    dispatch(billingActions.reactivateSubscriptionRequest());
  };

  if (isTrialLicense) {
    return (
      <div className={commonWrapperClass}>
        <BuyLicensePageButton />
      </div>
    );
  }

  if (isSubscriptionNonRenewing || isSubscriptionCancelled) {
    return (
      <div className={commonWrapperClass}>
        <Button label={labels.reactivate} onClick={handleReactivate} />
      </div>
    );
  }

  return (
    <div className={commonWrapperClass}>
      <Button label={labels.change} onClick={handleChange} disabled={isFetching || hasScheduledChangeTheTerm} />
      <Button label={labels.cancelSubscription} outlined disabled={isFetching} onClick={handleCancel} />
    </div>
  );
};
