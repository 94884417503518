import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { authenticationSelectors } from '../authentication';
import { CreateSmartAlertInput, CreateSmartAlertTemplateInput, UpdateBookingTemplateInput } from '../../API';
import {
  deleteSmartAlert,
  deleteSmartAlertTemplate,
  getSmartAlertById,
  getSmartAlertTemplateById,
  getSmartTypesByWorkspaceId,
  upsertSmartAlert,
  upsertSmartAlertTemplate,
} from './service';
import { SmartAlertsActionTypes, smartAlertsActions } from './actions';
import {
  SmartAlertResponse,
  SmartAlertTemplateResponse,
  SmartTypes,
  SmartTypesResponse,
  UpsertSmartAlertResponse,
  UpsertSmartTypeFrom,
} from './types';
import { createSelector } from 'reselect';
import { smartAlertsSelectors } from './selectors';
import { cloneSmartTypesModalActions } from './modal';
import {
  CLONE_SMART_ALERT_SUCCESS_TOAST,
  CLONE_SMART_TEMPLATE_SUCCESS_TOAST,
  CLONE_SMART_TYPE_ERROR_TOAST,
  CREATE_SMART_ALERT_SUCCESS_TOAST,
  CREATE_SMART_TEMPLATE_SUCCESS_TOAST,
  DEFAULT_SMART_ALERT,
  DELETE_SMART_TYPES_ERROR_TOAST,
  DELETE_SMART_TYPES_SUCCESS_TOAST,
  GET_SMART_ALERT_ERROR_TOAST,
  GET_SMART_TEMPLATE_ERROR_TOAST,
  GET_SMART_TYPES_ERROR_TOAST,
  UPDATE_SMART_ALERT_SUCCESS_TOAST,
  UPDATE_SMART_TEMPLATE_SUCCESS_TOAST,
  UPSERT_SMART_TYPE_ERROR_TOAST,
} from './constants';
import { handleServiceError } from '../utils/reduxUtils';
import { notificationsActions } from '../notifications';
import { getBookingPages } from '../bookingPages/service';
import { bookingPagesActions, GetBookingPagesResponse } from '../bookingPages';
import { usersSaga } from '../users/sagas';
import { navigationService } from '../../services/NavigationService';
import { Path } from '../../routing';
import { bookingTemplatesActions, bookingTemplatesSelectors } from '../bookingTemplates';
import { bookingTemplatesSagas } from '../bookingTemplates/sagas';

const selectCloneSmartAlertRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  smartAlertsSelectors.selectSmartAlert,
  smartAlertsSelectors.selectCloneName,
  (workspaceId, userId, smartAlert, cloneName) =>
    ({
      id: '',
      workspaceId,
      name: cloneName,
      icon: smartAlert.icon,
      isHide: smartAlert.isHide,
      shareWith: [],
      whenSend: smartAlert.whenSend,
      whomSend: smartAlert.whomSend,
      createdBy: userId,
    } as CreateSmartAlertInput)
);

const selectCloneSmartAlertTemplateRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  smartAlertsSelectors.selectSmartAlertTemplate,
  smartAlertsSelectors.selectCloneName,
  (workspaceId, userId, smartAlertTemplate, cloneName) =>
    ({
      id: '',
      workspaceId,
      name: cloneName,
      description: smartAlertTemplate.description,
      icon: smartAlertTemplate.icon,
      isHide: smartAlertTemplate.isHide,
      shareWith: [],
      whenSend: smartAlertTemplate.whenSend,
      whomSend: smartAlertTemplate.whomSend,
      createdBy: userId,
    } as CreateSmartAlertTemplateInput)
);

const selectUpsertSmartAlertRequest = createSelector(
  smartAlertsSelectors.selectSmartAlert,
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  (smartAlert, workspaceId, userId) =>
    ({
      id: smartAlert.id,
      workspaceId: smartAlert.workspaceId || workspaceId,
      name: smartAlert.name,
      icon: smartAlert.icon,
      isHide: smartAlert.isHide,
      shareWith: smartAlert.shareWith,
      whenSend: smartAlert.whenSend,
      whomSend: smartAlert.whomSend,
      createdBy: smartAlert.createdBy || userId,
    } as CreateSmartAlertInput)
);

const selectDetachSmartAlertRequest = createSelector(
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  smartAlertsSelectors.selectSmartAlert,
  (workspaceId, userId, smartAlert) =>
    ({
      id: '',
      workspaceId,
      name: smartAlert.name,
      icon: smartAlert.icon,
      isHide: smartAlert.isHide,
      shareWith: smartAlert.shareWith,
      whenSend: smartAlert.whenSend,
      whomSend: smartAlert.whomSend,
      createdBy: userId,
    } as CreateSmartAlertInput)
);

const selectUpsertSmartAlertTemplateRequest = createSelector(
  smartAlertsSelectors.selectSmartAlert,
  smartAlertsSelectors.selectSmartAlertTemplateDesc,
  authenticationSelectors.selectWorkspaceId,
  authenticationSelectors.selectUserId,
  (smartAlertTemplate, description, workspaceId, userId) =>
    ({
      id: smartAlertTemplate.id,
      workspaceId: smartAlertTemplate.workspaceId || workspaceId,
      name: smartAlertTemplate.name,
      description,
      icon: smartAlertTemplate.icon,
      isHide: smartAlertTemplate.isHide,
      shareWith: smartAlertTemplate.shareWith,
      whenSend: smartAlertTemplate.whenSend,
      whomSend: smartAlertTemplate.whomSend,
      createdBy: smartAlertTemplate.createdBy || userId,
    } as CreateSmartAlertTemplateInput)
);

function* getSmartTypesSaga() {
  try {
    const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
    const requests = [call(getSmartTypesByWorkspaceId, workspaceId), call(usersSaga.getUsers)];
    const [smartTypes]: [SmartTypesResponse] = yield all(requests);

    yield put(smartAlertsActions.getSmartTypesSuccess(smartTypes));
  } catch (error: unknown) {
    yield put(smartAlertsActions.getSmartTypesFail(error?.toString()));
    yield call(handleServiceError, error, GET_SMART_TYPES_ERROR_TOAST, true);
  }
}

function* cloneSmartAlertSaga() {
  try {
    const smartType: SmartTypes = yield select(smartAlertsSelectors.selectSmartType);
    if (smartType === SmartTypes.ALERT) {
      const smartAlert: CreateSmartAlertInput = yield select(selectCloneSmartAlertRequest);
      yield call(upsertSmartAlert, smartAlert, [], []);
    } else {
      const smartAlertTemplate: CreateSmartAlertTemplateInput = yield select(selectCloneSmartAlertTemplateRequest);
      yield call(upsertSmartAlertTemplate, smartAlertTemplate);
    }

    yield put(smartAlertsActions.cloneSmartAlertSuccess());
    yield put(cloneSmartTypesModalActions.closeModal());

    yield put(
      notificationsActions.showToast(
        smartType === SmartTypes.ALERT ? CLONE_SMART_ALERT_SUCCESS_TOAST : CLONE_SMART_TEMPLATE_SUCCESS_TOAST
      )
    );
    yield put(smartAlertsActions.getSmartTypesRequest());
  } catch (error: unknown) {
    yield put(smartAlertsActions.cloneSmartAlertFail(error?.toString()));
    yield call(handleServiceError, error, CLONE_SMART_TYPE_ERROR_TOAST);
  }
}

function* deleteSmartTypesSaga(action: ReturnType<typeof smartAlertsActions.deleteSmartTypesRequest>) {
  try {
    if (action.type === SmartAlertsActionTypes.DELETE_SMART_TYPES_REQUEST) {
      const smartAlertIds: string[] = [];
      const smartAlertTemplateIds: string[] = [];
      action.payload.forEach((item) =>
        item.type === SmartTypes.ALERT ? smartAlertIds.push(item.id) : smartAlertTemplateIds.push(item.id)
      );
      const request = [
        ...(smartAlertIds ? [call(deleteSmartAlert, smartAlertIds)] : []),
        ...(smartAlertTemplateIds.length ? [call(deleteSmartAlertTemplate, smartAlertTemplateIds)] : []),
      ];
      yield all(request);
      yield put(smartAlertsActions.deleteSmartTypesSuccess());
      yield put(notificationsActions.showToast(DELETE_SMART_TYPES_SUCCESS_TOAST));
      yield put(smartAlertsActions.getSmartTypesRequest());
    }
  } catch (error: unknown) {
    yield put(smartAlertsActions.deleteSmartTypesFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_SMART_TYPES_ERROR_TOAST);
  }
}

function* getSmartAlertSaga(action: ReturnType<typeof smartAlertsActions.getSmartAlertRequest>) {
  try {
    if (action.type === SmartAlertsActionTypes.GET_SMART_ALERT_REQUEST) {
      let smartAlert = {} as SmartAlertResponse;

      if (action.payload === 'new') {
        const workspaceId: string = yield select(authenticationSelectors.selectWorkspaceId);
        const requests = [
          call(getBookingPages, workspaceId),
          call(getSmartTypesByWorkspaceId, workspaceId),
          call(bookingTemplatesSagas.getBookingTemplates),
          call(usersSaga.getUsers),
        ];
        const [bookingPages, smartTypes]: [GetBookingPagesResponse, SmartTypesResponse] = yield all(requests);

        yield put(smartAlertsActions.getSmartTypesSuccess(smartTypes));
        const bookingTemplates: UpdateBookingTemplateInput[] = yield select(
          bookingTemplatesSelectors.selectBookingTemplates
        );

        smartAlert.bookingPages = bookingPages.bookingPages;
        smartAlert.smartAlert = DEFAULT_SMART_ALERT;
        smartAlert.bookingTemplates = bookingTemplates;
      } else {
        const requests = [call(getSmartAlertById, action.payload), call(usersSaga.getUsers)];
        [smartAlert] = yield all(requests);
        yield put(bookingTemplatesActions.getBookingTemplatesSuccess(smartAlert.bookingTemplates));
        yield put(bookingPagesActions.getBookingPagesSuccess(smartAlert.bookingPages));
      }
      yield put(smartAlertsActions.getSmartAlertSuccess(smartAlert));
    }
  } catch (error: unknown) {
    yield put(smartAlertsActions.getSmartAlertFail(error?.toString()));
    yield call(handleServiceError, error, GET_SMART_ALERT_ERROR_TOAST, true);
  }
}

function* getSmartAlertTemplateSaga(action: ReturnType<typeof smartAlertsActions.getSmartAlertTemplateRequest>) {
  try {
    if (action.type === SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_REQUEST) {
      const requests = [call(getSmartAlertTemplateById, action.payload), call(usersSaga.getUsers)];
      const [smartAlertTemplate]: [SmartAlertTemplateResponse] = yield all(requests);

      yield put(smartAlertsActions.getSmartAlertTemplateSuccess(smartAlertTemplate.smartAlertTemplate));
    }
  } catch (error: unknown) {
    yield put(smartAlertsActions.getSmartAlertTemplateFail(error?.toString()));
    yield call(handleServiceError, error, GET_SMART_TEMPLATE_ERROR_TOAST, true);
  }
}

function* upsertSmartTypeSaga(action: ReturnType<typeof smartAlertsActions.upsertSmartTypeRequest>) {
  try {
    if (action.type === SmartAlertsActionTypes.UPSERT_SMART_TYPE_REQUEST) {
      const smartType: SmartTypes = yield select(smartAlertsSelectors.selectSmartType);
      const isNew: boolean = yield select(smartAlertsSelectors.selectSmartAlertIsNew);
      const id: string = yield select(smartAlertsSelectors.selectSmartAlertId);
      const detach: boolean = yield select(smartAlertsSelectors.selectDetachAlert);
      let response = {} as UpsertSmartAlertResponse;
      if (smartType === SmartTypes.ALERT) {
        let bookingPageIds: string[] | null = null;
        let bookingTemplateIds: string[] | null = null;
        if (action.payload !== 'none') {
          // if it's not update from BP/BT
          bookingPageIds = yield select(smartAlertsSelectors.selectBookingPageIds);
          bookingTemplateIds = yield select(smartAlertsSelectors.selectBookingTemplateIds);
        }
        const smartAlert: CreateSmartAlertInput = yield select(
          detach ? selectDetachSmartAlertRequest : selectUpsertSmartAlertRequest
        );
        response = yield call(upsertSmartAlert, smartAlert, bookingPageIds, bookingTemplateIds);
      } else {
        const smartTemplate: CreateSmartAlertTemplateInput = yield select(selectUpsertSmartAlertTemplateRequest);
        yield call(upsertSmartAlertTemplate, smartTemplate);
      }
      yield put(smartAlertsActions.upsertSmartTypeSuccess());

      if (smartType === SmartTypes.ALERT) {
        yield put(
          notificationsActions.showToast(isNew ? CREATE_SMART_ALERT_SUCCESS_TOAST : UPDATE_SMART_ALERT_SUCCESS_TOAST)
        );
      } else {
        yield put(
          notificationsActions.showToast(
            isNew ? CREATE_SMART_TEMPLATE_SUCCESS_TOAST : UPDATE_SMART_TEMPLATE_SUCCESS_TOAST
          )
        );
      }
      if (action.payload === UpsertSmartTypeFrom.SMART_ALERT) {
        yield call(navigationService.navigateTo, Path.SmartAlerts);
      } else {
        if (action.payload === UpsertSmartTypeFrom.BOOKING_PAGE) {
          yield put(bookingPagesActions.updateSmartAlerts(response.id));
          if (detach) yield put(bookingPagesActions.updateSmartAlerts(id)); // remove id of initial alert
        } else if (action.payload === UpsertSmartTypeFrom.BOOKING_TEMPLATE) {
          yield put(bookingTemplatesActions.updateSmartAlerts(response.id));
          if (detach) yield put(bookingTemplatesActions.updateSmartAlerts(id)); // remove id of initial alert
        }
        yield put(smartAlertsActions.getSmartTypesRequest());
      }
    }
  } catch (error: unknown) {
    yield put(smartAlertsActions.upsertSmartTypeFail(error?.toString()));
    yield call(handleServiceError, error, UPSERT_SMART_TYPE_ERROR_TOAST);
  }
}

export function* watchSmartAlertsSaga() {
  yield takeLatest(SmartAlertsActionTypes.GET_SMART_TYPES_REQUEST, getSmartTypesSaga);
  yield takeLatest(SmartAlertsActionTypes.CLONE_SMART_ALERT_REQUEST, cloneSmartAlertSaga);
  yield takeLatest(SmartAlertsActionTypes.DELETE_SMART_TYPES_REQUEST, deleteSmartTypesSaga);
  yield takeLatest(SmartAlertsActionTypes.GET_SMART_ALERT_REQUEST, getSmartAlertSaga);
  yield takeLatest(SmartAlertsActionTypes.GET_SMART_ALERT_TEMPLATE_REQUEST, getSmartAlertTemplateSaga);
  yield takeLatest(SmartAlertsActionTypes.UPSERT_SMART_TYPE_REQUEST, upsertSmartTypeSaga);
}

export const smartAlertsSagas = {
  getSmartTypes: getSmartTypesSaga,
};
