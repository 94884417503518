import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { ConnectIntegrationRequest } from '../../generated-sources/internal-api/models/ConnectIntegrationRequest';
import { ConnectIntegrationResponse } from '../../generated-sources/internal-api/models/ConnectIntegrationResponse';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const connectIntegration = async (data: ConnectIntegrationRequest): Promise<ConnectIntegrationResponse> => {
  return handleAPIRequest<ConnectIntegrationResponse>(`${API_PUBLIC}/connectIntegration`, data);
};
