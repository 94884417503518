import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { rescheduleEventModalActions, scheduledMeetingsSelectors } from '../../../store/bookedMeetings';
import { ModalName } from '../../../store/modals';
import { Modal } from '../../common';
import { PublicBookingPageContainer } from '../../publicBookingPage';

export const BookedMeetingRescheduleModal = () => {
  const dispatch = useDispatch();
  const selectedEvent = useSelector(scheduledMeetingsSelectors.selectSelectedEvent);
  const previewBookingPage = useSelector(scheduledMeetingsSelectors.selectPreviewBookingPage);

  const handleClose = () => {
    dispatch(rescheduleEventModalActions.closeModal());
  };

  return (
    <Modal.Container
      name={ModalName.RESCHEDULE_EVENT_MODAL_NAME}
      className="w-840px h-520px"
      contentClassName="p-0 border-radius-8px"
      headerClassName="hidden"
    >
      <>
        <div className="action-button absolute right-0 -mr-48px bg-primary-white" onClick={handleClose}>
          <XMarkIcon className="icon-20px" />
        </div>
        {/* <Button icon="pi pi-times" className="absolute right-0 -mr-7 bg-white" outlined onClick={handleClose} /> */}
        <PublicBookingPageContainer rescheduleEvent={selectedEvent} previewBookingPage={previewBookingPage} />
      </>
    </Modal.Container>
  );
};
