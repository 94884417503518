import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';

export type IntegrationDataType = {
  type: IntegrationType | WorkspaceIntegrationType | AppType;
  label: string;
  icon: string;
  description: string;
};

export enum IntegrationFilter {
  ALL = 'ALL',
  CALENDARS = 'CALENDARS',
  EXTENSIONS = 'EXTENSIONS',
  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',
  WORKSPACE_INTEGRATIONS = 'WORKSPACE_INTEGRATIONS',
}

export type GetIntegrationURLParamsType = {
  code: string | null;
  type: IntegrationType | WorkspaceIntegrationType | null;
  workspaceId: string | null;
  customParameters?: any | null;
};

export type HubSpotCustomParameters = {
  bookContacts: string;
  bookLeads: string;
  createEvents: string;
};

export type GoogleAnalyticsCustomParameters = {
  trackingId: string;
  measurementId: string;
};

export enum SFBookingContacts {
  CONTACTS = 'CONTACTS',
  PERSON_ACCOUNT = 'PERSON_ACCOUNT',
}

export type SalesforceCustomParameters = {
  bookContacts: SFBookingContacts;
  bookLeads: string;
  createEvents: string;
}

export type GoogleMapsCustomParameters = {
  apiKey: string;
};

export type ConnectFormDataType = { [uniqueName: string]: string };
