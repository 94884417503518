import { FC, useEffect, useState } from 'react';
import { checkoutInIframe } from '../../../services/ChargebeeService';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { authenticationSelectors } from '../../../store/authentication';
import { billingActions } from '../../../store/billing';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { Skeleton } from 'primereact/skeleton';

interface IPayByCardProps {
  quantity: number;
  term: string;
  isShowBackButton: (isShow: boolean) => void;
  abortController?: AbortController;
}

export const PayByCard: FC<IPayByCardProps> = ({
  quantity,
  term,
  isShowBackButton,
  abortController,
}: IPayByCardProps) => {
  const dispatch = useDispatch();

  const customerInfo = useSelector(userSettingsSelectors.selectCustomerInfo);
  const tenantId = useSelector(authenticationSelectors.selectTenantId);

  const [isLoadingCheckoutIframe, setLoadingCheckoutIframe] = useState(false);
  const [iframeHeight, setIframeHeight] = useState(0);

  useEffect(() => {
    setIframeHeight(0);
    setLoadingCheckoutIframe(true);

    checkoutInIframe({
      quantity,
      term,
      customerInfo,
      tenantId,
      abortController,
      successCallback: checkoutSuccessHandler,
      loadedCallback: loadedIframeHandler,
      resizeCallback: resizeIframeHandler,
      stepChangedCallback: checkoutStepChangedHandler,
    });
  }, []);

  const checkoutSuccessHandler = () => {
    dispatch(billingActions.refreshTrialLicenseRequest());
    navigationService.navigateTo(Path.Users);
  };

  const resizeIframeHandler = (height: number) => {
    setIframeHeight(height);
  };

  const loadedIframeHandler = () => {
    setLoadingCheckoutIframe(false);
  };

  const checkoutStepChangedHandler = (stepName: string) => {
    if (stepName === 'cart-screen') {
      isShowBackButton(true);
    } else if (stepName === 'customer-details') {
      isShowBackButton(false);
    }
  };

  return (
    <>
      {isLoadingCheckoutIframe && (
        <>
          {Array(3)
            .fill(null)
            .map((_, index) => (
              <Skeleton key={index} width="100%" height="38px" className="mb-12px" />
            ))}
        </>
      )}
      {/* <div id="checkoutIframe" className='w-full' style={{height: iframeHeight, marginTop: '-92px'}}/> */}

      <div id="checkoutIframe" className="-mt-80px overflow-hidden" style={{ height: iframeHeight }} />
    </>
  );
};
