import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import {
  GetActionsRequest,
  GetActionsResponse,
  GetListUserDataByUserIdResponse,
  UserDataInputCreatedAt,
} from './types';
import { DeleteUserDataRequest } from '../../generated-sources/internal-api/models/DeleteUserDataRequest';
import { DeleteUserDataResponse } from '../../generated-sources/internal-api/models/DeleteUserDataResponse';
import { DeleteTenantRequest } from '../../generated-sources/internal-api/models/DeleteTenantRequest';
import { DeleteTenantResponse } from '../../generated-sources/internal-api/models/DeleteTenantResponse';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getUserDataById = async (): Promise<UserDataInputCreatedAt[]> => {
  const response = await handleAPIRequest<GetListUserDataByUserIdResponse>(`${API_PUBLIC}/getListUserDataByUserId`, {});
  return response.userData;
};

export const getActions = async (data: GetActionsRequest): Promise<GetActionsResponse> => {
  return handleAPIRequest<GetActionsResponse>(`${API_PUBLIC}/getActions`, data);
};

export const deleteUser = async (email: DeleteUserDataRequest['email']): Promise<DeleteUserDataResponse> => {
  return handleAPIRequest<DeleteUserDataResponse>(`${API_PUBLIC}/deleteUserData`, { email });
};

export const removeTenant = async (tenantId: DeleteTenantRequest['tenantId']): Promise<DeleteTenantResponse> => {
  return handleAPIRequest<DeleteTenantResponse>(`${API_PUBLIC}/deleteTenant`, { tenantId });
};
