import { t } from '../../../i18n/i18n';

export default {
  getStarted: t('Guide:GET_STARTED'),
  start: t('Guide:START'),
  add: t('Common:ADD'),

  back: t('Guide:BACK'),
  close: t('Guide:CLOSE'),
  last: t('Guide:LAST'),
  next: t('Guide:NEXT'),
  nextLabelWithProgress: t('Guide:NEXT_LABEL_WITH_PROGRESS'),
  open: t('Guide:OPEN'),
  skip: t('Guide:SKIP'),
  completeTour: t('Guide:COMPLETE_TOUR'),
  goTo: t('Guide:GO_TO'),

  profileTopTitle: t('Guide:PROFILE_TITLE'),
  profileDescription: t('Guide:PROFILE_DESCRIPTION'),
  profileLink: t('PrivateRoute:PROFILE'),

  integrationTopTitle: t('Guide:INTEGRATIONS_TITLE'),
  integrationDescription: t('Guide:INTEGRATIONS_DESCRIPTION'),
  integrationLink: t('IntegrationPage:TITLE'),

  availabilityTopTitle: t('Guide:AVAILABILITY_TITLE'),
  availabilityDescription: t('Guide:AVAILABILITY_DESCRIPTION'),
  availabilityLink: t('PrivateRoute:AVAILABILITY'),

  sumoInboxTopTitle: t('Guide:SUMO_INBOX_TITLE'),
  sumoInboxDescription: t('Guide:SUMO_INBOX_DESCRIPTION'),
  sumoInboxLink: t('Guide:SUMO_INBOX_LINK'),

  bookingPagesTopTitle: t('Guide:BOOKING_PAGES_TITLE'),
  bookingPagesDescription: t('Guide:BOOKING_PAGES_DESCRIPTION'),
  bookingPagesLink: t('BookingPages:TITLE'),

  bookedMeetingsTopTitle: t('Guide:BOOKED_MEETINGS_TITLE'),
  bookedMeetingsDescription: t('Guide:BOOKED_MEETINGS_DESCRIPTION'),
  bookedMeetingsLink: t('BookedMeetings:TITLE'),

  smartAlertsTopTitle: t('Guide:SMART_ALERTS_TITLE'),
  smartAlertsDescription: t('Guide:SMART_ALERTS_DESCRIPTION'),
  smartAlertsLink: t('AppMenu:SMART_ALERTS'),

  bookingTemplatesTopTitle: t('Guide:BOOKING_TEMPLATES_TITLE'),
  bookingTemplatesDescription: t('Guide:BOOKING_TEMPLATES_DESCRIPTION'),
  bookingTemplatesLink: t('BookingTemplates:TITLE'),

  teamsTopTitle: t('Guide:TEAMS_TITLE'),
  teamsDescription: t('Guide:TEAMS_DESCRIPTION'),
  teamsLink: t('Teams:TITLE'),

  journeyBuilderTopTitle: t('Guide:JOURNEY_BUILDER_TITLE'),
  journeyBuilderDescription: t('Guide:JOURNEY_BUILDER_DESCRIPTION'),
  journeyBuilderLink: t('AppMenu:JOURNEY_TITLE'),

  usersTopTitle: t('Guide:USERS_TITLE'),
  usersDescription: t('Guide:USERS_DESCRIPTION'),
  usersLink: t('Common:USERS'),

  rolesTopTitle: t('Guide:ROLES_TITLE'),
  rolesDescription: t('Guide:ROLES_DESCRIPTION'),
  rolesLink: t('Common:ROLES'),

  billingTopTitle: t('Guide:BILLING_TITLE'),
  billingDescription: t('Guide:BILLING_DESCRIPTION'),
  billingLink: t('Billing:TITLE'),
};
