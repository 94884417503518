export const Locations = Object.freeze({
  TITLE: `Ubicaciones`,
  CLONE_TITLE: `Ingrese el nombre de la ubicación clonada`,
  ADD_LABEL: `Nueva Ubicación`,
  DEACTIVATE_TITLE: `Desactivar Ubicación`,
  DEACTIVATE_DESCRIPTION: `¿Está seguro de que desea desactivar`,
  DELETE_TITLE: `Eliminar Ubicación`,
  DELETE_DESCRIPTION: `¿Está seguro de que desea eliminar`,
  IMPORT_CSV: `Importar CSV`,
  IMPORT_LABEL: `Importar`,
  STATUS: `Estado`,
  ACTIVE: `Activo`,
  INACTIVE: `Inactivo`,
  UPLOAD_DESCRIPTION_PART1: `Seleccione el archivo CSV en su computadora`,
  UPLOAD_DESCRIPTION_PART2: `o transfiera a esta área de carga`,
  IMPORT_DESCRIPTION_PART1: `Aquí puede encontrar un `,
  IMPORT_DESCRIPTION_PART2: `archivo de ejemplo`,
  IMPORT_DESCRIPTION_PART3: ``,
  EMPTY_LIST_DESCRIPTION: `Cree una ubicación o ajuste su búsqueda o filtros.`,
  SELECTED_LOCATIONS: `ubicaciones seleccionadas`,
  NO_MAPS_INTEGRATION_PART1: `Para habilitar la visualización de ubicaciones en Google Maps para la página de reserva pública, configure la cuenta de Google Maps Platform y la clave API en la sección `,
  NO_MAPS_INTEGRATION_PART2: `Integraciones`,
  NO_MAPS_INTEGRATION_PART3: `.`,
  LOCATION: `Ubicación`,
  SELECT_LOCATION: `Seleccionar ubicación`,
});

export const EditLocation = Object.freeze({
  ADD_LOCATION: `Agregar ubicación`,
  NAME_PLACEHOLDER: `Ingrese el nombre de la ubicación`,
  ADDRESS: `Dirección`,
});

export const LocationsToastsNotifications = Object.freeze({
  GET_LOCATIONS_ERROR_MESSAGE: `Error en la solicitud de ubicaciones`,
  DEACTIVATE_LOCATION_SUCCESS_MESSAGE: `Ubicación desactivada con éxito`,
  ACTIVATE_LOCATION_SUCCESS_MESSAGE: `Ubicación activada con éxito`,
  DEACTIVATE_LOCATION_ERROR_MESSAGE: `Error en la solicitud de activación/desactivación de la ubicación`,
  CLONE_LOCATION_SUCCESS_MESSAGE: `Ubicación clonada con éxito`,
  CLONE_LOCATION_ERROR_MESSAGE: `Error en la solicitud de clonación de la ubicación`,
  DELETE_LOCATION_SUCCESS_MESSAGE: `Ubicación eliminada con éxito`,
  DELETE_LOCATION_ERROR_MESSAGE: `Error en la solicitud de eliminación de la ubicación`,
  VALIDATE_LOCATIONS_ERROR_MESSAGE: `Uno o más registros tienen campos vacíos. Todos los campos son obligatorios.`,
  IMPORT_LOCATIONS_SUCCESS_MESSAGE: `Ubicaciones importadas con éxito`,
  IMPORT_LOCATIONS_ERROR_MESSAGE: `Error en la solicitud de importación de ubicaciones`,
  GET_LOCATION_ERROR_MESSAGE: `Error al obtener la ubicación`,
  SAVE_LOCATION_ERROR_MESSAGE: `Error al guardar la ubicación`,
  CREATE_LOCATION_SUCCESS_MESSAGE: `Ubicación creada correctamente`,
  UPDATE_LOCATION_SUCCESS_MESSAGE: `Ubicación actualizada correctamente`,
});
