import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconName } from '../../../../API';
import { bookingPagesActions, bookingPageSelectors } from '../../../../store/bookingPages';
import {
  bookingTemplatesActions,
  bookingTemplatesSelectors,
  smartAlertModalActions,
} from '../../../../store/bookingTemplates';
import { AppDispatch } from '../../../../store/rootStore';
import {
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
  UpsertSmartTypeFrom,
  WhomOptions,
  WHOM_SEND,
} from '../../../../store/smartAlerts';
import { getPersonalizeTitle, getSmartAlertIcon } from '../../../../store/smartAlerts/utils';
import { Personalize } from '../../../smartAlerts';
import { SumoTooltip } from '../../sumoTooltip/SumoTooltip';
import labels from './labels';

type SmartAlertItemProps = {
  smartAlertId: string;
  isReadOnly?: boolean;
  actions: typeof bookingTemplatesActions | typeof bookingPagesActions;
  selectors: typeof bookingTemplatesSelectors | typeof bookingPageSelectors;
};
export const SmartAlertItem = ({ smartAlertId, isReadOnly, actions, selectors }: SmartAlertItemProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const isBookingPage = useSelector(selectors.selectIsBookingPageSelector);
  const isFetchingSmartAlerts = useSelector(smartAlertsSelectors.selectIsFetching);
  const smartAlert = useSelector(smartAlertsSelectors.selectSmartAlertById(smartAlertId));
  const Icon = getSmartAlertIcon((smartAlert?.icon || '') as IconName);
  const [personalizeType, setPersonalizeType] = useState<WHOM_SEND | null>(null);
  const menu = useRef<Menu>(null);
  const isAlertReadOnly = useSelector(smartAlertsSelectors.selectIsAlertReadOnly(smartAlertId, SmartTypes.ALERT));

  const handleDelete = () => {
    dispatch(actions.updateSmartAlerts(smartAlertId));
  };

  const handleEdit = () => {
    if (smartAlert) {
      dispatch(smartAlertsActions.setSmartAlert(smartAlert));
      dispatch(smartAlertModalActions.openModal({ isEdit: true }));
    }
  };

  const menuItems: MenuItem[] = [
    { label: isAlertReadOnly ? labels.view : labels.edit, command: handleEdit },
    {
      label: labels.delete,
      command: handleDelete,
    },
  ];

  const handleWhomSendType = (value: WHOM_SEND, isActive: boolean) => {
    smartAlert &&
      dispatch(
        smartAlertsActions.updateWhomSendThunk(
          smartAlert,
          value,
          isActive,
          isBookingPage ? UpsertSmartTypeFrom.BOOKING_PAGE : UpsertSmartTypeFrom.BOOKING_TEMPLATE
        )
      );
  };

  const getSubject = () => {
    return (personalizeType && smartAlert?.whomSend && smartAlert.whomSend[personalizeType]?.subject) || '';
  };

  const getBody = () => {
    return (personalizeType && smartAlert?.whomSend && smartAlert.whomSend[personalizeType]?.body) || '';
  };

  const handlePersonalizeSave = (body: string, subject?: string) => {
    if (smartAlert && personalizeType) {
      dispatch(
        smartAlertsActions.personalizeSaveThunk(
          smartAlert,
          personalizeType,
          isBookingPage ? UpsertSmartTypeFrom.BOOKING_PAGE : UpsertSmartTypeFrom.BOOKING_TEMPLATE,
          body,
          subject
        )
      );
      setPersonalizeType(null);
    }
  };

  return (
    <>
      {smartAlert && (
        <div className="flex flex-row gap-8px">
          <div className="w-24px">
            <Icon className="icon-24px" />
          </div>

          <div className="flex flex-column gap-16px">
            <div className="flex-between-center">
              <div className="text-title-s-med">{smartAlert.name || ''}</div>
              <div
                className="-my-4px -mr-16px action-button"
                onClick={(e) => {
                  e.stopPropagation();
                  menu.current?.toggle(e);
                }}
              >
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            </div>
            <div className="flex flex-wrap -mx-28px -my-8px">
              {WhomOptions.map((whom) => (
                <div
                  key={whom.value}
                  className={`w-12 lg:w-6 py-8px px-28px flex-left-center gap-12px text-label-input-reg text-heavy-80`}
                >
                  <InputSwitch
                    checked={!!smartAlert.whomSend && !!smartAlert.whomSend[whom.value]?.isActive}
                    className="flex-none size-small"
                    onChange={(e) => handleWhomSendType(whom.value, !!e.value)}
                    disabled={isFetchingSmartAlerts || isAlertReadOnly || isReadOnly}
                  />
                  <div>{whom.label}</div>
                  {whom.value === WHOM_SEND.smsToHost && <SumoTooltip text={labels.smsToHostTooltip} />}
                  <Button
                    onClick={() => setPersonalizeType(whom.value)}
                    label={labels.personalize}
                    className="flex-none ml-auto button-text-line button-blue text-label-s-med"
                    text
                    disabled={isFetchingSmartAlerts}
                  />
                </div>
              ))}
            </div>
          </div>
          <Personalize
            visible={!!personalizeType}
            close={() => setPersonalizeType(null)}
            handleCancel={() => setPersonalizeType(null)}
            subject={getSubject()}
            body={getBody()}
            title={personalizeType ? getPersonalizeTitle(personalizeType) : ''}
            handleSave={handlePersonalizeSave}
            isSMS={!!personalizeType && [WHOM_SEND.smsToHost, WHOM_SEND.smsToInvitee].includes(personalizeType)}
            isReadOnly={isAlertReadOnly}
          />
        </div>
      )}
    </>
  );
};
