import { EventSendType, IconName, SmartAlertTemplate } from '../../API';

export const TEMPLATES: SmartAlertTemplate[] = [
  {
    __typename: 'SmartAlertTemplate',
    id: 'c5d17685-e73e-56c8-a08a-2f87c8dd4f03',
    name: 'Send your own feedback survey',
    description: 'Email a survey link from a third party like Typeform or Google Forms to get feedback from invitees',
    icon: IconName.STATISTIC,
    isHide: false,
    shareWith: [],
    whenSend: {
      __typename: 'WhenSend',
      eventSendType: EventSendType.EVENT_ENDS,
      delay: 120,
      immediately: false,
    },
    whomSend: {
      __typename: 'WhomSend',
      emailToInvitee: {
        __typename: 'AlertTemplate',
        isActive: true,
        subject: 'Let me know how &ltMeeting Name&gt went!',
        body: "<p>Hi &ltInvitee Name&gt,</p><br><p>Thanks for attending &ltMeeting Name&gt. If you have a few minutes, I'd appreciate you taking this quick survey to let me know what you thought of our meeting.</p><br><p><a href='#' rel='noopener noreferrer' target='_blank'>Insert Link</a></p><br><p>Don't hesitate to reach out if you have any feedback or questions.</p><br><p>Best,</p><p>&ltHost Name&gt</p>",
      },
      emailToHost: {
        __typename: 'AlertTemplate',
      },
      smsToInvitee: {
        __typename: 'AlertTemplate',
        body: "Thanks for attending &ltMeeting Name&gt. If you have a few minutes, I'd appreciate you taking this quick survey to let me know what you thought of our meeting. Insert Link Best, &ltHost Name&gt",
      },
      smsToHost: {
        __typename: 'AlertTemplate',
      },
    },
    createdAt: '',
    updatedAt: '',
  },
  {
    __typename: 'SmartAlertTemplate',
    id: '8b5c61c6-ed33-5db8-99fd-76af1494e196',
    name: 'Reminder to invitee',
    description: 'Reduce no-shows — send automated email reminders to invitees',
    icon: IconName.REMAINDER,
    isHide: false,
    shareWith: [],
    whenSend: {
      __typename: 'WhenSend',
      eventSendType: EventSendType.EVENT_BEFORE_START,
      delay: 2880,
      immediately: false,
    },
    whomSend: {
      __typename: 'WhomSend',
      emailToInvitee: {
        __typename: 'AlertTemplate',
        isActive: true,
        subject: 'Reminder: &ltMeeting Name&gt with &ltHost Name&gt at &ltMeeting Time&gt, &ltMeeting Date&gt',
        body: '<p>Hi &ltInvitee Name&gt,</p><br><p>This is a friendly reminder that your &ltMeeting Name&gt with &ltHost Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt.</p><br><p>&ltMeeting Location&gt</p><br><p>&ltMeeting Button&gt</p>',
      },
      emailToHost: {
        __typename: 'AlertTemplate',
        subject: 'Reminder: &ltMeeting Name&gt with &ltInvitee Name&gt at &ltMeeting Time&gt, &ltMeeting Date&gt',
        body: '<p>Hi &ltHost Name&gt,</p><br><p>This is a friendly reminder that your &ltMeeting Name&gt with &ltInvitee Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt.</p><br><p>&ltMeeting Location&gt</p><br><p>&ltMeeting Button&gt</p>',
      },
      smsToInvitee: {
        __typename: 'AlertTemplate',
        body: 'This is a friendly reminder that your &ltMeeting Name&gt with &ltHost Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt. View or edit meeting &ltMeeting Link&gt',
      },
      smsToHost: {
        __typename: 'AlertTemplate',
        body: 'This is a friendly reminder that your &ltMeeting Name&gt with &ltInvitee Name&gt is at &ltMeeting Time&gt, &ltMeeting Date&gt. View or edit meeting &ltMeeting Link&gt',
      },
    },
    createdAt: '',
    updatedAt: '',
    version: 0,
  },
  {
    __typename: 'SmartAlertTemplate',
    id: '264604ae-54c1-5a89-98ff-3978c42c478b',
    name: 'Send thank you',
    description: 'Build relationships with a quick thanks',
    icon: IconName.THANK_YOU,
    isHide: false,
    shareWith: [],
    whenSend: {
      __typename: 'WhenSend',
      eventSendType: EventSendType.EVENT_ENDS,
      immediately: true,
    },
    whomSend: {
      __typename: 'WhomSend',
      emailToInvitee: {
        __typename: 'AlertTemplate',
        subject: 'Thanks for your time!',
        body: "<p>Hi &ltInvitee Name&gt,</p><br><p>Thanks for attending &ltMeeting Name&gt.</p><br><p>Don't hesitate to reach out if you have any feedback or questions.</p><br><p>Thanks,</p><p>&ltHost Name&gt</p>",
      },
      emailToHost: {
        __typename: 'AlertTemplate',
      },
      smsToInvitee: {
        __typename: 'AlertTemplate',
        body: "Thanks for attending &ltMeeting Name&gt. Don't hesitate to reach out if you have any feedback or questions. Thanks, &ltHost Name&gt",
      },
      smsToHost: {
        __typename: 'AlertTemplate',
      },
    },
    createdAt: '',
    updatedAt: '',
  },
  {
    __typename: 'SmartAlertTemplate',
    id: 'ea8cd640-6c7d-5de8-bc93-4be74e38f144',
    name: 'Request follow-up meeting',
    description: "Don't wait to meet again",
    icon: IconName.FOLLOW_UP,
    isHide: false,
    shareWith: [],
    whenSend: {
      __typename: 'WhenSend',
      eventSendType: EventSendType.EVENT_ENDS,
      delay: 1440,
      immediately: false,
    },
    whomSend: {
      __typename: 'WhomSend',
      emailToInvitee: {
        __typename: 'AlertTemplate',
        isActive: true,
        subject: 'Ready to schedule the next &ltMeeting Name&gt?',
        body: "<p>Hi &ltInvitee Name&gt,</p><br><p>Thanks for attending &ltMeeting Name&gt. Let's get another meeting on the books. Please use my &ltBooking Page&gt to schedule our next &ltMeeting Name&gt.</p><br><p>Looking forward to our follow-up. Don't hesitate to reach out if you have any feedback or questions.</p><br><p>Best,</p><p>&ltHost Name&gt</p>",
      },
      emailToHost: {
        __typename: 'AlertTemplate',
      },
      smsToInvitee: {
        __typename: 'AlertTemplate',
        body: "Thanks for attending &ltMeeting Name&gt. Let's get another meeting on the books. Please use my &ltBooking Page&gt to schedule our next &ltMeeting Name&gt. Best, &ltHost Name&gt",
      },
      smsToHost: {
        __typename: 'AlertTemplate',
      },
    },
    createdAt: '',
    updatedAt: '',
  },
  {
    __typename: 'SmartAlertTemplate',
    id: 'c5e07ee0-0c42-5492-ab22-0243a99b1b21',
    name: 'Send additional resources',
    description: 'Send links for additional resources to your invitees',
    icon: IconName.ADDITIONAL_RESOURCES,
    isHide: false,
    shareWith: [],
    whenSend: {
      __typename: 'WhenSend',
      eventSendType: EventSendType.EVENT_ENDS,
      delay: 120,
      immediately: false,
    },
    whomSend: {
      __typename: 'WhomSend',
      emailToInvitee: {
        __typename: 'AlertTemplate',
        isActive: true,
        subject: 'Check out the additional information regarding &ltMeeting Name&gt',
        body: "<p>Hi &ltInvitee Name&gt,</p><br><p>Thanks for attending &ltMeeting Name&gt. Here is some additional information I wanted to share with you.</p><br><p><a href='#' rel='noopener noreferrer' target='_blank'>Insert Link</a></p><p><a href='#' rel='noopener noreferrer' target='_blank'>Insert Link</a></p><br><p>Thanks,</p><p>&ltHost Name&gt</p>",
      },
      emailToHost: {
        __typename: 'AlertTemplate',
      },
      smsToInvitee: {
        __typename: 'AlertTemplate',
        body: 'Thanks for attending &ltMeeting Name&gt. Here is some additional information I wanted to share with you. Insert Link Insert Link Thanks, &ltHost Name&gt',
      },
      smsToHost: {
        __typename: 'AlertTemplate',
      },
    },
    createdAt: '',
    updatedAt: '',
  },
];