import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  QUICK_SETUP_NAVIGATION_MODULE_KEY,
  QUICK_SETUP_NAVIGATION_SETTINGS_ADMIN,
  QUICK_SETUP_NAVIGATION_SETTINGS_STANDARD_USER,
  QuickSetupStep,
  quickSetupActions,
  quickSetupSelectors,
} from '../../store/quickSetup';
import { Navigation, Preloader } from '../../components/common';
import {
  SetupAvailabilityStep,
  SetupCalendarConnectedStep,
  SetupChromeExtensionStep,
  SetupPhoneCalls,
  SetupVideoConferenceStep,
  SetupYourBrandStep,
} from '../../components/quickSetup';
import { navigationsActionsFactory } from '../../store/navigations';
import { AppDispatch } from '../../store/rootStore';
import { UserSettingsKeys, userSettingsActions, userSettingsSelectors } from '../../store/userSettings';
import { useLocation } from 'react-router-dom';
import { authenticationSelectors } from '../../store/authentication';
import { Path } from '../../routing';
import { navigationService } from '../../services/NavigationService';
import { availabilityActions, availabilitySelectors } from '../../store/availability';
import { AvailabilityModel } from '../../generated-sources/internal-api/models/AvailabilityModel';

export const QuickSetup = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isFetching = useSelector(quickSetupSelectors.selectIsFetching);
  const isUserSettingsFetching = useSelector(userSettingsSelectors.selectIsFetching);
  const isAuthenticationFetching = useSelector(authenticationSelectors.selectIsFetching);
  const isUserSettingsReceived = useSelector(userSettingsSelectors.selectIsUserSettingsReceived);
  const isZoomConnected = useSelector(userSettingsSelectors.selectIsZoomConnected);
  const userSettingsError = useSelector(userSettingsSelectors.selectError);
  const isFirstAdmin = useSelector(userSettingsSelectors.selectIsFirstTenantUser);
  const userSettingsPhoneNumber = useSelector(userSettingsSelectors.selectPhoneNumber);
  const userSettingsPhoneDetails = useSelector(userSettingsSelectors.selectPhoneDetails);
  const userAvailability = useSelector(availabilitySelectors.selectAvailability);
  const location = useLocation();

  useEffect(() => {
    dispatch(
      quickSetupActions.updatePhoneCalls({
        phoneNumber: userSettingsPhoneNumber || '',
        phoneDetails: userSettingsPhoneDetails || '',
      })
    );
    dispatch(availabilityActions.getAvailabilityRequest());
  }, []);

  useEffect(() => {
    // checking Zoom redirect after trying to connect
    if (isUserSettingsReceived && !isAuthenticationFetching && !userSettingsError) {
      // TODO: move all that code to Zoom service
      const urlParams = new URLSearchParams(location.search);
      const authCode = urlParams.get('code');

      if (authCode && !isZoomConnected) {
        const url = new URL(window.location.href);
        url.search = ''; // clear all query params
        const redirectURI = url.href; // current page URL without params

        dispatch(
          userSettingsActions.connectZoomRequest({
            code: authCode,
            redirectURI,
          })
        );

        const weeklyHours = JSON.parse(localStorage.getItem(UserSettingsKeys.QUICK_SETUP_WEEKLY_HOURS) || '');
        const availability = {
          ...userAvailability,
          weeklyHours
        } as AvailabilityModel;
        dispatch(quickSetupActions.setUserAvailability(availability));

        navigationService.navigateTo(Path.QuickSetup); // clear the url from code

        dispatch(
          navigationsActionsFactory(QUICK_SETUP_NAVIGATION_MODULE_KEY).setCurrentStep(QuickSetupStep.VIDEO_CONFERENCE)
        );
      }
    }
  }, [isUserSettingsReceived, isAuthenticationFetching, userSettingsError]); // Call the function when we already downloaded userSettings and bookingPages

  return (
    <>
      {(isFetching || isUserSettingsFetching) && <Preloader />}
      <Navigation.Container
        settings={isFirstAdmin ? QUICK_SETUP_NAVIGATION_SETTINGS_ADMIN : QUICK_SETUP_NAVIGATION_SETTINGS_STANDARD_USER}
      >
        <Navigation.Step name={QuickSetupStep.CALENDAR_CONNECTED}>
          <SetupCalendarConnectedStep />
        </Navigation.Step>
        <Navigation.Step name={QuickSetupStep.AVAILABILITY}>
          <SetupAvailabilityStep />
        </Navigation.Step>
        <Navigation.Step name={QuickSetupStep.VIDEO_CONFERENCE}>
          <SetupVideoConferenceStep />
        </Navigation.Step>
        <Navigation.Step name={QuickSetupStep.PHONE_CALLS}>
          <SetupPhoneCalls />
        </Navigation.Step>
        <Navigation.Step name={QuickSetupStep.YOUR_BRAND}>
          <SetupYourBrandStep />
        </Navigation.Step>
        <Navigation.Step name={QuickSetupStep.CHROME_EXTENSION}>
          <SetupChromeExtensionStep />
        </Navigation.Step>
      </Navigation.Container>
    </>
  );
};
