import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const editMeetingNotesModalActions = modalsActionsFactory(ModalName.EDIT_MEETING_NOTES_MODAL_NAME);
export const editMeetingNotesModalSelectors = modalsSelectorsFactory(ModalName.EDIT_MEETING_NOTES_MODAL_NAME);

export const cancelEventModalActions = modalsActionsFactory(ModalName.CANCEL_EVENT_MODAL_NAME);
export const cancelEventModalSelectors = modalsSelectorsFactory(ModalName.CANCEL_EVENT_MODAL_NAME);

export const rescheduleEventModalActions = modalsActionsFactory(ModalName.RESCHEDULE_EVENT_MODAL_NAME);
export const rescheduleEventModalSelectors = modalsSelectorsFactory(ModalName.RESCHEDULE_EVENT_MODAL_NAME);

export const noShowEventModalActions = modalsActionsFactory(ModalName.NO_SHOW_EVENT_MODAL_NAME);
export const noShowEventModalSelectors = modalsSelectorsFactory(ModalName.NO_SHOW_EVENT_MODAL_NAME);
