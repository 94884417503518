import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions, BillingAddress, billingAddressModalActions, billingSelectors } from '../../../store/billing';
import { ModalName } from '../../../store/modals';
import { CountryDropdown, Modal } from '../../common';
import labels from './labels';
import { STATE_COUNTRIES } from '../../../store/opsConsole/orgs';
import { MAX_LENGTH_NAME } from '../../../types/constants';

export const BillingAddressModal = () => {
  const dispatch = useDispatch();
  const billingAddress = useSelector(billingSelectors.selectBillingAddress);
  const isBillingAddressUpdated = useSelector(billingSelectors.selectIsBillingAddressUpdated);

  const handleChange = (addressField: Partial<BillingAddress>) => {
    dispatch(billingActions.setBillingAddress({ ...billingAddress, ...addressField }));
  };

  const handleConfirm = () => {
    dispatch(billingActions.updateBillingAddressRequest());
    dispatch(billingAddressModalActions.closeModal());
  };

  const handleCancel = () => {
    dispatch(billingAddressModalActions.closeModal());
  };

  return (
    <Modal.Container name={ModalName.BILLING_ADDRESS_MODAL} className="w-460px">
      <Modal.Header>{labels.title}</Modal.Header>
      <div className="flex flex-wrap -m-12px">
        <div className="w-6 p-12px flex flex-column gap-10px">
          <div>{labels.name}</div>
          <InputText value={billingAddress.first_name} onChange={(e) => handleChange({ first_name: e.target.value })} />
        </div>
        <div className="w-6 p-12px flex flex-column gap-10px">
          <div>{labels.lastName}</div>
          <InputText value={billingAddress.last_name} onChange={(e) => handleChange({ last_name: e.target.value })} />
        </div>

        <div className="w-12 p-12px flex flex-column gap-10px">
          <div>{labels.address}</div>
          <InputText value={billingAddress.line1} onChange={(e) => handleChange({ line1: e.target.value })} />
        </div>

        <div className="w-6 p-12px flex flex-column gap-10px">
          <div>{labels.city}</div>
          <InputText value={billingAddress.city} onChange={(e) => handleChange({ city: e.target.value })} />
        </div>
        <div className="w-6 p-12px flex flex-column gap-10px">
          <div>{labels.state}</div>
          <InputText
            value={billingAddress.state}
            onChange={(e) => handleChange({ state: e.target.value })}
            className={
              STATE_COUNTRIES.includes(billingAddress.country || '') && !billingAddress.state ? 'p-invalid' : ''
            }
            maxLength={MAX_LENGTH_NAME}
          />
        </div>

        <div className="w-6 p-12px flex flex-column gap-10px">
          <div>{labels.zip}</div>
          <InputText value={billingAddress.zip} onChange={(e) => handleChange({ zip: e.target.value })} />
        </div>
        <div className="w-6 p-12px flex flex-column gap-10px">
          <div>{labels.country}</div>
          <CountryDropdown
            value={billingAddress.country || ''}
            onChange={(value) => handleChange({ country: value })}
            className={!billingAddress.country ? 'p-invalid' : ''}
          />
        </div>
      </div>
      <Modal.Buttons>
        <Button label={labels.confirm} onClick={handleConfirm} disabled={!isBillingAddressUpdated} />
        <Button label={labels.cancel} onClick={handleCancel} outlined />
      </Modal.Buttons>
    </Modal.Container>
  );
};
