import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { ModalName } from '../../../../store/modals';
import { deleteOrgModalActions, orgsActions } from '../../../../store/opsConsole/orgs';
import { Modal } from '../../../common';
import labels from './labels';

export const OrgDeleteModal = () => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(deleteOrgModalActions.closeModal());
  };

  const handleDelete = () => {
    dispatch(orgsActions.deleteOrgRequest());
  };

  return (
    <Modal.Container name={ModalName.DELETE_ORG_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl">{labels.title}</div>
      </Modal.Header>
      <div className="max-w-30rem mb-3">{labels.description}</div>
      <Modal.Buttons>
        <Button className="ml-auto" label={labels.cancel} onClick={handleCancel} outlined autoFocus />
        <Button label={labels.delete} onClick={handleDelete} severity="danger" />
      </Modal.Buttons>
    </Modal.Container>
  );
};
