import { useState, useEffect, useRef } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { InputText } from 'primereact/inputtext';
import { Address, AddressInput } from '../../../../API';
import {
  CITY_TYPES,
  getAddressComponent,
  getFullAddress,
  STATE_TYPES,
  STREET_TYPES,
} from '../../../../store/locations';

type GoogleMapSearchProps = {
  address?: Address | null;
  onAddressSelect: (address: AddressInput) => void;
  onPlaceSelect?: (place: google.maps.places.PlaceResult | null) => void;
  followLocation?: boolean;
  className?: string;
  isReadOnly?: boolean;
};

export const GoogleMapSearch = ({
  address,
  onAddressSelect,
  onPlaceSelect,
  followLocation,
  className,
  isReadOnly,
}: GoogleMapSearchProps) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary('places');
  const map = useMap();

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address', 'address_components'],
    };
    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace();
      onPlaceSelect && onPlaceSelect(place); // to convert place to map marker
      if (followLocation && map && place.geometry?.viewport) {
        map.fitBounds(place.geometry?.viewport);
      }
      const addressComponents = place.address_components;

      const getComponentValue = (types: string[]) => {
        let value = '';
        for (const componentType of types) {
          value = getAddressComponent(addressComponents, componentType);
          if (value) {
            break;
          }
        }
        return value;
      };

      const address = {
        name: getAddressComponent(addressComponents, 'street_number') + ' ' + getComponentValue(STREET_TYPES),
        city: getComponentValue(CITY_TYPES),
        state: getComponentValue(STATE_TYPES),
        country: getAddressComponent(addressComponents, 'country'),
        zip: getAddressComponent(addressComponents, 'postal_code'),
        lat: place.geometry?.location?.lat().toString(),
        lng: place.geometry?.location?.lng().toString(),
      } as AddressInput;

      onAddressSelect(address); // to store splitted address
    });
  }, [placeAutocomplete]);

  useEffect(() => {
    if (!placeAutocomplete || !address || !!inputRef.current?.value) return;

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        location: { lat: +(address.lat || ''), lng: +(address.lng || '') },
        address: getFullAddress(address),
      },
      (results, status) => {
        if (status === 'OK' && results && results[0]) {
          const place = results[0];
          onPlaceSelect && onPlaceSelect(place);
          if (followLocation && map && place.geometry?.viewport) {
            map.fitBounds(place.geometry?.viewport);
          }
          if (inputRef.current) {
            inputRef.current.value = place.formatted_address;
          }
        }
      }
    );
  }, [address, placeAutocomplete]);

  return <InputText ref={inputRef} className={className} disabled={isReadOnly} />;
};
