import { t } from '../../../i18n/i18n';

export default {
  addVariable: t('EditSmartAlert:ADD_VARIABLE'),
  subject: t('EditSmartAlert:SUBJECT'),
  body: t('EditSmartAlert:BODY'),
  cancel: t('Common:CANCEL'),
  save: t('Common:SAVE'),
  characters: t('Common:CHARACTERS'),
  timing: t('EditBookingTemplateNotificationsStep:TIMING'),
  beforeEvent: t('EditBookingTemplateNotificationsStep:BEFORE_EVENT'),
  afterEvent: t('EditBookingTemplateNotificationsStep:AFTER_EVENT'),
  smsLabelPart1: t('EditBookingTemplateNotificationsStep:SMS_LABEL_PART1'),
  smsLabelPart2: t('EditBookingTemplateNotificationsStep:SMS_LABEL_PART2'),
};
