import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { DateFormat, TimeFormat, userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { scheduledMeetingsSelectors } from '../../../store/bookedMeetings/selectors';
import { CreateUserEventInput, CustomFieldInput, CustomFieldType, LocationType } from '../../../API';
import { formatDateByThreeOptions, formatDateTimeThroughATSymbol } from '../../../services/DateService';
import { scheduledMeetingsActions } from '../../../store/bookedMeetings/actions';
import { SortMethod } from '../../../store/bookedMeetings/types';
import {
  DEFAULT_FILTER,
  SORT_OPTIONS,
  editorTextRegex,
  locationLabels,
  statusList,
} from '../../../store/bookedMeetings/constants';
import dayjs from 'dayjs';
import { usersSelectors } from '../../../store/users';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { FilterDateRange, SectionHeader } from '../../common';
import { workspacesSelectors } from '../../../store/workspaces';

export const BookedFilters = () => {
  const dispatch = useDispatch();
  const isSpinnerFetching = useSelector(scheduledMeetingsSelectors.selectIsSpinnerFetching);
  const dateFormat = useSelector(userSettingsSelectors.selectDateFormat) || DateFormat.default;
  const timeForamt = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const filteredMeetings = useSelector(scheduledMeetingsSelectors.selectFilteredEvents);
  const bookingPageOptions = useSelector(scheduledMeetingsSelectors.selectBookingPageOptions);
  const selectedStatuses = useSelector(scheduledMeetingsSelectors.selectStatuses);
  const selectedSortMethod = useSelector(scheduledMeetingsSelectors.selectSortMethod);
  const selectedWorkspaces = useSelector(scheduledMeetingsSelectors.selectSelectedWorkspaces);
  const selectedBookingPages = useSelector(scheduledMeetingsSelectors.selectFilteredSelectedBookingPages);
  const dateRange = useSelector(scheduledMeetingsSelectors.selectDateRange);
  const userNamesById = useSelector(usersSelectors.selectUserNamesById);
  const isDefaultFilter = useSelector(scheduledMeetingsSelectors.selectIsDefaultFilter);
  const isFilterActive = useSelector(scheduledMeetingsSelectors.selectIsFilterInUse);
  const isEventsLoaded = useSelector(scheduledMeetingsSelectors.selectIsEventsLoaded);
  const workspaceOptions = useSelector(workspacesSelectors.selectWorkspaceOptions);

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedRange, setSelectedRange] = useState(dateRange.map((date) => new Date(date)));

  useEffect(() => {
    isEventsLoaded && isDefaultFilter && resetFilters();
  }, [bookingPageOptions]);

  const getLocationName = (location: LocationType | null | undefined) => {
    return location ? locationLabels[location] : labels.noLocationGiven;
  };

  const getAttendeeEmail = (customField: Array<CustomFieldInput | null> | null | undefined) => {
    const emailField = customField?.find((field) => field?.fieldType === CustomFieldType.EMAIL) || null;
    return emailField && emailField?.answers && emailField.answers[0] ? emailField.answers[0] : '';
  };

  // utility to Convert Array to CSV
  const convertArrayToCSV = (data: CreateUserEventInput[]): string => {
    const CSVHeaders = [
      // 'MEETING NAME', // TODO Add Meeting name from event
      'DATETIME',
      'TIME ZONE',
      'LOCATION',
      'HOST',
      'ATTENDEES',
      'MEETING NOTES',
      'CANCELED',
      'NO-SHOW',
      // 'ATTENDEE LIMIT',
      // 'BOOKED VS LIMITS',
    ];

    const rows = data.map((obj) =>
      [
        // bookingTemplateNamesById[obj.bookingTemplateId] || '',
        formatDateTimeThroughATSymbol(obj.startTime, obj.endTime, dateFormat, timeForamt), // 01/01/1995 @ 01-00pm - 02:00pm
        obj.timeZone,
        getLocationName(obj.location?.type),
        userNamesById[obj.userId] || '',
        getAttendeeEmail(obj.inputFields) + (obj.guestEmails?.length ? '; ' + obj.guestEmails?.join('; ') : ''),
        obj.note ? obj.note.replace(editorTextRegex, '') : '',
        obj.canceled?.isCanceled ? labels.yes : labels.no,
        obj.isNoShow ? labels.yes : labels.no,
        // '', // empty for 'ATTENDEE LIMIT' until this entity is not exist
        // '', // empty for 'BOOKED VS LIMITS' until this entity is not exist
      ].join(',')
    );

    return [CSVHeaders.join(','), ...rows].join('\n');
  };

  // handler to download CSV
  const downloadCSV = () => {
    const csvContent = convertArrayToCSV(filteredMeetings);
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'booked-meetings.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const getRangeLabel = () =>
    selectedRange && selectedRange[0]
      ? formatDateByThreeOptions(selectedRange[0], dateFormat) +
        (selectedRange[1] ? ` - ${formatDateByThreeOptions(selectedRange[1], dateFormat)}` : '')
      : undefined;

  const handleDateChange = (value?: string | Date | Date[] | null) => {
    if (Array.isArray(value)) {
      setSelectedRange(value);
      const from = value[0] ? dayjs(value[0]).format('YYYY-MM-DD') : '';
      const to = value[1] ? dayjs(value[1]).format('YYYY-MM-DD') : from;
      dispatch(scheduledMeetingsActions.setFilter({ dateRange: [from, to] }));
    } else {
      setSelectedRange([]); //clear button clicked
      dispatch(scheduledMeetingsActions.setFilter({ dateRange: [] }));
    }
  };

  const handleSortClick = (sortMethod: SortMethod) => {
    dispatch(scheduledMeetingsActions.setFilter({ sortMethod }));
    setShowCalendar(sortMethod === SortMethod.DATE_RANGE);
  };

  const resetFilters = () => {
    dispatch(
      scheduledMeetingsActions.setFilter({
        ...DEFAULT_FILTER,
        workspaceIds: workspaceOptions.map((option) => option.id),
        bookingPageIds: bookingPageOptions.map((option) => option.value),
      })
    );
    setSelectedRange([]);
  };

  return (
    <div className="flex-1 flex flex-column">
      <SectionHeader
        loading={isSpinnerFetching}
        title={labels.title}
        hideButton
        filters={[
          {
            label: labels.workspace,
            value: selectedWorkspaces,
            options: workspaceOptions.map((option) => ({ value: option.id, label: option.name })),
            onChange: (e) => dispatch(scheduledMeetingsActions.setFilter({ workspaceIds: e.value })),
          },
          {
            label: labels.bookingPage,
            value: selectedBookingPages || [],
            options: bookingPageOptions,
            onChange: (e) => dispatch(scheduledMeetingsActions.setFilter({ bookingPageIds: e.value })),
          },
          {
            label: labels.status,
            value: selectedStatuses,
            options: statusList,
            onChange: (e) => dispatch(scheduledMeetingsActions.setFilter({ statuses: e.value })),
          },
        ]}
        extraContent={
          <Button className="flex-none button-xl button-icon-xl button-white" onClick={downloadCSV}>
            <ArrowDownTrayIcon className="icon-24px" />
          </Button>
        }
        onFilterReset={resetFilters}
        isFilterActive={isFilterActive}
      />

      <div className="flex gap-10px mb-32px">
        {SORT_OPTIONS.map((option) => (
          <div
            key={option.value}
            className={`sumo-card action-card flex-center w-120px h-60px text-title-xs-med text-center ${
              selectedSortMethod === option.value ? 'text-blue-main' : 'text-heavy-80'
            }`}
            onClick={() => handleSortClick(option.value)}
          >
            {(option.value === SortMethod.DATE_RANGE && getRangeLabel()) || option.label}
          </div>
        ))}
      </div>

      <FilterDateRange
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        selectedRange={selectedRange}
        handleDateChange={handleDateChange}
      />
    </div>
  );
};

BookedFilters.displayName = 'BookedFilters';
