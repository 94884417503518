import { t } from '../../../i18n/i18n';

export default {
  title: t('Billing:BILLING_ADDRESS'),
  address: t('Common:ADDRESS'),
  cancel: t('Common:CANCEL'),
  city: t('Common:CITY'),
  confirm: t('Common:CONFIRM'),
  country: t('Common:COUNTRY'),
  lastName: t('Common:LAST_NAME'),
  name: t('Common:NAME'),
  state: t('Common:STATE'),
  zip: t('Common:ZIP'),
};
