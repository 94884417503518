import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName, modalsActions } from '../../../store/modals';
import {
  cannotDeleteWorkspaceNameModalActions,
  cannotDeleteWorkspaceNameModalSelectors,
  workspacesSelectors,
} from '../../../store/workspaces';
import { ConfirmationModal } from '../../common';
import labels from './labels';

export const CannotDeleteWorkspaceModal = () => {
  const dispatch = useDispatch();
  const usersWithoutOtherWorkspace = useSelector(workspacesSelectors.selectUsersWithoutOtherWorkspace);
  const isOpen = useSelector(cannotDeleteWorkspaceNameModalSelectors.selectIsModalOpen);

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: ModalName.CANNOT_DELETE_WORKSPACE_NAME }));
    return () => {
      dispatch(modalsActions.deleteModal(ModalName.CANNOT_DELETE_WORKSPACE_NAME));
    };
  }, []);

  const handleClose = () => {
    dispatch(cannotDeleteWorkspaceNameModalActions.closeModal());
  };

  return (
    <ConfirmationModal
      visible={isOpen}
      title={labels.title}
      ExtraElements={
        <>
          <div>{labels.description + ':'}</div>
          {usersWithoutOtherWorkspace.map((user) => (
            <div key={user.email} className="white-space-nowrap">
              {'\u2022 ' + user.email}
            </div>
          ))}
          <div>{labels.notes}</div>
        </>
      }
      cancelButtonLabel={labels.close}
      onCancel={handleClose}
      onClose={handleClose}
    />
  );
};
