export const OPSConsoleStaff = Object.freeze({
  ACTIVE: `Actif`,
  ADD_NEW_MEMBER: `Ajouter un nouveau membre`,
  ADMIN_TITLE: `Admin principal`,
  INACTIVE: `Inactif`,
  SALES_TITLE: `Ventes`,
  SUPPORT_TITLE: `Support`,
  OPERATIONS_TITLE: `Opérations`,
  TITLE: `Personnel`,
  ADD_STAFF_TITLE: `Créer un membre du personnel`,
  DUPLICATE_EMAIL: `Cet e-mail a déjà été ajouté.`,
  LAST_LOGIN: `Dernière connexion`,
});

export const StaffToastsNotifications = Object.freeze({
  GET_STAFF_FAIL_MESSAGE: `Échec de la demande du personnel`,
  SAVE_STAFF_SUCCESS_MESSAGE: `Enregistrement de la demande du personnel avec succès`,
  SAVE_STAFF_ROLE_SELECTION_FORBIDDEN_MESSAGE: `Un nouvel utilisateur personnel Admin Principal ne peut être invité que par un autre Admin Principal.`,
  SAVE_STAFF_FAIL_MESSAGE: `Échec de la demande du personnel`,
  CHANGE_STAFF_STATUS_FAIL_MESSAGE: `Échec de la demande de changement de statut du personnel`,
  ACTIVATE_STAFF_SUCCESS_MESSAGE: `Activation de l'enregistrement du personnel avec succès`,
  DEACTIVATE_STAFF_SUCCESS_MESSAGE: `Désactivation de l'enregistrement du personnel avec succès`,
  DELETE_STAFF_SUCCESS_MESSAGE: `Suppression de l'enregistrement du personnel avec succès`,
  DELETE_STAFF_FAIL_MESSAGE: `Échec de la demande de suppression du personnel`,
  VIEW_AS_USER_FAIL_MESSAGE: `Échec de la requête de visualisation en tant qu'utilisateur`,
});
