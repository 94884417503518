import { useDispatch, useSelector } from 'react-redux';
import { authenticationSelectors } from '../../../store/authentication';
import { WorkspaceIntegrationType } from '../../../API';
import { useState } from 'react';
import { ConnectIntegration } from '../connectIntegration/ConnectIntegration';
import { Button, ButtonProps } from 'primereact/button';
import { ConnectFormIntegration } from '../connectFormIntegration/ConnectFormIntegration';
import { ConnectFormDataType, integrationActions } from '../../../store/integration';
import labels from './labels';

interface IGoogleMapsIntegration {
  integrationType: WorkspaceIntegrationType;
  handleDisconnect: () => void;
}

export const GoogleMapsIntegration = ({ integrationType, handleDisconnect }: IGoogleMapsIntegration) => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const isWorkspaceIntegrationConnected = useSelector(
    authenticationSelectors.selectIsWorkspaceIntegrationConnected(integrationType)
  );

  const [showForm, setShowForm] = useState(false);

  const toggleShowForm = () => {
    setShowForm((prevState) => !prevState);
  };

  const handleSave = (dataForm: ConnectFormDataType) => {
    dispatch(
      integrationActions.connectWorkspaceIntegrationRequest({
        type: integrationType,
        workspaceId: workspaceId,
        customParameters: {
          apiKey: dataForm['apiKey'],
        },
      })
    );
  };

  const handleDisconnectClick = () => {
    setShowForm(false);
    handleDisconnect();
  };

  const connectButton = ({ label, outlined, severity, className, disabled }: ButtonProps) => {
    return (
      <Button
        label={label}
        className={className}
        outlined={outlined}
        severity={severity as 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'help'}
        onClick={toggleShowForm}
        disabled={disabled}
      />
    );
  };

  const validate = (formData: ConnectFormDataType) => {
    return Object.values(formData).every((value) => value?.trim());
  };

  if (showForm && !isWorkspaceIntegrationConnected) {
    return (
      <ConnectFormIntegration
        integrationType={WorkspaceIntegrationType.GOOGLE_MAPS}
        handleSave={handleSave}
        handleCancel={toggleShowForm}
        contentForm={[
          {
            type: 'input',
            uniqueName: 'apiKey',
            placeholder: '',
            visibleName: labels.apiKey,
          },
        ]}
        validate={validate}
      />
    );
  } else {
    return (
      <ConnectIntegration
        integrationType={WorkspaceIntegrationType.GOOGLE_MAPS}
        handleDisconnect={handleDisconnectClick}
        ConnectButton={connectButton}
      />
    );
  }
};
