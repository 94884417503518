import { combineReducers } from 'redux';
import { AvailabilityAction, AvailabilityActionTypes } from './actions';
import { DEFAULT_AVAILABILITY_MODEL } from './constants';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import { AvailabilityModel } from '../../generated-sources/internal-api/models/AvailabilityModel';

const availabilities = (state: AvailabilityModel[] = [], action: AvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case AvailabilityActionTypes.GET_AVAILABILITY_SUCCESS:
      return action.payload.availabilities;
    case AvailabilityActionTypes.SAVE_AVAILABILITY_SUCCESS:
      return state.map((record) => (record.id === action.payload.id ? action.payload : record));
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const availability = (
  state: AvailabilityModel = DEFAULT_AVAILABILITY_MODEL,
  action: AvailabilityAction | GlobalAction
) => {
  switch (action.type) {
    case AvailabilityActionTypes.GET_AVAILABILITY_SUCCESS:
      if (state !== DEFAULT_AVAILABILITY_MODEL) {
        // leave the selected availability when we update it
        return (
          action.payload.availabilities.find(
            (availability) => availability?.name === state?.name
          ) || state
        );
      } else {
        return action.payload.availabilities.find((availability) => availability?.id === action.payload.defaultAvailability) || action.payload.availabilities[0];
      }
    case AvailabilityActionTypes.CREATE_AVAILABILITY_SUCCESS:
      return action.payload;
    case AvailabilityActionTypes.CLONE_AVAILABILITY_SUCCESS:
      return action.payload;
    case AvailabilityActionTypes.SET_AVAILABILITY:
      return action.payload;
    case AvailabilityActionTypes.UPDATE_AVAILABILITY:
      return {
        ...state,
        ...action.payload
      };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_AVAILABILITY_MODEL;
    default:
      return state;
  }
};

const cloneName = (state = '' as string, action: AvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case AvailabilityActionTypes.SET_CLONE_NAME:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return '';
    default:
      return state;
  }
};

const defaultAvailability = (state = null, action: AvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_ID_SUCCESS:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const isFetching = (state = false, action: AvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case AvailabilityActionTypes.GET_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.SAVE_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.CREATE_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.CLONE_AVAILABILITY_REQUEST:
      return true;
    case AvailabilityActionTypes.GET_AVAILABILITY_SUCCESS:
    case AvailabilityActionTypes.GET_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.SAVE_AVAILABILITY_SUCCESS:
    case AvailabilityActionTypes.SAVE_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.CREATE_AVAILABILITY_SUCCESS:
    case AvailabilityActionTypes.CREATE_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.DELETE_AVAILABILITY_SUCCESS:
    case AvailabilityActionTypes.DELETE_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.CLONE_AVAILABILITY_SUCCESS:
    case AvailabilityActionTypes.CLONE_AVAILABILITY_FAIL:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: AvailabilityAction | GlobalAction) => {
  switch (action.type) {
    case AvailabilityActionTypes.GET_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.SAVE_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.CREATE_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST:
    case AvailabilityActionTypes.CLONE_AVAILABILITY_REQUEST:
      return null;
    case AvailabilityActionTypes.GET_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.SAVE_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.CREATE_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL:
    case AvailabilityActionTypes.CLONE_AVAILABILITY_FAIL:
      return action.error;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  availabilities,
  availability,
  cloneName,
  defaultAvailability,
  isFetching,
  error,
});
