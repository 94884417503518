import { t } from '../../../i18n/i18n';

export default {
  amount: t('Billing:AMOUNT'),
  date: t('Billing:DATE_ISSUE'),
  download: t('Billing:DOWNLOAD'),
  invoice: t('Billing:INVOICE'),
  status: t('Billing:STATUS'),
  statusDue: t('Billing:STATUS_DUE'),
  statusPaid: t('Billing:STATUS_PAID'),
};
