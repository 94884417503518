import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { validatePhoneNumber } from '../../services/utils';
import { ImageType } from '../../types/types';
import { userSettingsSelectors } from '../userSettings';
import { AvailabilityModel } from '../../generated-sources/internal-api/models/AvailabilityModel';
import { WeeklyHours } from '../../generated-sources/internal-api/models/WeeklyHours';

const quickSetupState = (state: State) => state.quickSetup;

const selectIsFetching = createSelector(quickSetupState, (quickSetup) => quickSetup.isFetching);
const selectUserAvailability = createSelector(quickSetupState, (quickSetup) => quickSetup.userAvailability);
const selectUserAvailabilityWeeklyHours = createSelector(
  selectUserAvailability,
  (availability): WeeklyHours[] =>
    (availability as AvailabilityModel)?.weeklyHours?.filter(hour => hour !== null) || []
);
const selectDefaultWorkspace = createSelector(quickSetupState, (quickSetup) => quickSetup.workspace);
const selectWorkspaceStyle = createSelector(selectDefaultWorkspace, (defaultWorkspace) => defaultWorkspace?.style);
// const selectLookAndFeel = createSelector(selectWorkspaceStyle, (workspaceStyle) => workspaceStyle?.lookAndFeel);
const selectWorkspaceLogo = createSelector(selectWorkspaceStyle, (workspaceStyle) => workspaceStyle?.logoImage);
const selectWorkspaceBackground = createSelector(
  selectWorkspaceStyle,
  (workspaceStyle) => workspaceStyle?.backgroundImage
);
const selectWorkspaceLabels = createSelector(selectDefaultWorkspace, (defaultWorkspace) => defaultWorkspace?.labels);

const selectIsAcceptTerms = createSelector(quickSetupState, (quickSetup) => quickSetup.isAcceptTerms);

const selectPhoneCalls = createSelector(quickSetupState, (quickSetup) => quickSetup.phoneCalls);
const selectPhoneNumber = createSelector(selectPhoneCalls, (phoneCalls) => phoneCalls.phoneNumber);
const selectPhoneDetails = createSelector(selectPhoneCalls, (phoneCalls) => phoneCalls.phoneDetails);
const selectImageFiles = createSelector(quickSetupState, (quickSetup) => quickSetup.imageFiles);
const selectLogoData = createSelector(selectImageFiles, (images) => images[ImageType.avatar]);
const selectLogo = createSelector(selectLogoData, (logo) => logo?.url);
const selectBackgroundData = createSelector(selectImageFiles, (images) => images[ImageType.backgroundImage]);
const selectBackground = createSelector(selectBackgroundData, (background) => background?.url);
const selectStyleForPreview = createSelector(
  selectWorkspaceStyle,
  selectLogo,
  selectBackground,
  (workspaceStyle, avatar, backgroundImage) => ({ ...workspaceStyle, avatar, backgroundImage })
);

const selectIsUserPhoneInvalid = createSelector(
  selectPhoneNumber,
  userSettingsSelectors.selectIsCurrentUserPhoneRequired,
  (phoneNumber, isPhoneRequired) => isPhoneRequired && !validatePhoneNumber(phoneNumber)
);

export const quickSetupSelectors = {
  selectIsFetching,
  selectUserAvailability,
  selectUserAvailabilityWeeklyHours,
  selectDefaultWorkspace,
  selectWorkspaceLogo,
  selectWorkspaceBackground,
  selectWorkspaceStyle,
  selectWorkspaceLabels,
  selectIsAcceptTerms,
  selectPhoneCalls,
  selectPhoneNumber,
  selectPhoneDetails,
  selectImageFiles,
  selectLogoData,
  selectBackgroundData,
  selectLogo,
  selectBackground,
  selectStyleForPreview,
  selectIsUserPhoneInvalid,
};
