import { Button } from 'primereact/button';
import labels from './labels';
import { useSelector } from 'react-redux';
import {
  STAFF_STATUS_OPTIONS,
  deactivateStaffModalActions,
  deactivateStaffModalSelectors,
  staffActions,
  staffSelectors,
} from '../../../store/opsConsole/staff';
import { Path } from '../../../routing';
import { useEffect, useState } from 'react';
import { ConfirmationModal, Preloader, SectionHeader } from '../../../components/common';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { StaffRoles, StaffStatus } from '../../../API';
import { authenticationSelectors } from '../../../store/authentication';
import { navigationService } from '../../../services/NavigationService';
import { MAX_LENGTH_EMAIL, MAX_LENGTH_NAME, STAFF_ROLE_OPTIONS } from '../../../types/constants';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export const StaffDetails = () => {
  const dispatch = useDispatch();
  const staffRecord = useSelector(staffSelectors.selectStaffRecord);
  const lastLogin = useSelector(staffSelectors.selectLastLogin);
  const isFetching = useSelector(staffSelectors.selectIsFetching);
  const isEdited = useSelector(staffSelectors.selectIsEdited);
  const isAddNew = useSelector(staffSelectors.selectIsAddNew);
  const isEmailValid = useSelector(staffSelectors.selectIsEmailValid);
  const isDuplicateEmail = useSelector(staffSelectors.selectIsDuplicateEmail);
  const staffName = useSelector(staffSelectors.selectStaffNameOrEmail);
  const myStaffRole = useSelector(authenticationSelectors.selectSumo1AdminRoleName);

  const userId = useSelector(authenticationSelectors.selectUserId);

  const deactivateModalOpen = useSelector(deactivateStaffModalSelectors.selectIsModalOpen);

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [localName, setLocalName] = useState(staffRecord.userName);
  const [localEmail, setLocalEmail] = useState(staffRecord.email);
  const [oldStaffStatus, setOldStaffStatus] = useState(staffRecord.status);

  useEffect(() => {
    if (!Object.keys(staffRecord).length) {
      navigateBack();
    }
  }, []);

  useEffect(() => {
    setLocalName(staffRecord.userName);
  }, [staffRecord.userName]);

  useEffect(() => {
    setLocalEmail(staffRecord.email);
  }, [staffRecord.email]);

  useEffect(() => {
    setOldStaffStatus(staffRecord.status);
  }, [staffRecord.email]);

  const navigateBack = () => {
    navigationService.navigateTo(Path.OPSConsoleStaff);
  };

  const handleBack = () => {
    if (isEdited) {
      setIsCancelModalOpen(true);
    } else {
      navigateBack();
    }
  };

  const handleSave = () => {
    if (!isAddNew && oldStaffStatus === StaffStatus.ACTIVE && staffRecord.status === StaffStatus.INACTIVE) {
      dispatch(deactivateStaffModalActions.openModal());
    } else {
      dispatch(staffActions.saveStaffRequest());
    }
  };

  const handleStaffRoleChange = (roleName: StaffRoles) => {
    dispatch(staffActions.updateStaffRecord({ roleName }));
  };

  const handleStaffStatusChange = (status: StaffStatus) => {
    dispatch(staffActions.updateStaffRecord({ status }));
  };

  const handleNameChange = (userName: string) => {
    setLocalName(userName);
  };

  const handleNameBlur = () => {
    dispatch(staffActions.updateStaffRecord({ userName: localName }));
  };

  const handleEmailChange = (email: string) => {
    setLocalEmail(email);
  };

  const handleEmailBlur = () => {
    dispatch(staffActions.updateStaffRecord({ email: localEmail }));
  };

  const handleDeactivateClose = () => {
    dispatch(deactivateStaffModalActions.closeModal());
  };

  let dropdownRolesOptions: typeof STAFF_ROLE_OPTIONS;

  if (myStaffRole === StaffRoles.OPERATIONS && !staffRecord.userId) {
    dropdownRolesOptions = STAFF_ROLE_OPTIONS.filter((role) => role.value !== StaffRoles.MAIN_ADMIN);
  } else if (myStaffRole === StaffRoles.OPERATIONS) {
    dropdownRolesOptions = STAFF_ROLE_OPTIONS.map(({ value, label, disabled }) => {
      return {
        value,
        label,
        disabled: value === StaffRoles.MAIN_ADMIN ? true : disabled,
      };
    });
  } else {
    dropdownRolesOptions = STAFF_ROLE_OPTIONS;
  }

  return (
    <div>
      {isFetching && <Preloader />}
      <ConfirmationModal
        visible={isCancelModalOpen}
        title={labels.cancel}
        additionalText={[labels.cancelTextPart1, labels.cancelTextPart2]}
        confirmButtonLabel={labels.cancelYes}
        cancelButtonLabel={labels.cancelNo}
        confirmButtonProps={{ className: 'min-w-120px' }}
        onConfirm={navigateBack}
        onCancel={() => setIsCancelModalOpen(false)}
        onClose={() => setIsCancelModalOpen(false)}
      />
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateMessage} "${staffName}"?`}
        visible={deactivateModalOpen}
        onClose={handleDeactivateClose}
        onCancel={handleDeactivateClose}
        onConfirm={() => {
          handleDeactivateClose();
          dispatch(staffActions.saveStaffRequest());
        }}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />

      <Button className="button-blue button-text-line mb-16px" text onClick={handleBack}>
        <ArrowLeftIcon className="icon-18px" />
        <div className="flex-1 pl-8px">{labels.back}</div>
      </Button>

      <div className="sumo-card-bg flex flex-column pt-16px">
        <SectionHeader title={labels.title} hideButton />

        <div className="card">
          <div className="flex justify-content-between align-items-center mb-3">
            <div className="text-xl">{isAddNew ? `Add new member` : `${localName} (${staffRecord.email})`}</div>
            <div className="flex align-items-center">
              <Button
                label={labels.save}
                onClick={handleSave}
                className="w-6rem"
                disabled={!isEdited || !isEmailValid || isDuplicateEmail}
              />
            </div>
          </div>
          <div className="border-1 border-round p-4">
            <div className="grid justify-content-between">
              <div className="col-12 md:col-5 p-fluid">
                <label htmlFor="userName">{labels.name}</label>
                <InputText
                  value={localName || ''}
                  id="userName"
                  onChange={(e) => handleNameChange(e.target.value)}
                  onBlur={handleNameBlur}
                  disabled={isAddNew}
                  maxLength={MAX_LENGTH_NAME}
                />
              </div>
              <div className="col-12 md:col-5 p-fluid">
                <label htmlFor="email">{labels.email}</label>
                <InputText
                  value={localEmail}
                  id="email"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  onBlur={handleEmailBlur}
                  disabled={!isAddNew}
                  className={!isEmailValid || isDuplicateEmail ? 'p-invalid' : ''}
                  maxLength={MAX_LENGTH_EMAIL}
                />
                {isDuplicateEmail && (
                  <small id="email-help" className="p-error block">
                    {labels.duplicateEmail}
                  </small>
                )}
              </div>
              <div className="col-12 md:col-5 p-fluid">
                <label htmlFor="role">{labels.role}</label>
                <Dropdown
                  value={staffRecord.roleName}
                  options={dropdownRolesOptions}
                  id="role"
                  onChange={(e: DropdownChangeEvent) => handleStaffRoleChange(e.value)}
                  disabled={
                    staffRecord.userId === userId ||
                    (myStaffRole === StaffRoles.OPERATIONS && staffRecord.roleName === StaffRoles.MAIN_ADMIN)
                  }
                />
              </div>
              <div className="col-12 md:col-5 p-fluid">
                <label htmlFor="status">{labels.status}</label>
                <Dropdown
                  value={staffRecord.status}
                  options={STAFF_STATUS_OPTIONS}
                  id="status"
                  onChange={(e) => handleStaffStatusChange(e.target.value)}
                  disabled={staffRecord.userId === userId}
                />
              </div>
              <div className="col-12 md:col-5 col-offset-0 md:col-offset-7 p-fluid">
                <label htmlFor="lastLogin">{labels.lastLogin}</label>
                <InputText value={lastLogin} id="lastLogin" maxLength={MAX_LENGTH_NAME} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
