export const Locations = Object.freeze({
  TITLE: `Lieux`,
  CLONE_TITLE: `Entrez le nom du lieu cloné`,
  ADD_LABEL: `Nouveau lieu`,
  DEACTIVATE_TITLE: `Désactiver le lieu`,
  DEACTIVATE_DESCRIPTION: `Êtes-vous sûr de vouloir désactiver`,
  DELETE_TITLE: `Supprimer le lieu`,
  DELETE_DESCRIPTION: `Êtes-vous sûr de vouloir supprimer`,
  IMPORT_CSV: `Importer CSV`,
  IMPORT_LABEL: `Importer`,
  STATUS: `Statut`,
  ACTIVE: `Actif`,
  INACTIVE: `Inactif`,
  UPLOAD_DESCRIPTION_PART1: `Sélectionnez le fichier CSV sur votre ordinateur`,
  UPLOAD_DESCRIPTION_PART2: `ou transférez-le dans cette zone de téléchargement`,
  IMPORT_DESCRIPTION_PART1: `Ici, vous pouvez trouver un `,
  IMPORT_DESCRIPTION_PART2: `exemple de fichier`,
  IMPORT_DESCRIPTION_PART3: ``,
  EMPTY_LIST_DESCRIPTION: `Créez un lieu ou ajustez votre recherche ou vos filtres.`,
  SELECTED_LOCATIONS: `lieux sélectionnés`,
  NO_MAPS_INTEGRATION_PART1: `Pour activer l'affichage de l'emplacement sur Google Maps pour la page de réservation publique, configurez le compte Google Maps Platform et la clé API dans la section `,
  NO_MAPS_INTEGRATION_PART2: `Intégrations`,
  NO_MAPS_INTEGRATION_PART3: `.`,
  LOCATION: `Emplacement`,
  SELECT_LOCATION: `Sélectionnez l'emplacement`,
});

export const EditLocation = Object.freeze({
  ADD_LOCATION: `Ajouter un lieu`,
  NAME_PLACEHOLDER: `Entrez le nom du lieu`,
  ADDRESS: `Adresse`,
});

export const LocationsToastsNotifications = Object.freeze({
  GET_LOCATIONS_ERROR_MESSAGE: `Échec de la demande de localisation`,
  DEACTIVATE_LOCATION_SUCCESS_MESSAGE: `Emplacement désactivé avec succès`,
  ACTIVATE_LOCATION_SUCCESS_MESSAGE: `Emplacement activé avec succès`,
  DEACTIVATE_LOCATION_ERROR_MESSAGE: `Échec de la demande d'activation/désactivation de l'emplacement`,
  CLONE_LOCATION_SUCCESS_MESSAGE: `Emplacement cloné avec succès`,
  CLONE_LOCATION_ERROR_MESSAGE: `Échec de la demande de clonage de l'emplacement`,
  DELETE_LOCATION_SUCCESS_MESSAGE: `Emplacement supprimé avec succès`,
  DELETE_LOCATION_ERROR_MESSAGE: `Échec de la demande de suppression de l'emplacement`,
  VALIDATE_LOCATIONS_ERROR_MESSAGE: `Un ou plusieurs enregistrements contiennent des champs vides. Tous les champs sont obligatoires.`,
  IMPORT_LOCATIONS_SUCCESS_MESSAGE: `Emplacements importés avec succès`,
  IMPORT_LOCATIONS_ERROR_MESSAGE: `Échec de la demande d'importation des emplacements`,
  GET_LOCATION_ERROR_MESSAGE: `Échec de la demande de récupération du lieu`,
  SAVE_LOCATION_ERROR_MESSAGE: `Échec de la demande d'enregistrement du lieu`,
  CREATE_LOCATION_SUCCESS_MESSAGE: `Lieu créé avec succès`,
  UPDATE_LOCATION_SUCCESS_MESSAGE: `Lieu mis à jour avec succès`,
});
