import { XMarkIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import guideBackground from '../../../assets/images/guide_background.png';
import { promoActions, promoSelectors } from '../../../store/promo';
import labels from './labels';

export const GuideAds = () => {
  const dispatch = useDispatch();
  const isGuideRunning = useSelector(promoSelectors.selectIsGuideRunning);
  const isGuideVisible = useSelector(promoSelectors.selectIsGuideVisible);

  const handleClose = () => {
    dispatch(promoActions.hideGuide());
  };

  const handleStartGuide = () => {
    if (isGuideRunning) {
      dispatch(promoActions.stopGuide());
    } else {
      dispatch(promoActions.runGuide());
    }
  };

  if (!isGuideVisible) return null;

  return (
    <div className="flex-bottom flex-column gap-2px absolute right-0 top-0 z-1 px-20px pt-28px">
      <button className="action-button" onClick={handleClose}>
        <XMarkIcon className="icon-20px" />
      </button>
      <div
        className="flex flex-column gap-20px w-130px p-12px pb-18px border-1 border-solid border-blue-light border-radius-14px"
        style={{ background: `url(${guideBackground}) no-repeat` }}
      >
        <div className="text-title-s-med">{labels.getStarted}</div>
        <div>
          <Button label={labels.start} onClick={handleStartGuide} />
        </div>
      </div>
    </div>
  );
};
