import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import {
  cloneJourneysModalActions,
  deactivateJourneysModalActions,
  deactivateJourneysModalSelectors,
  deleteJourneysModalActions,
  deleteJourneysModalSelectors,
  journeysActions,
  journeysSelectors,
} from '../../../store/journeyBuilder';
import { ModalName } from '../../../store/modals';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { ConfirmationModal, Modal } from '../../common';
import labels from './labels';

export const JourneyModals = () => {
  const dispatch = useDispatch();
  const journey = useSelector(journeysSelectors.selectJourney);
  const journeys = useSelector(journeysSelectors.selectSelectedJourneys);
  const deactivateIsOpen = useSelector(deactivateJourneysModalSelectors.selectIsModalOpen);
  const deleteIsOpen = useSelector(deleteJourneysModalSelectors.selectIsModalOpen);
  const cloneName = useSelector(journeysSelectors.selectCloneName);
  const isCloneNameValid = useSelector(journeysSelectors.selectIsCloneNameValid);

  const deactivateModalClose = () => {
    dispatch(deactivateJourneysModalActions.closeModal());
  };
  const deactivateModalConfirm = () => {
    dispatch(journeysActions.deactivateJourneyRequest());
  };

  const deleteModalClose = () => {
    dispatch(deleteJourneysModalActions.closeModal());
  };
  const deleteModalConfirm = () => {
    dispatch(journeysActions.deleteJourneysRequest(journeys.length ? journeys : [journey.id]));
    deleteModalClose();
  };

  const handleNameChange = (name: string) => {
    dispatch(journeysActions.setCloneName(name));
  };
  const cloneModalClose = () => {
    dispatch(cloneJourneysModalActions.closeModal());
  };
  const cloneModalConfirm = () => {
    dispatch(journeysActions.cloneJourneyRequest());
    cloneModalClose();
  };

  return (
    <>
      <ConfirmationModal
        title={labels.deactivateTitle}
        description={`${labels.deactivateDescription} "${journey.name}"?`}
        visible={deactivateIsOpen}
        onClose={deactivateModalClose}
        onCancel={deactivateModalClose}
        onConfirm={deactivateModalConfirm}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <ConfirmationModal
        title={labels.deleteTitle}
        description={`${labels.deleteDescription}  ${
          journeys.length ? journeys.length + ' ' + labels.selected : '"' + journey.name + '"'
        }?`}
        visible={deleteIsOpen}
        onClose={deleteModalClose}
        onCancel={deleteModalClose}
        onConfirm={deleteModalConfirm}
        cancelButtonLabel={labels.no}
        confirmButtonLabel={labels.yes}
      />
      <Modal.Container
        name={ModalName.CLONE_JOURNEYS_MODAL_NAME}
        closable={true}
        onClose={cloneModalClose}
        className="w-440px"
      >
        <Modal.Header>{labels.cloneTitle}</Modal.Header>
        <InputText
          type="text"
          placeholder={labels.nameLabel}
          value={cloneName}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={`w-full ${!isCloneNameValid ? 'p-invalid' : ''}`}
          maxLength={MAX_LENGTH_NAME}
        />
        <Modal.Buttons>
          <Button
            label={labels.confirm}
            onClick={cloneModalConfirm}
            disabled={!isCloneNameValid}
            autoFocus
            className="min-w-120px"
          />
          <Button label={labels.cancel} onClick={cloneModalClose} text />
        </Modal.Buttons>
      </Modal.Container>
    </>
  );
};
