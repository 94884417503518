import { ACTIONS, Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Path } from '../../../routing';
import { promoActions } from '../../../store/promo';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { GUIDE_DELAY_MILLISECONDS } from '../../../types/constants';
import { createModalMenuItems, createNavigationMenuItems } from '../../private/auth/constants';
import labels from './labels';
import type { GuideStepData } from './types';

export const getGuideSteps = () => {
  const dispatch = useDispatch();
  const navigationMenuItems = createNavigationMenuItems();
  const modalMenuItems = createModalMenuItems();
  const menuItems = [...navigationMenuItems, ...modalMenuItems].filter((item) => item.id && item.visible);
  const isMenuOpened = useSelector(userSettingsSelectors.selectIsMenuOpened);

  const getExistStep = (id: string) => {
    return {
      exist: menuItems.some((item) => item.id === id),
      target: `#${id}`,
    };
  };

  const openMenuWithGuide = () => {
    if (!isMenuOpened) {
      dispatch(promoActions.stopGuide());
      dispatch(userSettingsActions.setIsMenuOpened(true));

      setTimeout(() => {
        dispatch(promoActions.runGuide());
      }, GUIDE_DELAY_MILLISECONDS);
    }
  };

  const stopGuideAndCloseMenu = () => {
    dispatch(promoActions.stopGuide());
    dispatch(userSettingsActions.setIsMenuOpened(false));
  };

  const ALL_STEPS: (Omit<Step, 'data'> & { exist: boolean; data?: GuideStepData })[] = [
    {
      ...getExistStep('profile-link'),
      title: labels.profileTopTitle,
      content: (
        <>
          <p>{labels.profileDescription}</p>
          <Link to={Path.Account} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.profileLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepAfter: openMenuWithGuide,
        onEventTargetNotFound: openMenuWithGuide,
      },
    },
    {
      ...getExistStep('integration-link'),
      title: labels.integrationTopTitle,
      content: (
        <>
          <p>{labels.integrationDescription}</p>
          <Link to={Path.Integration} onClick={stopGuideAndCloseMenu}>
            {labels.add} {labels.integrationLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepBefore: () => {
          dispatch(userSettingsActions.setIsMenuOpened(false));
        },
        onEventStepAfter: openMenuWithGuide,
      },
    },
    {
      ...getExistStep('availability-link'),
      title: labels.availabilityTopTitle,
      content: (
        <>
          <p>{labels.availabilityDescription}</p>
          <Link to={Path.Availability} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.availabilityLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
      data: {
        onEventStepAfter: openMenuWithGuide,
        onEventTargetNotFound: openMenuWithGuide,
      },
    },
    {
      exist: true,
      target: 'body',
      title: labels.sumoInboxTopTitle,
      content: (
        <>
          <p>{labels.sumoInboxDescription}</p>
          <Link to={Path.Integration} onClick={stopGuideAndCloseMenu}>
            {labels.sumoInboxLink}
          </Link>
        </>
      ),
      placement: 'center',
      disableBeacon: true,
      data: {
        onEventStepBefore: () => {
          dispatch(userSettingsActions.setIsMenuOpened(false));
        },
        onEventStepAfter: (props) => {
          if (props.action === ACTIONS.PREV) {
            openMenuWithGuide();
          }
        },
      },
    },
    {
      ...getExistStep('booking-pages-link'),
      title: labels.bookingPagesTopTitle,
      content: (
        <>
          <p>{labels.bookingPagesDescription}</p>
          <Link to={Path.BookingPages} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.bookingPagesLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('booked-meetings-link'),
      title: labels.bookedMeetingsTopTitle,
      content: (
        <>
          <p>{labels.bookedMeetingsDescription}</p>
          <Link to={Path.BookedMeetings} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.bookedMeetingsLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('smart-alerts-link'),
      title: labels.smartAlertsTopTitle,
      content: (
        <>
          <p>{labels.smartAlertsDescription}</p>
          <Link to={Path.SmartAlerts} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.smartAlertsLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('booking-templates-link'),
      title: labels.bookingTemplatesTopTitle,
      content: (
        <>
          <p>{labels.bookingTemplatesDescription}</p>
          <Link to={Path.BookingTemplates} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.bookingTemplatesLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('teams-link'),
      title: labels.teamsTopTitle,
      content: (
        <>
          <p>{labels.teamsDescription}</p>
          <Link to={Path.Teams} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.teamsLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('journey-builder-link'),
      title: labels.journeyBuilderTopTitle,
      content: (
        <>
          <p>{labels.journeyBuilderDescription}</p>
          <Link to={Path.Journeys} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.journeyBuilderLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('users-link'),
      title: labels.usersTopTitle,
      content: (
        <>
          <p>{labels.usersDescription}</p>
          <Link to={Path.Users} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.usersLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('roles-link'),
      title: labels.rolesTopTitle,
      content: (
        <>
          <p>{labels.rolesDescription}</p>
          <Link to={Path.Roles} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.rolesLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
    {
      ...getExistStep('billing-link'),
      title: labels.billingTopTitle,
      content: (
        <>
          <p>{labels.billingDescription}</p>
          <Link to={Path.Billing} onClick={stopGuideAndCloseMenu}>
            {labels.goTo} {labels.billingLink}
          </Link>
        </>
      ),
      placement: 'right',
      disableBeacon: true,
    },
  ];

  return ALL_STEPS.filter((item) => item.exist);
};
