import {
  AttachmentSize,
  AttachmentType,
  JourneyCard,
  JourneyCondition,
  JourneyConditionType,
  JourneyFormField,
  JourneyPageType,
  JourneyStep,
  JourneyStepType,
} from '../../../API';
import {
  CARD,
  EMPTY_VALUE,
  getConditionAttachmentSizeLabel,
  getConditionAttachmentTypeLabel,
  getConditionTypeLabel,
  getStepTypeDesc,
} from '../../../store/journeyBuilder';
import labels from './labels';

export const getStepTitle = (step: JourneyStep | null | undefined) => {
  let res = getStepTypeDesc(step?.type);
  if (step?.type === JourneyStepType.PAGE && step.page?.type === JourneyPageType.CARD) {
    res += ' ' + labels.withCard;
  } else if (step?.type === JourneyStepType.PAGE && step.page?.type === JourneyPageType.FORM) {
    res += ' ' + labels.withForm;
  }
  return res;
};

export const getConditionAttachmentDesc = (
  attachmentType: AttachmentType,
  attachmentSize: AttachmentSize | null | undefined,
  value: Array<string | null> | null | undefined
) => {
  return [AttachmentType.FILETYPE_IS, AttachmentType.FILETYPE_IS_NOT].includes(attachmentType) && value ? (
    value.map((value, index) => (
      <>
        <div className="text-heavy-60">
          {index > 0 ? labels.or + ' ' : ''}
          {getConditionAttachmentTypeLabel(attachmentType)}
        </div>
        <div className="step-node-text font-semibold pl-8px">{value || EMPTY_VALUE}</div>
      </>
    ))
  ) : (
    <>
      <div className="text-heavy-60">{getConditionAttachmentTypeLabel(attachmentType)}</div>
      <div className="step-node-text font-semibold pl-8px">
        {(attachmentSize?.value || EMPTY_VALUE) + ' ' + getConditionAttachmentSizeLabel(attachmentSize?.type) ||
          EMPTY_VALUE}
      </div>
    </>
  );
};

export const getCardLabel = (cardId: string | null | undefined, cards: JourneyCard[]) => {
  return cards?.find((card) => card.id === cardId)?.title || EMPTY_VALUE;
};

export const getFieldsConditionDesc = (
  type: JourneyConditionType,
  value: Array<string | null>,
  isCard: boolean,
  cards: JourneyCard[]
) => {
  return value?.map((value, index) =>
    type !== JourneyConditionType.RANGE || index % 2 === 1 ? (
      <div key={index}>
        <div className="text-heavy-60">
          {index > 0 ? labels.or + ' ' : ''}
          {getConditionTypeLabel(type)}
        </div>
        <div className="step-node-text font-semibold pl-8px">
          {isCard
            ? getCardLabel(value, cards)
            : value ||
              EMPTY_VALUE +
                (type === JourneyConditionType.RANGE ? ' - ' + ((value && value[index + 1]) || EMPTY_VALUE) : '')}
        </div>
      </div>
    ) : (
      <></>
    )
  );
};

export const getConditionField = (conditionField: string | null | undefined, fields: JourneyFormField[]) => {
  if (!conditionField) {
    return EMPTY_VALUE;
  }
  let res: string | null | undefined = null;
  if (conditionField === CARD) {
    res = 'Card';
  } else {
    res = fields?.find((field) => field.id === conditionField)?.label;
  }
  return res || EMPTY_VALUE;
};

export const getConditionDescription = (
  condition: JourneyCondition | null | undefined,
  fields: JourneyFormField[],
  cards: JourneyCard[]
) => {
  return (
    <div className="flex-column text-body-s-reg">
      <div className="text-heavy-60">{labels.if}</div>
      <div className="step-node-text font-semibold pl-8px">{getConditionField(condition?.conditionField, fields)}</div>
      {condition?.attachmentType
        ? getConditionAttachmentDesc(condition?.attachmentType, condition?.attachmentSize, condition.value)
        : condition?.type &&
          condition.value &&
          getFieldsConditionDesc(condition.type, condition.value, condition?.conditionField === CARD, cards)}
    </div>
  );
};
