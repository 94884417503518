import { SelectItemOptionsType } from 'primereact/selectitem';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import { CalculationTaxes, Customer, ItemPrices, Subscription, Tier } from './types';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
  TOAST_SUCCESS_LONG_DURATION,
} from '../../types/constants';

export const DEFAULT_SUBSCRIPTION: Subscription = {
  id: '',
  billing_period: 0,
  billing_period_unit: '',
  status: '',
  current_term_start: 0,
  current_term_end: 0,
  next_billing_at: 0,
  created_at: 0,
  updated_at: 0,
  has_scheduled_changes: false,
  currency_code: '',
  subscription_items: [
    {
      item_price_id: '',
      item_type: '',
      quantity: 0,
      unit_price: 0,
      amount: 0,
    },
  ],
  due_invoices_count: 0,
};

export const DEFAULT_CUSTOMER: Customer = {
  first_name: '',
  last_name: '',
  email: '',
};

export const NO_SUBSCRIPTION_ID = 'No subscription';

export const DEFAULT_SEATS_AMOUNT = 0;
export const MAX_SEATS_AMOUNT = 1000;
export const MAX_CONTACTS_AMOUNT = 5;
export const CHARGEBEE_STATUS_ACTIVE = 'active';
export const CHARGEBEE_STATUS_NON_RENEWING = 'non_renewing';
export const CHARGEBEE_STATUS_CANCELLED = 'cancelled';
export const CHARGEBEE_MONTH_UNIT = 'month';
export const CHARGEBEE_YEAR_UNIT = 'year';
export const CHARGEBEE_CURRENCY = 'USD';

export const CHARGEBEE_MONTHLY_TERM = process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Monthly';
export const CHARGEBEE_YEARLY_TERM = process.env.REACT_APP_CHARGEBEE_PLAN_PREFIX + 'Yearly';

// TODO: request tiers from ChargeBee before buy a fisrt licence
export const TIERS_DEFAULT_YEARLY: Tier[] = [
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 1,
    ending_unit: 50,
    price: 15600,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 51,
    ending_unit: 150,
    price: 13200,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 151,
    ending_unit: 250,
    price: 12000,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 251,
    ending_unit: 500,
    price: 10800,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 501,
    ending_unit: 1000,
    price: 9600,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 1001,
    ending_unit: 2500,
    price: 7200,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 2501,
    ending_unit: 5000,
    price: 6000,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_YEARLY_TERM,
    starting_unit: 5001,
    price: 4800,
    object: 'item_tier',
  },
];

export const TIERS_DEFAULT_MONTHLY: Tier[] = [
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 1,
    ending_unit: 50,
    price: 1600,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 51,
    ending_unit: 150,
    price: 1400,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 151,
    ending_unit: 250,
    price: 1300,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 251,
    ending_unit: 500,
    price: 1100,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 501,
    ending_unit: 1000,
    price: 1000,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 1001,
    ending_unit: 2500,
    price: 800,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 2501,
    ending_unit: 5000,
    price: 600,
    object: 'item_tier',
  },
  {
    item_price_id: CHARGEBEE_MONTHLY_TERM,
    starting_unit: 5001,
    price: 500,
    object: 'item_tier',
  },
];

export const ITEM_PRICES_DEFAULT: ItemPrices[] = [
  {
    id: CHARGEBEE_MONTHLY_TERM,
    name: CHARGEBEE_MONTHLY_TERM,
    currency: CHARGEBEE_CURRENCY,
    tiers: TIERS_DEFAULT_MONTHLY,
  },
  {
    id: CHARGEBEE_YEARLY_TERM,
    name: CHARGEBEE_YEARLY_TERM,
    currency: CHARGEBEE_CURRENCY,
    tiers: TIERS_DEFAULT_YEARLY,
  },
];

export const CALCULATION_TAXES_DEFAULT: CalculationTaxes = {
  totalAmount: 0,
  totalTax: 0,
};

export const expireMessage1Label = t('Billing:EXPIRE_MESSAGE_PART_1');
export const expireMessage2Label = t('Billing:EXPIRE_MESSAGE_PART_2');
export const expireMessage3Label = t('Billing:EXPIRE_MESSAGE_PART_3');
export const teamsMonthlyLabel = t('Billing:TERM_MONTHLY');
export const teamsAnnualLabel = t('Billing:TERM_ANNUAL');
export const scheduledChanges1Label = t('Billing:SCHEDULED_SEATS_CHANGE_PART_1');
export const scheduledChanges2Label = t('Billing:SCHEDULED_SEATS_CHANGE_PART_2');
export const termChangeLabel = t('Billing:SCHEDULED_TERM_CHANGE');
export const billIsLatePart1Label = t('Billing:BILL_IS_LATE_PART_1');
export const billIsLatePart2SingleLabel = t('Billing:BILL_IS_LATE_PART_2_SINGLE');
export const billIsLatePart2MultipleLabel = t('Billing:BILL_IS_LATE_PART_2_MULTIPLE');

export const CHARGEBEE_TERM_OPTIONS: SelectItemOptionsType = [
  { value: CHARGEBEE_MONTHLY_TERM, label: teamsMonthlyLabel },
  { value: CHARGEBEE_YEARLY_TERM, label: teamsAnnualLabel },
];

export const GET_BILLING_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:GET_BILLING_ERROR_TOAST'),
});

export const ADD_SEATS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:ADD_SEATS_ERROR_TOAST'),
});
export const REMOVE_SEATS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:REMOVE_SEATS_ERROR_TOAST'),
});

export const CHANGE_TERM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BillingToastsNotifications:CHANGE_TERM_SUCCESS_TOAST'),
});
export const CHANGE_TERM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:CHANGE_TERM_ERROR_TOAST'),
});

export const DELETE_CHANGE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BillingToastsNotifications:DELETE_CHANGE_SUCCESS_TOAST'),
});
export const DELETE_CHANGE_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:DELETE_CHANGE_ERROR_TOAST'),
});

export const getAddSeatsSuccessToast = (seats: number) =>
  ({
    color: TOAST_SUCCESS_COLOR,
    autoHideDuration: TOAST_SUCCESS_DURATION,
    title: `${seats} ${t('BillingToastsNotifications:ADD_SEATS_SUCCESS_TOAST_TITLE')}`,
    message: t('BillingToastsNotifications:ADD_SEATS_SUCCESS_TOAST'),
  } as ToastNotificationOptions);
export const getRemoveSeatsSuccessToast = (seats: number) =>
  ({
    color: TOAST_SUCCESS_COLOR,
    autoHideDuration: TOAST_SUCCESS_DURATION,
    title: `${t('BillingToastsNotifications:REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_1')} ${seats} ${t(
      'BillingToastsNotifications:REMOVE_SEATS_SUCCESS_TOAST_TITLE_PART_2'
    )}`,
    message: t('BillingToastsNotifications:REMOVE_SEATS_SUCCESS_TOAST'),
  } as ToastNotificationOptions);

export const GET_ITEM_TIERS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:GET_ITEM_TIERS_ERROR_TOAST'),
});

export const CHARGEBEE_PORTAL_CLOSE_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_LONG_DURATION,
  message: t('BillingToastsNotifications:CHARGEBEE_PORTAL_CLOSE_TOAST'),
});

export const PAY_LATER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:PAY_LATER_ERROR_TOAST'),
});

export const REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('BillingToastsNotifications:REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST'),
});

export const REACTIVATE_SUBSCRIPTION_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:REACTIVATE_SUBSCRIPTION_ERROR_TOAST'),
});

export const CALCULATE_TAXES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('BillingToastsNotifications:CALCULATE_TAXES_ERROR_TOAST'),
});
