import { call, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { deleteAvailability, getAvailability, setDefaultAvailability, upsertAvailability } from './service';
import { AvailabilityActionTypes } from './actions';
import { CreateUserDataInput } from '../../API';
import { notificationsActions } from '../notifications';
import {
  CREATE_AVAILABILITY_ERROR_TOAST,
  CREATE_AVAILABILITY_SUCCESS_TOAST,
  DELETE_AVAILABILITY_ERROR_TOAST,
  DELETE_AVAILABILITY_SUCCESS_TOAST,
  SAVE_AVAILABILITY_ERROR_TOAST,
  SAVE_AVAILABILITY_SUCCESS_TOAST,
  SET_DEFAULT_AVAILABILITY_ERROR_TOAST,
  SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST,
} from './constants';
import { availabilitySelectors } from './selectors';
import { authenticationSelectors } from '../authentication';
import { availabilityActions } from '.';
import { handleServiceError } from '../utils/reduxUtils';
import { AvailabilityModel } from '../../generated-sources/internal-api/models/AvailabilityModel';
import { UpsertAvailabilityResponse } from '../../generated-sources/internal-api/models/UpsertAvailabilityResponse';
import { GetAvailabilityResponse } from '../../generated-sources/internal-api/models/GetAvailabilityResponse';

const selectCreateAvailabilityRequest = createSelector(
  authenticationSelectors.selectUserId,
  authenticationSelectors.selectTenantId,
  availabilitySelectors.selectAvailability,
  (userId, tenant, availability) => ({
    ...availability,
    id: undefined,
  })
);

const selectCloneAvailabilityRequest = createSelector(
  authenticationSelectors.selectUserId,
  authenticationSelectors.selectTenantId,
  availabilitySelectors.selectAvailability,
  availabilitySelectors.selectCloneName,
  (userId, tenant, availability, cloneName) => ({
    ...availability,
    id: undefined,
    name: cloneName,
  })
);

// TODO: find out how to describe function generator with typeScript
function* getAvailabilitySaga() {
  try {
    const { availabilities, defaultAvailability }: GetAvailabilityResponse = yield call(getAvailability);

    if (!availabilities || availabilities.length === 0) {
      throw new Error('Availabilities not found');
    }

    yield put(
      availabilityActions.getAvailabilitySuccess({
        availabilities,
        defaultAvailability: defaultAvailability || '',
      })
    );
    yield put(availabilityActions.setDefaultAvailabilityIdSuccess(defaultAvailability || ''));
  } catch (error: unknown) {
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST, true);
    yield put(availabilityActions.getAvailabilityFail(error?.toString()));
  }
}

function* createAvailabilitySaga() {
  try {
    const request: AvailabilityModel = yield select(selectCreateAvailabilityRequest);
    const {availability}: UpsertAvailabilityResponse = yield call(upsertAvailability, request);

    yield put(notificationsActions.showToast(CREATE_AVAILABILITY_SUCCESS_TOAST));
    yield put(availabilityActions.createAvailabilitySuccess(availability as AvailabilityModel));
    yield put(availabilityActions.getAvailabilityRequest());
  } catch (error: unknown) {
    yield put(availabilityActions.createAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST);
  }
}

function* cloneAvailabilitySaga() {
  try {
    const request: CreateUserDataInput = yield select(selectCloneAvailabilityRequest);
    const {availability}: UpsertAvailabilityResponse = yield call(upsertAvailability, request);

    yield put(notificationsActions.showToast(CREATE_AVAILABILITY_SUCCESS_TOAST));
    yield put(availabilityActions.cloneAvailabilitySuccess(availability as AvailabilityModel));
    yield put(availabilityActions.getAvailabilityRequest());
  } catch (error: unknown) {
    yield put(availabilityActions.cloneAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, CREATE_AVAILABILITY_ERROR_TOAST);
  }
}

function* updateAvailabilitySaga() {
  try {
    const request: AvailabilityModel = yield select(availabilitySelectors.selectAvailability);
    const {availability}: UpsertAvailabilityResponse = yield call(upsertAvailability, request);

    yield put(availabilityActions.saveAvailabilitySuccess(availability as AvailabilityModel));
    // yield put(availabilityActions.getAvailabilityRequest()); // seems, it doesn't work well with autosave
    yield put(notificationsActions.showToast(SAVE_AVAILABILITY_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(availabilityActions.saveAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, SAVE_AVAILABILITY_ERROR_TOAST);
  }
}

function* deleteAvailabilitySaga(action: ReturnType<typeof availabilityActions.deleteAvailabilityRequest>) {
  try {
    if (action.type === AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST) {
      const id: string = action.payload;
      const defaultAvailability: AvailabilityModel = yield select(
        availabilitySelectors.selectDefaultAvailability
      );

      if (defaultAvailability && defaultAvailability.id === id) {
        const oldestRecord: AvailabilityModel = yield select(
          availabilitySelectors.selectOldestAvailability(id)
        );
        yield put(availabilityActions.setAvailability(oldestRecord));
        yield call(setDefaultAvailability, oldestRecord.id)
      } else {
        if (defaultAvailability) {
          yield put(availabilityActions.setAvailability(defaultAvailability));
        }
      }

      yield call(deleteAvailability, id);
      yield put(availabilityActions.deleteAvailabilitySuccess(id));
      yield put(notificationsActions.showToast(DELETE_AVAILABILITY_SUCCESS_TOAST));
      yield put(availabilityActions.getAvailabilityRequest());
    }
  } catch (error: unknown) {
    yield put(availabilityActions.deleteAvailabilityFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_AVAILABILITY_ERROR_TOAST);
  }
}

function* setDefaultAvailabilitySaga(action: ReturnType<typeof availabilityActions.setDefaultAvailabilityRequest>) {
  try {
    if (action.type === AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST) {
      const selected: AvailabilityModel = yield select(availabilitySelectors.selectAvailability);
      yield call(setDefaultAvailability, selected.id);
      yield put(availabilityActions.setDefaultAvailabilitySuccess());
      yield put(availabilityActions.getAvailabilityRequest());
      yield put(notificationsActions.showToast(SET_DEFAULT_AVAILABILITY_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(availabilityActions.setDefaultAvailabilityFail(error));
    yield call(handleServiceError, error, SET_DEFAULT_AVAILABILITY_ERROR_TOAST);
  }
}

export function* watchAvailabilitySaga() {
  yield takeLatest(AvailabilityActionTypes.GET_AVAILABILITY_REQUEST, getAvailabilitySaga);
  yield takeLatest(AvailabilityActionTypes.CREATE_AVAILABILITY_REQUEST, createAvailabilitySaga);
  yield takeLatest(AvailabilityActionTypes.CLONE_AVAILABILITY_REQUEST, cloneAvailabilitySaga);
  yield takeLatest(AvailabilityActionTypes.SAVE_AVAILABILITY_REQUEST, updateAvailabilitySaga);
  yield takeLatest(AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST, deleteAvailabilitySaga);
  yield takeLatest(AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST, setDefaultAvailabilitySaga);
}

export const availabilitySagas = {
  getAvailability: getAvailabilitySaga,
  updateAvailability: updateAvailabilitySaga,
};
