import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { Feature, ThirdPartyType, UserDataCoreType } from './types';
import dayjs from 'dayjs';
import { t } from '../../i18n/i18n';
import {
  AppType,
  IntegrationType,
  LicenseType,
  StaffRoles,
  TenantStatus,
  WorkspaceInput,
  WorkspaceIntegrationType,
} from '../../API';
import { EXPIRATION_PERIOD_DAYS, USER_WARNING_DAYS } from './constants';
import { capitalizeFirstLetter, isOldData } from '../../services/utils';
import { STAFF_ROLE_OPTIONS } from '../../types/constants';
import { GoogleMapsCustomParameters } from '../integration';

const authenticationState = (state: State) => state.authentication;

const selectIsFetching = createSelector(authenticationState, (authentication) => authentication.isFetching);

const selectIsTenantFetching = createSelector(authenticationState, (authentication) => authentication.tenantIsFetching);

const selectLicensesIsFetching = createSelector(
  authenticationState,
  (authentication) => authentication.licensesIsFetching
);

const selectLastLoadTime = createSelector(authenticationState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectLicensesIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectLicensesIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectIsOpsConsole = createSelector(authenticationState, (authentication) => authentication.isOpsConsole);
const selectIsCookieOpen = createSelector(authenticationState, (authentication) => authentication.isCookieOpen);

const selectUserDataCore = createSelector(authenticationState, (authentication) => authentication.userDataCore);

const selectUserId = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.userId);

const selectTenantId = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.tenantId);

const selectLink = createSelector(selectUserDataCore, (userDataCore: UserDataCoreType) => userDataCore.link);

const selectWorkspaceId = createSelector(
  selectUserDataCore,
  (userDataCore: UserDataCoreType) => userDataCore.workspaceId
);

const selectAuthenticationDataReceived = createSelector(
  authenticationState,
  (authentication) => authentication.authenticationDataReceived
);
const selectIsUserRegistered = createSelector(authenticationState, (authentication) => authentication.userRegistered);
const selectUserWasDeleted = createSelector(authenticationState, (authentication) => authentication.userDeleted);
const selectError = createSelector(authenticationState, (authentication) => authentication.error);
const selectThirdParty = createSelector(authenticationState, (authentication) => authentication.thirdParty);
const selectIsThirdPartyGoogle = createSelector(selectThirdParty, (thirdParty) => thirdParty === ThirdPartyType.GOOGLE);
const selectIsThirdPartyMicrosoft = createSelector(
  selectThirdParty,
  (thirdParty) => thirdParty === ThirdPartyType.MICROSOFT
);

const selectUserAccounts = createSelector(authenticationState, (authentication) => authentication.userAccounts);

const selectInviteToAccount = createSelector(authenticationState, (authentication) => authentication.inviteToAccount);

const selectIsLastSuperAdmin = createSelector(authenticationState, (authentication) => authentication.isLastSuperAdmin);

// TODO support multiple licenses if needed

const selectLicenses = createSelector(authenticationState, (authentication) => authentication.licenses);
const selectCurrentLicense = createSelector(selectLicenses, (licenses) => licenses[0]);
const selectTotalLicenseCount = createSelector(selectCurrentLicense, (license) => license?.owned || 0);
const selectAssigned = createSelector(selectCurrentLicense, (license) => license?.assigned || 0);
const selectScheduledChanges = createSelector(selectCurrentLicense, (license) => license?.scheduledChanges);
const selectScheduledRemoveSeats = createSelector(
  selectScheduledChanges,
  (changes) => changes?.reduce((result, change) => result + (change?.seats || 0), 0) || 0
);
const selectRemainingLicenses = createSelector(
  selectTotalLicenseCount,
  selectAssigned,
  selectScheduledRemoveSeats,
  (totalLicensesCount, assigned, removeSeats) => totalLicensesCount - assigned - removeSeats
);

const selectLicenseType = createSelector(selectCurrentLicense, (licenseData) => licenseData?.type);
const selectLicenseEndDate = createSelector(selectCurrentLicense, (licenseData) => licenseData?.endDate);
const selectIsTrialLicense = createSelector(selectLicenseType, (type) => type === LicenseType.TRIAL);
const selectIsPaidLicense = createSelector(selectLicenseType, (type) => type !== LicenseType.TRIAL);

const selectLicenseStatistics = createSelector(
  selectLicenseType,
  selectTotalLicenseCount,
  selectAssigned,
  selectRemainingLicenses,
  (type, totalLicensesCount, asigned, remaining) => [
    { value: capitalizeFirstLetter(type || '') },
    { value: totalLicensesCount },
    { value: asigned },
    { value: remaining },
  ]
);

const selectDaysUntilLicenseEnd = createSelector(selectLicenseEndDate, (endDate) => {
  if (!endDate) return 0;
  const days = dayjs(endDate).diff(dayjs(), 'day');
  return days < 0 ? 0 : days;
});

const selectTrialExpirationMessage = createSelector(
  selectIsTrialLicense,
  selectDaysUntilLicenseEnd,
  (isTrial, endDays) => (isTrial && endDays ? `${endDays} ${t('Common:DAYS')}` : '')
);

const selectIsPaymentLate = createSelector(selectLicenseEndDate, (endDate) =>
  endDate ? dayjs().isAfter(dayjs(endDate)) : false
);

const selectPaidExpirationMessage = createSelector(
  selectIsPaidLicense,
  selectIsPaymentLate,
  selectDaysUntilLicenseEnd,
  (isPaid, isPaymentLate, licenseEndDays) => {
    if (!isPaid || !isPaymentLate) {
      return '';
    }

    if (licenseEndDays === 0) {
      return `${t('License:PAID_EXPIRES_MESSAGE_PART_1')} ${t('License:PAID_EXPIRES_MESSAGE_PART_2_TODAY')}`;
    }

    const messagePart2Key =
      licenseEndDays > 0 ? 'License:PAID_EXPIRES_MESSAGE_PART_2_LEFT' : 'License:PAID_EXPIRES_MESSAGE_PART_2_AGO';

    return `${t('License:PAID_EXPIRES_MESSAGE_PART_1')} ${Math.abs(licenseEndDays)} ${t(messagePart2Key)}`;
  }
);
const selectShowExpirationUserMessage = createSelector(
  selectIsPaidLicense,
  selectIsPaymentLate,
  selectLicenseEndDate,
  (isPaid, isPaymentLate, endDate) =>
    isPaid &&
    isPaymentLate &&
    endDate &&
    dayjs().isAfter(dayjs(endDate).add(EXPIRATION_PERIOD_DAYS - USER_WARNING_DAYS, 'day'), 'day')
);
const selectSumo1AdminData = createSelector(authenticationState, (authentication) => authentication.SUMO1Admin);
const selectIsSumo1Admin = createSelector(selectSumo1AdminData, (data) => !!data.email);
const selectSumo1AdminUserId = createSelector(selectSumo1AdminData, (data) => data.userId);
const selectSumo1AdminRoleName = createSelector(selectSumo1AdminData, (data) => data.roleName);
const selectSumo1AdminRoleLabel = createSelector(
  selectSumo1AdminRoleName,
  (roleName) => STAFF_ROLE_OPTIONS.find((role) => role.value === roleName)?.label
);

const selectIsMainAdmin = createSelector(selectSumo1AdminRoleName, (role) => role === StaffRoles.MAIN_ADMIN);
const selectIsOperations = createSelector(selectSumo1AdminRoleName, (role) => role === StaffRoles.OPERATIONS);
const selectIsMainAdminOrOperations = createSelector(
  selectIsMainAdmin,
  selectIsOperations,
  (isMainAdmin, isOperations) => isMainAdmin || isOperations
);
const selectIsSupport = createSelector(selectSumo1AdminRoleName, (role) => role === StaffRoles.SUPPORT);
const selectIsSales = createSelector(selectSumo1AdminRoleName, (role) => role === StaffRoles.SALES);
const selectIsMainAdminOrSupport = createSelector(
  selectIsMainAdmin,
  selectIsSupport,
  (isMainAdmin, isSupport) => isMainAdmin || isSupport
);
const selectIsMainAdminOperationsOrSupport = createSelector(
  selectIsMainAdmin,
  selectIsOperations,
  selectIsSupport,
  (isMainAdmin, isOperations, isSupport) => isMainAdmin || isOperations || isSupport
);
const selectStaffEmail = createSelector(selectSumo1AdminData, (staff) => staff.email);
const selectStaffName = createSelector(selectSumo1AdminData, (staff) => staff.userName);
const selectStaffNameOrEmail = createSelector(selectStaffEmail, selectStaffName, (email, name) => name || email);

const selectIsViewAsUser = createSelector(
  selectSumo1AdminUserId,
  selectUserId,
  (sumo1UserId, userId) => sumo1UserId && userId && sumo1UserId !== userId
);
const selectIsOPSConsole = createSelector(
  () =>
    window.location.pathname.startsWith('/opsconsole') &&
    !window.location.pathname.startsWith('/opsconsole/view-as-user')
);

const selectTenant = createSelector(authenticationState, (authentication) => authentication.tenant);
const selectTenantName = createSelector(selectTenant, (tenant) => tenant.name);
const selectTenantStatus = createSelector(selectTenant, (tenant) => tenant.status);
const selectTenantType = createSelector(selectTenant, (tenant) => tenant.type);
const selectIsTenantExpired = createSelector(selectTenantStatus, (status) => status === TenantStatus.EXPIRED);

// checking license.endDate and tenant.type
const selectIsLicenseActive = createSelector(
  selectLicenseEndDate,
  selectIsTenantExpired,
  (endDate, isTenantExpired) => {
    return new Date() < new Date(endDate || 0) && !isTenantExpired;
  }
);

const selectTenantFileFolderPath = createSelector(selectTenantId, (tenantId) => (tenantId ? tenantId + '/' : ''));

const selectFeatures = createSelector(selectTenant, (tenant) => tenant.features);

const selectIsEnabledFeature = (feature: Feature) =>
  createSelector(selectFeatures, (features) => {
    if (!features || features.length === 0) return false;
    return !!features.find((item) => item?.featureTag === feature);
  });

const selectCurrentWorkspace = createSelector(selectTenant, selectWorkspaceId, (tenant, workspaceId) =>
  tenant.workspace?.find((workspace) => workspace?.id === workspaceId)
);

const selectCurrentWorkspaceIntegrations = createSelector(
  selectCurrentWorkspace,
  (workspace) => workspace?.integrations
);

const selectIsTrialLicenseExpired = createSelector(
  selectIsLicenseActive,
  selectIsTrialLicense,
  (isActive, isTrial) => isTrial && !isActive
);

const selectIsWorkspaceIntegrationConnected = (integrationType: IntegrationType | WorkspaceIntegrationType | AppType) =>
  createSelector(
    selectCurrentWorkspaceIntegrations,
    (integrations) => integrations?.some((integration) => integration?.type === integrationType) || false
  );

const selectWorkspaceIntegrationSettings = (integrationType: IntegrationType | WorkspaceIntegrationType | AppType) =>
  createSelector(
    selectCurrentWorkspaceIntegrations,
    (integrations) => integrations?.find((integration) => integration?.type === integrationType)?.settings
  );

const selectTenantWorkspaces = createSelector(selectTenant, (tenant) => tenant.workspace || []);
const selectTenantWorkspacesOptions = createSelector(selectTenantWorkspaces, (workspaces) =>
  workspaces.map((workspace) => ({ id: workspace?.id || '', name: workspace?.name || '' }))
);
const selectTenantWorkspacesOptionItems = createSelector(selectTenantWorkspaces, (workspaces) =>
  workspaces.map((workspace) => ({ value: workspace?.id || '', label: workspace?.name || '' }))
);
const selectTenantWorkspacesById = createSelector(selectTenantWorkspaces, (workspaces) =>
  workspaces.reduce((workspacesById, workspace) => {
    if (workspace?.id) {
      workspacesById[workspace.id] = workspace;
    }
    return workspacesById;
  }, {} as Record<string, WorkspaceInput>)
);
const selectTenantWorkspacesNamesById = createSelector(selectTenantWorkspaces, (workspaces) =>
  workspaces.reduce((namesById, workspace) => {
    if (workspace?.id) {
      namesById[workspace.id] = workspace.name;
    }
    return namesById;
  }, {} as Record<string, string>)
);

const selectGoogleMapsSettings = createSelector(
  selectWorkspaceIntegrationSettings(WorkspaceIntegrationType.GOOGLE_MAPS),
  (settings) => (settings ? (settings as unknown as GoogleMapsCustomParameters) : null)
);
const selectGoogleMapsKey = createSelector(selectGoogleMapsSettings, (settings) => settings?.apiKey || '');

export const authenticationSelectors = {
  selectIsFetching,
  selectIsTenantFetching,
  selectLicensesIsFetching,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectIsOpsConsole,
  selectIsCookieOpen,
  selectError,
  selectUserId,
  selectTenantId,
  selectTenantName,
  selectTenantType,
  selectLink,
  selectWorkspaceId,
  selectAuthenticationDataReceived,
  selectIsUserRegistered,
  selectUserWasDeleted,
  selectThirdParty,
  selectIsThirdPartyGoogle,
  selectIsThirdPartyMicrosoft,
  selectLicenseData: selectCurrentLicense,
  selectUserAccounts,
  selectInviteToAccount,
  selectIsLastSuperAdmin,
  selectIsLicenseActive,
  selectLicenseEndDate,
  selectIsTrialLicense,
  selectTrialExpirationMessage,
  selectPaidExpirationMessage,
  selectShowExpirationUserMessage,
  selectIsPaidLicense,
  selectLicenseStatistics,
  selectIsSumo1Admin,
  selectSumo1AdminRoleName,
  selectSumo1AdminRoleLabel,
  selectIsMainAdmin,
  selectIsOperations,
  selectIsMainAdminOrOperations,
  selectIsSupport,
  selectIsSales,
  selectIsMainAdminOrSupport,
  selectIsMainAdminOperationsOrSupport,
  selectStaffNameOrEmail,
  selectIsTenantExpired,
  selectSumo1AdminUserId,
  selectIsOPSConsole,
  selectIsViewAsUser,
  selectLicenses,
  selectCurrentLicense,
  selectAssigned,
  selectRemainingLicenses,
  selectLicenseType,
  selectTenantFileFolderPath,
  selectIsTrialLicenseExpired,
  selectCurrentWorkspace,
  selectIsWorkspaceIntegrationConnected,
  selectWorkspaceIntegrationSettings,
  selectIsEnabledFeature,
  selectTenantWorkspaces,
  selectTenantWorkspacesOptions,
  selectTenantWorkspacesOptionItems,
  selectTenantWorkspacesById,
  selectTenantWorkspacesNamesById,
  selectGoogleMapsSettings,
  selectGoogleMapsKey,
  selectDaysUntilLicenseEnd,
  selectTotalLicenseCount,
};
