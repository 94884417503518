export const Users = Object.freeze({
  ADD_TO_TEAM: `Adicionar à equipe`,
  ADD_TO_TEAM_PLACEHOLDER: `Selecionar equipe(s)`,
  ALREADY_INVITED_COUNT: `Já é um usuário`,
  ASSIGN_BOOKING_TEMPLATES: `Atribuir modelos de reserva`,
  ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER: `Selecionar modelo(s) de reserva`,
  ASSIGN_ROLE: `Atribuir função`,
  ASSIGN_WORKSPACE: `Atribuir espaço de trabalho`,
  CLOSE: `Fechar`,
  CONFIRM_DIALOG_DESC: `Para confirmar, você enviará um convite por e-mail da seguinte maneira:`,
  CSV_INVITE_MESSAGE_TITLE: `Configure uma grande equipe em um minuto`,
  CSV_TEMPLATE_DESC: `e preencha-o com os endereços de e-mail e funções de seus usuários.`,
  CSV_TEMPLATE_TITLE: `Baixar modelo`,
  CSV_UPLOAD_DESC: `Selecionar arquivo CSV no seu computador`,
  CSV_UPLOAD_TITLE: `Carregar CSV`,
  DELETE_TOOLTIP: `Você só pode excluir usuários com "Convite enviado" que você convidou. Super administradores podem excluir qualquer usuário.`,
  EMAIL: `E-mail`,
  FULL_NAME: `Nome completo`,
  HIDE_INACTIVE_USERS: `Ocultar usuários inativos`,
  INVITE: `Convidar`,
  INVITE_MESSAGE_DESC: `Digite ou cole e-mails, e enviaremos um convite para seus colegas de equipe. Separe os endereços de e-mail com uma vírgula.`,
  INVITE_MESSAGE_TITLE: `Endereços de e-mail`,
  INVITE_TITLE: `Convidar usuários`,
  LAST_ACTIVE: `Última atividade`,
  LOADING_LABEL_1: `Verificando duplicados`,
  LOADING_LABEL_2: `Importando os usuários`,
  LOADING_LABEL_3: `Atribuindo atributos`,
  LOADING_LABEL_4: `Enviando os convites`,
  BOOKING_TEMPLATE: `Modelo de reserva`,
  NAME: `Nome`,
  NO_EMAIL_ADDRESSES: `Nenhum endereço de e-mail encontrado.`,
  OPTIONAL: `Opcional`,
  PASTE_EMAILS_TITLE: `Adicionar e-mail`,
  ROLE: `Função`,
  STATUS: `Status`,
  STATUS_INVITE_SENT: `Convidado`,
  STATUS_ACTIVE: `Ativo`,
  STATUS_INACTIVE: `Inativo`,
  SUPER_ADMIN_ACCESS_DESCRIPTION: `O superadministrador terá acesso a todos os espaços de trabalho`,
  TEAM: `Equipe`,
  TOOLTIP: `Convide e gerencie o acesso dos funcionários a esta conta SUMO1`,
  USER_COUNT: `Usuários únicos a serem convidados`,
  WORKSPACE: `Espaço de trabalho`,
  DELETED_USERS: `Usuários excluídos`,
});

export const UsersConfirmDialog = Object.freeze({
  DELETE_DESCRIPTION: `Você tem certeza de que deseja excluir`,
  DELETE_COMMENT_1: `Desativar ou excluir um usuário liberará uma licença.`,
  DELETE_NOTE_DESCRIPTION: `Todos os dados do usuário serão excluídos permanentemente dos servidores SUMO1.`,
  DELETE_NOTE_TITLE: `Conformidade com GDPR`,
  DELETE_TITLE: `Excluir`,
  DISABLE_COMMENT_1: `Desativar um usuário liberará uma licença.`,
  DISABLE_COMMENT_2: `As reuniões não serão excluídas nem canceladas.`,
  DISABLE_DESCRIPTION: `Você tem certeza de que deseja desativar`,
  DISABLE_TITLE: `Desativar Usuário`,
});

export const EditUser = Object.freeze({
  ACCESS_TITLE: `Acesso`,
  ACTIVE: `Ativo`,
  ACTIVE_TOOLTIP: `Marque esta caixa para inativar este usuário e liberar uma licença.`,
  CONTACT_DETAILS_TITLE: `Detalhes de Contato`,
  DEACTIVATE_HIMSELF: `Você não pode se desativar.`,
  DAYS_10: `10 dias`,
  DAYS_15: `15 dias`,
  DAYS_20: `20 dias`,
  DAYS_30: `30 dias`,
  EXPIRATION_DATE: `Data de expiração`,
  HIGHER_ROLE_DESCRIPTION: `Este usuário tem uma função superior à sua,`,
  INVITE_SENT_TOOLTIP: `O usuário ainda não fez login. Excluir o registro liberará a licença.`,
  BOOKING_TEMPLATES: `Modelos de reserva`,
  BOOKING_TEMPLATES_MESSAGE: `Este usuário suporta os seguintes modelos de reserva`,
  BOOKING_TEMPLATES_TOOLTIP: `Selecione primeiro um espaço de trabalho`,
  NO_PERMISSION_DESCRIPTION: `Você não tem permissão para editar usuários,`,
  ONLY_ONE_SUPER_ADMIN: `Não há mais superadministradores exceto você. Para alterar a função, convide pelo menos mais um superadministrador`,
  READONLY_DESCRIPTION: `somente o modo de leitura está disponível.`,
  TEAMS: `Equipas`,
  TEAMS_MESSAGE: `Este usuário é membro das seguintes equipas.`,
  TITLE: `Editar Usuário`,
  USER_OF_WORKSPACE_TITLE: `Usuário do Espaço de Trabalho`,
  WORKSPACE_MESSAGE: `Este usuário pode usar os seguintes espaços de trabalho.`,
});

export const UsersToastsNotifications = Object.freeze({
  EDIT_USER_ERROR_TOAST: `Falha na solicitação de edição do usuário`,
  EDIT_USER_SUCCESS_TOAST: `Solicitação de edição do usuário bem-sucedida`,
  ENABLE_USER_TOAST_HEADER: `Usuário ativado`,
  ENABLE_USER_SUCCESS_TOAST: `agora está ativado.`,
  GET_LICENSES_ERROR_MESSAGE: `Falha na solicitação de licenças`,
  GET_USERS_ERROR_MESSAGE: `Falha na solicitação de usuários`,
  INVITE_USERS_EMAIL_INVALID: `Um ou mais dos endereços de e-mail inseridos são inválidos.`,
  INVITE_USERS_ERROR_TOAST: `Falha na solicitação de convite de usuários`,
  INVITE_USERS_SUCCESS_TOAST: `Solicitação de convite de usuários bem-sucedida`,
  DELETE_USER_TOAST_HEADER: `Usuário excluído`,
  DELETE_USER_ERROR_TOAST: `Falha na solicitação de exclusão do usuário`,
  DELETE_USER_SUCCESS_TOAST: `agora está excluído.`,
  DISABLE_USER_TOAST_HEADER: `Usuário desativado`,
  DISABLE_USER_ERROR_TOAST: `Falha na solicitação de desativação/ativação do usuário`,
  DISABLE_USER_SUCCESS_TOAST: `agora está desativado.`,
});

export const License = Object.freeze({
  ASSIGNED: `Atribuída`,
  BUY_LICENSES: `Comprar Licenças`,
  MY_BILLINGS: `Meus faturamentos`,
  SUBSCRIPTION: `Assinatura`,
  LICENSES: `Licenças`,
  PAID_EXPIRES_MESSAGE_PART_1: `Pagamento Pendente:`,
  PAID_EXPIRES_MESSAGE_PART_2_AGO: `dias atrás`,
  PAID_EXPIRES_MESSAGE_PART_2_LEFT: `dias restantes`,
  PAID_EXPIRES_MESSAGE_PART_2_TODAY: `hoje`,
  REMAINING: `Restantes`,
  REMAINING_MESSAGE: `Você não tem licenças restantes suficientes`,
  TITLE: `Status de Licença do Usuário`,
  TRIAL_EXPIRES_MESSAGE: `O teste gratuito termina em breve.`,
  SEATS_REMOVAL_SCHEDULED: `Mudanças programadas para remover assentos.`,
});
