export enum Path {
  Account = '/account',
  Availability = '/availability',
  Billing = '/billing',
  BookingPages = '/booking-pages',
  CalendarConnections = '/calendar-connections',
  ConnectIntegration = '/connect-integration',
  EditBookingPage = '/edit-booking-page/:bookingPageId',
  EditBookingTemplate = '/edit-booking-template/:bookingTemplateId',
  EditGroupBookingPage = '/edit-group-booking-page/:groupBookingPageId',
  EditWorkspace = '/edit-workspace',
  ErrorPage = '/error',
  Event = '/event/:eventId',
  Integration = '/integration',
  Login = 'login',
  Landing = '/',
  BookingTemplates = '/booking-templates',
  MicrosoftAuthRedirect = 'microsoft-auth-redirect',
  GoogleAuthRedirect = 'google-auth-redirect',
  MyLink = 'my-link',
  MyLogin = 'my-login',
  MyRole = 'my-role',
  OPSConsole = '/opsconsole',
  OPSConsoleAccounts = '/opsconsole/accounts',
  OPSConsoleAccount = '/opsconsole/account/:accountId',
  OPSConsoleBilling = '/opsconsole/billing',
  OPSConsoleUsers = '/opsconsole/users',
  OPSConsoleUserDetails = '/opsconsole/user/:tenantId/:email',
  OPSConsoleOrgs = '/opsconsole/orgs',
  OPSConsoleOrgDetails = '/opsconsole/org/:orgId',
  OPSConsoleStaff = '/opsconsole/staff',
  OPSConsoleStaffDetails = '/opsconsole/staff/details',
  OPSConsoleViewAsUser = '/opsconsole/view-as-user/:tenantId/:userId',
  PublicBookingPageRoot = '/book',
  PublicBookingPage = '/book/:bookingPageId',
  PublicGroupBookingPage = '/group/:groupBookingPageId',
  PublicJourneyPage = '/journey-book/:journeyId',
  QuickSetup = '/quick-setup',
  Roles = '/roles',
  BookedMeetings = '/booked-meetings',
  Tests = '/tests',
  BillingTests = '/billing-tests',
  LicenseExpired = '/license-expired',
  UnknownPath = '*',
  Users = '/users',
  Workspaces = '/workspaces',
  Teams = '/teams',
  SmartAlerts = '/smart-alerts',
  PreSelectSmartAlert = '/smart-alert/:smartType/pre-select',
  EditSmartAlert = '/smart-alert/:smartType/:smartAlertId',
  Locations = '/locations',
  LocationsImport = '/locations/import',
  EditLocation = '/location/:locationId',
  Journeys = '/journeys',
  EditJourney = '/journey/:journeyId',
  BuyLicenses = '/buy-licenses',
}
