import { createSelector } from 'reselect';
import { State } from '../rootStore';
import { ModalName } from './constants';

const modalsState = (state: State) => state.modals;

const selectIsModalOpen = (key: ModalName) => createSelector(modalsState, (modals) => modals[key]?.open || false);
const selectModalRequest = <T extends object>(key: ModalName) =>
  createSelector(modalsState, (modals) => modals[key]?.payload as T);

export const modalsSelectors = {
  selectIsModalOpen,
  selectModalRequest,
};

export const modalsSelectorsFactory = <T extends object>(key: ModalName) => ({
  selectIsModalOpen: selectIsModalOpen(key),
  selectModalRequest: selectModalRequest<T>(key),
});
