import { useSelector } from 'react-redux';
import { eventSelectors } from '../../../store/publicBookingPage';
import { GoogleMapsProvider } from '../../../providers';
import { PublicBookingPageLocationsNoMap } from '../publicBookingPageLocationsNoMap/PublicBookingPageLocationsNoMap';
import { PublicBookingPageLocationsWithMap } from '../publicBookingPageLocationsWithMap/PublicBookingPageLocationsWithMap';

export const PublicBookingPageLocations = () => {
  const googleMaps = useSelector(eventSelectors.selectGoogleMapsSettings);

  return (
    <>
      {googleMaps ? (
        <GoogleMapsProvider apiKey={googleMaps.apiKey}>
          <PublicBookingPageLocationsWithMap />
        </GoogleMapsProvider>
      ) : (
        <PublicBookingPageLocationsNoMap />
      )}
    </>
  );
};
