import { t } from '../../../i18n/i18n';

export default {
  createEvents: t('ConnectIntegrationPage:SALESFORCE_CREATE_EVENTS'),
  bookUsers: t('ConnectIntegrationPage:SALESFORCE_BOOK_USERS'),
  create: t('ConnectIntegrationPage:CREATE'),
  leads: t('ConnectIntegrationPage:LEADS'),
  contacts: t('ConnectIntegrationPage:CONTACTS'),
  bookingWithSFSumoInbox: t('ConnectIntegrationPage:SALESFORCE_BOOKING_WITH_SF_SUMO_INBOX'),
  personAccount: t('ConnectIntegrationPage:PERSON_ACCOUNT'),
};