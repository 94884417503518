import React from 'react';
import { Button } from 'primereact/button';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import labels from '../buyLicenseButton/labels';
import { useSelector } from 'react-redux';
import { billingSelectors } from '../../../store/billing';
import { useRotatingText } from '../../../hooks/useRotatingText';
import { authenticationSelectors } from '../../../store/authentication';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';

type BuyLicensePageButtonProps = {
  isButtonXl?: boolean;
};

export const BuyLicensePageButton = ({ isButtonXl }: BuyLicensePageButtonProps) => {
  const isRefreshLicenseFetching = useSelector(billingSelectors.selectIsRefreshLicencesFetching);
  const isLicenseFetching = useSelector(authenticationSelectors.selectLicensesIsFetching);

  const rotatingLoadingLabel = useRotatingText([labels.loadingLabel1, labels.loadingLabel2], isRefreshLicenseFetching);

  return (
    <Button
      className={`flex-none gap-8px ${isButtonXl ? 'button-xl' : ''}`}
      disabled={isLicenseFetching}
      onClick={() => {
        navigationService.navigateTo(Path.BuyLicenses);
      }}
      loading={isRefreshLicenseFetching}
      style={isRefreshLicenseFetching ? { width: '167px' } : undefined}
    >
      {!isRefreshLicenseFetching && <ShoppingCartIcon className="icon-18px" />}
      <div className="flex-1">{isRefreshLicenseFetching ? rotatingLoadingLabel : labels.buyLicenses}</div>
    </Button>
  );
};
