import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../API';
import { ConnectIntegrationRequest } from '../../generated-sources/internal-api/models/ConnectIntegrationRequest';
import { HubSpotCustomParameters, IntegrationFilter, SalesforceCustomParameters } from './types';

// TODO: rename to IntegrationsActionTypes
export enum IntegrationActionTypes {
  SET_DEFAULT_INTEGRATIONS = 'integrations/SET_DEFAULT_INTEGRATIONS',
  SET_CONNECT_INTEGRATION_TYPE = 'integrations/SET_CONNECT_INTEGRATION_TYPE',
  SET_INTEGRATION_FILTER = 'integrations/SET_INTEGRATION_FILTER',
  GET_ZOOM_CODE_REDIRECT = 'integrations/GET_ZOOM_CODE_REQUEST',
  GET_HUBSPOT_CODE_REDIRECT = 'integrations/GET_HUBSPOT_CODE_REDIRECT',
  GET_SALESFORCE_CODE_REDIRECT = 'integrations/GET_SALESFORCE_CODE_REDIRECT',
  CONNECT_WORKSPACE_INTEGRATION_REQUEST = 'integrations/CONNECT_WORKSPACE_INTEGRATION_REQUEST',
  CONNECT_WORKSPACE_INTEGRATION_SUCCESS = 'integrations/CONNECT_WORKSPACE_INTEGRATION_SUCCESS',
  CONNECT_WORKSPACE_INTEGRATION_FAIL = 'integrations/CONNECT_WORKSPACE_INTEGRATION_FAIL',
}

// TODO: rename to IntegrationsAction
export type IntegrationAction =
  | { type: IntegrationActionTypes.SET_DEFAULT_INTEGRATIONS }
  | {
      type: IntegrationActionTypes.SET_CONNECT_INTEGRATION_TYPE;
      payload: IntegrationType | WorkspaceIntegrationType | AppType;
    }
  | { type: IntegrationActionTypes.SET_INTEGRATION_FILTER; payload: IntegrationFilter }
  | { type: IntegrationActionTypes.GET_ZOOM_CODE_REDIRECT; uri: string }
  | {
      type: IntegrationActionTypes.GET_HUBSPOT_CODE_REDIRECT;
      uri: string;
      workspaceId: string;
      customParameters: HubSpotCustomParameters;
    }
  | {
      type: IntegrationActionTypes.GET_SALESFORCE_CODE_REDIRECT;
      uri: string;
      workspaceId: string;
      customParameters: SalesforceCustomParameters;
    }
  | { type: IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_REQUEST; payload: ConnectIntegrationRequest }
  | { type: IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_SUCCESS }
  | { type: IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_FAIL; error: unknown };

const setDefaultIntegration = (): IntegrationAction => ({
  type: IntegrationActionTypes.SET_DEFAULT_INTEGRATIONS,
});

const setConnectIntegrationType = (
  payload: IntegrationType | WorkspaceIntegrationType | AppType
): IntegrationAction => ({
  type: IntegrationActionTypes.SET_CONNECT_INTEGRATION_TYPE,
  payload,
});

const setIntegrationFilter = (payload: IntegrationFilter): IntegrationAction => ({
  type: IntegrationActionTypes.SET_INTEGRATION_FILTER,
  payload,
});

const getZoomCodeRedirect = (uri: string): IntegrationAction => ({
  type: IntegrationActionTypes.GET_ZOOM_CODE_REDIRECT,
  uri,
});

const getHubSpotCodeRedirect = (
  uri: string,
  workspaceId: string,
  customParameters: HubSpotCustomParameters
): IntegrationAction => ({
  type: IntegrationActionTypes.GET_HUBSPOT_CODE_REDIRECT,
  uri,
  workspaceId,
  customParameters,
});

const getSalesforceCodeRedirect = (
  uri: string,
  workspaceId: string,
  customParameters: SalesforceCustomParameters
): IntegrationAction => ({
  type: IntegrationActionTypes.GET_SALESFORCE_CODE_REDIRECT,
  uri,
  workspaceId,
  customParameters,
});

const connectWorkspaceIntegrationRequest = (payload: ConnectIntegrationRequest): IntegrationAction => ({
  type: IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_REQUEST,
  payload,
});
const connectWorkspaceIntegrationSuccess = (): IntegrationAction => ({
  type: IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_SUCCESS,
});
const connectWorkspaceIntegrationFail = (error: unknown): IntegrationAction => ({
  type: IntegrationActionTypes.CONNECT_WORKSPACE_INTEGRATION_FAIL,
  error,
});

// TODO: rename to integrationsActions
export const integrationActions = {
  setDefaultIntegration,
  setConnectIntegrationType,
  setIntegrationFilter,
  getZoomCodeRedirect,
  getHubSpotCodeRedirect,
  getSalesforceCodeRedirect,
  connectWorkspaceIntegrationRequest,
  connectWorkspaceIntegrationSuccess,
  connectWorkspaceIntegrationFail,
};
