import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Dropdown } from 'primereact/dropdown';
import { Menu as PrimeMenu } from 'primereact/menu';
import { classNames } from 'primereact/utils';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sumoLogo from '../../../assets/images/sumoLogo.svg';
import sumoLogoWhite from '../../../assets/images/sumoLogoWhite.svg';
import { Path } from '../../../routing';
import { authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { userSettingsActions, userSettingsSelectors } from '../../../store/userSettings';
import { workspacesSelectors } from '../../../store/workspaces';
import { UserAvatar } from '../../common';
import { ViewAsUserWarningBlock } from '../../opsConsole';
import { SumoistHelper } from '../sumoistHelper/SumoistHelper';
import { createModalMenuItems } from './constants';
import labels from './labels';
import { NavigationMenu } from './NavigationMenu';

export const Menu = () => {
  const menu = useRef<PrimeMenu>(null);
  const menuToggle = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const isOpConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const nameOrEmail = useSelector(userSettingsSelectors.selectNameOrEmail) || '';
  const isMenuOpened = useSelector(userSettingsSelectors.selectIsMenuOpened);
  const avatarLink = useSelector(userSettingsSelectors.selectAvatar);
  const roleName = useSelector(userSettingsSelectors.selectUserRoleName);
  const staffNameOrEmail = useSelector(authenticationSelectors.selectStaffNameOrEmail);
  const staffRoleName = useSelector(authenticationSelectors.selectSumo1AdminRoleLabel);
  const userWasDeleted = useSelector(authenticationSelectors.selectUserWasDeleted);
  const selectedWorkspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces);
  const userWorkspacesOptions = useSelector(workspacesSelectors.selectWorkspaceOptionsByIds(userWorkspaces));
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isTrialLicenseExpired = useSelector(authenticationSelectors.selectIsTrialLicenseExpired);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const trialExpirationMessage = useSelector(authenticationSelectors.selectTrialExpirationMessage);
  const paidExpirationMessage = useSelector(authenticationSelectors.selectPaidExpirationMessage);
  const showExpirationUserMessage = useSelector(authenticationSelectors.selectShowExpirationUserMessage);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);

  const name = isOpConsole ? staffNameOrEmail : nameOrEmail;
  const role = isOpConsole ? staffRoleName : roleName;

  const accountVisible = Boolean(isQuickSetupFinished && !isOpConsole && isLicenseActive);

  useEffect(() => {
    userWasDeleted && handleSignOut();
  }, [userWasDeleted]);

  const handleWorkspaceChange = (value: string) => {
    dispatch(authenticationActions.updateUserDataCore({ workspaceId: value }));
    window.location.reload();
  };

  const handleSignOut = () => {
    dispatch(authenticationActions.logoutUserRequest(Path.Landing));
  };

  const handleToggleMenu = () => {
    if (isMenuOpened) {
      dispatch(userSettingsActions.setIsMenuOpened(false));
    } else {
      dispatch(userSettingsActions.setIsMenuOpened(true));
    }
  };

  return (
    <div className="flex flex-column gap-16px p-24px min-h-full">
      <div className="flex">
        <img src={isOpConsole ? sumoLogoWhite : sumoLogo} className="h-30px" />
      </div>

      {!isOpConsole && (userWorkspaces?.length || 0) > 1 && (
        <Dropdown
          options={userWorkspacesOptions}
          optionValue="id"
          optionLabel="name"
          value={selectedWorkspaceId}
          dropdownIcon={`${userWorkspacesOptions?.length ? 'pi pi-chevron-down' : null}`}
          valueTemplate={(option) =>
            userWorkspacesOptions?.length ? (
              <div>{option?.name}</div>
            ) : (
              <div className="w-4rem text-right">
                <span className="text-lg vertical-align-middle pi pi-spinner pi-spin" />
              </div>
            )
          }
          placeholder="Loading..." // no need in labels - never shown, used to hide dropdown standard css class
          disabled={!userWorkspacesOptions?.length}
          onChange={(e) => handleWorkspaceChange(e.value)}
        />
      )}

      {isLicenseActive === false || isQuickSetupFinished || isOpConsole ? (
        <div className="flex-1 mt-8px">
          <NavigationMenu />
        </div>
      ) : (
        <div className="flex-1" />
      )}

      {isViewAsUserMode && !isOpConsole && <ViewAsUserWarningBlock />}
      {isQuickSetupFinished && !isOpConsole && isLicenseActive && (
        <>
          {trialExpirationMessage && (
            <SumoistHelper
              title={trialExpirationMessage}
              message={labels.trialMessage}
              buttonUrl={isSuperAdmin ? Path.Billing : undefined}
              buttonLabel={labels.buyLicenses}
            />
          )}
          {isSuperAdmin && paidExpirationMessage && (
            <SumoistHelper message={paidExpirationMessage} buttonUrl={Path.Billing} buttonLabel={labels.myBillings} />
          )}
          {showExpirationUserMessage && <SumoistHelper message={paidExpirationMessage} />}
        </>
      )}
      {/* Trial License Expired */}
      {!isOpConsole && isTrialLicenseExpired && (
        <SumoistHelper
          title={labels.expired}
          message={labels.trialIsOver}
          buttonUrl={isSuperAdmin ? Path.Billing : undefined}
          buttonLabel={labels.buyLicenses}
        />
      )}

      <div className="-mr-8px relative">
        <div
          ref={menuToggle}
          className={`flex align-items-center gap-8px border-radius-6px cursor-pointer ${
            isOpConsole ? 'text-primary-white hover-bg-heavy-80' : 'hover-bg-blue-light'
          }`}
          onClick={handleToggleMenu}
        >
          <UserAvatar size="large" name={name} avatarLink={avatarLink} />
          <div className="flex flex-column gap-4px">
            <div className="text-label-s-med">{name}</div>
            <div className="text-label-xs-reg text-heavy-60">{role}</div>
          </div>
          <div className="ml-auto flex align-items-center">
            <ChevronUpDownIcon className="text-heavy-60 w-16px" />
          </div>
        </div>

        <PrimeMenu
          ref={menu}
          model={createModalMenuItems()}
          className={classNames(
            `absolute bottom-40px left-0 shadow-level-3`,
            accountVisible ? 'w-fit' : 'w-240px',
            isMenuOpened ? 'block' : 'hidden'
          )}
        />
      </div>
    </div>
  );
};
