export const DEFAULT_MODAL_OPTIONS = Object.freeze({ open: false });

export enum ModalName {
  CHOOSE_THE_ACCOUNT = 'chooseTheAccount',
  LEAVE_THE_ACCOUNT = 'leaveTheAccount',
  BOOKING_PAGE_CLONE_MODAL = 'BookingPageCloneModal',
  DELETE_BOOKING_PAGES_MODAL_NAME = 'BookingPageDeleteModal',
  PREVIEW_MODAL = 'BookingPagePreviewModal',
  ADD_TO_WEBSITE_MODAL = 'BookingPageAddToWebsiteModal',
  CONFIRM_OVERRIDE_MODAL_NAME = 'BookingPageConfirmOverrideModal',
  CONFIRM_OVERRIDE_SMART_ALERT_MODAL = 'BookingPageConfirmOverrideSmartAlertModal',
  AVAILABILITY_OVERRIDE_MODAL_NAME = 'availabilityOverrideModal',
  NEW_AVAILABILITY_NAME = 'newAvailabilityNameModal',
  CHANGE_AVAILABILITY_NAME = 'changeAvailabilityNameModal',
  CHANGE_SEATS_AND_TERM_MODAL = 'BillingChangeSeatsAndTermModal',
  BILLING_ADDRESS_MODAL = 'BillingChangeTermModal',
  BUY_LICENSE_MODAL = 'BuyLicenseModal',
  EDIT_MEETING_NOTES_MODAL_NAME = 'editMeetingNotesModal',
  CANCEL_EVENT_MODAL_NAME = 'cancelEventModal',
  RESCHEDULE_EVENT_MODAL_NAME = 'rescheduleEventModal',
  NO_SHOW_EVENT_MODAL_NAME = 'noShowEventModal',
  BOOKING_TEMPLATE_CLONE_MODAL_NAME = 'BookingTemplateCloneModal',
  EDIT_CUSTOM_FIELD_MODAL_NAME = 'BookingTemplateEditCustomFieldModal',
  DELETE_BOOKING_TEMPLATES_MODAL_NAME = 'deleteBookingTemplatesModal',
  SMART_ALERT_MODAL_NAME = 'smartAlertModal',
  GROUP_CLONE_MODAL_NAME = 'GroupBookingPageCloneModal',
  GROUP_DELETE_MODAL_NAME = 'GroupBookingPageDeleteModal',
  GROUP_DEACTIVATE_MODAL_NAME = 'GroupBookingPageDeactivateModal',
  CLONE_JOURNEYS_MODAL_NAME = 'cloneJourneys',
  DELETE_JOURNEYS_MODAL_NAME = 'deleteJourneys',
  DEACTIVATE_JOURNEYS_MODAL_NAME = 'deactivateJourneys',
  DELETE_JOURNEY_STEP_MODAL_NAME = 'deleteJourneyStep',
  CLONE_LOCATION_MODAL_NAME = 'cloneLocation',
  DELETE_ACCOUNT_MODAL_NAME = 'DeleteAccountsModal',
  EXTEND_TRIAL_MODAL_NAME = 'ExtendTrialModal',
  EXPIRE_TRIAL_MODAL_NAME = 'ExpireTrialModal',
  CONVERT_TRIAL_MODAL_NAME = 'ConvertTrialModal',
  DELETE_ORG_MODAL_NAME = 'DeleteOrgModal',
  PAY_LATER_MODAL_NAME = 'PayLaterModal',
  DEACTIVATE_STAFF_MODAL_NAME = 'DeactivateStaffModal',
  DELETE_STAFF_MODAL_NAME = 'DeleteStaffModal',
  DEACTIVATE_USER_MODAL_NAME = 'DeactivateUserModal',
  DELETE_USER_MODAL_NAME = 'DeleteUserModal',
  INSTALL_MODAL = 'InstallModal',
  EDIT_ROLE_MODAL_NAME = 'editRoleModal',
  CLONE_SMART_TYPES_MODAL_NAME = 'cloneSmartTypes',
  DELETE_SMART_TYPES_MODAL_NAME = 'deleteSmartTypes',
  EDIT_TEAM_MODAL_NAME = 'editTeamModal',
  INVITE_USERS_MODAL_NAME = 'inviteUsersModal',
  CONFIRM_INVITE_USERS_MODAL_NAME = 'confirmInviteUsersModal',
  EDIT_USERS_MODAL_NAME = 'editUsersModal',
  CONFIRM_USERS_MODAL_NAME = 'confirmUsersModal',
  ACCOUNTS_DELETE_ACCOUNT_MODAL_NAME = 'deleteAccountModal',
  DELETE_WORKSPACE_NAME = 'deleteWorkspaceNameModal',
  CANNOT_DELETE_WORKSPACE_NAME = 'cannotDeleteWorkspaceNameModal',
  CLONE_WORKSPACE_NAME = 'cloneWorkspaceModal',
  DELETE_ACCOUNT_MODAL = 'DELETE_ACCOUNT_MODAL',
}
