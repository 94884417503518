import { useDispatch, useSelector } from 'react-redux';
import { billingActions, billingSelectors, MAX_CONTACTS_AMOUNT } from '../../../store/billing';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import labels from './labels';
import { usersSelectors } from '../../../store/users';
import { Button } from 'primereact/button';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

export const BillingNotifications = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(billingSelectors.selectIsFetching);
  const contactEmails = useSelector(billingSelectors.selectContactEmails);
  const sendBillingEmail = useSelector(billingSelectors.selectSendBillingEmail);
  const customerEmail = useSelector(billingSelectors.selectCustomerEmail);
  const isEmailsUpdated = useSelector(billingSelectors.selectIsEmailsUpdated);
  const isSendBillingEmailUpdated = useSelector(billingSelectors.selectIsSendBillingEmailUpdated);
  const adminEmails = useSelector(usersSelectors.selectActiveSuperAdminEmails);

  const handleEmailChange = (index: number, value: string) => {
    dispatch(
      billingActions.setContactEmails([...contactEmails.slice(0, index), value, ...contactEmails.slice(index + 1)])
    );
  };

  const handleEmailRemove = (index: number) => {
    dispatch(billingActions.setContactEmails([...contactEmails.slice(0, index), ...contactEmails.slice(index + 1)]));
  };

  const handleEmailAdd = () => {
    dispatch(billingActions.setContactEmails([...contactEmails, '']));
  };

  const handleToggleChange = () => {
    dispatch(billingActions.setSendBillingEmails(!sendBillingEmail));
  };

  const handleSave = () => {
    dispatch(billingActions.updateContactsRequest());
  };

  return (
    <div className="flex flex-wrap -mx-20px -my-8px">
      <div className="w-12 lg:w-6 px-20px py-8px flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.email}</div>

        <div className="flex-left-center gap-4px">
          <InputText className="flex-1" value={customerEmail} disabled />
          <div className="w-32px" />
        </div>

        {contactEmails.map((email, index) => {
          const disabled = adminEmails.includes(email || '');
          return (
            <div key={index} className="flex-left-center gap-4px">
              <InputText
                className="flex-1"
                value={email}
                disabled={disabled}
                onChange={(e) => handleEmailChange(index, e.target.value)}
              />
              {disabled ? (
                <div className="w-32px" />
              ) : (
                <Button className="action-button" onClick={() => handleEmailRemove(index)}>
                  <TrashIcon className="icon-16px" />
                </Button>
              )}
            </div>
          );
        })}

        {contactEmails.length < MAX_CONTACTS_AMOUNT && (
          <div className="flex-left-center gap-4px">
            <div className="flex-1 add-button-input" onClick={handleEmailAdd}>
              <PlusIcon className="icon-16px" />
            </div>
            <div className="w-32px" />
          </div>
        )}
      </div>

      <div className="w-12 lg:w-6 px-20px pt-32px pb-8px">
        <div className="flex-left-center gap-10px h-40px">
          <InputSwitch checked={sendBillingEmail} onChange={handleToggleChange} />
          <div className="text-label-s-med">{labels.receiveNotifications}</div>
        </div>
      </div>

      {(isEmailsUpdated || isSendBillingEmailUpdated) && (
        <div className="w-12 lg:w-6 px-20px py-8px">
          <Button label={labels.save} onClick={handleSave} disabled={isFetching} />
        </div>
      )}
    </div>
  );
};
