import { CanceledType, CreateUserEventInput } from '../../API';
import { GetBookedMeetingsPayload, MeetingFilterType, UpdateBookedMeeting } from './types';

export enum BookedMeetingsActionTypes {
  GET_SCHEDULED_MEETINGS_SUCCESS = 'scheduledMeetings/GET_SCHEDULED_MEETINGS_SUCCESS',
  GET_SCHEDULED_MEETINGS_REQUEST = 'scheduledMeetings/GET_SCHEDULED_MEETINGS_REQUEST',
  GET_SCHEDULED_MEETINGS_FAIL = 'scheduledMeetings/GET_SCHEDULED_MEETINGS_FAIL',
  SET_FILTER = 'scheduledMeetings/SET_FILTER',
  UPDATE_SCHEDULED_MEETING_REQUEST = 'scheduledMeetings/UPDATE_SCHEDULED_MEETING_REQUEST',
  UPDATE_SCHEDULED_MEETING_SUCCESS = 'scheduledMeetings/UPDATE_SCHEDULED_MEETING_SUCCESS',
  UPDATE_SCHEDULED_MEETING_FAIL = 'scheduledMeetings/UPDATE_SCHEDULED_MEETING_FAIL',
  SELECT_SCHEDULED_MEETING = 'scheduledMeetings/SELECT_SCHEDULED_MEETING',
  UPDATE_CANCEL_REASON = 'scheduledMeetings/UPDATE_CANCEL_REASON',
  UPDATE_CANCEL_NOTE = 'scheduledMeetings/UPDATE_CANCEL_NOTE',
  CANCEL_SCHEDULED_MEETING_REQUEST = 'scheduledMeetings/CANCEL_SCHEDULED_MEETING_REQUEST',
  CANCEL_SCHEDULED_MEETING_SUCCESS = 'scheduledMeetings/CANCEL_SCHEDULED_MEETING_SUCCESS',
  CANCEL_SCHEDULED_MEETING_FAIL = 'scheduledMeetings/CANCEL_SCHEDULED_MEETING_FAIL',
}

export type BookedMeetingsAction =
  | { type: BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST; payload: GetBookedMeetingsPayload }
  | { type: BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS; payload: CreateUserEventInput[] }
  | { type: BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL; error: unknown }
  | { type: BookedMeetingsActionTypes.SET_FILTER; payload: Partial<MeetingFilterType> }
  | { type: BookedMeetingsActionTypes.SELECT_SCHEDULED_MEETING; payload: CreateUserEventInput }
  | { type: BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST; payload: UpdateBookedMeeting }
  | { type: BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS; payload: CreateUserEventInput }
  | { type: BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL; error: unknown }
  | { type: BookedMeetingsActionTypes.UPDATE_CANCEL_REASON; reason: CanceledType }
  | { type: BookedMeetingsActionTypes.UPDATE_CANCEL_NOTE; note: string }
  | { type: BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST }
  | { type: BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_SUCCESS }
  | { type: BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL; error: string };

const getBookedMeetingsRequest = (payload: GetBookedMeetingsPayload): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_REQUEST,
  payload,
});
const getBookedMeetingsSuccess = (payload: CreateUserEventInput[]): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_SUCCESS,
  payload,
});
const getBookedMeetingsFail = (error: unknown): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.GET_SCHEDULED_MEETINGS_FAIL,
  error,
});
const setFilter = (payload: Partial<MeetingFilterType>): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.SET_FILTER,
  payload,
});
const selectBookedMeeting = (payload: CreateUserEventInput): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.SELECT_SCHEDULED_MEETING,
  payload,
});

const updateBookedMeetingRequest = (payload: UpdateBookedMeeting): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_REQUEST,
  payload,
});
const updateBookedMeetingSuccess = (payload: CreateUserEventInput): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_SUCCESS,
  payload,
});
const updateBookedMeetingFail = (error: unknown): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.UPDATE_SCHEDULED_MEETING_FAIL,
  error,
});
const updateCancelReason = (reason: CanceledType): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.UPDATE_CANCEL_REASON,
  reason,
});
const updateCancelNote = (note: string): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.UPDATE_CANCEL_NOTE,
  note,
});

const cancelBookedMeetingRequest = (): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_REQUEST,
});
const cancelBookedMeetingSuccess = (): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_SUCCESS,
});
const cancelBookedMeetingFail = (error: string): BookedMeetingsAction => ({
  type: BookedMeetingsActionTypes.CANCEL_SCHEDULED_MEETING_FAIL,
  error,
});

export const scheduledMeetingsActions = {
  updateCancelReason,
  getBookedMeetingsRequest,
  getBookedMeetingsSuccess,
  getBookedMeetingsFail,
  setFilter,
  updateBookedMeetingRequest,
  updateBookedMeetingSuccess,
  updateBookedMeetingFail,
  selectBookedMeeting,
  updateCancelNote,
  cancelBookedMeetingRequest,
  cancelBookedMeetingSuccess,
  cancelBookedMeetingFail,
};
