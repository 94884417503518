import { FunnelIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SelectItem } from 'primereact/selectitem';
import { ReactNode, useState } from 'react';
import { getInitials } from '../../../services/utils';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../../types/constants';
import { DropdownData, MultiselectData, MultiselectDataOption } from '../../../types/types';
import { Search } from '../search/Search';
import labels from './labels';

type SectionHeaderProps = {
  title: string;
  loading?: boolean;
  itemsLength?: number;
  searchPaths?: string[];
  buttonLabel?: string;
  buttonIcon?: React.ReactNode;
  buttonOutlined?: boolean;
  onButtonClick?: () => void;
  buttonNode?: ReactNode;
  dropdownfilters?: DropdownData[];
  filters?: MultiselectData[];
  onFilterReset?: () => void;
  isFilterActive?: boolean;
  hideButton?: boolean;
  extraContent?: ReactNode;
  customFilter?: ReactNode;
  hideBottomMargin?: boolean;
};
export const SectionHeader = ({
  title,
  loading,
  itemsLength,
  searchPaths,
  buttonLabel,
  buttonIcon,
  buttonOutlined,
  onButtonClick,
  buttonNode,
  dropdownfilters,
  filters,
  onFilterReset,
  isFilterActive,
  hideButton,
  extraContent,
  customFilter,
  hideBottomMargin,
}: SectionHeaderProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterOpen = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const getDropdownItemTemplate = (onClick: (item: SelectItem) => void) => {
    const itemTemplate = (item: SelectItem) => (
      <div className="-m-14px p-14px" style={{ width: 'calc(100% + 28px)' }} onClick={() => onClick(item)}>
        {item.label}
      </div>
    );
    itemTemplate.displayName = 'ItemTemplate';
    return itemTemplate;
  };

  const getDropdownValueTemplate = (options: SelectItem[], label: string | undefined, valueNode?: ReactNode) => {
    const itemTemplate = (item: SelectItem) => (
      <div className="flex-left-center gap-6px">
        <div>{label}</div>
        <div className="sumo-badge">{valueNode || options.find((option) => option.value == item.value)?.label}</div>
      </div>
    );
    itemTemplate.displayName = 'ItemTemplate';
    return itemTemplate;
  };

  const getItemTemplate = (option: MultiselectDataOption) => (
    <div className="flex-left-center gap-8px text-label-md-reg">
      <Avatar label={getInitials(option.label || '')} />
      <div className="flex-1">{option.label}</div>
      {option.displayId && (
        <div className="text-body-s-reg bg-heavy-10 border-radius-4px py-2px px-6px">{option.displayId}</div>
      )}
    </div>
  );

  const getSelectedItemTemplate = (selectedValues: (string | null)[], label: string | undefined) => {
    const itemTemplate = (value: string) =>
      !selectedValues.length || selectedValues[0] === value ? (
        <div className="flex-left-center gap-6px">
          <div>{label}</div>
          {!!selectedValues.length && <div className="sumo-badge">{selectedValues.length}</div>}
        </div>
      ) : (
        <></>
      );
    itemTemplate.displayName = 'ItemTemplate';
    return itemTemplate;
  };

  return (
    <>
      <div className={`flex ${hideBottomMargin ? '' : 'mb-24px'}`}>
        <div className="flex-1 flex">
          <div className="text-title-xl-med pt-12px">{title}</div>
          <div className="h-48px flex-left-center">
            {loading && <ProgressSpinner className="w-32px h-32px ml-16px" strokeWidth="5" />}
          </div>
        </div>
        <div className="flex gap-6px h-fit">
          {searchPaths && Boolean(itemsLength && itemsLength >= MIN_ITEMS_FOR_SEARCH) && (
            <Search placeholder={labels.search} paths={searchPaths} maxTextLength={MAX_LENGTH_NAME} collapsible />
          )}
          {Boolean(dropdownfilters?.length || filters?.length) && (
            <Button
              className={`flex-none button-xl button-icon-xl button-white ${isFilterActive ? 'selected' : ''}`}
              onClick={handleFilterOpen}
            >
              <FunnelIcon className="icon-24px" />
            </Button>
          )}
          {extraContent}
          {!hideButton &&
            (buttonNode || (
              <Button className="flex-none button-xl" outlined={buttonOutlined} onClick={onButtonClick}>
                {buttonIcon || <PlusIcon className="icon-18px" />}
                <span className="flex-1 pl-8px">{buttonLabel}</span>
              </Button>
            ))}
        </div>
      </div>
      {isFilterOpen && (
        <div className="flex-left-center flex-wrap gap-12px sumo-card border-radius-10px py-8px px-20px mb-20px sumo-filter">
          {customFilter}
          {dropdownfilters?.map((filter) => (
            <Dropdown
              key={filter.label}
              options={filter.options}
              value={filter.value}
              onChange={filter.onChange}
              filter={filter.options.length >= MIN_ITEMS_FOR_SEARCH}
              itemTemplate={filter.onValueClick ? getDropdownItemTemplate(filter.onValueClick) : undefined}
              valueTemplate={getDropdownValueTemplate(filter.options, filter.label, filter.valueLabel)}
              pt={filter.pt}
            />
          ))}
          {filters?.map((filter) => (
            <MultiSelect
              key={filter.label}
              options={filter.options}
              value={filter.value}
              onChange={filter.onChange}
              filter={filter.options.length >= MIN_ITEMS_FOR_SEARCH}
              itemTemplate={getItemTemplate}
              selectedItemTemplate={getSelectedItemTemplate(filter.value, filter.label)}
              disabled={filter.disabled}
            />
          ))}
          {isFilterActive && (
            <div className="flex-left-center gap-12px">
              <div className="mx-4px border-left-1 border-heavy-20 h-20px" />
              <Button className="gap-8px py-4px pl-8px pr-12px border-radius-4px" text onClick={onFilterReset}>
                <XMarkIcon className="icon-20px" />
                <div>{labels.clearFilters}</div>
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
