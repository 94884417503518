import { t } from '../../../i18n/i18n';

export default {
  active: t('Common:ACTIVE'),
  cancel: t('Common:CANCEL'),
  cancelSubscription: t('Billing:CANCEL_SUBSCRIPTION'),
  cancellationText: t('Billing:CANCELLATION_TEXT'),
  cancellationDescription: t('Billing:CANCELLATION_DESCRIPTION'),
  change: t('Billing:CHANGE'),
  days: t('Billing:DAYS'),
  nextInvoiceDue: t('Billing:NEXT_INVOICE_DUE'),
  noCancel: t('Common:NO_CANCEL'),
  planSilver: t('Billing:PLAN_SILVER'),
  planTrial: t('Billing:PLAN_TRIAL'),
  reactivate: t('Billing:REACTIVATE'),
  scheduledChanges: t('Billing:SCHEDULED_CHANGES'),
  scheduledChanges1Label: t('Billing:SCHEDULED_SEATS_CHANGE_PART_1'),
  scheduledChanges2Label: t('Billing:SCHEDULED_SEATS_CHANGE_PART_2'),
  scheduledColumnChangeBy: t('Billing:SCHEDULED_COLUMN_CHANGE_BY'),
  scheduledColumnDate: t('Billing:SCHEDULED_COLUMN_DATE'),
  scheduledColumnLabel: t('Billing:SCHEDULED_COLUMN_LABEL'),
  seats: t('Billing:SEATS'),
  subscription: t('Billing:SUBSCRIPTION'),
  suspended: t('Common:SUSPENDED'),
  teamsAnnual: t('Billing:TERM_ANNUAL'),
  teamsMonthly: t('Billing:TERM_MONTHLY'),
  term: t('Billing:TERM'),
  termChangeLabel: t('Billing:SCHEDULED_TERM_CHANGE'),
  users: t('Billing:USERS'),
  usersOf: t('Billing:USERS_OF'),
  yesDelete: t('Common:YES_DELETE'),
  yourPlan: t('Billing:YOUR_PLAN'),
};
