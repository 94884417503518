import { checkTimeOverlapping, checkTimeWrongOrder, formatDateHHMM } from '../../../services/DateService';
import { ModifyWeeklyHours } from '../../../types/types';
import {
  availabilityActions,
  availabilitySelectors,
  DEFAULT_AVAILABILITY_TIME,
  WEEKDAY_LABEL_BY_TYPE,
} from '../../../store/availability';
import { TimeFormat } from '../../../store/userSettings';
import labels from './labels';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsSelectors } from '../../../store/userSettings';
import { BookingPageAndTemplateOption, bookingPageSelectors } from '../../../store/bookingPages';
import { InputSwitch } from 'primereact/inputswitch';
import { ErrorText } from '../../common/errorText/ErrorText';
import { AvailabilityTimeIntervals } from '../availabilityTimeIntervals/AvailabilityTimeIntervals';
import { MultiSelectWithChips } from '../../common';
import { WeeklyHours } from '../../../generated-sources/internal-api/models/WeeklyHours';
import { WeekDay } from '../../../generated-sources/internal-api/models/WeekDay';
import { FromTo } from '../../../generated-sources/internal-api/models/FromTo';
import { WeekDay as WeekDayEnum } from '../../../API';
import { authenticationSelectors, Feature } from '../../../store/authentication';
import { LocationAvailabilityOption, locationsSelectors } from '../../../store/locations';
import { Dropdown } from 'primereact/dropdown';
import { bookingTemplatesSelectors } from '../../../store/bookingTemplates';

export const AvailabilityTable = () => {
  const dispatch = useDispatch();
  const weeklyHours = useSelector(availabilitySelectors.selectWeeklyHours);
  const timeFormat = useSelector(userSettingsSelectors.selectTimeFormat) || TimeFormat.default;
  const activeBookingPages = useSelector(availabilitySelectors.selectBookingPages);
  const activeBookingTemplates = useSelector(availabilitySelectors.selectBookingTemplates);
  const bookingPages = useSelector(bookingPageSelectors.selectBookingPagesOptions);
  const bookingTemplates = useSelector(bookingTemplatesSelectors.selectBookingTemplates);
  const bookingPageIds = useSelector(bookingPageSelectors.selectBookingPageIds);
  const isEditAvail = useSelector(userSettingsSelectors.selectAvailabilityEdit);
  const isEnableLocationsAvailability = useSelector(
    authenticationSelectors.selectIsEnabledFeature(Feature.LOCATIONS_AVAILABILITY)
  );
  const locations = useSelector(locationsSelectors.selectLocations);
  const availabilityLocations = useSelector(availabilitySelectors.selectLocations);
  const isDefault = useSelector(availabilitySelectors.selectIsDefault);

  const handleLocationChange = (value: string) => {
    dispatch(availabilityActions.updateAvailability({ locations: value ? [value] : [] }));
  };

  const modifyWeeklyHours = (type: WeekDay, callback: ModifyWeeklyHours) => {
    dispatch(
      availabilityActions.updateAvailability({
        weeklyHours: (weeklyHours.map((day: WeeklyHours | null) => (day?.type === type ? callback(day) : day)) ||
          []) as WeeklyHours[],
      })
    );
  };

  const handleInputSwitch = (type: WeekDay, value: boolean) => {
    modifyWeeklyHours(type, (day: WeeklyHours | null): WeeklyHours | null => {
      if (!day) return null;

      return {
        ...day,
        time: value ? [DEFAULT_AVAILABILITY_TIME] : [],
      };
    });
  };

  const handleTimeFromChange = (type: WeekDay, index: number, value: Date) => {
    modifyWeeklyHours(type, (day: WeeklyHours | null): WeeklyHours | null => {
      if (!day) return null;

      return {
        ...day,
        time:
          day.time?.map((time: FromTo | null, i: number) => {
            if (!time) return {};
            return i === index ? { ...time, from: formatDateHHMM(value, TimeFormat.military) } : time;
          }) || [],
      };
    });
  };

  const handleTimeToChange = (type: WeekDay, index: number, value: Date) => {
    modifyWeeklyHours(type, (day: WeeklyHours | null): WeeklyHours | null => {
      if (!day) return null;
      return {
        ...day,
        time:
          day.time?.map((time: FromTo | null, i: number) => {
            if (!time) return {};
            return i === index ? { ...time, to: formatDateHHMM(value, TimeFormat.military) } : time;
          }) || [],
      };
    });
  };

  const addInterval = (type: WeekDay) => {
    modifyWeeklyHours(type, (day: WeeklyHours | null): WeeklyHours | null => {
      if (!day) return null;

      return {
        ...day,
        time: [...(day.time || []), DEFAULT_AVAILABILITY_TIME],
      };
    });
  };

  const removeInterval = (type: WeekDay, index: number) => {
    modifyWeeklyHours(type, (day: WeeklyHours | null): WeeklyHours | null => {
      if (!day) return null;

      return {
        ...day,
        time: day.time?.filter((_: FromTo | null, i: number) => i !== index) || [],
      };
    });
  };

  // Booking page and template selector
  const itemTemplate = (option: any) => {
    return (
      <div className="w-16rem flex justify-content-between gap-1">
        <div className="flex-1 overflow-hidden text-overflow-ellipsis">{option.label}</div>
        <div className="bg-gray-200 px-2 border-round">{option.displayId}</div>
      </div>
    );
  };

  const handleBookingPageChange = (value: string[]) => {
    const bookingPageIds = value.filter((id) => id.startsWith('BP:')).map((item) => item.replace(/^BP:/, ''));
    const bookingTemplateIds = value.filter((id) => id.startsWith('BT:')).map((item) => item.replace(/^BT:/, ''));

    dispatch(
      availabilityActions.updateAvailability({
        bookingPages: bookingPageIds,
        bookingTemplates: bookingTemplateIds,
      })
    );
  };

  const onRemoveBookingPage = (value: string) => {
    const id = value.replace(/^(BT:|BP:)/, '');

    dispatch(
      availabilityActions.updateAvailability({
        bookingPages: activeBookingPages?.filter((bp) => bp !== id),
        bookingTemplates: activeBookingTemplates?.filter((bt) => bt !== id),
      })
    );
  };

  const selectedItemContent = (value: string) => {
    let label: string | undefined;

    if (value.startsWith('BP:')) {
      const option = bookingPages.find((bp) => bp.value === value.replace(/^BP:/, ''));
      label = `${option?.label || ''} ${option?.displayId || ''}`;
    } else if (value.startsWith('BT:')) {
      const option = bookingTemplates.find((bt) => bt.id === value.replace(/^BT:/, ''));
      label = option?.what?.customName || '';
    }

    return label;
  };

  const bookingOptions = (): BookingPageAndTemplateOption[] => {
    const response = [{
      label: labels.bookingPages,
      code: 'BP',
      items: bookingPages.map((item) => ({
        ...item,
        value: `BP:${item.value}`,
      })),
    }];

    if (isEnableLocationsAvailability) {
      response.push({
        label: labels.bookingTemplates,
        code: 'BT',
        items: bookingTemplates.map((item) => ({
          label: item.what?.customName || '',
          value: `BT:${item.id}`,
        })),
      });
    }

    return response;
  };

  const currentBookingValues = (): string[] => {
    const values: string[] = [];

    bookingPageIds.forEach((id) => {
      if (activeBookingPages?.includes(id)) {
        values.push(`BP:${id}`);
      }
    });

    bookingTemplates.forEach((item) => {
      if (activeBookingTemplates?.includes(item.id)) {
        values.push(`BT:${item.id}`);
      }
    });

    return values;
  };

  const locationOptions = () => {
    const options: LocationAvailabilityOption[] = [];

    locations.forEach((location) => {
      const search = [
        location?.name,
        location.address?.name,
        location.address?.city,
        location.address?.zip,
        location.address?.country,
      ].filter((item) => item) as string[];

      options.push({
        label: location?.name || '',
        description: `${location.address?.name && location.address?.name + ', '} ${
          location.address?.zip && location.address?.zip + ', '
        } ${location.address?.country && location.address?.country}`.trim(),
        value: location.id,
        search,
      } as LocationAvailabilityOption);
    });

    return options;
  };

  const locationDropdownTemplate = ({ label, description }: { label: string; description: string }) => {
    return (
      <div className="p-d-flex p-flex-column">
        <div className="text-label-md-reg">{label}</div>
        <div className="text-label-xs-reg">{description}</div>
      </div>
    );
  };

  return (
    <div className="grid justify-content-between p-20px -mb-2">
      <div className="col-12 lg:col-5">
        <div className="text-title-s-med text-heavy-100">{labels.availableHours}</div>
        <div className="mt-30px flex flex-column">
          {weeklyHours.map((day, idx, arr) => (
            <div
              key={day?.type}
              className={`flex flex-column gap-10px py-8px ${
                idx !== arr.length - 1 ? 'border-bottom-1 border-heavy-10' : ''
              }`}
            >
              <div className="flex flex-row align-items-start justify-content-between gap-10px">
                <div className="flex align-items-center my-6px">
                  <InputSwitch
                    checked={!!day?.time?.length}
                    className="size-small"
                    onChange={(e) => handleInputSwitch(day?.type as WeekDay, !!e.value)}
                    disabled={!isEditAvail}
                  />
                  <div className="ml-12px text-title-xs-med">
                    {WEEKDAY_LABEL_BY_TYPE.get((day?.type as WeekDayEnum) || WeekDayEnum.MON)}
                  </div>
                </div>
                <AvailabilityTimeIntervals
                  time={day?.time}
                  isReadonly={!isEditAvail}
                  timeFormat={timeFormat}
                  addInterval={() => addInterval(day?.type as WeekDay)}
                  removeInterval={(index: number) => removeInterval(day?.type as WeekDay, index)}
                  handleTimeFromChange={(index: number, value: Date) =>
                    handleTimeFromChange(day?.type as WeekDay, index, value)
                  }
                  handleTimeToChange={(index: number, value: Date) =>
                    handleTimeToChange(day?.type as WeekDay, index, value)
                  }
                  isFirstIntervalRemovable={true}
                />
              </div>
              {day?.time && (checkTimeWrongOrder(day?.time) || checkTimeOverlapping(day?.time)) && (
                <div className="flex flex-column gap-2px">
                  {checkTimeWrongOrder(day?.time) && <ErrorText text={labels.timeInValidText} />}
                  {checkTimeOverlapping(day?.time) && <ErrorText text={labels.timeOverlappingText} />}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="col-12 lg:col-5">
        <div className="text-title-s-med text-heavy-100 mb-10px">{labels.activeOn}</div>
        <MultiSelectWithChips<BookingPageAndTemplateOption>
          value={currentBookingValues()}
          options={bookingOptions()}
          onChange={(e) => handleBookingPageChange(e.value)}
          onRemove={onRemoveBookingPage}
          placeholder={labels.activeOnPlaceholder}
          disabled={!isEditAvail || isDefault}
          className="w-full multiselect-wrap"
          itemTemplate={itemTemplate}
          selectedItemContent={selectedItemContent}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
        />

        {isEnableLocationsAvailability && (
          <>
            <div className="text-title-s-med text-heavy-100 mb-10px mt-20px">{labels.location}</div>
            <Dropdown
              value={locations?.find((item) => availabilityLocations?.includes(item.id))?.id}
              onChange={(e) => handleLocationChange(e.target.value as string)}
              options={locationOptions()}
              optionLabel="label"
              optionValue="value"
              className={`p-multiselect-chip w-full multiselect-wrap`}
              itemTemplate={locationDropdownTemplate}
              showClear
              filter
              filterBy="search"
              placeholder={labels.selectLocation}
              disabled={!isEditAvail || isDefault}
            />
          </>
        )}
      </div>
    </div>
  );
};
