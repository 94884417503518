import { AppType, IntegrationType, WorkspaceIntegrationType } from '../../../API';
import { t } from '../../../i18n/i18n';

export default {
  connect: t('Common:CONNECT'),
  update: t('Common:UPDATE'),
  disconnect: t('Common:DISCONNECT'),
  benefitsTitle: t('ConnectIntegrationPage:BENEFITS_TITLE'),
  requirementsTitle: t('ConnectIntegrationPage:REQUIREMENTS_TITLE'),
  benefitsDesc: {
    [IntegrationType.GOOGLE_CALENDAR]: t('ConnectIntegrationPage:GOOGLE_CALENDAR_BENEFITS'),
    [IntegrationType.GOOGLE_MEET]: t('ConnectIntegrationPage:GOOGLE_MEET_BENEFITS'),
    [IntegrationType.MICROSOFT_CALENDAR]: t('ConnectIntegrationPage:MICROSOFT_CALENDAR_BENEFITS'),
    [IntegrationType.MICROSOFT_TEAMS]: t('ConnectIntegrationPage:MICROSOFT_TEAMS_BENEFITS'),
    [IntegrationType.ZOOM]: t('ConnectIntegrationPage:ZOOM_BENEFITS'),
    [WorkspaceIntegrationType.HUBSPOT]: t('ConnectIntegrationPage:HUBSPOT_BENEFITS'),
    [WorkspaceIntegrationType.SALESFORCE]: t('ConnectIntegrationPage:SALESFORCE_BENEFITS'),
    [AppType.INBOX]: t('ConnectIntegrationPage:INBOX_BENEFITS'),
    [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_BENEFITS'),
    [WorkspaceIntegrationType.GOOGLE_MAPS]: t('ConnectIntegrationPage:GOOGLE_MAPS_BENEFITS'),
  },
  requirementsDesc: {
    [IntegrationType.GOOGLE_CALENDAR]: t('ConnectIntegrationPage:GOOGLE_CALENDAR_REQUIREMENTS'),
    [IntegrationType.GOOGLE_MEET]: t('ConnectIntegrationPage:GOOGLE_MEET_REQUIREMENTS'),
    [IntegrationType.MICROSOFT_CALENDAR]: t('ConnectIntegrationPage:MICROSOFT_CALENDAR_REQUIREMENTS'),
    [IntegrationType.MICROSOFT_TEAMS]: t('ConnectIntegrationPage:MICROSOFT_TEAMS_REQUIREMENTS'),
    [IntegrationType.ZOOM]: t('ConnectIntegrationPage:ZOOM_REQUIREMENTS'),
    [WorkspaceIntegrationType.HUBSPOT]: t('ConnectIntegrationPage:HUBSPOT_REQUIREMENTS'),
    [WorkspaceIntegrationType.SALESFORCE]: t('ConnectIntegrationPage:SALESFORCE_REQUIREMENTS'),
    [AppType.INBOX]: t('ConnectIntegrationPage:INBOX_REQUIREMENTS'),
    [WorkspaceIntegrationType.GOOGLE_ANALYTICS]: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_REQUIREMENTS'),
    [WorkspaceIntegrationType.GOOGLE_MAPS]: t('ConnectIntegrationPage:GOOGLE_MAPS_REQUIREMENTS'),
  },
};
