export const EditTeam = Object.freeze({
  AVAILABLE_FOR_USE: `Verfügbar für Verwendung`,
  AVAILABLE_FOR_USE_TOOLTIP: `Dies aktiviert oder deaktiviert dieses Team für alle Buchungstools, einschließlich Buchungsseiten, Buchungsvorlagen und mehr.`,
  EDIT_TEAM: `Team bearbeiten`,
  MEMBERS_LABEL: `Teammitglieder hinzufügen`,
  MEMBERS_PLACEHOLDER: `Suchen & Teammitglied hinzufügen`,
  ROUND_ROBIN_DESC_PART1: `Round-Robin`,
  ROUND_ROBIN_DESC_PART2: `Damit SUMO1 automatisch einen Host für ein Meeting zuweist, müssen Sie ein Team (auch Ressourcenpool genannt) von SUMO1-Benutzern erstellen. Dann können Benutzer und Administratoren Teams über Buchungsseiten oder SUMO Meeting Booker (Chrome-Erweiterung) zu Meetings hinzufügen.`,
  TEAM_NAME: `Name anzeigen`,
  TEAM_NAME_TOOLTIP: `Dieser Text wird den Eingeladenen auf der öffentlichen Buchungsseite angezeigt. Zum Beispiel: „Team der Vertriebsingenieure" oder „Team der Zahnärzte" wären gute Namen.`,
  TEAM_NAME_PLACEHOLDER: `Teamnamen eingeben`,
  WORKSPACES: `Arbeitsbereiche`,
  WORKSPACES_LABEL: `Standardarbeitsbereich`,
  WORKSPACES_PLACEHOLDER: `Suchen & Arbeitsbereich hinzufügen`,
  DEFAULT_WORKSPACE_TOOLTIP: `Arbeitsbereich, in dem das Team erstellt wurde und der geändert oder gelöscht werden kann.`,
});

export const TeamsToastsNotifications = Object.freeze({
  ACTIVATE_TEAM_SUCCESS_MESSAGE: `Team erfolgreich aktiviert`,
  DEACTIVATE_ACTIVATE_TEAM_ERROR_MESSAGE: `Deaktivieren/Aktivieren des Teams fehlgeschlagen`,
  DEACTIVATE_TEAM_SUCCESS_MESSAGE: `Team erfolgreich deaktiviert`,
  DELETE_TEAM_ERROR_MESSAGE: `Löschen des Teams fehlgeschlagen`,
  DELETE_TEAM_SUCCESS_MESSAGE: `Team erfolgreich gelöscht`,
  GET_TEAMS_FAIL_MESSAGE: `Abrufen der Teams fehlgeschlagen`,
  SAVE_TEAM_ERROR_MESSAGE: `Speichern des Teams fehlgeschlagen`,
  SAVE_TEAM_SUCCESS_MESSAGE: `Team erfolgreich gespeichert`,
});

export const Teams = Object.freeze({
  CREATE_TEAM: `Team erstellen`,
  CREATED_BY: `Erstellt von`,
  CREATED_DATE: `Erstellungsdatum`,
  STATUS: `Status`,
  ACTIVE: `Aktiv`,
  INACTIVE: `Inaktiv`,
  DEACTIVATE_TEAM_DESCRIPTION: `Sind Sie sicher, dass Sie deaktivieren möchten`,
  DEACTIVATE_TEAM_TITLE: `Team deaktivieren`,
  DELETE_TEAM_DESCRIPTION: `Sind Sie sicher, dass Sie löschen möchten`,
  DELETE_TEAM_TITLE: `Team löschen`,
  MEMBERS: `Mitglieder`,
  NAME: `Name`,
  TITLE: `Teams`,
  TOOLTIP: `Teams sind wichtig, um festzulegen, ob SUMO automatisch einen Meeting-Host oder Co-Host zuweisen soll, basierend auf Zuweisungslogik (Round-Robin usw.).`,
  WORKSPACE: `Arbeitsbereich`,
});
