import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { ActionType, AppType, IntegrationType, PermissionAction, WorkspaceIntegrationType } from '../../API';
import i18n from '../../i18n/i18n';
import { countriesTimeZonesService } from '../../services/CountriesTimeZoneService';
import { isOldData } from '../../services/utils';
import { adminRoles, CURRENT_TIME_ZONE, TIME_ZONE_OPTIONS } from '../../types/constants';
import { ThirdPartySettings } from '../authentication';
import { SubscribeCustomerInfo } from '../billing';
import { FULL_PERMISSION_LIST, rolesSelectors } from '../roles';
import { State } from '../rootStore';
import { teamsSelectors } from '../teams';
import { workspacesSelectors } from '../workspaces';
import { TimeFormat } from './types';

const userSettingsState = (state: State) => state.userSettings;

const selectIsFetching = createSelector(userSettingsState, (userSettings) => userSettings.isFetching);
const selectError = createSelector(userSettingsState, (userSettings) => userSettings.error); // TODO: start hadling settings.error
const selectIsMenuOpened = createSelector(userSettingsState, (userSettings) => userSettings.isMenuOpened);
const selectUserSettings = createSelector(userSettingsState, (userSettings) => userSettings.userSettings || {});

const selectLastLoadTime = createSelector(userSettingsState, (state) => state.lastLoadTime);
const selectIsSpinnerFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && !isOldData(loadTime)
);
const selectIsSkeletonFetching = createSelector(
  selectIsFetching,
  selectLastLoadTime,
  (isFetching, loadTime) => isFetching && isOldData(loadTime)
);

const selectAvatar = createSelector(selectUserSettings, (userSettings) => userSettings.avatar);
const selectCountry = createSelector(selectUserSettings, (userSettings) => userSettings.country);
const selectCountryCode = createSelector(selectUserSettings, (userSettings) => userSettings.countryCode);
const selectEmail = createSelector(selectUserSettings, (userSettings) => userSettings.email);
const selectFullName = createSelector(selectUserSettings, (userSettings) => userSettings.fullName);
const selectFirstName = createSelector(selectFullName, (fullName) => fullName?.split(' ')[0]);
const selectLastName = createSelector(selectFullName, selectFirstName, (fullName, firstName) =>
  fullName?.substring(firstName?.length || 0)
);
const selectNameOrEmail = createSelector(selectEmail, selectFullName, (email, name) => name || email);
const selectIntegrations = createSelector(selectUserSettings, (userSettings) => userSettings.integrations);
const selectLanguage = createSelector(selectUserSettings, (userSettings) => userSettings.language);
const selectPhoneNumber = createSelector(selectUserSettings, (userSettings) => userSettings.phoneNumber);
const selectDateFormat = createSelector(selectUserSettings, (userSettings) => userSettings.dateFormat);
const selectTimeFormat = createSelector(selectUserSettings, (userSettings) => userSettings.timeFormat);
const selectTimeZone = createSelector(selectUserSettings, (userSettings) => userSettings.timeZone);
const selectPhoneDetails = createSelector(selectUserSettings, (userSettings) => userSettings.phoneDetails);
const selectDefaultVideoIntegration = createSelector(
  selectUserSettings,
  (userSettings) => userSettings.defaultVideoIntegration
);

const selectPhoneInfo = createSelector(selectCountryCode, selectPhoneNumber, (countryCode, phoneNumber) => ({
  countryCode,
  phoneNumber,
}));
const selectLogin = createSelector(selectEmail, (email) => ({ email }));

const selectIsGoogleCalendarConnected = createSelector(
  selectIntegrations,
  (integrations) => integrations?.some((integration) => integration?.type === IntegrationType.GOOGLE_CALENDAR) || false
);
const selectIsMicrosoftCalendarConnected = createSelector(
  selectIntegrations,
  (integrations) =>
    integrations?.some((integration) => integration?.type === IntegrationType.MICROSOFT_CALENDAR) || false
);
const selectIsZoomConnected = createSelector(
  selectIntegrations,
  (integrations) => integrations?.some((integration) => integration?.type === IntegrationType.ZOOM) || false
);

const selectConnectedEmail = createSelector(selectIntegrations, (integrations) => {
  const connectedCalendar = integrations?.find(
    (integration) =>
      integration && [IntegrationType.MICROSOFT_CALENDAR, IntegrationType.GOOGLE_CALENDAR].includes(integration?.type)
  );
  if (connectedCalendar && connectedCalendar.settings) {
    const settings = connectedCalendar.settings as unknown as ThirdPartySettings;
    return settings.email ? settings.email : '';
  }
  return '';
});

const selectIsIntegrationConnected = (integrationType: IntegrationType | WorkspaceIntegrationType | AppType) =>
  createSelector(
    selectIntegrations,
    (integrations) => integrations?.some((integration) => integration?.type === integrationType) || false
  );

const selectUserDetails = createSelector(userSettingsState, (userSettings) => userSettings.details);
// const selectUserRoleId = createSelector(selectUserDetails, (userDetails) => userDetails.loggedRole); // TODO: keep roleId only in permissions
const selectUserWorkspaces = createSelector(selectUserDetails, (userDetails) => userDetails.workspaceIds);
const selectUserPermissions = createSelector(userSettingsState, (userSettings) => userSettings.permissions);
const selectUserRoleName = createSelector(selectUserPermissions, (role) => role.name);
const selectUserRoleId = createSelector(selectUserPermissions, (role) => role.id);
const selectIsFirstTenantUser = createSelector(selectUserDetails, (userDetails) => userDetails.isFirstTenantUser);
const selectIsRoleLoaded = createSelector(selectUserRoleId, (role) => !!role);
const selectIsStandardRole = createSelector(selectUserRoleName, (role) => role === adminRoles.standardUser);
const selectIsAdminRole = createSelector(
  selectUserRoleName,
  (role) =>
    (role && (role === adminRoles.superAdmin || role === adminRoles.workspaceAdmin || role === adminRoles.sumoAdmin)) ||
    false
);
const selectIsSuperAdmin = createSelector(
  selectUserPermissions,
  (permissions) => permissions.isAccessToAllWorkspaces || false
);

const selectIsQuickSetupFinished = createSelector(
  selectUserSettings,
  (userSettings) => userSettings.isQuickSetupFinished
);
const selectIsGuideTourFinished = createSelector(
  selectUserSettings,
  (userSettings) => userSettings.isGuideTourFinished
);
const selectIsInvitePeopleClosed = createSelector(
  selectUserSettings,
  (userSettings) => userSettings.isInvitePeopleClosed
);
const selectIsInstallInboxClosed = createSelector(
  selectUserSettings,
  (userSettings) => userSettings.isInstallInboxClosed
);
const selectCalendarWarningDate = createSelector(
  selectUserSettings,
  (userSettings) => userSettings.calendarWarningDate
);
const selectIsCalendarWarning = createSelector(
  selectCalendarWarningDate,
  selectIsGoogleCalendarConnected,
  selectIsMicrosoftCalendarConnected,
  (warningDate, isGoogleConnected, isMicrosoftConnected) =>
    !isGoogleConnected && !isMicrosoftConnected && (!warningDate || dayjs().diff(warningDate, 'day') >= 7)
);
const selectIsUserSettingsReceived = createSelector(
  userSettingsState,
  (userSettings) => userSettings.userSettingsReceived
);

const selectUTC = createSelector(userSettingsState, (userSettings) => userSettings.UTC);
const selectUserTimeZoneWithTime = createSelector(
  selectUTC,
  selectTimeZone,
  selectTimeFormat,
  (UTC, timeZone, timeFormat) =>
    `${timeZone} - ${dayjs(UTC)
      .tz(timeZone || CURRENT_TIME_ZONE)
      .format(timeFormat !== TimeFormat.default ? 'HH:mm' : 'hh:mm A')}`
);
const selectGroupedTimeZoneLits = createSelector(selectUTC, selectTimeFormat, (UTC, timeFormat) =>
  TIME_ZONE_OPTIONS.map((gropedTimeZone) => ({
    ...gropedTimeZone,
    zones: gropedTimeZone.zones.map((timeZone) => ({
      ...timeZone,
      time: dayjs(UTC)
        .tz(timeZone.timeZone)
        .format(timeFormat !== TimeFormat.default ? 'HH:mm' : 'hh:mm A'),
    })),
  }))
);

const selectTimeZoneCity = createSelector(selectTimeZone, (timeZone) => timeZone?.split('/')[1] || '');
const selectTimeZoneUTC = createSelector(selectTimeZone, (timeZone) => {
  const offset = countriesTimeZonesService.getTimezoneByName(timeZone || CURRENT_TIME_ZONE)?.utcOffset || 0;
  return `UTC ${offset >= 0 ? '+' : ''}${offset / 60}`;
});

const selectPermissionsUsers = createSelector(selectUserPermissions, (permissions) => permissions.users || []);
const selectUsersRead = createSelector(selectPermissionsUsers, (users) => users.includes(PermissionAction.READ));
const selectUsersAdd = createSelector(selectPermissionsUsers, (users) => users.includes(PermissionAction.CREATE));
const selectUsersEdit = createSelector(selectPermissionsUsers, (users) => users.includes(PermissionAction.EDIT));
const selectUsersDelete = createSelector(selectPermissionsUsers, (users) => users.includes(PermissionAction.DELETE));
const selectPermissionsAvailability = createSelector(
  selectUserPermissions,
  (permissions) => permissions.availability || []
);
const selectAvailabilityEdit = createSelector(selectPermissionsAvailability, (availability) =>
  availability.includes(PermissionAction.EDIT)
);
const selectAvailabilityCreate = createSelector(selectPermissionsAvailability, (availability) =>
  availability.includes(PermissionAction.CREATE)
);
const selectAvailabilityDelete = createSelector(selectPermissionsAvailability, (availability) =>
  availability.includes(PermissionAction.DELETE)
);
const selectPermissionsMyBookingPages = createSelector(
  selectUserPermissions,
  (permissions) => permissions.myBookingPages || []
);
const selectMyBookingPagesRead = createSelector(selectPermissionsMyBookingPages, (myBookingPages) =>
  myBookingPages.includes(PermissionAction.READ)
);
const selectMyBookingPagesCreate = createSelector(selectPermissionsMyBookingPages, (myBookingPages) =>
  myBookingPages.includes(PermissionAction.CREATE)
);
const selectMyBookingPagesEdit = createSelector(selectPermissionsMyBookingPages, (myBookingPages) =>
  myBookingPages.includes(PermissionAction.EDIT)
);
const selectMyBookingPagesDelete = createSelector(selectPermissionsMyBookingPages, (myBookingPages) =>
  myBookingPages.includes(PermissionAction.DELETE)
);
const selectMyBookingPagesReadOnly = createSelector(
  selectMyBookingPagesRead,
  selectMyBookingPagesEdit,
  (read, edit) => read && !edit
);

const selectPermissionsAllBookingPages = createSelector(
  selectUserPermissions,
  (permissions) => permissions.allBookingPages || []
);
const selectAllBookingPagesRead = createSelector(selectPermissionsAllBookingPages, (allBookingPages) =>
  allBookingPages.includes(PermissionAction.READ)
);
const selectAllBookingPagesCreate = createSelector(selectPermissionsAllBookingPages, (allBookingPages) =>
  allBookingPages.includes(PermissionAction.CREATE)
);
const selectAllBookingPagesEdit = createSelector(selectPermissionsAllBookingPages, (allBookingPages) =>
  allBookingPages.includes(PermissionAction.EDIT)
);
const selectAllBookingPagesDelete = createSelector(selectPermissionsAllBookingPages, (allBookingPages) =>
  allBookingPages.includes(PermissionAction.DELETE)
);
const selectAllBookingPagesReadOnly = createSelector(
  selectAllBookingPagesRead,
  selectAllBookingPagesEdit,
  (read, edit) => read && !edit
);

const selectIsCreateOnlyMyBookingPages = createSelector(
  selectMyBookingPagesCreate,
  selectAllBookingPagesCreate,
  (myCreate, allCreate) => myCreate && !allCreate
);

const selectIsEditOnlyMyBookingPages = createSelector(
  selectMyBookingPagesEdit,
  selectAllBookingPagesEdit,
  (myEdit, allEdit) => myEdit && !allEdit
);

const selectPermissionsBookingTemplates = createSelector(
  selectUserPermissions,
  (permissions) => permissions.bookingTemplates || []
);
const selectBookingTemplatesRead = createSelector(selectPermissionsBookingTemplates, (bookingTemplates) =>
  bookingTemplates.includes(PermissionAction.READ)
);
const selectBookingTemplatesCreate = createSelector(selectPermissionsBookingTemplates, (bookingTemplates) =>
  bookingTemplates.includes(PermissionAction.CREATE)
);
const selectBookingTemplatesEdit = createSelector(selectPermissionsBookingTemplates, (bookingTemplates) =>
  bookingTemplates.includes(PermissionAction.EDIT)
);
const selectBookingTemplatesDelete = createSelector(selectPermissionsBookingTemplates, (bookingTemplates) =>
  bookingTemplates.includes(PermissionAction.DELETE)
);
const selectBookingTemplatesReadOnly = createSelector(
  selectBookingTemplatesRead,
  selectBookingTemplatesEdit,
  (read, edit) => read && !edit
);

const selectPermissionsResources = createSelector(selectUserPermissions, (permissions) => permissions.resources || []);
const selectPermissionsWorkspaces = createSelector(
  selectUserPermissions,
  (permissions) => permissions.workspaceAdminActions || []
);
const selectWorkspacesRead = createSelector(selectPermissionsWorkspaces, (workspaces) =>
  workspaces.includes(PermissionAction.READ)
);
const selectWorkspacesCreate = createSelector(selectPermissionsWorkspaces, (workspaces) =>
  workspaces.includes(PermissionAction.CREATE)
);
const selectWorkspacesEdit = createSelector(selectPermissionsWorkspaces, (workspaces) =>
  workspaces.includes(PermissionAction.EDIT)
);
const selectWorkspacesDelete = createSelector(selectPermissionsWorkspaces, (workspaces) =>
  workspaces.includes(PermissionAction.DELETE)
);
const selectIsReadOnlyWorkspace = createSelector(
  workspacesSelectors.selectIsExistingWorkspace,
  selectWorkspacesRead,
  selectWorkspacesCreate,
  selectWorkspacesEdit,
  (isExisting, read, create, edit) => (isExisting ? read && !edit : read && !create)
);
const selectIsWorkspaceOrSuperAdmin = createSelector(
  selectWorkspacesRead,
  selectWorkspacesCreate,
  selectWorkspacesDelete,
  selectWorkspacesEdit,
  (isRead, isCreate, isDelete, isEdit) => isRead && isCreate && isDelete && isEdit
);

const selectPermissionsTeams = createSelector(selectUserPermissions, (permissions) => permissions.teams || []);
const selectTeamsRead = createSelector(selectPermissionsTeams, (teams) => teams.includes(PermissionAction.READ));
const selectTeamsCreate = createSelector(selectPermissionsTeams, (teams) => teams.includes(PermissionAction.CREATE));
const selectTeamsEdit = createSelector(selectPermissionsTeams, (teams) => teams.includes(PermissionAction.EDIT));
const selectTeamsDelete = createSelector(selectPermissionsTeams, (teams) => teams.includes(PermissionAction.DELETE));
const selectIsReadOnlyTeam = createSelector(
  teamsSelectors.selectIsExistingTeam,
  selectTeamsRead,
  selectTeamsCreate,
  selectTeamsEdit,
  (isExisting, read, create, edit) => (isExisting ? read && !edit : read && !create)
);

const selectPermissionsSmartAlerts = createSelector(
  selectUserPermissions,
  (permissions) => permissions.smartAlerts || []
);
const selectSmartAlertsRead = createSelector(selectPermissionsSmartAlerts, (smartAlerts) =>
  smartAlerts.includes(PermissionAction.READ)
);
const selectSmartAlertsCreate = createSelector(selectPermissionsSmartAlerts, (smartAlerts) =>
  smartAlerts.includes(PermissionAction.CREATE)
);
const selectSmartAlertsEdit = createSelector(selectPermissionsSmartAlerts, (smartAlerts) =>
  smartAlerts.includes(PermissionAction.EDIT)
);
const selectSmartAlertsDelete = createSelector(selectPermissionsSmartAlerts, (smartAlerts) =>
  smartAlerts.includes(PermissionAction.DELETE)
);

const selectPermissionsSmartAlertTemplates = createSelector(
  selectUserPermissions,
  (permissions) => permissions.smartAlertTemplates || []
);
const selectSmartAlertTemplatesRead = createSelector(selectPermissionsSmartAlertTemplates, (templates) =>
  templates.includes(PermissionAction.READ)
);
const selectSmartAlertTemplatesCreate = createSelector(selectPermissionsSmartAlertTemplates, (templates) =>
  templates.includes(PermissionAction.CREATE)
);
const selectSmartAlertTemplatesEdit = createSelector(selectPermissionsSmartAlertTemplates, (templates) =>
  templates.includes(PermissionAction.EDIT)
);
const selectSmartAlertTemplatesDelete = createSelector(selectPermissionsSmartAlertTemplates, (templates) =>
  templates.includes(PermissionAction.DELETE)
);
const selectIsReadOnlySmartAlertTemplate = createSelector(
  selectSmartAlertsRead,
  selectSmartAlertsEdit,
  (read, edit) => read && !edit
);

const selectPermissionsLocations = createSelector(selectUserPermissions, (permissions) => permissions.locations || []);
const selectLocationsRead = createSelector(selectPermissionsLocations, (locations) =>
  locations.includes(PermissionAction.READ)
);
const selectLocationsCreate = createSelector(selectPermissionsLocations, (locations) =>
  locations.includes(PermissionAction.CREATE)
);
const selectLocationsEdit = createSelector(selectPermissionsLocations, (locations) =>
  locations.includes(PermissionAction.EDIT)
);
const selectLocationsDelete = createSelector(selectPermissionsLocations, (locations) =>
  locations.includes(PermissionAction.DELETE)
);

const selectPermissionsJourneyBuilder = createSelector(
  selectUserPermissions,
  (permissions) => permissions.journey || []
);
const selectJourneyBuilderRead = createSelector(selectPermissionsJourneyBuilder, (journey) =>
  journey.includes(PermissionAction.READ)
);
const selectJourneyBuilderCreate = createSelector(selectPermissionsJourneyBuilder, (journey) =>
  journey.includes(PermissionAction.CREATE)
);
const selectJourneyBuilderEdit = createSelector(selectPermissionsJourneyBuilder, (journey) =>
  journey.includes(PermissionAction.EDIT)
);
const selectJourneyBuilderDelete = createSelector(selectPermissionsJourneyBuilder, (journey) =>
  journey.includes(PermissionAction.DELETE)
);

const selectSupportedRoles = createSelector(rolesSelectors.selectRoles, selectUserPermissions, (roles, permissions) =>
  permissions.isAccessToAllWorkspaces
    ? roles
    : roles.filter((role) => {
        if (role.isAccessToAllWorkspaces) return false;
        let isRoleSupported = true;
        for (const item of FULL_PERMISSION_LIST) {
          const currentPermissions = permissions[item] || [];
          const permissionsList = role[item] || [];
          for (const action of permissionsList) {
            if (!currentPermissions.includes(action)) {
              isRoleSupported = false;
              break;
            }
          }
          if (!isRoleSupported) {
            break;
          }
        }
        return isRoleSupported;
      })
);
const selectSupportedRolesId = createSelector(selectSupportedRoles, (roles) => roles.map((role) => role.id));
const selectSupportedRolesName = createSelector(selectSupportedRoles, (roles) => roles.map((role) => role.name));

const selectStatistics = createSelector(userSettingsState, (userSettings) => userSettings.statistics);
const selectEventCreated = createSelector(
  selectStatistics,
  (statistics) => statistics[ActionType.MEETING_CREATED] || 0
);
const selectEmailsSent = createSelector(selectStatistics, (statistics) => statistics[ActionType.REMINDER_SENT] || 0);
const selectEventRescheduled = createSelector(
  selectStatistics,
  (statistics) => statistics[ActionType.MEETING_RESCHEDULED] || 0
);
const selectEventRescheduledPercent = createSelector(
  selectEventRescheduled,
  selectEventCreated,
  (eventRescheduled, eventCreated) => Math.round(eventCreated ? (100 * eventRescheduled) / eventCreated : 0)
);
const selectEventCanceled = createSelector(
  selectStatistics,
  (statistics) => statistics[ActionType.MEETING_CANCELED] || 0
);
const selectEventCanceledPercent = createSelector(
  selectEventCanceled,
  selectEventCreated,
  (eventCanceled, eventCreated) => Math.round(eventCreated ? (100 * eventCanceled) / eventCreated : 0)
);

const selectEventsStatistics = createSelector(
  selectEventCreated,
  selectEmailsSent,
  selectEventRescheduled,
  selectEventRescheduledPercent,
  selectEventCanceled,
  selectEventCanceledPercent,
  (eventCreated, emailsSent, eventRescheduled, eventRescheduledPercent, eventCanceled, eventCanceledPercent) => [
    { value: eventCreated },
    { value: emailsSent },
    { value: eventRescheduled, percent: eventRescheduledPercent },
    { value: eventCanceled, percent: eventCanceledPercent },
  ]
);

const selectCustomerInfo = createSelector(
  selectEmail,
  selectFirstName,
  selectLastName,
  (email, firstName, lastName) =>
    ({
      email: email || '',
      first_name: firstName || '',
      last_name: lastName || '',
      locale: i18n.language,
    } as SubscribeCustomerInfo)
);

const selectIsCurrentUserPhoneRequired = createSelector(
  selectUserWorkspaces,
  (state: State) => state,
  (workspaceIds, state) => workspacesSelectors.selectIsWorkspacePhoneRequired(workspaceIds)(state)
);

const selectUserWorkspacesOptions = createSelector(
  selectUserWorkspaces,
  workspacesSelectors.selectWorkspaceOptions,
  (workspaceIds, options) => options.filter((option) => workspaceIds.includes(option.id))
);

export const userSettingsSelectors = {
  selectIsMenuOpened,
  selectAvatar,
  selectConnectedEmail,
  selectCountry,
  selectCountryCode,
  selectDateFormat,
  selectEmail,
  selectError,
  selectFullName,
  selectNameOrEmail,
  selectIntegrations,
  selectIsAdminRole,
  selectIsFetching,
  selectIsSpinnerFetching,
  selectIsSkeletonFetching,
  selectIsGoogleCalendarConnected,
  selectIsIntegrationConnected,
  selectIsMicrosoftCalendarConnected,
  selectUserRoleId,
  selectIsRoleLoaded,
  selectLanguage,
  selectLogin,
  selectPhoneNumber,
  selectPhoneInfo,
  selectPhoneDetails,
  selectDefaultVideoIntegration,
  selectTimeFormat,
  selectTimeZone,
  selectUserSettings,
  selectIsZoomConnected,
  selectIsStandardRole,
  selectIsSuperAdmin,
  selectIsWorkspaceOrSuperAdmin,
  selectUserWorkspaces,
  selectIsQuickSetupFinished,
  selectIsGuideTourFinished,
  selectIsInvitePeopleClosed,
  selectIsInstallInboxClosed,
  selectIsCalendarWarning,
  selectIsUserSettingsReceived,
  selectIsFirstTenantUser,
  selectUTC,
  selectUserTimeZoneWithTime,
  selectGroupedTimeZoneLits,
  selectTimeZoneCity,
  selectTimeZoneUTC,
  selectUserPermissions,
  selectUserRoleName,

  selectPermissionsUsers,
  selectUsersRead,
  selectUsersAdd,
  selectUsersEdit,
  selectUsersDelete,
  selectPermissionsAvailability,
  selectAvailabilityEdit,
  selectAvailabilityCreate,
  selectAvailabilityDelete,

  selectPermissionsMyBookingPages,
  selectMyBookingPagesRead,
  selectMyBookingPagesCreate,
  selectMyBookingPagesEdit,
  selectMyBookingPagesDelete,
  selectMyBookingPagesReadOnly,
  selectPermissionsAllBookingPages,
  selectAllBookingPagesRead,
  selectAllBookingPagesCreate,
  selectAllBookingPagesEdit,
  selectAllBookingPagesDelete,
  selectAllBookingPagesReadOnly,
  selectIsCreateOnlyMyBookingPages,
  selectIsEditOnlyMyBookingPages,

  selectPermissionsBookingTemplates,
  selectBookingTemplatesRead,
  selectBookingTemplatesCreate,
  selectBookingTemplatesEdit,
  selectBookingTemplatesDelete,
  selectBookingTemplatesReadOnly,

  selectPermissionsResources,
  selectPermissionsWorkspaces,
  selectWorkspacesRead,
  selectWorkspacesCreate,
  selectWorkspacesEdit,
  selectWorkspacesDelete,
  selectIsReadOnlyWorkspace,

  selectTeamsRead,
  selectTeamsCreate,
  selectTeamsEdit,
  selectTeamsDelete,
  selectIsReadOnlyTeam,

  selectPermissionsSmartAlerts,
  selectSmartAlertsRead,
  selectSmartAlertsCreate,
  selectSmartAlertsEdit,
  selectSmartAlertsDelete,

  selectPermissionsSmartAlertTemplates,
  selectSmartAlertTemplatesRead,
  selectSmartAlertTemplatesCreate,
  selectSmartAlertTemplatesEdit,
  selectSmartAlertTemplatesDelete,
  selectIsReadOnlySmartAlertTemplate,

  selectPermissionsLocations,
  selectLocationsRead,
  selectLocationsCreate,
  selectLocationsEdit,
  selectLocationsDelete,

  selectPermissionsJourneyBuilder,
  selectJourneyBuilderRead,
  selectJourneyBuilderCreate,
  selectJourneyBuilderEdit,
  selectJourneyBuilderDelete,

  selectSupportedRoles,
  selectSupportedRolesId,
  selectSupportedRolesName,

  selectStatistics,
  selectEventsStatistics,

  selectCustomerInfo,
  selectIsCurrentUserPhoneRequired,
  selectUserWorkspacesOptions,
};
