import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../../store/modals';
import { cloneWorkspaceModalActions, workspacesSelectors } from '../../../store/workspaces';
import { workspacesActions } from '../../../store/workspaces/actions';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Modal } from '../../common';
import labels from './labels';

export const CloneWorkspaceModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(workspacesSelectors.selectCloneName);
  const isNameDuplicate = useSelector(workspacesSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(workspacesSelectors.selectIsCloneNameValid);

  const handleNameChange = (name: string) => {
    dispatch(workspacesActions.setCloneName(name));
  };

  const handleCancel = () => {
    dispatch(cloneWorkspaceModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(workspacesActions.cloneWorkspaceRequest());
    dispatch(cloneWorkspaceModalActions.closeModal());
  };

  return (
    <Modal.Container name={ModalName.CLONE_WORKSPACE_NAME} className="w-440px">
      <Modal.Header>{labels.title}</Modal.Header>
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.name}</div>
        <InputText
          type="text"
          placeholder={labels.namePlaceholder}
          value={name}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={(!isNameValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_NAME}
        />
        {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicate}</div>}
      </div>
      <Modal.Buttons>
        <Button label={labels.save} className="min-w-120px" onClick={handleSave} autoFocus disabled={!isNameValid} />
        <Button label={labels.cancel} onClick={handleCancel} text />
      </Modal.Buttons>
    </Modal.Container>
  );
};
