import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { ModalName } from '../../../../store/modals';
import {
  convertTrialModalActions,
  DEFAULT_PRICING_TIER,
  PRICING_TIER_OPTIONS,
} from '../../../../store/opsConsole/orgs';
import { MAX_LENGTH_NAME } from '../../../../types/constants';
import { Modal } from '../../../common';
import labels from './labels';

export const OrgConvertTrialModal = () => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(convertTrialModalActions.closeModal());
  };

  const handleCancel = () => {
    handleClose();
  };
  const handleSave = () => {
    handleClose();
  };

  return (
    <Modal.Container name={ModalName.CONVERT_TRIAL_MODAL_NAME}>
      <Modal.Header>
        <div className="text-3xl text-center">{labels.title}</div>
      </Modal.Header>
      <div style={{ maxWidth: '800px' }}>
        <div className="w-full py-2 px-3 mb-3 uppercase sumo-header-bg-dark">{labels.rules}</div>
        <div className="w-full px-3 uppercase">{labels.immediateProcess}</div>
        <ul>
          <li>{labels.immediateProcessDesc}</li>
        </ul>
        <div className="w-full px-3 uppercase">{labels.manualProcess}</div>
        <ul>
          {labels.manualProcessDesc.map((desc, index) => (
            <li key={`manualProcess-${index}`}>{desc}</li>
          ))}
        </ul>
        <div className="w-full py-2 px-3 my-3 uppercase sumo-header-bg-dark">{labels.annualContract}</div>
        <div className="grid justify-content-between px-3">
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="startDate">{labels.contractStartDate}</label>
            <InputText value={''} id="startDate" maxLength={MAX_LENGTH_NAME} />
          </div>
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="expirationDate">{labels.contractEndDate}</label>
            <InputText value={''} id="expirationDate" maxLength={MAX_LENGTH_NAME} disabled />
          </div>
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="pricingTier">{labels.featurePricingTier}</label>
            <Dropdown value={DEFAULT_PRICING_TIER} options={PRICING_TIER_OPTIONS} id="pricingTier" />
          </div>
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="userLicenses">{labels.userLicensesNumber}</label>
            <InputText value="25" id="userLicenses" maxLength={MAX_LENGTH_NAME} />
          </div>
          <div className="flex flex-column col-12 md:col-5 col-offset-0 md:col-offset-7">
            <label htmlFor="salesforceOpp">{labels.salesforceOpportunity}</label>
            <InputText
              value={''}
              id="salesforceOpp"
              placeholder={labels.salesforceOpportunityPlaceholder}
              maxLength={MAX_LENGTH_NAME}
            />
          </div>
        </div>

        <div className="w-full py-2 px-3 my-3 uppercase sumo-header-bg-dark">{labels.billing}</div>
        <div className="grid justify-content-between px-3">
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="netTerms">{labels.netTerms}</label>
            <Dropdown value={DEFAULT_PRICING_TIER} options={PRICING_TIER_OPTIONS} id="netTerms" />
          </div>
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="invDueDate">{labels.invoiceDueDate}</label>
            <InputText value={''} id="invDueDate" maxLength={MAX_LENGTH_NAME} disabled />
          </div>
          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="userAmount">{labels.amountPerUser}</label>
            <InputText value={''} id="userAmount" maxLength={MAX_LENGTH_NAME} />
          </div>

          <div className="flex flex-column col-12 md:col-5">
            <label htmlFor="totalAmount">{labels.totalAmount}</label>
            <InputText value="25" id="totalAmount" maxLength={MAX_LENGTH_NAME} disabled />
          </div>
        </div>
      </div>
      <Modal.Buttons>
        <div className="flex justify-content-between align-items-center -mr-2 mt-3 -mb-2 flex-wrap">
          <Button label={labels.cancel} onClick={handleCancel} outlined />
          <div className="flex align-items-center flex-wrap">
            <div className="p-error mr-3">{labels.warningMessage}</div>
            <Button label={labels.convertToCustomer} onClick={handleSave} autoFocus />
          </div>
        </div>
      </Modal.Buttons>
    </Modal.Container>
  );
};
