import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName } from '../../../store/modals';
import { smartAlertsActions, smartAlertsSelectors, SmartTypes } from '../../../store/smartAlerts';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Modal } from '../../common';
import labels from './labels';

export const SmartAlertCloneModal = () => {
  const dispatch = useDispatch();
  const cloneName = useSelector(smartAlertsSelectors.selectCloneName);
  const smartType = useSelector(smartAlertsSelectors.selectSmartType);
  const isCloneNameValid = useSelector(smartAlertsSelectors.selectIsCloneNameValid);

  const handleNameChange = (cloneName: string) => {
    dispatch(smartAlertsActions.setCloneName(cloneName));
  };

  const handleConfirm = () => {
    dispatch(smartAlertsActions.cloneSmartAlertRequest());
  };

  const handleCancel = () => {
    handleNameChange('');
  };
  return (
    <Modal.Container name={ModalName.CLONE_SMART_TYPES_MODAL_NAME} closable={true} onClose={handleCancel}>
      <Modal.Header>
        <div>{smartType === SmartTypes.ALERT ? labels.cloneAlertTitle : labels.cloneTemplateTitle}</div>
      </Modal.Header>
      <div className="w-440px">
        <InputText
          type="text"
          value={cloneName}
          onChange={(e) => {
            handleNameChange(e.target.value);
          }}
          placeholder={labels.dialogPlaceholder}
          className={`w-full ${!isCloneNameValid ? 'p-invalid' : ''}`}
          maxLength={MAX_LENGTH_NAME}
        />
      </div>
      <Modal.Buttons>
        <Button onClick={handleConfirm} disabled={!isCloneNameValid} autoFocus>
          {labels.confirm}
        </Button>
        <Button onClick={handleCancel} text>
          {labels.cancel}
        </Button>
      </Modal.Buttons>
    </Modal.Container>
  );
};
