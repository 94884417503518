import { t } from '../../../i18n/i18n';

export default {
  connect: t('Common:CONNECT'),
  fillOneField: t('IntegrationPage:FILL_ONE_FIELD'),
  trackingIdPlaceholder: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_PLACEHOLDER_TRACKING_ID'),
  trackingIdVisibleName: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_VISIBLE_NAME_TRACKING_ID'),
  measurementIdPlaceholder: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_PLACEHOLDER_MEASUREMENT_ID'),
  measurementIdVisibleName: t('ConnectIntegrationPage:GOOGLE_ANALYTICS_VISIBLE_NAME_MEASUREMENT_ID'),
};
