import { GoogleMapSearch } from '../../../components/common/GoogleMap/search/GoogleMapSearch';
import { GoogleMap } from '../../../components/common/GoogleMap/map/GoogleMap';
import { Address, AddressInput, PhysicalLocation } from '../../../API';
import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { useEffect } from 'react';
import { Coordinates, getAddressCoordinates, locationsActions } from '../../../store/locations';
import { useDispatch } from 'react-redux';
import labels from './labels';
import { ProgressSpinner } from 'primereact/progressspinner';

type EditLocationWithMapProps = {
  locationId: string;
  location: PhysicalLocation;
  initialAddress: Address | null;
  userLocation: Coordinates | null;
  isReadOnly: boolean;
  isValid: boolean;
};
export const EditLocationWithMap = ({
  locationId,
  location,
  initialAddress,
  userLocation,
  isReadOnly,
  isValid,
}: EditLocationWithMapProps) => {
  const dispatch = useDispatch();
  const map = useMap();

  useEffect(() => {
    if (userLocation && map) {
      map.panTo(userLocation);
    }
  }, [userLocation, map]);

  const onAddressSelect = (value: AddressInput) => {
    const address = { __typename: 'Address', ...value } as Address;
    dispatch(locationsActions.updateLocation({ address }));
  };

  return (
    <>
      <div className="w-full flex flex-column gap-8px my-20px">
        <div className="text-title-xs-med">{labels.address}</div>
        <GoogleMapSearch
          onAddressSelect={onAddressSelect}
          address={initialAddress}
          isReadOnly={isReadOnly}
          className={`${!isValid && !location.address?.name?.trim() ? 'p-invalid' : ''}`}
          followLocation
        />
      </div>
      <div className="w-full h-440px">
        {!userLocation && locationId === 'new' && (
          <div className="w-full h-full flex-center bg-heavy-1">
            <ProgressSpinner />
          </div>
        )}
        <GoogleMap
          style={{ width: '100%', height: '100%' }}
          advancedMarkers={location.address && <AdvancedMarker position={getAddressCoordinates(location.address)} />}
          disableDefaultUI={true}
          className={`${!userLocation && locationId === 'new' ? 'hidden' : ''}`}
        />
      </div>
    </>
  );
};
