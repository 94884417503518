import { Dialog } from 'primereact/dialog';
import React, { isValidElement, memo, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName, ModalOptions, modalsActions, modalsSelectors } from '../../../store/modals';

interface Children {
  children?: ReactNode;
}

interface IContainer {
  name: ModalName;
  style?: React.CSSProperties;
  className?: string;
  headerStyle?: React.CSSProperties;
  headerClassName?: string;
  contentStyle?: React.CSSProperties;
  contentClassName?: string;
  children?: ReactNode;
  dividers?: boolean;
  disableClose?: boolean;
  closeOnEscape?: boolean;
  initializeModalOptions?: ModalOptions;
  closable?: boolean;
  onClose?: () => void;
}

const Header = memo(({ children }: Children) => <>{children}</>);
const Buttons = memo(({ children }: Children) => <>{children}</>);

const Container = memo(
  ({
    name,
    style,
    className,
    headerStyle,
    headerClassName,
    contentStyle,
    contentClassName,
    children,
    dividers,
    disableClose,
    closeOnEscape,
    initializeModalOptions,
    closable,
    onClose,
  }: IContainer) => {
    const dispatch = useDispatch();
    const isModalOpen = useSelector(modalsSelectors.selectIsModalOpen(name));

    useEffect(() => {
      dispatch(modalsActions.initializeModal({ name, options: initializeModalOptions }));
      return () => {
        dispatch(modalsActions.deleteModal(name));
      };
    }, []);

    const handleClose = () => {
      if (!disableClose) {
        onClose && onClose();
        dispatch(modalsActions.closeModal(name)());
      }
    };

    let header: ReactNode;
    let buttons: ReactNode;
    const content: ReactNode[] = [];

    React.Children.forEach(children, (child) => {
      if (!isValidElement(child)) return;

      if (child.type === Header) {
        header = child;
      } else if (child.type === Buttons) {
        buttons = child;
      } else {
        content.push(child);
      }
    });

    return (
      <Dialog
        visible={isModalOpen}
        position="center"
        draggable={false}
        closable={closable}
        closeOnEscape={closeOnEscape}
        header={header}
        footer={buttons}
        style={style}
        className={className}
        headerStyle={headerStyle}
        headerClassName={headerClassName}
        contentStyle={contentStyle}
        contentClassName={`${dividers ? 'border-y-1 surface-border py-3' : ''} ${contentClassName}`}
        onHide={handleClose}
        focusOnShow={undefined}
        resizable={false}
      >
        {content}
      </Dialog>
    );
  }
);

export const Modal = {
  Header,
  Buttons,
  Container,
};

Header.displayName = 'Modal.Header';
Buttons.displayName = 'Modal.Buttons';
Container.displayName = 'Modal.Container';
