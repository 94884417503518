import { t } from '../../../i18n/i18n';

export default {
  addressLine1: t('OPSConsoleOrgs:ADDRESS_LINE_1'),
  addressLine2: t('OPSConsoleOrgs:ADDRESS_LINE_2'),
  billingAddress: t('Billing:BILLING_ADDRESS'),
  cancel: t('Common:CANCEL'),
  checkout: t('Billing:CHECKOUT'),
  city: t('Common:CITY'),
  confirm: t('Common:CONFIRM'),
  country: t('Common:COUNTRY'),
  firstName: t('Common:FIRST_NAME'),
  lastName: t('Common:LAST_NAME'),
  licenses: t('Billing:LICENSES_TITLE'),
  month: t('Billing:MONTH_TITLE'),
  orderSummary: t('Billing:ORDER_SUMMARY'),
  state: t('Common:STATE'),
  total: t('Common:TOTAL'),
  zip: t('Common:ZIP'),
  tax: t('Billing:TAX'),
};
