import { EllipsisVerticalIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Tooltip } from 'primereact/tooltip';
import { Ref, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SmartAlert, SmartAlertTemplate } from '../../../API';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { AppDispatch } from '../../../store/rootStore';
import {
  DeleteSmartTypeModalRequest,
  deleteSmartTypesModalActions,
  SelectedAlertsType,
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
} from '../../../store/smartAlerts';
import { getCardDesc, getCardIcon } from '../../../store/smartAlerts/utils';
import { userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';

type SmartAlertCardProps = {
  alert?: SmartAlert;
  template?: SmartAlertTemplate;
  isReadOnly?: boolean;
  isDeleteLocked?: boolean;
  useTemplate?: () => void;
  useAlert?: () => void;
};
export const SmartAlertCard = (props: SmartAlertCardProps) => {
  const { alert, template, useTemplate, useAlert } = props;

  const dispatch = useDispatch<AppDispatch>();
  const selectedAlerts: SelectedAlertsType[] = useSelector(smartAlertsSelectors.selectSelectedAlerts);
  const isSelected = useSelector(smartAlertsSelectors.selectIsSelectedAlert(alert?.id || template?.id || ''));
  const isReadOnly =
    props.isReadOnly !== undefined
      ? props.isReadOnly
      : useSelector(
          smartAlertsSelectors.selectIsAlertReadOnly(
            alert?.id || template?.id || '',
            alert ? SmartTypes.ALERT : SmartTypes.TEMPLATE
          )
        );
  const isDeleteLocked =
    props.isDeleteLocked !== undefined
      ? props.isDeleteLocked
      : useSelector(
          smartAlertsSelectors.selectIsAlertDeleteLocked(
            alert?.id || template?.id || '',
            alert ? SmartTypes.ALERT : SmartTypes.TEMPLATE
          )
        );
  const isCreateAlert = useSelector(userSettingsSelectors.selectSmartAlertsCreate);
  const isCreateTemplate = useSelector(userSettingsSelectors.selectSmartAlertTemplatesCreate);

  const Icon = getCardIcon(alert?.icon || template?.icon || '');
  const menu = useRef<Menu>(null);
  const lockedIconRef = useRef<HTMLElement>();

  let blockCardClick = false;

  const handleEdit = () => {
    if (blockCardClick) {
      blockCardClick = false;
      return;
    }

    if (alert) {
      dispatch(smartAlertsActions.setSmartAlert(alert));
    } else if (template) {
      dispatch(smartAlertsActions.setSmartAlertTemplate(template));
    }

    navigationService.navigateTo(
      Path.EditSmartAlert.replace(':smartType', alert ? SmartTypes.ALERT : SmartTypes.TEMPLATE).replace(
        ':smartAlertId',
        alert?.id || template?.id || ''
      )
    );
  };

  const handleClone = () => {
    if (alert) {
      dispatch(smartAlertsActions.setSmartAlert(alert));
      dispatch(smartAlertsActions.cloneSmartAlertThunk());
    } else if (template) {
      dispatch(smartAlertsActions.setSmartAlertTemplate(template));
      dispatch(smartAlertsActions.cloneSmartAlertTemplateThunk());
    }
  };

  const handleDelete = () => {
    if (alert) {
      dispatch(
        deleteSmartTypesModalActions.openModal({
          selectedAlerts: [{ type: SmartTypes.ALERT, id: alert.id }],
          name: alert.name,
        } as DeleteSmartTypeModalRequest)
      );
    } else if (template) {
      dispatch(
        deleteSmartTypesModalActions.openModal({
          selectedAlerts: [{ type: SmartTypes.TEMPLATE, id: template.id }],
          name: template.name,
        } as DeleteSmartTypeModalRequest)
      );
    }
  };

  const handleSelectAlert = (checked: boolean) => {
    if (alert) {
      const alerts = checked
        ? [...selectedAlerts, { type: SmartTypes.ALERT, id: alert.id } as SelectedAlertsType]
        : selectedAlerts.filter((item) => item.id !== alert.id);
      dispatch(smartAlertsActions.selectAlerts(alerts));
    } else if (template) {
      const templates = checked
        ? [...selectedAlerts, { type: SmartTypes.TEMPLATE, id: template.id } as SelectedAlertsType]
        : selectedAlerts.filter((item) => item.id !== template.id);
      dispatch(smartAlertsActions.selectAlerts(templates));
    }
  };

  const itemTemplate: MenuItem['template'] = (item, options) => (
    <a className="p-menuitem-link" onClick={options.onClick}>
      <span className="p-menuitem-text">{item.label}</span>
    </a>
  );

  const menuItems: MenuItem[] = [
    {
      label: labels.edit,
      command: handleEdit,
      template: itemTemplate,
      visible: !isReadOnly,
    },
    {
      label: labels.view,
      command: handleEdit,
      template: itemTemplate,
      visible: isReadOnly,
    },
    {
      label: labels.clone,
      command: handleClone,
      template: itemTemplate,
      visible: alert ? isCreateAlert : isCreateTemplate,
    },
    {
      label: labels.delete,
      command: handleDelete,
      template: itemTemplate,
      visible: !isDeleteLocked,
    },
  ];

  const handleCardClick = () => {
    if (selectedAlerts.length) {
      !isDeleteLocked && handleSelectAlert(!isSelected);
    } else if (useTemplate) {
      useTemplate();
    } else if (useAlert) {
      useAlert();
    } else {
      handleEdit();
    }
  };

  return (
    <div
      className={`sumo-card action-card flex flex-column h-full p-20px pb-12px ${isSelected ? 'selected' : ''}`}
      onClick={handleCardClick}
    >
      <div className="flex">
        <div className="flex-1 p-4px">
          <Icon className="icon-48px" />
        </div>
        {useTemplate || useAlert ? (
          <div className="card-element-hidden text-button-md-med">
            {useTemplate ? labels.useTemplate : labels.useAlert}
          </div>
        ) : (
          <div className="flex-left-center gap-4px h-24px">
            {!isDeleteLocked && (
              <Checkbox
                checked={isSelected}
                onChange={(e) => {
                  e.stopPropagation();
                  handleSelectAlert(!!e.checked);
                }}
                className={`card-selector ${selectedAlerts.length ? '' : 'card-selector-hidden'}`}
              />
            )}
            {!isReadOnly && (
              <div
                className="-my-4px -mr-16px action-button"
                onClick={(e) => {
                  blockCardClick = true;
                  menu.current?.toggle(e);
                }}
                style={{ visibility: selectedAlerts.length ? 'hidden' : 'visible' }}
              >
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex-left-center mt-16px gap-4px">
        <Tooltip target={lockedIconRef.current} position="top" className="max-w-200px">
          {labels.lockedSystemTemplate}
        </Tooltip>
        {isReadOnly && (
          <LockClosedIcon ref={lockedIconRef as unknown as Ref<SVGSVGElement>} className="card-locked-icon icon-16px" />
        )}
        <div className="text-title-lg-med">{alert?.name || template?.name}</div>
      </div>

      <div className="text-card-s-med text-heavy-60 mt-6px">
        {template ? template.description || '' : getCardDesc(alert?.whenSend, alert?.whomSend)}
      </div>
    </div>
  );
};
