import { t } from 'i18next';
import { ToastNotificationOptions } from '../notifications';
import {
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';

export enum UserDataKeys {
  AVATAR = 'avatar',
  COUNTRY = 'country',
  COUNTRY_CODE = 'countryCode',
  DATE_FORMAT = 'dateFormat',
  EMAIL = 'email',
  FULL_NAME = 'fullName',
  ID = 'id',
  LANGUAGE = 'language',
  PHONE_NUMBER = 'phoneNumber',
  TIME_FORMAT = 'timeFormat',
  TIME_ZONE = 'timeZone',
  USER_DEFAULT_AVAILABILITY_ID = 'userDefaultAvailabilityId',
  WELCOME_MESSAGE = 'welcomeMessage',
}

export enum UserSettingsKeys {
  LINK = 'link',
  RECORD_TYPE = 'recordType',
  USER_ID = 'userId',
  TENANT_ID = 'tenantId',
  COOKIES = 'cookies',
  WORKSPACE_ID = 'workspaceId',
  SUMO1_STAFF_DATA = 'Sumo1StaffData',
  QUICK_SETUP_WEEKLY_HOURS = 'QuickSetupAvailability',
  COOKIE_SAVED = 'cookieSaved',
}

export const SAVE_USER_SETTINGS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UserSettingsToastsNotifications:SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE'),
});
export const SAVE_USER_SETTINGS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UserSettingsToastsNotifications:SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE'),
});
export const DELETE_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UserSettingsToastsNotifications:DELETE_ACCOUNT_ERROR_TOAST_MESSAGE'),
});

export const CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UserSettingsToastsNotifications:CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE'),
});
export const CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UserSettingsToastsNotifications:CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE'),
});

export const SWITCH_ACCOUNT_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UserSettingsToastsNotifications:SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE'),
});
export const SWITCH_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UserSettingsToastsNotifications:SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE'),
});

export const CONNECT_ZOOM_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UserSettingsToastsNotifications:CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE'),
});
export const CONNECT_ZOOM_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UserSettingsToastsNotifications:CONNECT_ZOOM_ERROR_TOAST_MESSAGE'),
});
