import { Column } from 'primereact/column';
import {
  DATA_TERM_OPTIONS,
  ORG_STATUS_OPTIONS,
  ORG_TYPE_OPTIONS,
  OrgLicenseLog,
  OrgsDataTableType,
  orgsSelectors,
  OrgTermLog,
} from '../../../../store/opsConsole/orgs';
import { OrgsTableRowActions } from '../orgsTableRowActions/OrgsTableRowActions';
import { useSelector } from 'react-redux';
import labels from './labels';
import { formatDateUTC } from '../../../../services/DateService';
import { DateFormat } from '../../../../store/userSettings';
import { Path } from '../../../../routing';
import { navigationService } from '../../../../services/NavigationService';
import { Link } from 'react-router-dom';
import { Table } from '../../table/Table';
import { authenticationSelectors } from '../../../../store/authentication';
import { DataTableRowClickEvent } from 'primereact/datatable';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { Tooltip } from 'primereact/tooltip';
import { LicenseType } from '../../../../API';

export const OrgsTable = () => {
  const orgsRecords = useSelector(orgsSelectors.selectSearchOrgs);
  const isPaginatorEnabled = useSelector(orgsSelectors.selectIsPaginatorEnabled);
  const isMainAdminOrOperations = useSelector(authenticationSelectors.selectIsMainAdminOrOperations);
  const isMainAdminOperationsOrSupport = useSelector(authenticationSelectors.selectIsMainAdminOperationsOrSupport);

  const openOrgDetails = (e: DataTableRowClickEvent) => {
    navigationService.navigateTo(Path.OPSConsoleOrgDetails.replace(':orgId', e.data.tenantId));
  };

  const getLicenseChangesTooltip = (changes: OrgLicenseLog[]) =>
    changes
      .map(({ value, createdAt }) => {
        const action = value < 0 ? labels.reduce : labels.added;
        const licenseCount = Math.abs(value);
        const licenseLabel = licenseCount === 1 ? labels.license : labels.licenses;
        const formattedDate = formatDateUTC(createdAt, 'MM/DD/YYYY');

        return `${action} ${licenseCount} ${licenseLabel} ${formattedDate}`;
      })
      .join('\u000A'); // line break

  const getTermChangesTooltip = (changes: OrgTermLog[]) =>
    changes
      .map(({ prevTerm, currentTerm, createdAt }) => {
        const action = prevTerm === LicenseType.TRIAL ? labels.changedLabel : labels.scheduledChangedLabel;
        return `${action} ${labels.termLabel[prevTerm]} ${labels.to} ${labels.termLabel[currentTerm]} ${formatDateUTC(
          createdAt,
          'MM/DD/YYYY'
        )}`;
      })
      .join('\u000A'); // line break

  const getTermCellData = (data: OrgsDataTableType) => {
    const id = data.tenantId.replaceAll('-', '');
    const { value, logs } = data.termChanges;
    const termLabel = DATA_TERM_OPTIONS.find((type) => type.value === data.term)?.label;
    const isNegative = value < 0;
    const IconComponent = isNegative ? ArrowDownIcon : ArrowUpIcon;

    return value === 0 ? (
      <span>{termLabel}</span>
    ) : (
      <>
        <Tooltip target={`.term_${id}`} />
        <div className={`term_${id} flex-left-center gap-4px`} data-pr-tooltip={getTermChangesTooltip(logs)}>
          {termLabel}
          <div className={`text-label-s-med flex-center w-fit ${isNegative ? 'ors-changes-down' : 'ors-changes-up'}`}>
            <IconComponent className="icon-8px" />
          </div>
        </div>
      </>
    );
  };

  const getChangesCellData = (data: OrgsDataTableType) => {
    const id = data.tenantId.replaceAll('-', '');
    const { value, logs } = data.licenseChanges;

    if (value === 0) return null;

    const isNegative = value < 0;
    const formattedValue = `${isNegative ? '' : '+'}${value}`;
    const IconComponent = isNegative ? ArrowDownIcon : ArrowUpIcon;

    return (
      <>
        <Tooltip target={`.tooltip_${id}`} />
        <div
          className={`tooltip_${id} text-label-s-med flex-center w-fit ${
            isNegative ? 'ors-changes-down' : 'ors-changes-up'
          }`}
          data-pr-tooltip={getLicenseChangesTooltip(logs)}
        >
          {formattedValue}
          <IconComponent className="icon-8px ml-4px" />
        </div>
      </>
    );
  };

  return (
    <Table
      value={orgsRecords}
      paginator={isPaginatorEnabled}
      resizableColumns
      className="pt-3 cursor-pointer"
      onRowClick={openOrgDetails}
      rowHover
      paths={[Path.OPSConsoleOrgs, Path.OPSConsoleOrgDetails]}
    >
      <Column
        field="accountName"
        header={labels.account}
        body={(data: OrgsDataTableType) => (
          <Link
            className="text-color underline hover:text-primary"
            to={Path.OPSConsoleAccount.replace(':accountId', data.accountId)}
          >
            {data.accountName}
          </Link>
        )}
        sortable
        bodyClassName="text-overflow-ellipsis max-w-10rem"
      />
      <Column field="tenantId" header={labels.orgId} sortable bodyClassName="text-overflow-ellipsis max-w-5rem" />
      <Column
        field="status"
        body={(data) => ORG_STATUS_OPTIONS.find((status) => status.value === data.status)?.label}
        header={labels.status}
        sortable
        bodyClassName="text-overflow-ellipsis"
      />
      <Column
        field="type"
        body={(data) => ORG_TYPE_OPTIONS.find((type) => type.value === data.type)?.label}
        header={labels.type}
        sortable
        bodyClassName="text-overflow-ellipsis"
      />
      <Column
        field="term"
        body={getTermCellData}
        header={labels.term}
        sortable
        bodyClassName="text-overflow-ellipsis"
      />
      <Column field="amount" header={labels.arr} sortable bodyClassName="text-overflow-ellipsis" />
      <Column field="subscriptionId" header={labels.subscriptionId} sortable bodyClassName="text-overflow-ellipsis" />
      <Column
        field="owned"
        header={labels.licensesNum}
        sortable
        bodyClassName="text-overflow-ellipsis"
        className="max-w-90px"
      />
      <Column
        field="changes.value"
        body={getChangesCellData}
        header={labels.changes}
        sortable
        bodyClassName="text-overflow-ellipsis"
        className="max-w-90px"
      />
      <Column
        field="percentOfWeekAdoption"
        header={labels.adoptionPercent}
        sortable
        bodyClassName="text-overflow-ellipsis"
        className="max-w-90px"
      />
      <Column field="daysLeft" header={labels.daysLeft} sortable bodyClassName="text-overflow-ellipsis" />
      <Column field="bookedMeetings" header={labels.bookedNumber} sortable bodyClassName="text-overflow-ellipsis" />
      <Column
        field="updatedAt"
        header={labels.lastActive}
        body={(data: OrgsDataTableType) => formatDateUTC(data.updatedAt, DateFormat.default)}
        sortable
        bodyClassName="text-overflow-ellipsis"
        frozen
      />
      <Column
        body={(data: OrgsDataTableType) =>
          OrgsTableRowActions({
            tenantId: data.tenantId,
            orgType: data.type,
            showExpire: isMainAdminOrOperations,
            showExtend: isMainAdminOrOperations,
            showView: !isMainAdminOperationsOrSupport,
          })
        }
        className="w-4rem"
        align="center"
        frozen
      />
    </Table>
  );
};
