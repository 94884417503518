export const OPSConsoleStaff = Object.freeze({
  ACTIVE: `Aktiv`,
  ADD_NEW_MEMBER: `Neues Mitglied hinzufügen`,
  ADMIN_TITLE: `Hauptadministrator`,
  INACTIVE: `Inaktiv`,
  SALES_TITLE: `Vertrieb`,
  SUPPORT_TITLE: `Support`,
  OPERATIONS_TITLE: `Operationen`,
  TITLE: `Personal`,
  ADD_STAFF_TITLE: `Mitarbeiter erstellen`,
  DUPLICATE_EMAIL: `Diese E-Mail wurde bereits hinzugefügt.`,
  LAST_LOGIN: `Letzte Anmeldung`,
});

export const StaffToastsNotifications = Object.freeze({
  GET_STAFF_FAIL_MESSAGE: `Fehler bei der Anforderung des Personals`,
  SAVE_STAFF_SUCCESS_MESSAGE: `Erfolgreiches Speichern der Personalanforderung`,
  SAVE_STAFF_ROLE_SELECTION_FORBIDDEN_MESSAGE: `Ein neuer Hauptadministrator-Mitarbeiter kann nur von einem anderen Hauptadministrator eingeladen werden.`,
  SAVE_STAFF_FAIL_MESSAGE: `Fehler bei der Anforderung des Personals`,
  CHANGE_STAFF_STATUS_FAIL_MESSAGE: `Fehler bei der Anforderung zur Änderung des Mitarbeiterstatus`,
  ACTIVATE_STAFF_SUCCESS_MESSAGE: `Erfolgreiche Aktivierung der Mitarbeiteraufzeichnung`,
  DEACTIVATE_STAFF_SUCCESS_MESSAGE: `Erfolgreiche Deaktivierung der Mitarbeiteraufzeichnung`,
  DELETE_STAFF_SUCCESS_MESSAGE: `Erfolgreiche Löschung der Mitarbeiteraufzeichnung`,
  DELETE_STAFF_FAIL_MESSAGE: `Fehler bei der Anforderung zur Löschung des Personals`,
  VIEW_AS_USER_FAIL_MESSAGE: `Anforderung zur Ansicht als Benutzer fehlgeschlagen`,
});
