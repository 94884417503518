import { AdminDataStatus, CreateAdminDataInput } from '../../API';
import { t } from '../../i18n/i18n';
import { ToastNotificationOptions } from '../notifications';
import {
  adminRoles,
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { UserFilterType } from './types';
import { SelectItem } from 'primereact/selectitem';

export const DEFAULT_INVITE_DATA: CreateAdminDataInput = {
  email: '',
  tenantId: '',
  workspaceIds: [],
  roleId: '',
  status: undefined,
  team: [],
  expirationDate: null,
};

export const InviteUsersTemplateLabels = ['email', 'role', 'team', 'bookingTemplate'];
export const INVITE_SEND_STATUS = AdminDataStatus.INVITE_SENT;
export const FILTER_USER_OPTION_DELETED_USERS = 'FILTER_USER_OPTION_DELETED_USERS';

export const roleList = [adminRoles.superAdmin, adminRoles.workspaceAdmin, adminRoles.standardUser];
export const sumoAdminList = [adminRoles.sumoAdmin];

export const defaultExpirationDays = 30;

export const potentialExpirationDays = {
  days_30: 30,
  days_20: 20,
  days_15: 15,
  days_10: 10,
};

const inviteSentLabel = t('Users:STATUS_INVITE_SENT');
const activeLabel = t('Users:STATUS_ACTIVE');
const inactiveLabel = t('Users:STATUS_INACTIVE');

export const StatusLabels = {
  [AdminDataStatus.INVITE_SENT]: inviteSentLabel,
  [AdminDataStatus.ACTIVE]: activeLabel,
  [AdminDataStatus.INACTIVE]: inactiveLabel,
};

export const StatusOptions: SelectItem[] = [
  { value: AdminDataStatus.INVITE_SENT, label: inviteSentLabel },
  { value: AdminDataStatus.ACTIVE, label: activeLabel },
  { value: AdminDataStatus.INACTIVE, label: inactiveLabel },
];

export const INVITE_USERS_EMAIL_INVALID: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UsersToastsNotifications:INVITE_USERS_EMAIL_INVALID'),
});

export const INVITE_USERS_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UsersToastsNotifications:INVITE_USERS_SUCCESS_TOAST'),
});

export const INVITE_USERS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UsersToastsNotifications:INVITE_USERS_ERROR_TOAST'),
});

export const EDIT_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UsersToastsNotifications:EDIT_USER_SUCCESS_TOAST'),
});

export const EDIT_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UsersToastsNotifications:EDIT_USER_ERROR_TOAST'),
});

export const DISABLE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  title: t('UsersToastsNotifications:DISABLE_USER_TOAST_HEADER'),
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UsersToastsNotifications:DISABLE_USER_SUCCESS_TOAST'),
});

export const DISABLE_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UsersToastsNotifications:DISABLE_USER_ERROR_TOAST'),
});

export const ENABLE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  title: t('UsersToastsNotifications:ENABLE_USER_TOAST_HEADER'),
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('UsersToastsNotifications:ENABLE_USER_SUCCESS_TOAST'),
});

export const DELETE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  title: t('UsersToastsNotifications:DELETE_USER_TOAST_HEADER'),
  message: t('UsersToastsNotifications:DELETE_USER_SUCCESS_TOAST'),
});

export const DELETE_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  title: t('UsersToastsNotifications:DELETE_USER_TOAST_HEADER'),
  message: t('UsersToastsNotifications:DELETE_USER_ERROR_TOAST'),
});

export const GET_USERS_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('UsersToastsNotifications:GET_USERS_ERROR_MESSAGE'),
});

export const BUY_LICENSE_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  title: t('BillingToastsNotifications:BUY_LICENSE_SUCCESS_TOAST'), // TODO: move this const to Billing consts
  message: t('BillingToastsNotifications:ADD_SEATS_SUCCESS_TOAST'), // TODO: move this const to Billing consts
});

// export const GET_LICENSES_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
//   color: TOAST_ERROR_COLOR,
//   autoHideDuration: TOAST_ERROR_DURATION,
//   message: t('UsersToastsNotifications:GET_LICENSES_ERROR_MESSAGE'),
// });

export const DEFAULT_FILTER: UserFilterType = {
  workspaceIds: [],
  roles: [],
  statuses: StatusOptions.map((option) => option.value),
};
