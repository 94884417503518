import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { RadioButton } from 'primereact/radiobutton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EventSendType, IconName, ShareWith, UpdateBookingPageInput } from '../../../API';
import { authenticationSelectors } from '../../../store/authentication';
import { bookingPageSelectors } from '../../../store/bookingPages';
import { bookingTemplatesSelectors } from '../../../store/bookingTemplates';
import {
  DelayUnitsOptions,
  DELAY_UNITS,
  IconOption,
  IconOptions,
  smartAlertsActions,
  smartAlertsSelectors,
  SmartTypes,
  WhenOptions,
  WhomOptions,
  WHOM_SEND,
} from '../../../store/smartAlerts';
import { convertToMinutes, getPersonalizeTitle, isTemplateValid } from '../../../store/smartAlerts/utils';
import { usersSelectors } from '../../../store/users';
import { userSettingsSelectors } from '../../../store/userSettings';
import { WorkspaceOption } from '../../../store/workspaces';
import { MAX_LENGTH_DESCRIPTION, MAX_LENGTH_NAME } from '../../../types/constants';
import { OptionType } from '../../../types/types';
import { Personalize } from '../personalize/Personalize';
import labels from './labels';

type SmartAlertEditProps =
  | {
      handleCancel: () => void;
      handleSave: () => void;
      hideTypeChange?: boolean;
      hideApplyTo?: boolean;
      isReadOnly?: boolean;
    } & ({ isEdit: boolean; isCreate?: never } | { isCreate: boolean; isEdit?: never });

export const SmartAlertEdit = ({
  handleCancel,
  handleSave,
  hideTypeChange,
  hideApplyTo,
  isReadOnly,
  isEdit,
  isCreate,
}: SmartAlertEditProps) => {
  const dispatch = useDispatch();
  const alert = useSelector(smartAlertsSelectors.selectSmartAlert);
  const bookingPages = useSelector(bookingPageSelectors.selectBookingPagesAvailForEdit);
  const bookingPageIds = useSelector(smartAlertsSelectors.selectBookingPageIds);
  const bookingTemplates = useSelector(bookingTemplatesSelectors.selectBookingTemplatesAvailForEdit);
  const bookingTemplateIds = useSelector(smartAlertsSelectors.selectBookingTemplateIds);
  const tenantWorkspacesOptions = useSelector(authenticationSelectors.selectTenantWorkspacesOptions);
  const userWorkspaces = useSelector(userSettingsSelectors.selectUserWorkspaces) || [];
  const userOptions = useSelector(usersSelectors.selectLoggedUserListOptionsGroupByWorkspace);
  const currentWorkspace = useSelector(authenticationSelectors.selectWorkspaceId);
  const howLongText = useSelector(smartAlertsSelectors.selectHowLongText);
  const immediatelyText = useSelector(smartAlertsSelectors.selectImmediatelyText);
  const alertDelayUnit = useSelector(smartAlertsSelectors.selectSmartAlertDelayUnit);
  const alertDelayNum = useSelector(smartAlertsSelectors.selectSmartAlertDelayNum);
  const isFetching = useSelector(smartAlertsSelectors.selectIsFetching);
  const isNew = useSelector(smartAlertsSelectors.selectSmartAlertIsNew);
  const smartType = useSelector(smartAlertsSelectors.selectSmartType);
  const description = useSelector(smartAlertsSelectors.selectSmartAlertTemplateDesc);
  const shareWithWorkspaces = useSelector(smartAlertsSelectors.selectSmartAlertShareWithWorkspaces);
  const shareUsersByWorkspaces = useSelector(smartAlertsSelectors.selectSmartAlertShareWithUsersByWorkspace);
  const isNameValid = useSelector(smartAlertsSelectors.selectIsAlertNameValid);

  const [shareWith, setShareWith] = useState(!!alert.shareWith?.length);
  const [localDelayNum, setLocalDelayNum] = useState<number | null>(alertDelayNum);
  const [personalizeType, setPersonalizeType] = useState<WHOM_SEND | null>(null);

  useEffect(() => {
    setLocalDelayNum(alertDelayNum);
  }, [alertDelayUnit, alertDelayNum]);

  useEffect(() => {
    setShareWith(!!alert.shareWith?.length);
  }, [alert.shareWith]);

  const getTemplate = (option: IconOption) => {
    return option ? (
      <div className="w-16px h-16px">
        <option.icon className="icon-16px" />
      </div>
    ) : undefined;
  };

  const getValueTemplate = () => {
    const test = IconOptions.find((option) => option.value === alert.icon);
    return <div className="w-16px h-16px">{test && <test.icon className="icon-16px" />}</div>;
  };

  const handleIconSelect = (icon: IconName) => {
    dispatch(smartAlertsActions.updateSmartAlert({ icon }));
  };

  const handleNameChange = (name: string) => {
    dispatch(smartAlertsActions.updateSmartAlert({ name }));
  };

  const handleHideChange = (isHide: boolean) => {
    dispatch(smartAlertsActions.updateSmartAlert({ isHide }));
  };

  const handleDescriptionChange = (description: string) => {
    dispatch(smartAlertsActions.updateSmartTemplate({ description }));
  };

  const handleBookingPageSelect = (value: string[]) => {
    dispatch(smartAlertsActions.setBookingPageIds(value));
  };

  const handleBookingTemplateSelect = (value: string[]) => {
    dispatch(smartAlertsActions.setBookingTemplateIds(value));
  };

  const handleSmartType = (value: SmartTypes) => {
    value === SmartTypes.TEMPLATE && handleBookingPageSelect([]);
    dispatch(smartAlertsActions.setSmartType(value));
  };

  const handleShareWith = (value: boolean) => {
    handleWorkspaceSelect(value ? [currentWorkspace] : []);
  };

  const selectedMultiselectTemplate = (list: Array<string | null | undefined>, value: string) => {
    return list.length && list[0] === value ? (
      <span className="text-label-input-reg">
        {list.length} {labels.selected}
      </span>
    ) : undefined;
  };

  const isWorkspaceOptionDisabled = (id: string) => !userWorkspaces.includes(id);

  const handleWorkspaceSelect = (workspaceIds: string[]) => {
    const sharedWith = workspaceIds.map(
      (workspace) =>
        ({
          __typename: 'ShareWith',
          workspaceId: workspace,
          userIds: alert.shareWith?.find((share) => share?.workspaceId === workspace)?.userIds || [],
        } as ShareWith)
    );
    dispatch(smartAlertsActions.updateShareWith(sharedWith));
  };

  const handleUsersSelect = (userIds: string[]) => {
    const sharedWith = shareWithWorkspaces.map(
      (workspace) =>
        ({
          __typename: 'ShareWith',
          workspaceId: workspace,
          userIds: userIds.filter((user) => user?.split('_')[0] === workspace).map((user) => user.split('_')[1]),
        } as ShareWith)
    );
    dispatch(smartAlertsActions.updateShareWith(sharedWith));
  };

  const handleWhenTypeChange = (eventSendType: EventSendType) => {
    const immediately = eventSendType !== EventSendType.EVENT_BEFORE_START;
    dispatch(smartAlertsActions.updateWhenSend({ eventSendType, immediately }));
  };

  const handleWhenImmediately = (immediately: boolean) => {
    dispatch(smartAlertsActions.updateWhenSend({ immediately }));
  };

  const handleChangeDelayUnit = (value: DELAY_UNITS) => {
    const delay = convertToMinutes(value, localDelayNum || 1);
    dispatch(smartAlertsActions.updateWhenSend({ delay }));
  };

  const handleOnBlurDelayNum = () => {
    !localDelayNum && setLocalDelayNum(1);
    const delay = convertToMinutes(alertDelayUnit, localDelayNum || 1);
    dispatch(smartAlertsActions.updateWhenSend({ delay }));
  };

  const handleWhomSendType = (value: WHOM_SEND, isActive: boolean) => {
    const newValue = alert.whomSend && alert.whomSend[value] ? { ...alert.whomSend[value], isActive } : { isActive };
    dispatch(smartAlertsActions.updateWhomSend({ [value]: newValue }));
  };

  const getSubject = () => {
    return (personalizeType && alert.whomSend && alert.whomSend[personalizeType]?.subject) || '';
  };

  const getBody = () => {
    return (personalizeType && alert.whomSend && alert.whomSend[personalizeType]?.body) || '';
  };

  const handlePersonalizeSave = (body: string, subject?: string) => {
    if (personalizeType) {
      const newValue =
        alert.whomSend && alert.whomSend[personalizeType]
          ? { ...alert.whomSend[personalizeType], subject, body }
          : { subject, body };
      dispatch(smartAlertsActions.updateWhomSend({ [personalizeType]: newValue }));
      setPersonalizeType(null);
    }
  };

  return (
    <div className="sumo-card-bg flex flex-column p-24px">
      <div className="flex-left-center gap-8px">
        {isCreate || isEdit ? (
          <div className="text-title-xl-med mb-16px">
            {isCreate && <>{smartType === SmartTypes.ALERT ? labels.createAlertTitle : labels.createTemplateTitle}</>}
            {isEdit && <>{smartType === SmartTypes.ALERT ? labels.editAlertTitle : labels.editTemplateTitle}</>}
          </div>
        ) : null}
        {isFetching && <ProgressSpinner className="w-32px h-32px mx-0 -my-4px" strokeWidth="5" />}
      </div>

      <div className="flex flex-column gap-16px">
        <div className="sumo-card p-20px pt-24px">
          <div className="grid -mb-2">
            <div className="col-12 lg:col-6 lg:pr-4 flex flex-row gap-16px">
              <div className="flex flex-column gap-8px">
                <div className="text-title-xs-med">{labels.icon}</div>
                <Dropdown
                  options={IconOptions}
                  value={alert.icon}
                  optionLabel="value"
                  onChange={(e) => handleIconSelect(e.target.value)}
                  itemTemplate={getTemplate}
                  valueTemplate={getValueTemplate}
                  className="text-center"
                  disabled={isReadOnly}
                />
              </div>
              <div className="flex flex-column gap-8px w-full">
                <div className="text-title-xs-med">{labels.name}</div>
                <InputText
                  value={alert.name || ''}
                  onChange={(e) => handleNameChange(e.target.value)}
                  placeholder={labels.namePlaceholder}
                  maxLength={MAX_LENGTH_NAME}
                  className={`${!isNameValid ? 'p-invalid' : ''}`}
                  disabled={isReadOnly}
                />
              </div>
            </div>
            <div className="col-12 lg:col-6 lg:pl-4 flex flex-column flex-right">
              <div className="text-title-xs-med flex-left-center gap-12px">
                <InputSwitch
                  inputId="hide"
                  checked={alert.isHide || false}
                  className="size-small"
                  onChange={(e) => handleHideChange(!!e.value)}
                  disabled={isReadOnly}
                />
                <label htmlFor="hide" className="cursor-pointer">
                  {labels.hide}
                </label>
              </div>
              <div className="text-body-s-reg text-heavy-60 pt-2px">{labels.hideDesc}</div>
            </div>
          </div>
          {isNew && !isFetching && !hideTypeChange && (
            <div className="flex flex-left-center mt-20px">
              <div className="text-title-xs-med mr-16px">{labels.type}</div>
              <div className="flex flex-left-center gap-12px text-label-input-reg text-heavy-80">
                <RadioButton
                  inputId="alert"
                  value="alert"
                  checked={smartType === SmartTypes.ALERT}
                  onChange={() => handleSmartType(SmartTypes.ALERT)}
                />
                <label htmlFor="alert" className="cursor-pointer mr-20px">
                  {labels.alert}
                </label>
                <RadioButton
                  inputId="template"
                  value="template"
                  checked={smartType === SmartTypes.TEMPLATE}
                  onChange={() => handleSmartType(SmartTypes.TEMPLATE)}
                />
                <label htmlFor="template" className="cursor-pointer">
                  {labels.template}
                </label>
              </div>
            </div>
          )}
          {smartType === SmartTypes.ALERT && !hideApplyTo && (
            <>
              <div className="w-12 border-top-1 border-heavy-20 my-24px" />
              <div className="grid -mb-2">
                <div className="col-12 lg:col-6 lg:pr-4 flex flex-column gap-8px">
                  <div className="text-title-xs-med">{labels.applyTo}</div>
                  <MultiSelect
                    options={bookingPages}
                    optionLabel="what.customName"
                    optionValue="id"
                    value={bookingPageIds}
                    onChange={(e) => handleBookingPageSelect(e.value)}
                    selectedItemTemplate={(value: string) => selectedMultiselectTemplate(bookingPageIds, value)}
                    optionDisabled={(option: UpdateBookingPageInput) => !!option.what?.bookingTemplateId}
                    placeholder={labels.bookingPages}
                    disabled={isReadOnly || !bookingPages.length}
                  />
                </div>
                <div className="col-12 lg:col-6 lg:pl-4 flex flex-column align-self-end gap-8px">
                  <MultiSelect
                    options={bookingTemplates}
                    optionLabel="what.customName"
                    optionValue="id"
                    value={bookingTemplateIds}
                    onChange={(e) => handleBookingTemplateSelect(e.value)}
                    selectedItemTemplate={(value: string) => selectedMultiselectTemplate(bookingTemplateIds, value)}
                    placeholder={labels.bookingTemplates}
                    disabled={isReadOnly || !bookingTemplates.length}
                  />
                </div>
              </div>
            </>
          )}
          {smartType === SmartTypes.TEMPLATE && (
            <>
              <div className="w-12 border-top-1 border-heavy-20 my-24px" />
              <div className="w-12  flex flex-column gap-8px">
                <div className="text-title-xs-med">{labels.description}</div>
                <InputTextarea
                  value={description || ''}
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  placeholder={labels.descPlaceholder}
                  maxLength={MAX_LENGTH_DESCRIPTION}
                  autoResize={true}
                  disabled={isReadOnly}
                />
              </div>
            </>
          )}
        </div>

        <div className="sumo-card p-20px pt-24px">
          <div className="text-title-s-med flex-left-center gap-12px">
            <InputSwitch
              inputId="shareWith"
              checked={shareWith}
              className="size-small"
              onChange={(e) => handleShareWith(!!e.value)}
              disabled={isReadOnly || shareWithWorkspaces.some((id) => isWorkspaceOptionDisabled(id))}
            />
            <label htmlFor="shareWith" className="cursor-pointer">
              {labels.shareWith}
            </label>
          </div>
          <div className="grid -mb-2 mt-8px">
            <div className="col-12 lg:col-6 lg:pr-4">
              <MultiSelect
                options={tenantWorkspacesOptions}
                optionLabel="name"
                optionValue="id"
                value={shareWithWorkspaces}
                onChange={(e) => handleWorkspaceSelect(e.value)}
                selectedItemTemplate={(value: string) => selectedMultiselectTemplate(shareWithWorkspaces || [], value)}
                className="w-full"
                placeholder={labels.workspaces}
                disabled={!shareWith || isReadOnly}
                optionDisabled={(option: WorkspaceOption) => isWorkspaceOptionDisabled(option.id)}
                showSelectAll={tenantWorkspacesOptions.every((option) => !isWorkspaceOptionDisabled(option.id))}
              />
            </div>
            <div className="col-12 lg:col-6 lg:pl-4">
              <MultiSelect
                options={userOptions.filter((option) => shareWithWorkspaces.includes(option.workspaceId))}
                optionGroupChildren="users"
                optionGroupLabel="workspaceName"
                onChange={(e) => handleUsersSelect(e.value)}
                value={shareUsersByWorkspaces}
                selectedItemTemplate={(value: string) =>
                  selectedMultiselectTemplate(shareUsersByWorkspaces || [], value)
                }
                className="w-full"
                placeholder={labels.users}
                disabled={!shareWith || !alert.shareWith?.length || isReadOnly}
                optionDisabled={(option: OptionType) =>
                  !!option.value && isWorkspaceOptionDisabled(option.value.split('_')[0])
                }
                showSelectAll={tenantWorkspacesOptions.every((option) => !isWorkspaceOptionDisabled(option.id))}
              />
            </div>
          </div>
        </div>

        <div className="sumo-card p-20px pt-24px">
          <div className="text-title-s-med">{labels.whenTitle}</div>
          <div className="grid -mb-2 mt-8px">
            {WhenOptions.map((when, index) => (
              <div
                key={when.value}
                className={`col-12 lg:col-6 lg:${
                  index % 2 === 0 ? 'pr-4' : 'pl-4'
                } flex-left-center gap-12px text-label-input-reg text-heavy-80 ${
                  isReadOnly ? 'pointer-events-none' : ''
                }`}
              >
                <RadioButton
                  inputId={`when_${when.value}`}
                  value={when.value}
                  checked={alert.whenSend?.eventSendType === when.value}
                  onChange={(e) => handleWhenTypeChange(e.value)}
                  disabled={isReadOnly}
                />
                <label htmlFor={`when_${when.value}`} className="cursor-pointer">
                  {when.label}
                </label>
              </div>
            ))}
          </div>

          <div className="text-title-s-med mt-32px">{howLongText || labels.howLongAfter}</div>
          <div className="grid -mb-2 mt-0">
            <div
              className={`col-12 lg:col-6 lg:pr-4 gap-12px text-label-input-reg text-heavy-80 ${
                alert.whenSend?.eventSendType === EventSendType.EVENT_BEFORE_START ? 'hidden' : 'flex-left-center '
              } ${isReadOnly ? 'pointer-events-none' : ''}`}
            >
              <RadioButton
                inputId="immediately"
                value="immediately"
                checked={!!alert.whenSend?.immediately}
                onChange={() => handleWhenImmediately(true)}
                disabled={isReadOnly}
              />
              <label htmlFor="immediately" className="cursor-pointer">
                {immediatelyText || labels.immediately}
              </label>
            </div>
            <div
              className={`col-12 lg:col-6 flex-left-center gap-12px text-label-input-reg text-heavy-80 ${
                alert.whenSend?.eventSendType === EventSendType.EVENT_BEFORE_START ? '' : 'lg:pl-4'
              } ${isReadOnly ? 'pointer-events-none' : ''}`}
            >
              <RadioButton
                value="delay"
                checked={!alert.whenSend?.immediately}
                onChange={() => handleWhenImmediately(false)}
                disabled={isReadOnly}
              />
              <div className="flex-center gap-8px">
                <InputNumber
                  min={1}
                  max={999}
                  inputClassName="w-60px"
                  value={localDelayNum}
                  onChange={(e) => setLocalDelayNum(e.value)}
                  onBlur={handleOnBlurDelayNum}
                  disabled={!!alert.whenSend?.immediately || isReadOnly}
                />
                <Dropdown
                  options={DelayUnitsOptions}
                  value={alertDelayUnit}
                  onChange={(e) => handleChangeDelayUnit(e.value)}
                  disabled={!!alert.whenSend?.immediately || isReadOnly}
                />
              </div>
            </div>
          </div>

          <div className="border-top-1 border-heavy-20 my-32px -mx-22px px-22px" />
          <div className="text-title-s-med">{labels.doThis}</div>
          <div className="grid -mb-2 mt-8px">
            {WhomOptions.map((whom, index) => (
              <div key={whom.value} className={`col-12 lg:col-6 lg:${index % 2 === 0 ? 'pr-4' : 'pl-4'} `}>
                <div className="flex-left-center gap-12px text-label-input-reg text-heavy-80">
                  <InputSwitch
                    checked={!!alert.whomSend && !!alert.whomSend[whom.value]?.isActive}
                    className="flex-none size-small"
                    onChange={(e) => handleWhomSendType(whom.value, !!e.value)}
                    disabled={isReadOnly}
                  />
                  {whom.label}
                  <Button
                    onClick={() => setPersonalizeType(whom.value)}
                    label={labels.personalize}
                    className="flex-none ml-auto button-text-line button-blue text-label-s-med"
                    text
                  />
                </div>
                {!!alert.whomSend &&
                  !!alert.whomSend[whom.value]?.isActive &&
                  !isTemplateValid(alert.whomSend[whom.value]?.body || '') && (
                    <div
                      className="flex-right-center gap-6px text-body-xss-reg -mt-4px -mb-12px"
                      style={{ color: '#C08F0C' }}
                    >
                      <ExclamationTriangleIcon className="icon-16px" /> {labels.needsYourAttention}
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className="border-top-1 border-heavy-20 mt-32px flex-left-center gap-6px pt-16px -mb-4px -mx-22px px-22px">
            <Button
              className="flex-center button-xl min-w-120px"
              label={isNew ? labels.create : labels.save}
              onClick={handleSave}
              disabled={!isNameValid || isReadOnly || isFetching}
            />
            <Button className="button-xl" label={labels.cancel} text onClick={handleCancel} />
          </div>
        </div>
        <Personalize
          visible={!!personalizeType}
          close={() => setPersonalizeType(null)}
          handleCancel={() => setPersonalizeType(null)}
          subject={getSubject()}
          body={getBody()}
          title={personalizeType ? getPersonalizeTitle(personalizeType) : ''}
          handleSave={handlePersonalizeSave}
          isSMS={!!personalizeType && [WHOM_SEND.smsToHost, WHOM_SEND.smsToInvitee].includes(personalizeType)}
          isReadOnly={isReadOnly}
        />
      </div>
    </div>
  );
};
