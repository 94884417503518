import dayjs, { Dayjs } from 'dayjs';
import {
  ActionType,
  CreateActionLogInput,
  CreateLicenseInput,
  CreateUserEventInput,
  LicenseType,
  TenantStatus,
} from '../../../API';
import { converLocalDateTimeObjectToDate, formatDateJourneyLastDate } from '../../../services/DateService';
import { UserDetails, getRoleNameByRole } from '../users';
import {
  LicenseDataType,
  LogsByDate,
  OPSConsoleRole,
  OPSConsoleTenant,
  ORG_STATUS_VALUES,
  OrgDetailsUserRecord,
  OrgLicenseLog,
  OrgsChartData,
  OrgsDataTableType,
  OrgsDataType,
  OrgTermLog,
  StatisticsByOrgs,
} from './types';
import { DEFAULT_SUBSCRIPTION, Subscription, NO_SUBSCRIPTION_ID, getCurrencyLabelByCode } from '../../billing';

export const assignRoleToUser = (tenant: OPSConsoleTenant, users: OrgDetailsUserRecord[], roles: OPSConsoleRole[]) => {
  const usersWithRoles: UserDetails[] = users.map((user) => {
    const role = roles.find((role) => role.id === user.roleId);

    return {
      userId: user.userId,
      userName: user.name,
      lastActive: user.lastActive,
      email: user.email,
      accountId: '', // TODO add accountId
      accountName: '', // TODO add accountName
      tenantId: tenant.tenantId,
      status: user.status,
      role: role || ({} as OPSConsoleRole),
      roleName: role && getRoleNameByRole(role),
    };
  });

  return usersWithRoles;
};

export const convertToOrgsDataTableType = (orgsData: OrgsDataType[], statistics: CreateActionLogInput[]) => {
  const logsByOrgs = splitStatisticsByOrgs(statistics);
  const convertedData: OrgsDataTableType[] = orgsData.map((org) => {
    const lastLicense = org.licenses.sort((a, b) => (a.endDate > b.endDate ? 1 : -1))[0];

    const daysLeft = dayjs(lastLicense.endDate).diff(dayjs().format('YYYY-MM-DD'), 'day');

    const status = defineStatus(lastLicense, daysLeft, org);

    const userEmails: string[] = [];
    const subscription: Subscription = org?.subscription || DEFAULT_SUBSCRIPTION;
    const currencyCode: string = subscription.currency_code;
    const amount: number = subscription.subscription_items[0]?.amount / 100 || 0;
    const unitPrice: number = subscription.subscription_items[0]?.unit_price / 100 || 0;
    const subscriptionId: string = subscription.id || NO_SUBSCRIPTION_ID;

    for (const user of org.userRecords) {
      user.email && userEmails.push(user.email.toLowerCase());
    }

    const { licenseLogs, termLogs } = filterOrgLogs(logsByOrgs[org.tenantId]);

    return {
      tenantId: org.tenantId,
      name: org.name,
      status,
      type: org.type,
      term: lastLicense.type,
      owned: lastLicense.owned,
      assigned: lastLicense.assigned,
      percentOfWeekAdoption: (+org.percentOfWeekAdoption * 100).toFixed(),
      percentOfMonthAdoption: (+org.percentOfMonthAdoption * 100).toFixed(),
      createdAt: converLocalDateTimeObjectToDate(org.createdAt).toISOString(),
      updatedAt: converLocalDateTimeObjectToDate(lastLicense.updatedAt).toISOString(),
      accountId: org.accountId,
      accountName: org.accountName,
      note: org.note,
      amount: getCurrencyLabelByCode(currencyCode) + amount,
      amountPerUser: getCurrencyLabelByCode(currencyCode) + unitPrice,
      subscriptionId,
      bookedMeetings: org.bookedMeetings,
      startDate: lastLicense.startDate,
      endDate: lastLicense.endDate,
      daysLeft,
      userEmails,
      licenseChanges: { logs: licenseLogs, value: getChangesValue(licenseLogs) },
      termChanges: { logs: termLogs, value: getTermChanges(termLogs) },
    };
  });

  return convertedData;
};

const defineStatus = (lastLicense: LicenseDataType, daysLeft: number, org: OrgsDataType) => {
  // check if the license is a trial and has expired or the tenant status is expired
  if ((lastLicense.type === LicenseType.TRIAL && daysLeft <= 0) || org.status === TenantStatus.EXPIRED) {
    return ORG_STATUS_VALUES.Expired;
  }

  // check if the license is not a trial and has expired or there are due invoices
  if (
    (lastLicense.type !== LicenseType.TRIAL && daysLeft <= 0) ||
    (org.subscription && org.subscription?.due_invoices_count > 0)
  ) {
    return ORG_STATUS_VALUES.PaymentLate;
  }

  return ORG_STATUS_VALUES.Active;
};

const splitStatisticsByOrgs = (statistics: CreateActionLogInput[]) => {
  const res: StatisticsByOrgs = {};
  const from = dayjs().subtract(30, 'day').unix();

  statistics.forEach((record) => {
    if (!record.tenantId || +(record.createdAt || '') < from) return;
    res[record.tenantId] = res[record.tenantId] || [];
    res[record.tenantId].push(record);
  });

  return res;
};

const parseLicenseAction = (log: CreateActionLogInput) =>
  (log.action ? JSON.parse(log.action)[0] : {}) as CreateLicenseInput;
const parseEventAction = (log: CreateActionLogInput) =>
  (log.action ? JSON.parse(log.action) : {}) as CreateUserEventInput;

const calculateScheduledSeats = (license: CreateLicenseInput) =>
  license?.scheduledChanges?.reduce((total, change) => total + (change?.seats || 0), 0) || 0;

const getScheduledTermChange = (license: CreateLicenseInput) =>
  license?.scheduledChanges?.find((record) => record?.plan)?.plan;

const sortLogsByDate = (logs: CreateActionLogInput[]) =>
  logs?.sort((a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime());

const addLicenseLog = (
  licenseLogs: OrgLicenseLog[],
  prev: CreateActionLogInput,
  license: CreateLicenseInput,
  prevLicense: CreateLicenseInput,
  isConverted = false
) => {
  const scheduledSeats = calculateScheduledSeats(license);
  const prevScheduledSeats = calculateScheduledSeats(prevLicense);
  const value =
    (license.owned || 0) - scheduledSeats - (isConverted ? 0 : (prevLicense.owned || 0) - prevScheduledSeats);

  value !== 0 &&
    licenseLogs.push({
      createdAt: prev.createdAt || '',
      value: value,
    });
};

const addTermLog = (
  termLogs: OrgTermLog[],
  prev: CreateActionLogInput,
  prevLicense: CreateLicenseInput,
  newTerm: LicenseType | undefined | null,
  isScheduled: boolean
) => {
  prevLicense.type &&
    newTerm &&
    termLogs.push({
      createdAt: prev.createdAt || '',
      prevTerm: prevLicense.type,
      currentTerm: newTerm,
      isScheduled,
    });
};

const filterOrgLogs = (logs: CreateActionLogInput[]) => {
  const licenseLogs: OrgLicenseLog[] = [];
  let termLogs: OrgTermLog[] = [];

  sortLogsByDate(logs);

  logs?.forEach((log, index) => {
    if (index === 0) return;

    const prev = logs[index - 1];
    if (log.action === prev.action) return;

    const license = parseLicenseAction(log);
    const prevLicense = parseLicenseAction(prev);

    const scheduledTermChange = getScheduledTermChange(license);
    const prevScheduledTermChange = getScheduledTermChange(prevLicense);

    if (prevLicense.type === LicenseType.TRIAL && license.type !== prevLicense.type) {
      addTermLog(termLogs, prev, prevLicense, license.type, false);
      addLicenseLog(licenseLogs, prev, license, prevLicense, true);
    } else if (
      prevLicense.owned !== license.owned ||
      calculateScheduledSeats(prevLicense) !== calculateScheduledSeats(license)
    ) {
      addLicenseLog(licenseLogs, prev, license, prevLicense);
    }

    if (
      (prevLicense.type === LicenseType.MONTHLY || prevLicense.type === LicenseType.ANNUAL) &&
      prevScheduledTermChange !== scheduledTermChange
    ) {
      const prevTerm = prevScheduledTermChange?.endsWith('Monthly') ? LicenseType.MONTHLY : LicenseType.ANNUAL;
      const newTerm = scheduledTermChange?.endsWith('Monthly') ? LicenseType.MONTHLY : LicenseType.ANNUAL;

      if (prevScheduledTermChange && prevTerm !== license.type) {
        // Cancelled - remove from term logs
        termLogs = termLogs.filter(
          (record) =>
            !(
              record.prevTerm === prevLicense.type &&
              record.currentTerm === prevTerm &&
              record.createdAt <= (prev.createdAt || '')
            )
        );
      } else {
        // New scheduled term change
        addTermLog(termLogs, prev, prevLicense, newTerm, true);
      }
    }
  });

  return { licenseLogs, termLogs };
};

const getChangesValue = (logs: OrgLicenseLog[]) => logs.reduce((total, log) => total + log.value, 0) || 0;

const getTermChanges = (logs: OrgTermLog[]) => {
  if (!logs.length) return 0;
  const lastRecord = logs[logs.length - 1];

  return lastRecord.prevTerm === LicenseType.ANNUAL && lastRecord.currentTerm === LicenseType.MONTHLY ? -1 : 1;
};

export const spliLogsByDay = (logs: CreateActionLogInput[]) => {
  const res: LogsByDate = {};

  logs.forEach((record) => {
    if (!record.createdAt) return;
    const formattedDate = formatDateJourneyLastDate(record.createdAt);
    res[formattedDate] = res[formattedDate] || [];
    res[formattedDate].push(record);
  });

  return res;
};

export const getLogsForPastWeek = (logsByDate: LogsByDate, date: Dayjs) => {
  return Array.from({ length: 7 }, (_, i) => {
    const formattedDate = formatDateJourneyLastDate(date.subtract(i, 'day').toString());
    return logsByDate[formattedDate] || [];
  }).flat();
};

export const getLicenseOwned = (logs: CreateActionLogInput[]) => {
  const log = logs.find((record) => record.actionType === ActionType.SUBSCRIPTIONS_DAILY_SUMMARY);
  return log ? parseLicenseAction(log).owned || 0 : 0;
};

export const getActiveUsers = (logs: CreateActionLogInput[]) => {
  const users = new Set<string>();

  logs.forEach((record) => {
    if (record.actionType === ActionType.MEETING_CREATED || record.actionType === ActionType.MEETING_RESCHEDULED) {
      const event = parseEventAction(record);
      event?.host && users.add(event.host);
      event?.cohostsMembers?.forEach((cohost) => cohost?.id && users.add(cohost.id));
    } else if (
      record.actionType &&
      [
        ActionType.BOOKING_PAGE_CREATED,
        ActionType.BOOKING_PAGE_REMOVED,
        ActionType.BOOKING_PAGE_UPDATED,
        ActionType.BOOKING_TEMPLATE_CREATED,
        ActionType.BOOKING_TEMPLATE_REMOVED,
        ActionType.BOOKING_TEMPLATE_UPDATED,
      ].includes(record.actionType)
    ) {
      record.userId && users.add(record.userId);
    }
  });

  return users.size;
};

export const getLicenseTotal = (chartData: OrgsChartData[]) => {
  if (chartData.length === 0) {
    return 0;
  }

  const startLicenses = chartData[0].y;
  const endLicenses = chartData[chartData.length - 1].y;

  return endLicenses - startLicenses;
};

export const getAdoptionTotalAverage = (chartData: OrgsChartData[]) => {
  if (chartData.length === 0) {
    return 0;
  }

  const total = chartData.reduce((sum, item) => sum + item.y, 0);
  return total / chartData.length;
};
