export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Solo puede conectar 1 calendario`, // Para aumentar este límite, contacte con ventas para actualizar su nivel de licencia.
  DESCRIPTION: `Conecte su calendario para que SUMO sepa cuándo está disponible y actualice su calendario a medida que se reservan citas.`,
  DISCONNECT_MESSAGE: `¿Está seguro de que desea desconectar su calendario?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Las citas no serán eliminadas ni canceladas.`,
  DISCONNECT_TITLE: `Desconectar Calendario`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 o 2019`,
  EXCHANGE_CALENDAR_TITLE: `Calendario Exchange`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Calendario de Google`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com o Hotmail Calendar`,
  MICROSOFT_CALENDAR_TITLE: `Calendario de Office 365`,
  SELECT_CALENDAR_TITLE: `Seleccionar Calendario`,
  TITLE: `Conexiones de Calendario`,
  TOOLTIP: `Conecte su calendario de Google o Microsoft a SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Aceptar y Cerrar`,
  DESCRIPTION: `Cuando visitas un sitio web, tiene la capacidad de almacenar o recuperar cierta información en tu navegador. Esta información, a menudo en forma de cookies, puede ser sobre tus preferencias, dispositivo u otros detalles. Su propósito principal es asegurar que el sitio web funcione como esperas. Aunque esta información no te identifica directamente, puede mejorar tu experiencia de navegación web personalizando el contenido. Entendemos y valoramos tu derecho a la privacidad, por lo que te ofrecemos la opción de controlar y personalizar tus preferencias de cookies. Al hacer clic en los diferentes encabezados de categoría, puedes aprender más sobre cada tipo de cookie y modificar nuestras configuraciones predeterminadas. Ten en cuenta que bloquear ciertos tipos de cookies puede afectar tu experiencia general en el sitio y limitar los servicios que podemos ofrecerte.`,
  DESCRIPTION_FIRST_PART: `Cuando visitas un sitio web, tiene la capacidad de almacenar o recuperar cierta información en tu navegador. Esta información, a menudo en forma de cookies, puede ser sobre tus preferencias, dispositivo u otros detalles. Su propósito principal es asegurar que el sitio web funcione como esperas.`,
  DESCRIPTION_SECOND_PART: `Aunque esta información no te identifica directamente, puede mejorar tu experiencia de navegación web personalizando el contenido. Entendemos y valoramos tu derecho a la privacidad, por lo que te ofrecemos la opción de controlar y personalizar tus preferencias de cookies.`,
  DESCRIPTION_THIRD_PART: `Al hacer clic en los diferentes encabezados de categoría, puedes aprender más sobre cada tipo de cookie y modificar nuestras configuraciones predeterminadas. Ten en cuenta que bloquear ciertos tipos de cookies puede afectar tu experiencia general en el sitio y limitar los servicios que podemos ofrecerte.`,
  FUNCTIONAL_NAME: `Cookies Funcionales`,
  MANAGE_TITLE: `Gestionar Preferencias de Consentimiento`,
  NECESSARY_ONLY: `Solo Estrictamente Necesarias`,
  PERFORMANCE_NAME: `Cookies de Rendimiento`,
  STRICTLY_NAME: `Cookies Estrictamente Necesarias`,
  TARGETING_NAME: `Cookies de Segmentación`,
  TITLE: `Configuración de Cookies`,
  SHOW_ALL: `Mostrar Todo`,
  HIDE_ALL: `Ocultar Todo`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Cuenta de Google`,
  GOOGLE_ACCOUNTS_SWITCH: `Cambiar Cuentas de Google`,
  GOOGLE_SWITCH: `Iniciar sesión con Google`,
  GOOGLE_SWITCH_DESC: `Cambiar de autenticación de Microsoft a Google`,
  MICROSOFT_ACCOUNT: `Cuenta de Microsoft`,
  MICROSOFT_ACCOUNTS_SWITCH: `Cambiar Cuentas de Microsoft`,
  MICROSOFT_SWITCH: `Iniciar sesión con Microsoft`,
  MICROSOFT_SWITCH_DESC: `Cambiar de autenticación de Google a Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Tu cuenta SUMO1 perderá acceso a datos disponibles solo a través de tu proveedor original. También es posible que necesites volver a otorgar acceso a integraciones y calendarios.`,
  SWITCH_DESCRIPTION_PART1: `Cambiar de `,
  SWITCH_DESCRIPTION_PART2: ` autenticación a `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Tu rol actual`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Formato de Fecha`,
  LANGUAGE: `Idioma`,
  NAME: `Nombre`,
  WELCOME_MESSAGE: `Mensaje de Bienvenida`,
  PHONE_NUMBER: `Número de Teléfono`,
  PROFILE_IMAGE: `Imagen de Perfil`,
  PHONE_DETAILS: `Detalles del Teléfono`,
  PHONE_DETAILS_PLACEHOLDER: `Agrega información adicional o instrucciones aquí.\r\nEj: Espero conocerte. Envíame un SMS en cualquier momento.`,
  TIME_FORMAT: `Formato de Hora`,
  TIME_ZONE: `Zona Horaria`,
  COUNTRY: `País`,
});

export const Profile = Object.freeze({
  TITLE: `Perfil`,
  ACCOUNT_SETTINGS: `Configuración de la Cuenta`,
  ACCOUNT_DESCRIPTION: `Información básica y configuraciones`,
  LOGIN: `Iniciar Sesión`,
  LOGIN_DESCRIPTION: `Configurar cuenta conectada`,
  MY_ROLE: `Mi Rol`,
  MY_ROLE_DESCRIPTION: `Tu rol y permisos actuales`,
  COOKIE_SETTINGS: `Configuración de Cookies`,
  COOKIE_DESCRIPTION: `Cookies Necesarias, de Rendimiento, Funcionales, de Segmentación`,
  DELETE_ACCOUNT_DESCRIPTION: `Elimina tu cuenta y todos los datos relacionados de tu organización`,
});

export const DeleteAccount = Object.freeze({
  TITLE: `Eliminar cuenta`,
  WARNING_DELETE: `Cuidado, esta acción es permanente y no se puede deshacer.`,
  DELETE_ACCOUNT_AND_ORG: `Eliminar cuenta y organización`,
  YES_DELETE: `Sí, eliminar`,
  DELETE_TO_CONFIRM: `Escriba <b>Eliminar</b> para confirmar.`,
  DELETE_CONFIRM_WORD: `Eliminar`,
  DELETE_ACCOUNT_MODAL_DESCRIPTION: `Elimine sus datos personales y todos los datos relacionados de su organización. Esta acción es permanente y no se puede deshacer.`,
  DELETE_ACCOUNT_AND_ORG_MODAL_DESCRIPTION: `Elimine su cuenta y organización. Esta acción es permanente y no se puede deshacer.`,
  LIST_DELETING_ENTITIES_TITLE: `Al eliminar su cuenta se realizará lo siguiente`,
  LIST_DELETING_ACCOUNT_AND_ORG_ENTITIES_TITLE: `Al eliminar su cuenta y organización se realizará`,
  LIST_DELETING_ENTITIES: `Cerrar sesión en todos los dispositivos \\p Eliminar toda la información personal \\p Eliminar todas las integraciones personales`,
  ADDITIONAL_LIST_DELETING_ORG_ENTITIES: `\\p Eliminar todos los datos de la organización`,
  TRANSFER_OWNERSHIP_TITLE: `Transferir propiedad y eliminar mi cuenta`,
  TRANSFER_OWNERSHIP_DESCRIPTION: `Antes de poder eliminar su cuenta, debe transferir la propiedad a otro miembro del equipo para que puedan gestionar la organización.`,
  DELETE_ACCOUNT_AND_ORG_TITLE: `Eliminar mi cuenta y disolver la organización`,
  DELETE_ACCOUNT_AND_ORG_DESCRIPTION: `Si no desea transferir la propiedad, puede eliminar su cuenta y disolver la organización.`,
  TRANSFER_OWNERSHIP: `Transferir propiedad`,
  NEED_INVITE_NEW_USER_TO_ORG: `Invite a alguien a su organización y acepte la invitación`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `Fallo en la solicitud de nuevo calendario`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `¡Nuevo calendario conectado exitosamente!`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Fallo en la solicitud de conexión de Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `¡Zoom conectado exitosamente!`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Fallo en la solicitud de guardar configuraciones`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `¡Tus configuraciones se guardaron exitosamente!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `Cambiado de cuenta exitosamente`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Fallo al cambiar de cuenta. Por favor intenta de nuevo más tarde`,
  DELETE_ACCOUNT_ERROR_TOAST_MESSAGE: `Error al eliminar la cuenta`,
});

export const Guide = Object.freeze({
  GET_STARTED: `Comenzar con SUMO1`,
  START: `Iniciar`,

  BACK: `Volver`,
  CLOSE: `Cerrar`,
  LAST: `Finalizar`,
  NEXT: `Siguiente`,
  NEXT_LABEL_WITH_PROGRESS: `Siguiente (Paso {step} de {steps})`,
  OPEN_THE_DIALOG: `Abrir el diálogo`,
  SKIP: `Finalizar tour`,
  COMPLETE_TOUR: `Completar Tour`,
  GO_TO: `Ir a`,

  PROFILE_TITLE: `Gestiona tu perfil`,
  PROFILE_DESCRIPTION: `Puedes gestionar tus ajustes de perfil de usuario, incluyendo el idioma, zona horaria, tu rol y permisos, etc.`,
  INTEGRATIONS_TITLE: `Conecta tus reuniones de video`,
  INTEGRATIONS_DESCRIPTION: `SUMO 1 tiene integraciones listas para usar para CRM, reuniones de video, procesadores de pago y más.`,
  AVAILABILITY_TITLE: `Modifica tus horas de disponibilidad`,
  AVAILABILITY_DESCRIPTION: `Puedes modificar tu disponibilidad o crear nuevas horarios de disponibilidad como "Después de horas" aquí.`,
  SUMO_INBOX_TITLE: `SUMO Inbox instalar`,
  SUMO_INBOX_DESCRIPTION: `SUMO Meeting Booker es una extensión de navegador que el 90% de los usuarios usan diariamente para traer funciones avanzadas de planificación de citas de SUMO1 a Gmail y Outlook.`,
  SUMO_INBOX_LINK: `Instalar la extensión de navegador de SUMO1`,
  BOOKING_PAGES_TITLE: `Crea tu página de reservas`,
  BOOKING_PAGES_DESCRIPTION: `Puedes crear páginas de reservas que se usarán más permanentemente. Por ejemplo, un enlace de página de reservas para tu firma de correo electrónico, tu sitio web, etc.`,
  BOOKED_MEETINGS_TITLE: `Cómo ver las reuniones agendadas`,
  BOOKED_MEETINGS_DESCRIPTION: `Ver una lista de reuniones agendadas, incluyendo la opción de cancelar, marcar como no asistido, reagendar y más.`,
  SMART_ALERTS_TITLE: `Optimiza tu agenda con alertas inteligentes`,
  SMART_ALERTS_DESCRIPTION: `Las páginas de reservas incluyen alertas básicas, como enviar un recordatorio por texto 1 día antes de la reunión. Las alertas inteligentes ayudan a los usuarios a configurar alertas de reunión más sofisticadas.`,
  BOOKING_TEMPLATES_TITLE: `Plantilla de reserva`,
  BOOKING_TEMPLATES_DESCRIPTION: `Puedes crear plantillas de reserva (también conocidas como tipos de reunión) para facilitar la reserva rápida de una reunión específica con la persona adecuada, en el lugar adecuado y en el momento adecuado.`,
  TEAMS_TITLE: `Gestiona tus equipos`,
  TEAMS_DESCRIPTION: `Puedes crear equipos (también conocidos como grupos de recursos) para asignar automáticamente a una reunión basándote en un modo de asignación, como Round-Robin.`,
  USERS_TITLE: `Invita y gestiona tus usuarios`,
  USERS_DESCRIPTION: `Puedes invitar a usuarios a usar SUMO1 y activar o desactivar usuarios.`,
  ROLES_TITLE: `Gestiona los permisos de tus compañeros de equipo`,
  ROLES_DESCRIPTION: `Puedes gestionar los permisos de tus compañeros de equipo para asegurarte de que tienen los CRUD adecuados (crear, leer, actualizar, eliminar) requeridos.`,
  BILLING_TITLE: `Elige tu plan y configura tu pago`,
  BILLING_DESCRIPTION: `Puedes gestionar tu suscripción a SUMO1 tú mismo, incluyendo la capacidad de agregar más licencias cuando las necesites.`,
});
