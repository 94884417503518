import { useDispatch, useSelector } from 'react-redux';
import { billingSelectors, billingActions, billingAddressModalActions } from '../../../store/billing';
import { ReactComponent as VisaIcon } from '../../../assets/icons/visa.svg';
import { ReactComponent as MastercardIcon } from '../../../assets/icons/mastercard.svg';
import { InputTextarea } from 'primereact/inputtextarea';
import { DocumentChartBarIcon, PencilIcon } from '@heroicons/react/24/outline';
import labels from './labels';
import { Button } from 'primereact/button';
import { BillingAddressModal } from '../billingAddressModal/BillingAddressModal';
import { openPortal } from '../../../services/ChargebeeService';
import { Skeleton } from 'primereact/skeleton';

export const BillingDetails = () => {
  const dispatch = useDispatch();
  const paymentSources = useSelector(billingSelectors.selectPaymentSources);
  const billingAddress = useSelector(billingSelectors.selectCustomerBillingAddressText);
  const customerBillingAddress = useSelector(billingSelectors.selectCustomerBillingAddress);
  const isFetching = useSelector(billingSelectors.selectIsFetching);

  const handleEditPaymentMethod = () => {
    openPortal(() => {
      // Chargebee portal closed
      dispatch(billingActions.getBillingRequest());
      dispatch(billingActions.getItemPricesRequest());
    }, true);
  };

  const handleBillingAddressEdit = () => {
    dispatch(billingActions.setBillingAddress({ ...customerBillingAddress }));
    dispatch(billingAddressModalActions.openModal());
  };

  return (
    <>
      <BillingAddressModal />

      <div className="flex flex-wrap -mx-20px -my-12px">
        {isFetching ? (
          <div className="flex gap-32px ml-20px mt-12px">
            <Skeleton className="w-360px h-42px" />
            <Skeleton className="w-360px h-42px" />
          </div>
        ) : (
          <>
            <div className="w-12 lg:w-6 px-20px py-12px flex flex-column gap-16px">
              <div className="text-title-xs-med">{labels.paymentMethod}</div>
              {paymentSources.map((paymentSource) => (
                <div key={paymentSource.id} className="flex gap-4px">
                  <div className="flex-1 border-1 border-heavy-20 border-radius-10px px-8px h-40px text-label-s-med flex-left-center gap-8px">
                    {paymentSource.card ? (
                      <>
                        <div className="flex border-radius-4px bg-heavy-1 text-heavy-80 w-32px h-24px">
                          {paymentSource.card.brand === 'visa' ? (
                            <VisaIcon className="w-full h-full" />
                          ) : paymentSource.card.brand === 'mastercard' ? (
                            <MastercardIcon className="w-full h-full" />
                          ) : null}
                        </div>
                        <div>{paymentSource.card.masked_number}</div>
                        <div>{`${paymentSource.card.expiry_month}/${paymentSource.card.expiry_year}`}</div>
                      </>
                    ) : (
                      <>
                        <div className="flex-center border-radius-4px bg-heavy-1 text-heavy-80 w-32px h-24px">
                          <DocumentChartBarIcon className="icon-16px text-heavy-60" />
                        </div>
                        <div>{labels.byInvoice}</div>
                      </>
                    )}
                  </div>
                  <Button className="action-button" onClick={handleEditPaymentMethod}>
                    <PencilIcon className="icon-16px" />
                  </Button>
                </div>
              ))}
            </div>
            <div className="w-12 lg:w-6 px-20px py-12px flex flex-column gap-16px">
              <div className="text-title-xs-med">{labels.billingAddress}</div>
              <div className="flex gap-4px">
                <InputTextarea value={billingAddress} disabled autoResize className="flex-1" />
                <Button className="action-button" onClick={handleBillingAddressEdit}>
                  <PencilIcon className="icon-16px" />
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
