import { NavigateFunction } from 'react-router-dom';

class NavigationService {
  private navigate: NavigateFunction | null = null;
  private previousPath: string | null = null;

  constructor() {
    this.navigateTo = this.navigateTo.bind(this);
  }

  setNavigate(navigateFunction: NavigateFunction) {
    this.navigate = navigateFunction;
  }

  navigateTo(path: string) {
    if (this.navigate) {
      this.previousPath = window.location.pathname;
      this.navigate(path);
    }
  }

  getPreviousPath(): string | null {
    return this.previousPath;
  }
}

export const navigationService = new NavigationService();
