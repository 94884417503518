import { createSelector } from 'reselect';
import { State } from '../../rootStore';
import dayjs from 'dayjs';
import { ORG_TYPE_OPTIONS } from './constants';
import { LicenseType, TenantStatus, OrgType, CreateLicenseInput, ScheduledChanges } from '../../../API';
import { getCurrencyLabelByCode } from '../../billing';
import { globalSelectors } from '../../global';
import { formatDateJourneyLastDate } from '../../../services/DateService';
import { CHANGES_FILTER_VALUES, ORG_STATUS_VALUES } from './types';
import { getActiveUsers, getLicenseOwned, getLogsForPastWeek, spliLogsByDay } from '.';

const orgsState = (state: State) => state.OPSConsoleOrgs;

const selectIsFetching = createSelector(orgsState, (state) => state.isFetching);
const selectError = createSelector(orgsState, (state) => state.error);
const selectOrgs = createSelector(orgsState, (state) => state.orgs);
const selectOrgDetails = createSelector(orgsState, (state) => state.orgDetails);
const selectLicense = createSelector(orgsState, (state) => state.license);
const selectIsEdited = createSelector(orgsState, (state) => state.isEdited);
const selectFilter = createSelector(orgsState, (state) => state.filter);
const selectOrgsLogs = createSelector(orgsState, (state) => state.orgsActionLogs);
const selectOrgsLogsFetching = createSelector(orgsState, (state) => state.orgsActionLogsFetching);
const selectOrgsChartFilter = createSelector(orgsState, (state) => state.orgsChartFilter);
const selectOrgLogs = createSelector(orgsState, (state) => state.orgActionLogs);
const selectOrgLogsFetching = createSelector(orgsState, (state) => state.orgActionLogsFetching);
const selectOrgChartFilter = createSelector(orgsState, (state) => state.orgChartFilter);

const selectFilterStatus = createSelector(selectFilter, (filter) => filter.status);
const selectFilterTerm = createSelector(selectFilter, (filter) => filter.term);
const selectFilterChanges = createSelector(selectFilter, (filter) => filter.changes);
const selectIsFilterActive = createSelector(
  selectFilter,
  (filter) =>
    filter.term.length !== 0 ||
    filter.status.length !== 1 ||
    !filter.status.includes(ORG_STATUS_VALUES.Active) ||
    filter.changes.length !== 0
);

const selectSearchOrgs = createSelector(
  selectOrgs,
  globalSelectors.selectLowercasedSearchString,
  selectFilterStatus,
  selectFilterTerm,
  selectFilterChanges,
  (orgs, searchString, filterStatus, filterTerm, filterChanges) =>
    orgs
      .filter(
        (org) =>
          (!filterStatus.length || filterStatus.includes(org.status)) &&
          (!filterTerm.length || filterTerm.includes(org.term)) &&
          (!filterChanges.length ||
            (org.licenseChanges.value < 0 && filterChanges.includes(CHANGES_FILTER_VALUES.Reduce)) ||
            (org.licenseChanges.value > 0 && filterChanges.includes(CHANGES_FILTER_VALUES.Added)) ||
            (org.licenseChanges.value === 0 && filterChanges.includes(CHANGES_FILTER_VALUES.None))) &&
          (org.accountName?.toLowerCase().includes(searchString) ||
            org.tenantId.toLowerCase().includes(searchString) ||
            org.userEmails.some((email) => email.includes(searchString)))
      )
      .sort((orgA, orgB) => (orgA.createdAt < orgB.createdAt ? 1 : -1))
);

const selectTenantDetails = createSelector(selectOrgDetails, (details) => details.tenantRecord);
const selectTenantId = createSelector(selectTenantDetails, (tenant) => tenant?.tenantId);
const selectTenantName = createSelector(selectTenantDetails, (tenant) => tenant?.name);
const selectTenantType = createSelector(selectTenantDetails, (tenant) => tenant?.type);
const selectTenantStatus = createSelector(selectTenantDetails, (tenant) => tenant?.status);
const selectTenantIsActive = createSelector(selectTenantStatus, (status) => status === TenantStatus.ACTIVE);
const selectTenantIsTrial = createSelector(selectTenantType, (type) => type === OrgType.TRIAL);
const selectTenantNote = createSelector(selectTenantDetails, (tenant) => tenant?.note || '');
const selectAmountPerUser = createSelector(
  selectTenantDetails,
  (tenant) =>
    getCurrencyLabelByCode(tenant?.subscription?.currency_code) +
    (tenant?.subscription?.subscription_items[0]?.unit_price / 100 || 0)
);

const selectLicensesDetails = createSelector(selectOrgDetails, (details) => details.licenseRecords);
const selectLicenseId = createSelector(selectLicense, (license) => license.id);
const selectStartDate = createSelector(selectLicense, (license) => dayjs(license?.startDate).toDate());
const selectEndDate = createSelector(selectLicense, (license) => dayjs(license?.endDate).toDate());
const selectLicenseDuration = createSelector(selectStartDate, selectEndDate, (startDate, endDate) =>
  dayjs(endDate).diff(startDate, 'day')
);
const selectRolesDetails = createSelector(selectOrgDetails, (details) => details.roleRecords);
const selectDaysRemaining = createSelector(selectEndDate, (endDate) =>
  dayjs(endDate).diff(dayjs().format('YYYY-MM-DD'), 'day').toString()
);
const selectLicenseAssigned = createSelector(selectLicense, (license) => license.assigned || 0);
const selectLicenseOwned = createSelector(selectLicense, (license) => license.owned || 0);

const selectWeeklyAdoptionPercent = createSelector(selectOrgDetails, (org) => org.percentOfWeekAdoption);
const selectMonthlyAdoptionPercent = createSelector(selectOrgDetails, (org) => org.percentOfMonthAdoption);
const selectLicenseTerm = createSelector(selectLicense, (license) => license?.type || LicenseType.TRIAL);

const selectScheduledChanges = createSelector(
  selectLicense,
  (license) => license.scheduledChanges?.filter((changes): changes is ScheduledChanges => !!changes) || []
);
const selectIsScheduledChanges = createSelector(
  selectLicense,
  (license) => license.hasScheduledChanges && license.scheduledChanges?.length
);
const selectScheduledSeats = createSelector(selectScheduledChanges, (changes) =>
  changes.reduce((count, record) => {
    if (record.seats) {
      count += record.seats;
    }
    return count;
  }, 0)
);
const selectIsScheduledPositive = createSelector(selectScheduledChanges, (changes) =>
  changes.find((record) => record.plan && record.plan.endsWith('Yearly'))
);

const selectAccountId = createSelector(selectOrgDetails, (details) => details.accountId);

const selectBookedMeetings = createSelector(selectOrgDetails, (details) => '' + (details.bookedMeetings || 0));

const selectOrgStatus = createSelector(
  selectTenantIsActive,
  selectDaysRemaining,
  selectLicenseTerm,
  (tenantIsActive, daysRemaining, licenseTerm) =>
    (licenseTerm === LicenseType.TRIAL && +daysRemaining <= 0) || !tenantIsActive
      ? ORG_STATUS_VALUES.Expired
      : licenseTerm !== LicenseType.TRIAL && +daysRemaining <= 0
      ? ORG_STATUS_VALUES.PaymentLate
      : ORG_STATUS_VALUES.Active
);

const selectOrgTypeDesc = createSelector(
  selectTenantType,
  (orgType) => ORG_TYPE_OPTIONS.find((item) => item.value === orgType)?.label || ''
);

const selectIsPaginatorEnabled = createSelector(selectSearchOrgs, (orgs) => orgs.length > 10);
const selectPayLaterDetails = createSelector(orgsState, (state) => state.payLaterDetails);
const selectPayLaterDetailsAddress = createSelector(orgsState, (state) => state.payLaterDetails.billing_address);

const selectPayLaterEmail = createSelector(selectPayLaterDetails, (payLater) => payLater.email);
const selectPayLaterTerm = createSelector(selectPayLaterDetails, (payLater) => payLater.term);
const selectPayLaterQuantity = createSelector(selectPayLaterDetails, (payLater) => payLater.quantity);
const selectPayLaterAddressFirstName = createSelector(
  selectPayLaterDetailsAddress,
  (billingAddress) => billingAddress.first_name
);
const selectPayLaterAddressLastName = createSelector(
  selectPayLaterDetailsAddress,
  (billingAddress) => billingAddress.last_name
);
const selectPayLaterAddressLine1 = createSelector(
  selectPayLaterDetailsAddress,
  (billingAddress) => billingAddress.line1
);
const selectPayLaterAddressLine2 = createSelector(
  selectPayLaterDetailsAddress,
  (billingAddress) => billingAddress.line2
);
const selectPayLaterAddressCity = createSelector(selectPayLaterDetailsAddress, (billingAddress) => billingAddress.city);
const selectPayLaterAddressZip = createSelector(selectPayLaterDetailsAddress, (billingAddress) => billingAddress.zip);
const selectPayLaterAddressState = createSelector(
  selectPayLaterDetailsAddress,
  (billingAddress) => billingAddress.state
);
const selectPayLaterAddressCountry = createSelector(
  selectPayLaterDetailsAddress,
  (billingAddress) => billingAddress.country
);

const selectLicenseValid = createSelector(
  selectLicenseAssigned,
  selectLicenseOwned,
  (assigned, owned) => owned >= assigned
);
const selectOrgValid = createSelector(selectLicenseValid, (isLicenseValid) => isLicenseValid);

const selectOrgsChartData = createSelector(selectOrgsLogs, selectOrgsChartFilter, (actionLogs, filter) => {
  const today = dayjs();
  const daysRange = Array.from({ length: today.diff(today.subtract(+filter, 'month'), 'day') + 1 }, (_, i) => {
    const date = today.subtract(+filter, 'month').add(i, 'day');
    return { x: formatDateJourneyLastDate(date.toString()), y: 0 };
  });

  actionLogs.forEach((record) => {
    const createdAt = formatDateJourneyLastDate(record.createdAt);
    const owned = record.action ? (JSON.parse(record.action) as CreateLicenseInput[])[0]?.owned ?? 0 : 0;

    const dayStatistic = daysRange.find((value) => value.x === createdAt);
    if (dayStatistic) {
      dayStatistic.y += owned;
    }
  });

  return daysRange;
});

const selectOrgChartData = createSelector(selectOrgLogs, selectOrgChartFilter, (actionLogs, filter) => {
  const today = dayjs();
  const logsByDate = spliLogsByDay(actionLogs);
  const from = today.subtract(+filter, 'month');

  return Array.from({ length: today.diff(from, 'day') + 1 }, (_, i) => {
    const date = from.add(i, 'day');
    const formattedDate = formatDateJourneyLastDate(date.toString());
    const logs = logsByDate[formattedDate];
    const logsForWeek = getLogsForPastWeek(logsByDate, date);
    const licenseOwned = logs ? getLicenseOwned(logs) : 0;
    const activeUsers = logs ? getActiveUsers(logsForWeek) : 0;

    return { x: formattedDate, y: licenseOwned ? Math.round((activeUsers / licenseOwned) * 100) : 0 };
  });
});

// TODO: rename to OPSConsoleOrgsSelectors
export const orgsSelectors = {
  selectIsFetching,
  selectError,
  selectOrgs,
  selectOrgDetails,
  selectLicense,
  selectIsEdited,
  selectFilterStatus,
  selectFilterTerm,
  selectFilterChanges,
  selectIsFilterActive,
  selectSearchOrgs,
  selectOrgsLogs,
  selectOrgsLogsFetching,
  selectOrgsChartFilter,
  selectOrgLogs,
  selectOrgLogsFetching,
  selectOrgChartFilter,

  selectTenantDetails,
  selectTenantId,
  selectTenantName,
  selectTenantType,
  selectTenantIsActive,
  selectTenantIsTrial,
  selectTenantNote,
  selectAmountPerUser,

  selectOrgStatus,
  selectOrgTypeDesc,

  selectLicensesDetails,
  selectLicenseId,
  selectLicenseDuration,
  selectRolesDetails,
  selectStartDate,
  selectEndDate,
  selectDaysRemaining,

  selectAccountId,
  selectBookedMeetings,

  selectWeeklyAdoptionPercent,
  selectMonthlyAdoptionPercent,
  selectIsPaginatorEnabled,
  selectLicenseAssigned,
  selectLicenseTerm,
  selectScheduledChanges,
  selectIsScheduledChanges,
  selectScheduledSeats,
  selectIsScheduledPositive,

  selectPayLaterDetails,
  selectPayLaterEmail,
  selectPayLaterTerm,
  selectPayLaterQuantity,
  selectPayLaterAddressFirstName,
  selectPayLaterAddressLastName,
  selectPayLaterAddressLine1,
  selectPayLaterAddressLine2,
  selectPayLaterAddressCity,
  selectPayLaterAddressZip,
  selectPayLaterAddressState,
  selectPayLaterAddressCountry,

  selectLicenseValid,
  selectOrgValid,

  selectOrgsChartData,
  selectOrgChartData,
};
