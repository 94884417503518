import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import labels from './labels';
import { useSelector } from 'react-redux';
import { OPSConsoleLicense, orgsSelectors, payLaterModalActions } from '../../../../store/opsConsole/orgs';
import { Button } from 'primereact/button';
import { converLocalDateTimeObjectToDate, formatDateTimeThroughGap } from '../../../../services/DateService';
import { useDispatch } from 'react-redux';
import { authenticationSelectors } from '../../../../store/authentication';
import { OrgPayLaterModal } from '../orgPayLaterModal/OrgPayLaterModal';

export const OrgDetailsLicenses = () => {
  const dispatch = useDispatch();
  const licenses = useSelector(orgsSelectors.selectLicensesDetails);
  const isTrial = useSelector(orgsSelectors.selectTenantIsTrial);
  const isMainAdminOrOperations = useSelector(authenticationSelectors.selectIsMainAdminOrOperations);
  const orgsIsFetching = useSelector(orgsSelectors.selectIsFetching);

  const handleCreateLicense = () => {
    dispatch(payLaterModalActions.openModal());
  };

  return (
    <>
      <OrgPayLaterModal />
      <div className="w-full mt-4 mb-3 flex align-items-center justify-content-between gap-4 border-round sumo-header-bg-dark">
        <div className="text-lg uppercase py-2 pl-3 flex-1">
          {labels.title}
          {orgsIsFetching && <i className="pi pi-spin pi-spinner" style={{ marginLeft: '10px' }} />}
        </div>
        {isTrial && isMainAdminOrOperations && (
          <Button label={labels.createLicense} icon="pi pi-plus" text onClick={handleCreateLicense} />
        )}
      </div>
      <DataTable value={licenses} size="small" showGridlines removableSort resizableColumns>
        <Column field="type" header={labels.name} sortable bodyClassName="text-overflow-ellipsis" />
        <Column field="owned" header={labels.licensesNumber} sortable bodyClassName="text-overflow-ellipsis" />
        <Column
          body={
            (data: OPSConsoleLicense) =>
              formatDateTimeThroughGap(converLocalDateTimeObjectToDate(data.createdAt).toDate()) // TODO: make a separate optimize function to convert the date
          }
          header={labels.purchasedDate}
          sortable
          bodyClassName="text-overflow-ellipsis"
        />
        <Column field="purchaserBy" header={labels.purchasedName} sortable bodyClassName="text-overflow-ellipsis" />
        {/* <Column body={OrgLicenseRowActions} className="w-4rem" align="center" frozen /> */}
      </DataTable>
    </>
  );
};
