import { call, put, select, takeLatest } from 'redux-saga/effects';
import { UsersActionTypes, OPSConsoleUsersActions } from './actions';
import { notificationsActions } from '../../notifications';
import { getUserDetails, getUsers } from './service';
import { UserDetails } from './types';
import { createSelector } from 'reselect';
import { OPSConsoleUsersSelectors } from './selectors';
import { UserAdminDataFullRecord, UserStatus } from '../../users/types';
import { updateUser } from '../../users/service';
import { deactivateUserModalActions, deleteUserModalActions } from './modal';
import {
  GET_USERS_FAIL_TOAST,
  GET_USER_DETAILS_FAIL_TOAST,
  DELETE_USER_SUCCESS_TOAST,
  DELETE_USER_FAIL_TOAST,
  DEACTIVATE_USER_SUCCESS_TOAST,
  ACTIVATE_USER_SUCCESS_TOAST,
  DEACTIVATE_USER_FAIL_TOAST,
} from './constants';
import { getRoleNameByRole } from './utils';
import { handleServiceError } from '../../utils/reduxUtils';
import { deleteUser } from '../../global/services';

const changeUserStatusRequest = createSelector(OPSConsoleUsersSelectors.selectUserDetails, (userDetails) => [
  {
    userId: userDetails.userId,
    tenantId: userDetails.tenantId,
    email: userDetails.email,
    status: UserStatus.Inactive,
  },
]);

function* getUsersSaga() {
  try {
    const users: UserDetails[] = yield call(getUsers);

    if (users && users.length > 0) {
      const usersWithRoleName = users.map((user) => ({ ...user, roleName: getRoleNameByRole(user.role) }));
      yield put(OPSConsoleUsersActions.getUsersSuccess(usersWithRoleName));
    } else {
      throw new Error('No users found');
    }
  } catch (error: unknown) {
    yield put(OPSConsoleUsersActions.getUsersFail(error?.toString()));
    yield call(handleServiceError, error, GET_USERS_FAIL_TOAST, true);
  }
}

function* getUserDetailsSaga(action: ReturnType<typeof OPSConsoleUsersActions.getUserDetailsRequest>) {
  try {
    if (action.type === UsersActionTypes.GET_USER_DETAILS_REQUEST) {
      const userDetails: UserDetails = yield call(getUserDetails, action.payload);

      if (!userDetails) {
        throw new Error('User details retrieval failed');
      }

      yield put(OPSConsoleUsersActions.getUserDetailsSuccess(userDetails));
    }
  } catch (error: unknown) {
    yield put(OPSConsoleUsersActions.getUserDetailsFail(error?.toString()));
    yield call(handleServiceError, error, GET_USER_DETAILS_FAIL_TOAST, true);
  }
}

function* changeUserStatusSaga() {
  try {
    const isActive: boolean = yield select(OPSConsoleUsersSelectors.selectUserIsActive);
    const changeUserStatus: UserAdminDataFullRecord[] = yield select(changeUserStatusRequest);
    yield call(updateUser, changeUserStatus[0]);

    yield put(OPSConsoleUsersActions.changeUserStatusSuccess());
    yield put(notificationsActions.showToast(isActive ? DEACTIVATE_USER_SUCCESS_TOAST : ACTIVATE_USER_SUCCESS_TOAST));
    if (isActive) {
      yield put(deactivateUserModalActions.closeModal());
    }
    yield put(OPSConsoleUsersActions.getUsersRequest());
  } catch (error: unknown) {
    yield put(OPSConsoleUsersActions.changeUserStatusFail(error?.toString()));
    yield call(handleServiceError, error, DEACTIVATE_USER_FAIL_TOAST);
  }
}

function* deleteUserSaga() {
  try {
    const userDetails: UserDetails = yield select(OPSConsoleUsersSelectors.selectUserDetails);
    yield call(deleteUser, userDetails.email);

    yield put(OPSConsoleUsersActions.changeUserStatusSuccess());
    yield put(notificationsActions.showToast(DELETE_USER_SUCCESS_TOAST));
    yield put(deleteUserModalActions.closeModal());
    yield put(OPSConsoleUsersActions.getUsersRequest());
  } catch (error: unknown) {
    yield put(OPSConsoleUsersActions.deleteUserFail(error?.toString()));
    yield call(handleServiceError, error, DELETE_USER_FAIL_TOAST);
  }
}

export function* watchOPSConsoleUsersSaga() {
  yield takeLatest(UsersActionTypes.GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(UsersActionTypes.GET_USER_DETAILS_REQUEST, getUserDetailsSaga);
  yield takeLatest(UsersActionTypes.CHANGE_USER_STATUS_REQUEST, changeUserStatusSaga);
  yield takeLatest(UsersActionTypes.DELETE_USER_REQUEST, deleteUserSaga);
}
