import { Tenant, License, Role, OrgType, LicenseType, Account, CreateActionLogInput } from '../../../API';
import { Subscription } from '../../billing';

export type DateTimeType = {
  dateTime: {
    date: {
      year: number;
      month: number;
      day: number;
    };
    time: {
      hour: number;
      minute: number;
      second: number;
      nano: number;
    };
  };
};

export type LicenseDataType = {
  type: LicenseType;
  updatedAt: string;
  endDate: string;
  startDate: string;
  owned: number;
  assigned: number;
};

export type OrgsDataType = {
  name: string;
  tenantId: string;
  type: OrgType;
  status: string;
  createdAt: string;
  licenses: LicenseDataType[];
  accountId: string;
  accountName: string;
  userRecords: OrgDetailsUserRecord[];
  note: string;
  percentOfWeekAdoption: string;
  percentOfMonthAdoption: string;
  subscription?: Subscription;
  bookedMeetings: number;
};

export type OrgsDataTableType = {
  tenantId: string;
  name: string;
  status: ORG_STATUS_VALUES;
  type: OrgType;
  term: LicenseType;
  subscriptionId: string;
  owned: number;
  assigned: number;
  percentOfWeekAdoption: string;
  percentOfMonthAdoption: string;
  createdAt: string;
  updatedAt: string;
  accountId: string;
  accountName: string;
  note: string;
  amount: string;
  amountPerUser: string;
  bookedMeetings: number;
  startDate: string;
  endDate: string;
  daysLeft: number;
  userEmails: string[];
  licenseChanges: OrgsLicenseChanges;
  termChanges: OrgsTermChanges;
};

export type GetOrgsResponse = {
  tenants: OrgsDataType[];
};

export type OrgDetailsUserRecord = {
  name: string;
  email: string;
  roleId: string;
  status: string;
  userId: string;
  updatedAt: string;
  lastActive: string;
};

export type OPSConsoleTenant = Omit<Tenant, ('__typename' & 'createdAt') | 'updatedAt' | 'subscription'> & {
  createdAt: string;
  updatedAt: string;
  subscription: Subscription;
};

export type OPSConsoleLicense = Omit<License, '__typename' | 'createdAt' | 'updatedAt'> & {
  createdAt: string;
  updatedAt: string;
};

export type OPSConsoleRole = Omit<Role, '__typename'>;

export type GetOrgDetailsResponse = {
  tenantRecord: OPSConsoleTenant;
  userRecords: OrgDetailsUserRecord[];
  roleRecords: OPSConsoleRole[];
  licenseRecords: OPSConsoleLicense[];
  accountList: Account[];
  bookedMeetings: number;
};

export type OrgDetailsType = {
  tenantRecord: OPSConsoleTenant;
  // users will be saved separately
  roleRecords: OPSConsoleRole[];
  licenseRecords: OPSConsoleLicense[];
  accountId: string;
  bookedMeetings: number;
  percentOfWeekAdoption: string | null;
  percentOfMonthAdoption: string | null;
};

export type ExtendLicenseRequest = {
  expirationDate: Date;
  extendDays: number;
  isFromDetails?: boolean;
  superAdminsEmails: string[];
};

export type ExpireTrialRequest = {
  isFromDetails?: boolean;
  superAdminsEmails: string[];
};

export type SaveOrgDetailsRequest = {
  status: string;
  endDate: Date;
  note?: string;
};

// Snake case bacause ChargeBee use it (billing_address / billingAddress)
export type PayLaterDetails = {
  email?: string;
  term: string;
  quantity: number;
  billing_address: PayLaterDetailsAddress;
};

// Snake case bacause ChargeBee use it
export type PayLaterDetailsAddress = {
  first_name: string;
  last_name: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  state_code?: string;
  zip?: string;
  country: string;
};

export interface PayLaterRequest extends PayLaterDetails {
  tenantId: string;
}

export enum ORG_STATUS_VALUES {
  'Active' = 'Active',
  'Expired' = 'Expired',
  'PaymentLate' = 'PaymentLate',
}

export enum CHANGES_FILTER_VALUES {
  'Reduce' = 'Reduce',
  'Added' = 'Added',
  'None' = 'None',
}

export type OrgFilters = {
  term: LicenseType[];
  status: ORG_STATUS_VALUES[];
  changes: CHANGES_FILTER_VALUES[];
};

export type OrgsChartData = {
  x: string;
  y: number;
};

export enum ORGS_CHART_VALUES {
  '1_MONTH' = '1',
  '3_MONTHS' = '3',
  '6_MONTHS' = '6',
  '12_MONTHS' = '12',
}
export type StatisticsByOrgs = {
  [key: string]: CreateActionLogInput[];
};

export type OrgLicenseLog = {
  createdAt: string;
  value: number;
};
export type OrgsLicenseChanges = {
  logs: OrgLicenseLog[];
  value: number;
};

export type OrgTermLog = {
  createdAt: string;
  prevTerm: LicenseType;
  currentTerm: LicenseType;
  isScheduled: boolean;
};
export type OrgsTermChanges = {
  logs: OrgTermLog[];
  value: number; // -1 negative, 0 none, 1 positive changes
};

export type LogsByDate = {
  [key: string]: CreateActionLogInput[];
};
