import { t } from '../../../../i18n/i18n';

export default {
  warningTitle: t('SmartAlertsWarningModal:WARNING_TITLE'),
  update: t('Common:UPDATE'),
  createNewSmartAlert: t('SmartAlertsWarningModal:CREATE_NEW'),
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  next: t('Common:NEXT'),
  back: t('Common:BACK'),
  FOR: t('Common:FOR'),
  bookingPagesTitle: t('BookingPages:TITLE'),
  bookingTemplatesTitle: t('BookingTemplates:TITLE'),
  howToApplyChange: t('SmartAlertsWarningModal:HOW_TO_APPLY_CHANGE'),
  createNewTitle: t('SmartAlertsWarningModal:CREATE_NEW_TITLE'),
  createNewSubTitleForBookingPage: t('SmartAlertsWarningModal:CREATE_NEW_SUB_TITLE_FOR_BOOKING_PAGE'),
  createNewSubTitleForBookingTemplate: t('SmartAlertsWarningModal:CREATE_NEW_SUB_TITLE_FOR_BOOKING_TEMPLATE'),
  createNewLabelTitle: t('SmartAlertsWarningModal:CREATE_NEW_LABEL_TITLE'),
  createNewInputPlaceholder: t('SmartAlertsWarningModal:CREATE_NEW_INPUT_PLACEHOLDER'),
  updateAllTitle: t('SmartAlertsWarningModal:UPDATE_ALL_TITLE'),
  updateAllSubTitle: t('SmartAlertsWarningModal:UPDATE_ALL_SUB_TITLE'),
};
