export const Users = Object.freeze({
  ADD_TO_TEAM: `Zum Team hinzufügen`,
  ADD_TO_TEAM_PLACEHOLDER: `Team/s auswählen`,
  ALREADY_INVITED_COUNT: `Bereits ein Benutzer`,
  ASSIGN_BOOKING_TEMPLATES: `Buchungsvorlagen zuweisen`,
  ASSIGN_BOOKING_TEMPLATES_PLACEHOLDER: `Buchungsvorlage/n auswählen`,
  ASSIGN_ROLE: `Rolle zuweisen`,
  ASSIGN_WORKSPACE: `Arbeitsbereich zuweisen`,
  CLOSE: `Schließen`,
  CONFIRM_DIALOG_DESC: `Zur Bestätigung wird eine E-Mail-Einladung wie folgt gesendet:`,
  CSV_INVITE_MESSAGE_TITLE: `Großes Team in einer Minute einrichten`,
  CSV_TEMPLATE_DESC: `und fügen Sie die E-Mail-Adressen und Rollen Ihrer Benutzer hinzu.`,
  CSV_TEMPLATE_TITLE: `Vorlage herunterladen`,
  CSV_UPLOAD_DESC: `CSV-Datei auf Ihrem Computer auswählen`,
  CSV_UPLOAD_TITLE: `CSV hochladen`,
  DELETE_TOOLTIP: `Sie können nur Benutzer mit "Einladung gesendet" löschen, die Sie eingeladen haben. Super-Administratoren können jeden Benutzer löschen.`,
  EMAIL: `E-Mail`,
  FULL_NAME: `Vollständiger Name`,
  HIDE_INACTIVE_USERS: `Inaktive Benutzer ausblenden`,
  INVITE: `Einladen`,
  INVITE_MESSAGE_DESC: `Geben Sie E-Mail-Adressen ein oder fügen Sie sie ein, und wir senden eine Einladung an Ihre Teammitglieder. Trennen Sie die E-Mail-Adressen mit einem Komma.`,
  INVITE_MESSAGE_TITLE: `E-Mail-Adressen`,
  INVITE_TITLE: `Benutzer einladen`,
  LAST_ACTIVE: `Zuletzt aktiv`,
  LOADING_LABEL_1: `Doppelte überprüfen`,
  LOADING_LABEL_2: `Benutzer importieren`,
  LOADING_LABEL_3: `Attribute zuweisen`,
  LOADING_LABEL_4: `Einladungen senden`,
  BOOKING_TEMPLATE: `Buchungsvorlage`,
  NAME: `Name`,
  NO_EMAIL_ADDRESSES: `Keine E-Mail-Adressen gefunden.`,
  OPTIONAL: `Optional`,
  PASTE_EMAILS_TITLE: `E-Mail hinzufügen`,
  ROLE: `Rolle`,
  STATUS: `Status`,
  STATUS_INVITE_SENT: `Eingeladen`,
  STATUS_ACTIVE: `Aktiv`,
  STATUS_INACTIVE: `Inaktiv`,
  SUPER_ADMIN_ACCESS_DESCRIPTION: `Super-Admin hat Zugriff auf alle Arbeitsbereiche`,
  TEAM: `Team`,
  TOOLTIP: `Laden Sie Mitarbeiter ein und verwalten Sie den Zugriff auf dieses SUMO1-Konto`,
  USER_COUNT: `Einzigartige Benutzer, die eingeladen werden sollen`,
  WORKSPACE: `Arbeitsbereich`,
  DELETED_USERS: `Gelöschte Benutzer`,
});

export const UsersConfirmDialog = Object.freeze({
  DELETE_DESCRIPTION: `Möchten Sie wirklich löschen`,
  DELETE_COMMENT_1: `Das Deaktivieren oder Löschen eines Benutzers gibt eine Lizenz frei.`,
  DELETE_NOTE_DESCRIPTION: `Alle Benutzerdaten werden dauerhaft von den SUMO1-Servern gelöscht.`,
  DELETE_NOTE_TITLE: `DSGVO-Konformität`,
  DELETE_TITLE: `Löschen`,
  DISABLE_COMMENT_1: `Das Deaktivieren eines Benutzers gibt eine Lizenz frei.`,
  DISABLE_COMMENT_2: `Termine werden nicht gelöscht oder abgesagt.`,
  DISABLE_DESCRIPTION: `Möchten Sie wirklich deaktivieren`,
  DISABLE_TITLE: `Benutzer deaktivieren`,
});

export const EditUser = Object.freeze({
  ACCESS_TITLE: `Zugriff`,
  ACTIVE: `Aktiv`,
  ACTIVE_TOOLTIP: `Aktivieren Sie dieses Kontrollkästchen, um diesen Benutzer zu deaktivieren und eine Lizenz freizugeben.`,
  CONTACT_DETAILS_TITLE: `Kontaktdetails`,
  DEACTIVATE_HIMSELF: `Sie dürfen sich nicht selbst deaktivieren.`,
  DAYS_10: `10 Tage`,
  DAYS_15: `15 Tage`,
  DAYS_20: `20 Tage`,
  DAYS_30: `30 Tage`,
  EXPIRATION_DATE: `Verfallsdatum`,
  HIGHER_ROLE_DESCRIPTION: `Dieser Benutzer hat eine höhere Rolle als Sie,`,
  INVITE_SENT_TOOLTIP: `Der Benutzer hat sich noch nicht angemeldet. Das Löschen des Datensatzes gibt die Lizenz frei.`,
  BOOKING_TEMPLATES: `Buchungsvorlagen`,
  BOOKING_TEMPLATES_MESSAGE: `Dieser Benutzer unterstützt die folgenden Buchungsvorlagen`,
  BOOKING_TEMPLATES_TOOLTIP: `Wählen Sie zuerst einen Arbeitsbereich aus.`,
  NO_PERMISSION_DESCRIPTION: `Sie haben keine Berechtigung, Benutzer zu bearbeiten,`,
  ONLY_ONE_SUPER_ADMIN: `Außer Ihnen gibt es keine weiteren Superadministratoren. Um die Rolle zu ändern, laden Sie mindestens einen weiteren Superadministrator ein`,
  READONLY_DESCRIPTION: `nur der Lesemodus ist verfügbar.`,
  TEAMS: `Teams`,
  TEAMS_MESSAGE: `Dieser Benutzer ist Mitglied der folgenden Teams`,
  TITLE: `Benutzer bearbeiten`,
  USER_OF_WORKSPACE_TITLE: `Benutzer des Arbeitsbereichs`,
  WORKSPACE_MESSAGE: `Dieser Benutzer kann die folgenden Arbeitsbereiche verwenden`,
});

export const UsersToastsNotifications = Object.freeze({
  EDIT_USER_ERROR_TOAST: `Fehler bei der Bearbeitungsanfrage für Benutzer`,
  EDIT_USER_SUCCESS_TOAST: `Bearbeitungsanfrage für Benutzer erfolgreich`,
  ENABLE_USER_TOAST_HEADER: `Benutzer aktiviert`,
  ENABLE_USER_SUCCESS_TOAST: `ist jetzt aktiviert.`,
  GET_LICENSES_ERROR_MESSAGE: `Anfrage für Lizenzen fehlgeschlagen`,
  GET_USERS_ERROR_MESSAGE: `Anfrage für Benutzer fehlgeschlagen`,
  INVITE_USERS_EMAIL_INVALID: `Eine oder mehrere der eingegebenen E-Mail-Adressen sind ungültig.`,
  INVITE_USERS_ERROR_TOAST: `Einladungsanfrage für Benutzer fehlgeschlagen`,
  INVITE_USERS_SUCCESS_TOAST: `Einladungsanfrage für Benutzer erfolgreich`,
  DELETE_USER_TOAST_HEADER: `Benutzer gelöscht`,
  DELETE_USER_ERROR_TOAST: `Anfrage zum Löschen des Benutzers fehlgeschlagen`,
  DELETE_USER_SUCCESS_TOAST: `ist jetzt gelöscht.`,
  DISABLE_USER_TOAST_HEADER: `Benutzer deaktiviert`,
  DISABLE_USER_ERROR_TOAST: `Fehler bei der Deaktivierungs-/Aktivierungsanfrage für Benutzer`,
  DISABLE_USER_SUCCESS_TOAST: `ist jetzt deaktiviert.`,
});

export const License = Object.freeze({
  ASSIGNED: `Zugewiesen`,
  BUY_LICENSES: `Lizenzen kaufen`,
  MY_BILLINGS: `Meine Abrechnungen`,
  SUBSCRIPTION: `Abonnement`,
  LICENSES: `Lizenzen`,
  PAID_EXPIRES_MESSAGE_PART_1: `Zahlung ausstehend:`,
  PAID_EXPIRES_MESSAGE_PART_2_AGO: `vor Tagen`,
  PAID_EXPIRES_MESSAGE_PART_2_LEFT: `Tage verbleibend`,
  PAID_EXPIRES_MESSAGE_PART_2_TODAY: `heute`,
  REMAINING: `Verbleibend`,
  REMAINING_MESSAGE: `Sie haben nicht genügend verbleibende Lizenzen`,
  TITLE: `Benutzer Lizenzstatus`,
  TRIAL_EXPIRES_MESSAGE: `Kostenlose Testversion endet bald.`,
  SEATS_REMOVAL_SCHEDULED: `Geplante Änderungen zum Entfernen von Plätzen.`,
});
