import { t } from '../../../../i18n/i18n';

export default {
  smsLabelPart1: t('EditBookingTemplateNotificationsStep:SMS_LABEL_PART1'),
  smsLabelPart2: t('EditBookingTemplateNotificationsStep:SMS_LABEL_PART2'),
  smartAlerts: t('SmartAlerts:TITLE'),
  basicAlerts: t('SmartAlerts:BASIC_ALERTS'),
  addSmartAlert: t('SmartAlerts:ADD_SMART_ALERT'),
  cancelationTitle: t('EditBookingTemplateNotificationsStep:CANCELATION_TITLE'),
  confirmationTitle: t('EditBookingTemplateNotificationsStep:CONFIRMATION_TITLE'),
  followUpTitle: t('EditBookingTemplateNotificationsStep:FOLLOW_UP_TITLE'),
  reminderTitle: t('EditBookingTemplateNotificationsStep:REMINDER_TITLE'),
  rescheduleTitle: t('EditBookingTemplateNotificationsStep:RESCHEDULE_TITLE'),
  smsTitle: t('EditBookingTemplateNotificationsStep:SMS_TITLE'),
};
