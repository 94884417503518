import { t } from '../../../i18n/i18n';

export default {
  selected: t('Common:SELECTED'),
  icon: t('EditSmartAlert:ICON'),
  name: t('EditSmartAlert:NAME'),
  namePlaceholder: t('EditSmartAlert:NAME_PLACEHOLDER'),
  hide: t('EditSmartAlert:HIDE'),
  hideDesc: t('EditSmartAlert:HIDE_DESCRIPTION'),
  type: t('Common:TYPE'),
  alert: t('EditSmartAlert:ALERT'),
  template: t('EditSmartAlert:TEMPLATE'),
  applyTo: t('EditSmartAlert:APPLY_TO'),
  select: t('Common:SELECT'),
  shareWith: t('EditSmartAlert:SHARE_WITH'),
  workspaces: t('EditSmartAlert:WORKSPACES'),
  users: t('Common:USERS'),
  whenTitle: t('EditSmartAlert:WHEN_TITLE'),
  doThis: t('EditSmartAlert:DO_THIS'),
  personalize: t('EditSmartAlert:PERSONALIZE'),
  howLongAfter: t('EditSmartAlert:HOW_LONG_AFTER'),
  immediately: t('EditSmartAlert:IMMEDIATELY'),
  create: t('Common:CREATE'),
  save: t('Common:SAVE'),
  cancel: t('Common:CANCEL'),
  description: t('Common:DESCRIPTION'),
  descPlaceholder: t('EditSmartAlert:TEMPLATE_DESCRIPTION_PLACEHOLDER'),
  bookingPages: t('EditSmartAlert:BOOKING_PAGES'),
  bookingTemplates: t('EditSmartAlert:BOOKING_TEMPLATES'),
  needsYourAttention: t('EditSmartAlert:NEEDS_YOUR_ATTENTION'),
  createAlertTitle: t('EditSmartAlert:CREATE_ALERT_TITLE'),
  createTemplateTitle: t('EditSmartAlert:CREATE_TEMPLATE_TITLE'),
  editAlertTitle: t('EditSmartAlert:EDIT_ALERT_TITLE'),
  editTemplateTitle: t('EditSmartAlert:EDIT_TEMPLATE_TITLE'),
};
