import { GetOrgDetailsResponse, GetOrgsResponse, OPSConsoleLicense, OPSConsoleTenant, PayLaterDetails } from './types';
import { handleAPIRequest } from '../../utils/reduxUtils';
import { API_PUBLIC } from '../../../types/constants';
// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getOrgs = async (): Promise<GetOrgsResponse> => {
  return handleAPIRequest<GetOrgsResponse>(`${API_PUBLIC}/opsconsole/getOrgs`, {});
};

export const getOrgDetailById = async (tenantId: string): Promise<GetOrgDetailsResponse> => {
  return handleAPIRequest<GetOrgDetailsResponse>(`${API_PUBLIC}/opsconsole/getOrgDetails`, { tenantId });
};

export const updateLicense = async (
  tenantId: string,
  id: string,
  payload: Partial<OPSConsoleLicense>
): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/updateLicense`, { tenantId, id, ...payload });
};

export const updateTenant = async (tenantId: string, payload: Partial<OPSConsoleTenant>): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/updateTenant`, {
    tenant: { tenantId, ...payload },
    fromOPSConsole: true,
  });
};

export const convertToPayLater = async (input: PayLaterDetails): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/billing/payLater`, input);
};

export const sendUIEmails = async (toAddresses: string[], subjectText: string, bodyText: string): Promise<void> => {
  return handleAPIRequest<void>(`${API_PUBLIC}/sendUIEmails`, { toAddresses, subjectText, bodyText });
};
