import { t } from '../../../../i18n/i18n';

export default {
  personalizedTitle: t('EditBookingPageInviteeStep:PERSONALIZED_TITLE'),
  personalizedDescription: t('EditBookingPageInviteeStep:PERSONALIZED_DESCRIPTION'),
  personalInfo: t('EditBookingPageInviteeStep:PERSONAL_INFO'),
  questions: t('EditBookingPageInviteeStep:QUESTIONS'),
  questionsDescription: t('EditBookingPageInviteeStep:QUESTIONS_DESCRIPTION'),
  permissions: t('EditBookingPageInviteeStep:PERMISSIONS'),
  permissionsDescription: t('EditBookingPageInviteeStep:PERMISSIONS_DESCRIPTION'),
  optionInviteOthers: t('EditBookingPageInviteeStep:OPTION_INVITE_OTHERS'),
  optionInviteOthersTooltip: t('EditBookingPageInviteeStep:OPTION_INVITE_OTHERS_TOOLTIP'),
  optionEnterNote: t('EditBookingPageInviteeStep:OPTION_ENTER_NOTE'),
  optionEnterNoteTooltip: t('EditBookingPageInviteeStep:OPTION_ENTER_NOTE_TOOLTIP'),
};
