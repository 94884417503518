import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const availabilityOverrideModalActions = modalsActionsFactory(ModalName.AVAILABILITY_OVERRIDE_MODAL_NAME);
export const availabilityOverrideModalSelectors = modalsSelectorsFactory(ModalName.AVAILABILITY_OVERRIDE_MODAL_NAME);

export const newAvailabilityNameModalActions = modalsActionsFactory(ModalName.NEW_AVAILABILITY_NAME);
export const newAvailabilityNameModalSelectors = modalsSelectorsFactory(ModalName.NEW_AVAILABILITY_NAME);

export const changeAvailabilityNameModalActions = modalsActionsFactory(ModalName.CHANGE_AVAILABILITY_NAME);
export const changeAvailabilityNameModalSelectors = modalsSelectorsFactory(ModalName.CHANGE_AVAILABILITY_NAME);
