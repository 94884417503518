import { t } from '../../../../i18n/i18n';

export default {
  account: t('OPSConsoleOrgs:ACCOUNT_TITLE'),
  adoptionPercent: t('OPSConsoleOrgs:ADOPTION_PERCENT'),
  arr: t('OPSConsoleOrgs:AMOUNT_TITLE'),
  bookedNumber: t('OPSConsoleOrgs:BOOKED_NUMBER'),
  changes: t('OrgsChanges:CHANGES_TITLE'),
  daysLeft: t('OPSConsoleOrgs:DAYS_LEFT_TITLE'),
  lastActive: t('OPSConsoleOrgs:LAST_ACTIVE_TITLE'),
  licensesNum: t('OPSConsoleOrgs:LICENSES_NUMBER'),
  orgId: t('OPSConsoleOrgs:ORG_ID_TITLE'),
  status: t('OPSConsoleOrgs:STATUS_TITLE'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
  subscriptionId: t('OPSConsoleOrgs:SUBSCRIPTION_ID'),
  type: t('OPSConsoleOrgs:TYPE_TITLE'),
  termLabel: {
    TRIAL: t('OPSConsoleOrgs:TRIAL'),
    MONTHLY: t('OPSConsoleOrgs:TERM_MONTHLY'),
    ANNUAL: t('OPSConsoleOrgs:TERM_ANNUALLY'),
  },
  reduce: t('OrgsChanges:REDUCE'),
  added: t('OrgsChanges:ADDED'),
  license: t('OrgsChanges:LICENSE'),
  licenses: t('OrgsChanges:LICENSES'),
  to: t('Common:TO'),
  changedLabel: t('OrgsChanges:CHANGED_LABEL'),
  scheduledChangedLabel: t('OrgsChanges:SCHEDULED_CHANGED_LABEL'),
};
