import 'chartjs-adapter-dayjs-4';
import { useDispatch, useSelector } from 'react-redux';
import { getLicenseTotal, ORGS_CHART_VALUES, orgsActions, orgsSelectors } from '../../../../store/opsConsole/orgs';
import labels from './labels';
import { OPSConsoleChart } from '../../OPSConsoleChart/OPSConsoleChart';

export const OrgsChart = () => {
  const dispatch = useDispatch();
  const orgsChartData = useSelector(orgsSelectors.selectOrgsChartData);
  const chartFilter = useSelector(orgsSelectors.selectOrgsChartFilter);
  const orgsLogsFetching = useSelector(orgsSelectors.selectOrgsLogsFetching);
  const orgsTotal = getLicenseTotal(orgsChartData);

  const handleFilterChange = (value: ORGS_CHART_VALUES) => {
    dispatch(orgsActions.getOrgsLogsRequest(value));
  };

  return (
    <OPSConsoleChart
      data={orgsChartData}
      title={labels.title}
      total={orgsTotal.toFixed()}
      totalLabel={labels.total}
      isFethcing={orgsLogsFetching}
      filter={chartFilter}
      handleFilterChange={handleFilterChange}
    />
  );
};
