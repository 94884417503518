import { BillingAddress, BillingCalculateTaxesRequestPayload, ChargeeBeeResponse, ItemPrices } from './types';
import { PayLaterDetails } from '../opsConsole/orgs';
import { BillingCalculateTaxes } from '../../generated-sources/internal-api/models/BillingCalculateTaxes';

export enum BillingActionTypes {
  GET_BILLING_REQUEST = 'billing/GET_BILLING_REQUEST',
  GET_BILLING_SUCCESS = 'billing/GET_BILLING_SUCCESS',
  GET_BILLING_FAIL = 'billing/GET_BILLING_FAIL',
  CHANGE_REQUEST = 'billing/CHANGE_REQUEST',
  CHANGE_SUCCESS = 'billing/CHANGE_SUCCESS',
  CHANGE_FAIL = 'billing/CHANGE_FAIL',
  CANCEL_SUBSCRIPTION_REQUEST = 'billing/CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS = 'billing/CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAIL = 'billing/CANCEL_SUBSCRIPTION_FAIL',
  UPDATE_CONTACTS_REQUEST = 'billing/UPDATE_CONTACTS_REQUEST',
  UPDATE_CONTACTS_SUCCESS = 'billing/UPDATE_CONTACTS_SUCCESS',
  UPDATE_CONTACTS_FAIL = 'billing/UPDATE_CONTACTS_FAIL',
  UPDATE_BILLING_ADDRESS_REQUEST = 'billing/UPDATE_BILLING_ADDRESS_REQUEST',
  UPDATE_BILLING_ADDRESS_SUCCESS = 'billing/UPDATE_BILLING_ADDRESS_SUCCESS',
  UPDATE_BILLING_ADDRESS_FAIL = 'billing/UPDATE_BILLING_ADDRESS_FAIL',
  DOWNLOAD_REQUEST = 'billing/DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS = 'billing/DOWNLOAD_SUCCESS',
  DOWNLOAD_FAIL = 'billing/DOWNLOAD_FAIL',
  DELETE_SCHEDULED_CHANGE_REQUEST = 'billing/DELETE_SCHEDULED_CHANGE_REQUEST',
  DELETE_SCHEDULED_CHANGE_SUCCESS = 'billing/DELETE_SCHEDULED_CHANGE_SUCCESS',
  DELETE_SCHEDULED_CHANGE_FAIL = 'billing/DELETE_SCHEDULED_CHANGE_FAIL',
  SET_BILLING_ADDRESS = 'billing/SET_BILLING_ADDRESS',
  SET_CONTACT_EMAILS = 'billing/SET_CONTACT_EMAILS',
  SET_SEND_BILLING_EMAILS = 'billing/SET_SEND_BILLING_EMAILS',
  SET_ADD_OR_REMOVE_SEATS = 'billing/SET_ADD_OR_REMOVE_SEATS',
  SET_CHANGE_SEATS = 'billing/SET_CHANGE_SEATS',
  SET_TERM = 'billing/SET_TERM',
  GET_ITEM_PRICES_REQUEST = 'billing/GET_ITEM_PRICES_REQUEST',
  GET_ITEM_PRICES_SUCCESS = 'billing/GET_ITEM_PRICES_SUCCESS',
  GET_ITEM_PRICES_FAIL = 'billing/GET_ITEM_PRICES_FAIL',
  REFRESH_TRIAL_LICENSE_REQUEST = 'authentication/REFRESH_TRIAL_LICENSE_REQUEST',
  REFRESH_TRIAL_LICENSE_SUCCESS = 'authentication/REFRESH_TRIAL_LICENSE_SUCCESS',
  REFRESH_TENANT_REQUEST = 'authentication/REFRESH_TENANT_REQUEST',
  REFRESH_TENANT_SUCCESS = 'authentication/REFRESH_TENANT_SUCCESS',
  PAY_LATER_REQUEST = 'billing/PAY_LATER_REQUEST',
  PAY_LATER_SUCCESS = 'billing/PAY_LATER_SUCCESS',
  PAY_LATER_FAIL = 'billing/PAY_LATER_FAIL',
  REACTIVATE_SUBSCRIPTION_REQUEST = 'billing/REACTIVATE_SUBSCRIPTION_REQUEST',
  REACTIVATE_SUBSCRIPTION_SUCCESS = 'billing/REACTIVATE_SUBSCRIPTION_SUCCESS',
  REACTIVATE_SUBSCRIPTION_FAIL = 'billing/REACTIVATE_SUBSCRIPTION_FAIL',
  CALCULATE_TAXES_REQUEST = 'billing/CALCULATE_TAXES_REQUEST',
  CALCULATE_TAXES_SUCCESS = 'billing/CALCULATE_TAXES_SUCCESS',
  CALCULATE_TAXES_FAIL = 'billing/CALCULATE_TAXES_FAIL',
  CALCULATE_TAXES_RESET = 'billing/CALCULATE_TAXES_RESET',
}

export type BillingAction =
  | { type: BillingActionTypes.GET_BILLING_REQUEST }
  | { type: BillingActionTypes.GET_BILLING_SUCCESS; payload: ChargeeBeeResponse }
  | { type: BillingActionTypes.GET_BILLING_FAIL; error: string }
  | { type: BillingActionTypes.CHANGE_REQUEST }
  | { type: BillingActionTypes.CHANGE_SUCCESS }
  | { type: BillingActionTypes.CHANGE_FAIL; error: string }
  | { type: BillingActionTypes.CANCEL_SUBSCRIPTION_REQUEST }
  | { type: BillingActionTypes.CANCEL_SUBSCRIPTION_SUCCESS }
  | { type: BillingActionTypes.CANCEL_SUBSCRIPTION_FAIL; error: string }
  | { type: BillingActionTypes.UPDATE_CONTACTS_REQUEST }
  | { type: BillingActionTypes.UPDATE_CONTACTS_SUCCESS }
  | { type: BillingActionTypes.UPDATE_CONTACTS_FAIL; error: string }
  | { type: BillingActionTypes.UPDATE_BILLING_ADDRESS_REQUEST }
  | { type: BillingActionTypes.UPDATE_BILLING_ADDRESS_SUCCESS }
  | { type: BillingActionTypes.UPDATE_BILLING_ADDRESS_FAIL; error: string }
  | { type: BillingActionTypes.DOWNLOAD_REQUEST; payload: string }
  | { type: BillingActionTypes.DOWNLOAD_SUCCESS }
  | { type: BillingActionTypes.DOWNLOAD_FAIL; error: string }
  | { type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST; payload: string }
  | { type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS }
  | { type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL; error: string }
  | { type: BillingActionTypes.SET_BILLING_ADDRESS; payload: BillingAddress }
  | { type: BillingActionTypes.SET_CONTACT_EMAILS; payload: string[] }
  | { type: BillingActionTypes.SET_SEND_BILLING_EMAILS; payload: boolean }
  | { type: BillingActionTypes.SET_ADD_OR_REMOVE_SEATS; payload: boolean }
  | { type: BillingActionTypes.SET_CHANGE_SEATS; payload: number }
  | { type: BillingActionTypes.SET_TERM; payload: string }
  | { type: BillingActionTypes.GET_ITEM_PRICES_REQUEST }
  | { type: BillingActionTypes.GET_ITEM_PRICES_SUCCESS; payload: ItemPrices[] }
  | { type: BillingActionTypes.GET_ITEM_PRICES_FAIL; error: string }
  | { type: BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST }
  | { type: BillingActionTypes.REFRESH_TRIAL_LICENSE_SUCCESS }
  | { type: BillingActionTypes.REFRESH_TENANT_REQUEST }
  | { type: BillingActionTypes.REFRESH_TENANT_SUCCESS }
  | { type: BillingActionTypes.PAY_LATER_REQUEST; payload: PayLaterDetails }
  | { type: BillingActionTypes.PAY_LATER_SUCCESS }
  | { type: BillingActionTypes.PAY_LATER_FAIL; error: string }
  | { type: BillingActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST }
  | { type: BillingActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS }
  | { type: BillingActionTypes.REACTIVATE_SUBSCRIPTION_FAIL; error: string }
  | { type: BillingActionTypes.CALCULATE_TAXES_REQUEST; payload: BillingCalculateTaxesRequestPayload }
  | { type: BillingActionTypes.CALCULATE_TAXES_SUCCESS; payload: BillingCalculateTaxes }
  | { type: BillingActionTypes.CALCULATE_TAXES_FAIL; error: string }
  | { type: BillingActionTypes.CALCULATE_TAXES_RESET };

const getBillingRequest = (): BillingAction => ({ type: BillingActionTypes.GET_BILLING_REQUEST });
const getBillingSuccess = (payload: ChargeeBeeResponse): BillingAction => ({
  type: BillingActionTypes.GET_BILLING_SUCCESS,
  payload,
});
const getBillingFail = (error: string): BillingAction => ({ type: BillingActionTypes.GET_BILLING_FAIL, error });

const changeRequest = (): BillingAction => ({ type: BillingActionTypes.CHANGE_REQUEST });
const changeSuccess = (): BillingAction => ({ type: BillingActionTypes.CHANGE_SUCCESS });
const changeFail = (error: string): BillingAction => ({ type: BillingActionTypes.CHANGE_FAIL, error });

const cancelSubscriptionRequest = (): BillingAction => ({ type: BillingActionTypes.CANCEL_SUBSCRIPTION_REQUEST });
const cancelSubscriptionSuccess = (): BillingAction => ({ type: BillingActionTypes.CANCEL_SUBSCRIPTION_SUCCESS });
const cancelSubscriptionFail = (error: string): BillingAction => ({
  type: BillingActionTypes.CANCEL_SUBSCRIPTION_FAIL,
  error,
});

const updateContactsRequest = (): BillingAction => ({ type: BillingActionTypes.UPDATE_CONTACTS_REQUEST });
const updateContactsSuccess = (): BillingAction => ({ type: BillingActionTypes.UPDATE_CONTACTS_SUCCESS });
const updateContactsFail = (error: string): BillingAction => ({ type: BillingActionTypes.UPDATE_CONTACTS_FAIL, error });

const updateBillingAddressRequest = (): BillingAction => ({ type: BillingActionTypes.UPDATE_BILLING_ADDRESS_REQUEST });
const updateBillingAddressSuccess = (): BillingAction => ({ type: BillingActionTypes.UPDATE_BILLING_ADDRESS_SUCCESS });
const updateBillingAddressFail = (error: string): BillingAction => ({
  type: BillingActionTypes.UPDATE_BILLING_ADDRESS_FAIL,
  error,
});

const downloadRequest = (payload: string): BillingAction => ({ type: BillingActionTypes.DOWNLOAD_REQUEST, payload });
const downloadSuccess = (): BillingAction => ({
  type: BillingActionTypes.DOWNLOAD_SUCCESS,
});
const downloadFail = (error: string): BillingAction => ({ type: BillingActionTypes.DOWNLOAD_FAIL, error });

const deleteScheduledChangeRequest = (payload: string): BillingAction => ({
  type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST,
  payload,
});
const deleteScheduledChangeSuccess = (): BillingAction => ({
  type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_SUCCESS,
});
const deleteScheduledChangeFail = (error: string): BillingAction => ({
  type: BillingActionTypes.DELETE_SCHEDULED_CHANGE_FAIL,
  error,
});

const setBillingAddress = (payload: BillingAddress): BillingAction => ({
  type: BillingActionTypes.SET_BILLING_ADDRESS,
  payload,
});
const setContactEmails = (payload: string[]): BillingAction => ({
  type: BillingActionTypes.SET_CONTACT_EMAILS,
  payload,
});
const setSendBillingEmails = (payload: boolean): BillingAction => ({
  type: BillingActionTypes.SET_SEND_BILLING_EMAILS,
  payload,
});

const setAddOrRemoveSeats = (payload: boolean): BillingAction => ({
  type: BillingActionTypes.SET_ADD_OR_REMOVE_SEATS,
  payload,
});

const setChangeSeats = (payload: number): BillingAction => ({
  type: BillingActionTypes.SET_CHANGE_SEATS,
  payload,
});

const setTerm = (payload: string): BillingAction => ({
  type: BillingActionTypes.SET_TERM,
  payload,
});

const getItemPricesRequest = (): BillingAction => ({ type: BillingActionTypes.GET_ITEM_PRICES_REQUEST });
const getItemPricesSuccess = (payload: ItemPrices[]): BillingAction => ({
  type: BillingActionTypes.GET_ITEM_PRICES_SUCCESS,
  payload,
});
const getItemPricesFail = (error: string): BillingAction => ({ type: BillingActionTypes.GET_ITEM_PRICES_FAIL, error });

const refreshTrialLicenseRequest = (): BillingAction => ({
  type: BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST,
});
const refreshTrialLicenseSuccess = (): BillingAction => ({
  type: BillingActionTypes.REFRESH_TRIAL_LICENSE_SUCCESS,
});

const refreshTenantRequest = (): BillingAction => ({
  type: BillingActionTypes.REFRESH_TENANT_REQUEST,
});
const refreshTenantSuccess = (): BillingAction => ({
  type: BillingActionTypes.REFRESH_TENANT_SUCCESS,
});

const payLaterRequest = (payload: PayLaterDetails): BillingAction => ({
  type: BillingActionTypes.PAY_LATER_REQUEST,
  payload,
});

const payLaterSuccess = (): BillingAction => ({
  type: BillingActionTypes.PAY_LATER_SUCCESS,
});

const payLaterFail = (error: string): BillingAction => ({
  type: BillingActionTypes.PAY_LATER_FAIL,
  error,
});

const reactivateSubscriptionRequest = (): BillingAction => ({
  type: BillingActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST,
});

const reactivateSubscriptionSuccess = (): BillingAction => ({
  type: BillingActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS,
});

const reactivateSubscriptionFail = (error: string): BillingAction => ({
  type: BillingActionTypes.REACTIVATE_SUBSCRIPTION_FAIL,
  error,
});

const calculateTaxesRequest = (payload: BillingCalculateTaxesRequestPayload): BillingAction => ({
  type: BillingActionTypes.CALCULATE_TAXES_REQUEST,
  payload,
});

const calculateTaxesSuccess = (payload: BillingCalculateTaxes): BillingAction => ({
  type: BillingActionTypes.CALCULATE_TAXES_SUCCESS,
  payload,
});

const calculateTaxesFail = (error: string): BillingAction => ({
  type: BillingActionTypes.CALCULATE_TAXES_FAIL,
  error,
});

const calculateTaxesReset = (): BillingAction => ({
  type: BillingActionTypes.CALCULATE_TAXES_RESET,
});

export const billingActions = {
  getBillingRequest,
  getBillingSuccess,
  getBillingFail,

  changeRequest,
  changeSuccess,
  changeFail,
  cancelSubscriptionRequest,
  cancelSubscriptionSuccess,
  cancelSubscriptionFail,
  updateContactsRequest,
  updateContactsSuccess,
  updateContactsFail,
  updateBillingAddressRequest,
  updateBillingAddressSuccess,
  updateBillingAddressFail,
  downloadRequest,
  downloadSuccess,
  downloadFail,

  deleteScheduledChangeRequest,
  deleteScheduledChangeSuccess,
  deleteScheduledChangeFail,

  setBillingAddress,
  setContactEmails,
  setSendBillingEmails,
  setAddOrRemoveSeats,
  setChangeSeats,
  setTerm,

  getItemPricesRequest,
  getItemPricesSuccess,
  getItemPricesFail,

  refreshTrialLicenseRequest,
  refreshTrialLicenseSuccess,

  refreshTenantRequest,
  refreshTenantSuccess,

  payLaterRequest,
  payLaterSuccess,
  payLaterFail,

  reactivateSubscriptionRequest,
  reactivateSubscriptionSuccess,
  reactivateSubscriptionFail,

  calculateTaxesRequest,
  calculateTaxesSuccess,
  calculateTaxesFail,
  calculateTaxesReset,
};
