export const EditRole = Object.freeze({
  ACCESS_TO_ALL_WORKSPACES: `Acesso a todos os espaços de trabalho e funções`,
  ACTIVE: `Ativo`,
  ADMIN_TITLE: `Administrador`,
  MY_BOOKING_PAGE: `Minhas Páginas de Reserva`,
  ALL_BOOKING_PAGE: `Todas as Páginas de Reserva`,
  BOOKING_TEMPLATES: `Modelos de Reserva`,
  CHROME_EXTENSION_TITLE: `SUMO Meeting Booker`,
  CREATE: `Criar`,
  DELETE: `Excluir`,
  EDIT: `Editar`,
  FEATURE_ACCESS_TITLE: `Acesso à Funcionalidade`,
  JOURNEY: `Construtor de Jornada`,
  LOCATIONS: `Localizações`,
  PERMISSIONS_TITLE: `Permissões`,
  PRIMARY_TITLE: `Primário`,
  READ: `Ler`,
  RESOURCES_TITLE: `Recursos (Localizações, Salas)`,
  ROLE_NAME: `Nome do papel`,
  SEARCH_ADD_WORKSPACE: `Pesquisar e Adicionar Espaço de Trabalho`,
  SMART_ALERT_TEMPLATES: `Modelos de alertas inteligentes`,
  STANDARD_USER: `Usuário Padrão`,
  SUPER_ADMIN: `Super Administrador`,
  TEAMS: `Equipas`,
  EDIT_TITLE: 'Editar função',
  NEW_TITLE: 'Nova função',
  WORKSPACE_ADMIN: `Administrador do Espaço de Trabalho`,
  WORKSPACE_ADMIN_DESCRIPTION: `Por padrão, um administrador com esta função pode administrar todos os espaços de trabalho, a menos que especifique abaixo espaços de trabalho específicos que pode administrar:`,
});

export const RoleToastsNotifications = Object.freeze({
  ACTIVATE_ROLE_SUCCESS_MESSAGE: `Papel ativado`,
  DEACTIVATE_ACTIVATE_ROLE_ERROR_MESSAGE: `Erro ao ativar/desativar o papel`,
  DEACTIVATE_ROLE_SUCCESS_MESSAGE: `Papel desativado`,
  DELETE_ROLE_ASSIGNED_ERROR_MESSAGE: `Não pode ser excluído. Usuários estão atribuídos a esta função.`,
  DELETE_ROLE_ERROR_MESSAGE: `Erro ao excluir o papel`,
  DELETE_ROLE_SUCCESS_MESSAGE: `Papel excluído com sucesso`,
  GET_ROLES_FAIL_MESSAGE: `Erro ao obter papéis`,
  GET_ROLE_FAIL_MESSAGE: `Falha no pedido de obtenção de função`,
  SAVE_ROLE_ERROR_MESSAGE: `Erro ao salvar o papel`,
  SAVE_ROLE_SUCCESS_MESSAGE: `Papel salvo com sucesso`,
});

export const Roles = Object.freeze({
  ACTIVE: `Ativo`,
  CUSTOM: `Personalizado`,
  DEACTIVATE_DESCRIPTION: `Tem certeza de que deseja desativar`,
  DELETE_DESCRIPTION: `Tem certeza de que deseja excluir`,
  DELETE_MESSAGE: `Os usuários não serão excluídos, mas podem perder o acesso, exigindo uma atribuição de função.`,
  LAST_MODIFIED: `Última Modificação`,
  LAST_MODIFIED_BY: `Última Modificado por`,
  NEW_ROLE: `Novo Papel`,
  ROLE: `Papel`,
  TOOLTIP: `Gerir funções para dar aos utilizadores permissões para ver, criar/editar ou eliminar várias funcionalidades`,
});
