import { API_PUBLIC } from '../../types/constants';
import { handleAPIRequest } from '../utils/reduxUtils';
import { GetAvailabilityResponse } from '../../generated-sources/internal-api/models/GetAvailabilityResponse';
import { GetAvailabilityRequest } from '../../generated-sources/internal-api/models/GetAvailabilityRequest';
import { UpsertAvailabilityRequest } from '../../generated-sources/internal-api/models/UpsertAvailabilityRequest';
import { UpsertAvailabilityResponse } from '../../generated-sources/internal-api/models/UpsertAvailabilityResponse';
import { DeleteAvailabilityRequest } from '../../generated-sources/internal-api/models/DeleteAvailabilityRequest';
import { DeleteAvailabilityResponse } from '../../generated-sources/internal-api/models/DeleteAvailabilityResponse';
import {
  SetDefaultAvailabilityRequest
} from '../../generated-sources/internal-api/models/SetDefaultAvailabilityRequest';
import {
  SetDefaultAvailabilityResponse
} from '../../generated-sources/internal-api/models/SetDefaultAvailabilityResponse';

// ***
// When you change the requests do not forget to specify new data
// in docs/Endpoints.md
// ***

export const getAvailability = async (ownerId?: GetAvailabilityRequest['ownerId']): Promise<GetAvailabilityResponse> => {
  return handleAPIRequest<GetAvailabilityResponse>(`${API_PUBLIC}/getAvailability`, {ownerId});
};

export const upsertAvailability = async (availability: UpsertAvailabilityRequest['availability']): Promise<UpsertAvailabilityResponse> => {
  return handleAPIRequest<UpsertAvailabilityResponse>(`${API_PUBLIC}/upsertAvailability`, {availability});
};

export const deleteAvailability = async (id: DeleteAvailabilityRequest['id']): Promise<DeleteAvailabilityResponse> => {
  return handleAPIRequest<DeleteAvailabilityResponse>(`${API_PUBLIC}/deleteAvailability`, {id});
};

export const setDefaultAvailability = async (id: SetDefaultAvailabilityRequest['id']): Promise<SetDefaultAvailabilityResponse> => {
  return handleAPIRequest<SetDefaultAvailabilityResponse>(`${API_PUBLIC}/setDefaultAvailability`, {id});
};
