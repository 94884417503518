import {
  ArrowLeftCircleIcon,
  BellIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  CubeIcon,
  CursorArrowRaysIcon,
  InformationCircleIcon,
  LockClosedIcon,
  MapPinIcon,
  PowerIcon,
  Square2StackIcon,
  Square3Stack3DIcon,
  Squares2X2Icon,
  SquaresPlusIcon,
  UserCircleIcon,
  UserGroupIcon,
  UsersIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';
import { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Path } from '../../../routing';
import urlConstants from '../../../shared/JSON/urlConstants.json';
import { authenticationActions, authenticationSelectors } from '../../../store/authentication';
import { promoActions, promoSelectors } from '../../../store/promo';
import { userSettingsSelectors } from '../../../store/userSettings';
import labels from './labels';
import { navigationService } from '../../../services/NavigationService';

export const createNavigationMenuItems = () => {
  const location = useLocation();
  const isOpConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const isUsersRead = useSelector(userSettingsSelectors.selectUsersRead);
  const isBookingTemplatesRead = useSelector(userSettingsSelectors.selectBookingTemplatesRead);
  const isWorkspacesRead = useSelector(userSettingsSelectors.selectWorkspacesRead);
  const isTeamsRead = useSelector(userSettingsSelectors.selectTeamsRead);
  const isRoleLoaded = useSelector(userSettingsSelectors.selectIsRoleLoaded);
  const isViewAsUserMode = useSelector(authenticationSelectors.selectIsViewAsUser);
  const isSumoONEAdmin = useSelector(authenticationSelectors.selectIsSumo1Admin);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);

  const [meetingsOpen, setMeetingsOpen] = useState(true);
  const [toolsOpen, setToolsOpen] = useState(true);
  const [peopleOpen, setPeopleOpen] = useState(true);
  const [organizationOpen, setOrganizationOpen] = useState(true);
  const titleClass = 'p-0 pb-8px text-label-s-med text-heavy-60';

  const getTitleTemplate = (isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>>) => {
    const MenuTitleTemplate: MenuItem['template'] = (item) => (
      <div
        className="flex-between-center pb-8px text-label-s-med text-heavy-60 hover-text-heavy-100 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div>{item.label}</div>
        {isOpen ? <ChevronUpIcon className="icon-12px" /> : <ChevronDownIcon className="icon-12px" />}
      </div>
    );
    return MenuTitleTemplate;
  };

  const itemTemplate: MenuItem['template'] = (item) => (
    <div
      onClick={() => navigationService.navigateTo(item.url || '')}
      id={item.id}
      className={`text-title-xs-med ${isOpConsole ? 'hover-text-primary-white' : 'hover-text-heavy-80'} ${
        location.pathname.startsWith(item.url || '')
          ? isOpConsole
            ? 'text-primary-white'
            : 'text-heavy-100'
          : 'text-heavy-60'
      } cursor-pointer`}
    >
      <div className="flex-left-center gap-10px py-8px">
        {item.data || <i className={item.icon}></i>}
        <div>{item.label}</div>
      </div>
    </div>
  );

  const spaceTemplate: MenuItem['template'] = () => <div className="h-24px"></div>;

  if (isOpConsole) {
    const OPSConsoleModel: MenuItem[] = [
      {
        visible: true,
        label: labels.openMySumo1,
        id: 'open-my-sumo1-link',
        url: isQuickSetupFinished ? Path.BookingPages : Path.QuickSetup,
        template: itemTemplate,
        data: <ArrowLeftCircleIcon className="icon-20px" />,
      },
      { template: spaceTemplate },
      {
        label: labels.supportTitle,
        className: titleClass,
        items: [
          {
            visible: true,
            label: labels.accountsTitle,
            id: 'accounts-link',
            url: Path.OPSConsoleAccounts,
            template: itemTemplate,
            data: <Squares2X2Icon className="icon-20px" />,
          },
          {
            visible: true,
            label: labels.orgsTitle,
            id: 'ops-console-link',
            url: Path.OPSConsoleOrgs,
            template: itemTemplate,
            data: <BuildingOffice2Icon className="icon-20px" />,
          },
          {
            visible: true,
            label: labels.users,
            id: 'users-link',
            url: Path.OPSConsoleUsers,
            template: itemTemplate,
            data: <UsersIcon className="icon-20px" />,
          },
        ],
      },
      { template: spaceTemplate },
      {
        label: labels.operationsTitle,
        className: titleClass,
        items: [
          // {
          //   label: t('AppMenu:BILLING_TITLE'),
          //   icon: 'pi pi-dollar',
          //   to: Path.OPSConsoleBilling,
          // },
          {
            visible: true,
            label: labels.staffTitle,
            id: 'staff-link',
            url: Path.OPSConsoleStaff,
            template: itemTemplate,
            data: <UserCircleIcon className="icon-20px" />,
          },
        ],
      },
      { template: spaceTemplate },
    ];
    return OPSConsoleModel;
  }

  if (isLicenseActive === false) {
    const licenseExpiredModel: MenuItem[] = [
      ...(isSumoONEAdmin && !isViewAsUserMode
        ? [
            {
              visible: true,
              label: labels.opsConsole,
              id: 'ops-console-link',
              url: Path.OPSConsoleOrgs,
              template: itemTemplate,
              data: <WrenchScrewdriverIcon className="icon-20px" />,
            },
            { template: spaceTemplate },
          ]
        : []),
      ...(isSuperAdmin
        ? [
            {
              label: labels.peopleTitle,
              className: titleClass,
              items: [
                {
                  visible: true,
                  label: labels.users,
                  id: 'users-link',
                  url: Path.Users,
                  template: itemTemplate,
                  data: <UsersIcon className="icon-20px" />,
                },
              ],
            },
            { template: spaceTemplate },
            {
              label: labels.organizationTitle,
              className: titleClass,
              items: [
                {
                  visible: true,
                  label: labels.billingTitle,
                  id: 'billing-link',
                  url: Path.Billing,
                  template: itemTemplate,
                  data: <CreditCardIcon className="icon-20px" />,
                },
              ],
            },
            { template: spaceTemplate },
          ]
        : []),
    ];
    return licenseExpiredModel;
  }

  const privateModel: MenuItem[] = [
    ...(isSumoONEAdmin && !isViewAsUserMode
      ? [
          {
            visible: true,
            label: labels.opsConsole,
            id: 'ops-console-link',
            url: Path.OPSConsoleOrgs,
            template: itemTemplate,
            data: <WrenchScrewdriverIcon className="icon-20px" />,
          },
          { template: spaceTemplate },
        ]
      : []),
    {
      label: labels.meetingsTitle,
      template: getTitleTemplate(meetingsOpen, setMeetingsOpen),
    },
    ...(meetingsOpen
      ? [
          {
            visible: true,
            label: labels.bookingPagesTitle,
            id: 'booking-pages-link',
            url: Path.BookingPages,
            template: itemTemplate,
            data: <Squares2X2Icon className="icon-20px" />,
          },
          {
            visible: true,
            label: labels.bookedMeetingsTitle,
            id: 'booked-meetings-link',
            url: Path.BookedMeetings,
            template: itemTemplate,
            data: <CalendarIcon className="icon-20px" />,
          },
        ]
      : []),
    { template: spaceTemplate },
    {
      label: labels.toolsTitle,
      template: getTitleTemplate(toolsOpen, setToolsOpen),
    },
    ...(toolsOpen
      ? [
          {
            visible: isBookingTemplatesRead,
            label: labels.bookingTemplatesTitle,
            id: 'booking-templates-link',
            url: Path.BookingTemplates,
            template: itemTemplate,
            data: <Square3Stack3DIcon className="icon-20px" />,
          },
          {
            visible: true,
            label: labels.smartAlerts,
            id: 'smart-alerts-link',
            url: Path.SmartAlerts,
            template: itemTemplate,
            data: <BellIcon className="icon-20px" />,
          },
          {
            visible: true,
            label: labels.journeyBuilder,
            id: 'journey-builder-link',
            url: Path.Journeys,
            template: itemTemplate,
            data: <SquaresPlusIcon className="icon-20px" />,
          },
          {
            visible: true,
            label: labels.integrationPageTitle,
            id: 'integration-link',
            url: Path.Integration,
            template: itemTemplate,
            data: <Square2StackIcon className="icon-20px" />,
          },
        ]
      : []),
    { template: spaceTemplate },
    ...(isRoleLoaded && (isUsersRead || isTeamsRead)
      ? [
          {
            label: labels.peopleTitle,
            template: getTitleTemplate(peopleOpen, setPeopleOpen),
          },
          ...(peopleOpen
            ? [
                {
                  visible: isUsersRead,
                  label: labels.users,
                  id: 'users-link',
                  url: Path.Users,
                  template: itemTemplate,
                  data: <UsersIcon className="icon-20px" />,
                },
                {
                  visible: isTeamsRead,
                  label: labels.teamsTitle,
                  id: 'teams-link',
                  url: Path.Teams,
                  template: itemTemplate,
                  data: <UserGroupIcon className="icon-20px" />,
                },
              ]
            : []),
          { template: spaceTemplate },

          {
            label: labels.organizationTitle,
            template: getTitleTemplate(organizationOpen, setOrganizationOpen),
          },
          ...(organizationOpen
            ? [
                {
                  visible: isSuperAdmin,
                  label: labels.roles,
                  id: 'roles-link',
                  url: Path.Roles,
                  template: itemTemplate,
                  data: <LockClosedIcon className="icon-20px" />,
                },
                {
                  visible: isWorkspacesRead,
                  label: labels.workspacesTitle,
                  id: 'workspaces-link',
                  url: Path.Workspaces,
                  template: itemTemplate,
                  data: <CubeIcon className="icon-20px" />,
                },
                {
                  visible: true,
                  label: labels.locationsTitle,
                  id: 'locations-link',
                  url: Path.Locations,
                  template: itemTemplate,
                  data: <MapPinIcon className="icon-20px" />,
                },
                {
                  visible: isSuperAdmin,
                  label: labels.billingTitle,
                  id: 'billing-link',
                  url: Path.Billing,
                  template: itemTemplate,
                  data: <CreditCardIcon className="icon-20px" />,
                },
              ]
            : []),
          { template: spaceTemplate },
        ]
      : []),
  ];
  return privateModel;
};

export const createModalMenuItems = () => {
  const dispatch = useDispatch();
  const isOpConsole = useSelector(authenticationSelectors.selectIsOpsConsole);
  const isQuickSetupFinished = useSelector(userSettingsSelectors.selectIsQuickSetupFinished);
  const isLicenseActive = useSelector(authenticationSelectors.selectIsLicenseActive);
  const isGuideRunning = useSelector(promoSelectors.selectIsGuideRunning);

  const accountVisible = Boolean(isQuickSetupFinished && !isOpConsole && isLicenseActive);

  const handleSignOut = () => {
    dispatch(authenticationActions.logoutUserRequest(Path.Landing));
  };

  const linkTemplate: MenuItem['template'] = (item) => (
    <Link
      to={item.url || Path.Landing}
      onClick={() => item.label === labels.logOut && handleSignOut()}
      target={item.target}
      className={`p-menuitem-link px-16px py-10px ${
        location.pathname.startsWith(item.url || '') ? 'text-heavy-100' : 'text-heavy-80'
      }`}
      id={item.id}
    >
      <div className="p-menuitem-text flex-left-center gap-8px">
        {item.data}
        <span>{item.label}</span>
      </div>
    </Link>
  );

  const commandTemplate: MenuItem['template'] = (item) => (
    <div
      onClick={(event) => {
        if (item.label === labels.logOut) {
          handleSignOut();
        }
        if (item.command) {
          item.command({ originalEvent: event, item });
        }
      }}
      className="p-menuitem-link px-16px py-10px"
      id={item.id}
    >
      <div className="p-menuitem-text flex-left-center gap-8px">
        {item.data}
        <span>{item.label}</span>
      </div>
    </div>
  );

  const handleStartGuide = (_event: MenuItemCommandEvent) => {
    if (isGuideRunning) {
      dispatch(promoActions.stopGuide());
    } else {
      dispatch(promoActions.runGuide());
    }
  };

  const menuItems: MenuItem[] = accountVisible
    ? [
        {
          visible: true,
          label: labels.guidedTour,
          id: 'guided-tour-link',
          command: handleStartGuide,
          template: commandTemplate,
          data: <CursorArrowRaysIcon className="icon-20px" />,
        },
        {
          separator: true,
        },
        {
          visible: true,
          label: labels.availability,
          id: 'availability-link',
          url: Path.Availability,
          template: linkTemplate,
          data: <CalendarIcon className="icon-20px" />,
        },
        {
          visible: true,
          label: labels.calendarConnections,
          id: 'calendar-connections-link',
          url: Path.CalendarConnections,
          template: linkTemplate,
          data: <Cog6ToothIcon className="icon-20px" />,
        },
        {
          separator: true,
        },
        {
          visible: true,
          label: labels.profile,
          id: 'profile-link',
          url: Path.Account,
          template: linkTemplate,
          data: <UserCircleIcon className="icon-20px" />,
        },
        {
          visible: true,
          label: labels.helpCenter,
          id: 'help-center-link',
          url: urlConstants.KNOWLEDGE_BASE_URL,
          target: 'blank',
          template: linkTemplate,
          data: <InformationCircleIcon className="icon-20px" />,
        },
        {
          separator: true,
        },
        {
          visible: true,
          label: labels.logOut,
          id: 'log-out-link',
          template: linkTemplate,
          data: <PowerIcon className="icon-20px" />,
        },
      ]
    : [
        {
          visible: true,
          label: labels.logOut,
          id: 'log-out-link',
          template: linkTemplate,
          data: <PowerIcon className="icon-20px" />,
        },
      ];

  return menuItems;
};
