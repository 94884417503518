import { t } from '../../../i18n/i18n';

export default {
  annually: t('Billing:ANNUALLY_TITLE'),
  buyLicenses: t('License:BUY_LICENSES'),
  buyLicensesMessage: t('Billing:BUY_LICENSES_MESSAGE'),
  cancel: t('Common:CANCEL'),
  enterNumber: t('Billing:LICENSES_PLACEHOLDER'),
  licenseMessagePart1: t('Billing:LICENSE_MESSAGE_PART_1'),
  licenseMessagePart2: t('Billing:LICENSE_MESSAGE_PART_2'),
  licenseMessagePart3: t('Billing:LICENSE_MESSAGE_PART_3'),
  loadingLabel1: t('Billing:LOADING_LABEL_1'),
  loadingLabel2: t('Billing:LOADING_LABEL_2'),
  monthly: t('Billing:MONTHLY_TITLE'),
  next: t('Common:NEXT'),
  numberLicenses: t('Billing:NUMBER_OF_LICENSES'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
};
