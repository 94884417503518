import { combineReducers } from 'redux';
import { UserSettingsKeys } from '../userSettings/constants';
import { AuthenticationActionTypes, AuthenticationAction } from './actions';
import { InviteToAccount, ThirdPartyType, UserDataCoreType } from './types';
import {
  DEFAULT_USER_DATA_CORE,
  defaultThirdParty,
  EMPTY_USER_DATA_CORE,
  DEFAULT_TENANT_INFO,
  DEFAULT_SUMO1_DATA,
  EMPTY_SUMO1_ADMIN,
} from './constants';
import { CreateAdminDataInput, CreateLicenseInput, CreateStaffInput, CreateTenantInput, TenantStatus } from '../../API';
import { GlobalAction, GlobalActionTypes } from '../global/actions';
import dayjs from 'dayjs';

const userDataCore = (
  state: UserDataCoreType = DEFAULT_USER_DATA_CORE,
  action: AuthenticationAction | GlobalAction
) => {
  switch (action.type) {
    case AuthenticationActionTypes.UPDATE_USER_DATA_CORE:
      if (action.payload.workspaceId && state.workspaceId !== action.payload.workspaceId) {
        localStorage.setItem(UserSettingsKeys.WORKSPACE_ID, action.payload.workspaceId);
      }
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return EMPTY_USER_DATA_CORE;
    default:
      return state;
  }
};

const authenticationDataReceived = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case GlobalActionTypes.GET_MAIN_DATA_SUCCESS:
      return true;
    case AuthenticationActionTypes.VIEW_AS_USER_REQUEST:
    case AuthenticationActionTypes.LOGOUT_USER_SUCCESS:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const isFetching = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.THIRD_PARTY_AUTH_REQUEST:
    case AuthenticationActionTypes.LOGOUT_USER_REQUEST:
    case AuthenticationActionTypes.DELETE_USER_REQUEST:
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST:
    case AuthenticationActionTypes.VIEW_AS_USER_REQUEST:
    case AuthenticationActionTypes.RESET_VIEW_AS_USER_REQUEST:
    case AuthenticationActionTypes.GET_LICENSE_REQUEST:
      return true;
    case AuthenticationActionTypes.THIRD_PARTY_AUTH_SUCCESS:
    case AuthenticationActionTypes.THIRD_PARTY_AUTH_FAIL:
    case AuthenticationActionTypes.LOGOUT_USER_SUCCESS:
    case AuthenticationActionTypes.LOGOUT_USER_FAIL:
    case AuthenticationActionTypes.DELETE_USER_SUCCESS:
    case AuthenticationActionTypes.DELETE_USER_FAIL:
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS:
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_FAIL:
    case AuthenticationActionTypes.VIEW_AS_USER_SUCCESS:
    case AuthenticationActionTypes.VIEW_AS_USER_FAIL:
    case AuthenticationActionTypes.RESET_VIEW_AS_USER_SUCCESS:
    case AuthenticationActionTypes.RESET_VIEW_AS_USER_FAIL:
    case AuthenticationActionTypes.SET_SUMO1_ADMIN_DATA:
    case AuthenticationActionTypes.GET_LICENSE_SUCCESS:
    case AuthenticationActionTypes.GET_LICENSE_FAIL:
    case GlobalActionTypes.GET_MAIN_DATA_SUCCESS:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

// TODO: move it to Billing reducer
const licensesIsFetching = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.GET_LICENSE_REQUEST:
      return true;
    case AuthenticationActionTypes.GET_LICENSE_SUCCESS:
    case AuthenticationActionTypes.GET_LICENSE_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const tenantIsFetching = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.GET_TENANT_REQUEST:
      return true;
    case AuthenticationActionTypes.GET_TENANT_SUCCESS:
    case AuthenticationActionTypes.GET_TENANT_FAIL:
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const error = (state: unknown | null = null, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.THIRD_PARTY_AUTH_FAIL:
    case AuthenticationActionTypes.LOGOUT_USER_FAIL:
    case AuthenticationActionTypes.DELETE_USER_FAIL:
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_FAIL:
    case AuthenticationActionTypes.VIEW_AS_USER_FAIL:
    case AuthenticationActionTypes.RESET_VIEW_AS_USER_FAIL:
    case AuthenticationActionTypes.GET_LICENSE_FAIL:
      return action.error;
    case AuthenticationActionTypes.THIRD_PARTY_AUTH_REQUEST:
    case AuthenticationActionTypes.GET_LICENSE_REQUEST:
    case AuthenticationActionTypes.LOGOUT_USER_REQUEST:
    case AuthenticationActionTypes.DELETE_USER_REQUEST:
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_REQUEST:
    case AuthenticationActionTypes.VIEW_AS_USER_REQUEST:
    case AuthenticationActionTypes.RESET_VIEW_AS_USER_REQUEST:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const userRegistered = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS:
      return true;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const userDeleted = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.DELETE_USER_SUCCESS:
      return true;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const thirdParty = (state: ThirdPartyType | null = defaultThirdParty, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_THIRD_PARTY:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const userAccounts = (state: CreateAdminDataInput[] | null = null, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_ACCOUNTS:
      return action.payload;
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const inviteToAccount = (state: InviteToAccount | null = null, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_INVITE_TO_ACCOUNT:
      return action.payload;
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS:
      return null;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return null;
    default:
      return state;
  }
};

const isLastSuperAdmin = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_IS_LAST_SUPER_ADMIN:
      return action.payload;
    case AuthenticationActionTypes.CHOOSE_AN_ACCOUNT_SUCCESS:
      return false;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

// TODO: move it to Billing reducer
const licenses = (state: CreateLicenseInput[] = [], action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.GET_LICENSE_SUCCESS:
      return action.payload;
    case AuthenticationActionTypes.EXPIRE_LICENSE:
      return state.map((license) => ({ ...license, endDate: dayjs().format('YYYY-MM-DD') }));
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return [];
    default:
      return state;
  }
};

const SUMO1Admin = (state: CreateStaffInput = DEFAULT_SUMO1_DATA, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_SUMO1_ADMIN_DATA:
      return { ...state, ...action.payload };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return EMPTY_SUMO1_ADMIN;
    default:
      return state;
  }
};

const tenant = (state: CreateTenantInput = DEFAULT_TENANT_INFO, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.GET_TENANT_SUCCESS:
      return action.payload;
    case AuthenticationActionTypes.EXPIRE_LICENSE:
      return { ...state, status: TenantStatus.EXPIRED };
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return DEFAULT_TENANT_INFO;
    default:
      return state;
  }
};

const isOpsConsole = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_IS_OPS_CONSOLE:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

const lastLoadTime = (state = 0, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.GET_LICENSE_SUCCESS:
      return new Date().getTime();
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return 0;
    default:
      return state;
  }
};

const isCookieOpen = (state = false, action: AuthenticationAction | GlobalAction) => {
  switch (action.type) {
    case AuthenticationActionTypes.SET_COOKIE_OPEN:
      return action.payload;
    case GlobalActionTypes.RESET_WHOLE_STORE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  userDataCore,
  authenticationDataReceived,
  userRegistered,
  userDeleted,
  isFetching,
  licensesIsFetching,
  error,
  thirdParty,
  userAccounts,
  inviteToAccount,
  isLastSuperAdmin,
  licenses,
  SUMO1Admin,
  tenant,
  isOpsConsole,
  lastLoadTime,
  tenantIsFetching,
  isCookieOpen,
});
