import { CreateStaffInput, CreateTenantInput } from '../../API';
import { t } from '../../i18n/i18n';
import {
  TOAST_10_SEC_DURATION,
  TOAST_ERROR_COLOR,
  TOAST_ERROR_DURATION,
  TOAST_SUCCESS_COLOR,
  TOAST_SUCCESS_DURATION,
} from '../../types/constants';
import { ToastNotificationOptions } from '../notifications';
import { UserSettingsKeys } from '../userSettings/constants';
import { ThirdPartyType, UserDataCoreType } from './types';

export const EXPIRATION_PERIOD_DAYS = 15;
export const USER_WARNING_DAYS = 3;
export const ENCRYPTED_USER_ID = 'encryptedUserId';
export const USER_CONTEXT_PARAM = 'userContext';
export const THIRD_PARTY = 'thirdParty';
export const SUMO1TenantId = 'SUMO1';

const userId = localStorage.getItem(UserSettingsKeys.USER_ID) || '';
const tenantId = localStorage.getItem(UserSettingsKeys.TENANT_ID) || '';
const link = localStorage.getItem(UserSettingsKeys.LINK) || '';
const workspaceId = localStorage.getItem(UserSettingsKeys.WORKSPACE_ID) || '';

export const DEFAULT_USER_DATA_CORE: UserDataCoreType = {
  userId,
  tenantId,
  link,
  workspaceId,
};

export const defaultThirdParty = (localStorage.getItem(THIRD_PARTY) as ThirdPartyType) || null;

export const EMPTY_SUMO1_ADMIN: CreateStaffInput = {
  email: '',
  userId: null,
  userName: null,
  roleName: null,
  status: null,
};

export const DEFAULT_SUMO1_DATA: CreateStaffInput = JSON.parse(
  localStorage.getItem(UserSettingsKeys.SUMO1_STAFF_DATA) || JSON.stringify(EMPTY_SUMO1_ADMIN)
);

export const EMPTY_USER_DATA_CORE = {
  userId: '',
  tenant: '',
  link: '',
  workspaceId: '',
};

export const DEFAULT_TENANT_INFO: CreateTenantInput = {
  tenantId: '',
};

export const GET_USER_AUTH_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:GET_USER_AUTH_ERROR_MESSAGE'),
});
export const GET_USER_LOGIN_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:GET_USER_LOGIN_ERROR_MESSAGE'),
});
export const GET_USER_LOGOUT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:GET_USER_LOGOUT_ERROR_MESSAGE'),
});

export const DELETE_USER_SUCCESS_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_SUCCESS_COLOR,
  autoHideDuration: TOAST_SUCCESS_DURATION,
  message: t('AuthenticationToastsNotifications:DELETE_USER_SUCCESS_MESSAGE'),
});
export const DELETE_USER_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:DELETE_USER_ERROR_MESSAGE'),
});

export const CHOOSE_AN_ACCOUNT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:CHOOSE_AN_ACCOUNT_ERROR_TOAST'),
});

export const GET_MAIN_DATA_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:GET_MAIN_DATA_ERROR_MESSAGE'),
});
export const ROLE_DEACTIVATED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:ROLE_DEACTIVATED_ERROR_MESSAGE'),
});
export const USER_DEACTIVATED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:USER_DEACTIVATED_ERROR_MESSAGE'),
});
export const TRIAL_LIMIT_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:TRIAL_LIMIT_ERROR_MESSAGE'),
});
export const ACCOUNT_ALREADY_EXIST_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_ERROR_DURATION,
  message: t('AuthenticationToastsNotifications:ACCOUNT_ALREADY_EXIST_ERROR_TOAST_MESSAGE'),
});

export const INVITE_EXPIRED_ERROR_TOAST: ToastNotificationOptions = Object.freeze({
  color: TOAST_ERROR_COLOR,
  autoHideDuration: TOAST_10_SEC_DURATION,
  message: t('AuthenticationToastsNotifications:INVITE_EXPIRED_ERROR_TITLE'),
  isInviteExpired: true,
});
