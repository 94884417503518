import { createSelector } from 'reselect';
import { State } from '../rootStore';

const promoState = (state: State) => state.promo;

const selectIsGuideVisible = createSelector(promoState, (state) => state.selectIsGuideVisible);
const selectIsGuideRunning = createSelector(promoState, (state) => state.selectIsGuideRunning);
const selectGuideStepIndex = createSelector(promoState, (state) => state.selectGuideStepIndex);

export const promoSelectors = {
  selectIsGuideVisible,
  selectIsGuideRunning,
  selectGuideStepIndex,
};
