import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { CreateLicenseInput, CreateTenantInput, LicenseType, TenantStatus } from '../../API';
import { Path } from '../../routing';
import { navigationService } from '../../services/NavigationService';
import { authenticationActions, authenticationSelectors } from '../authentication';
import { getLicenses, getTenantData } from '../authentication/service';
import { notificationsActions } from '../notifications';
import { convertToPayLater } from '../opsConsole/orgs/service';
import { userSettingsSelectors } from '../userSettings';
import { handleServiceError } from '../utils/reduxUtils';
import { billingActions, BillingActionTypes } from './actions';
import {
  ADD_SEATS_ERROR_TOAST,
  CHANGE_TERM_ERROR_TOAST,
  CHANGE_TERM_SUCCESS_TOAST,
  CHARGEBEE_PORTAL_CLOSE_TOAST,
  DELETE_CHANGE_ERROR_TOAST,
  DELETE_CHANGE_SUCCESS_TOAST,
  getAddSeatsSuccessToast,
  getRemoveSeatsSuccessToast,
  GET_BILLING_ERROR_TOAST,
  PAY_LATER_ERROR_TOAST,
  REMOVE_SEATS_ERROR_TOAST,
  REACTIVATE_SUBSCRIPTION_ERROR_TOAST,
  REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST,
  CALCULATE_TAXES_ERROR_TOAST,
  DEFAULT_SEATS_AMOUNT,
} from './constants';
import { billingChangeModalActions } from './modal';
import { billingSelectors } from './selectors';
import {
  calculateTaxes,
  cancelSubscription,
  changeSeatsOrTerm,
  deleteScheduledChange,
  downloadInvoice,
  getItemPrices,
  getSubscriptionDetails,
  reactivateSubscription,
  updateCustomer,
} from './service';
import {
  ChargeeBeeResponse,
  DownloadResponse,
  ItemPrices,
  updateCustomerRequest,
  updateSubscriptionRequest,
} from './types';
import { BillingCalculateTaxesResponse } from '../../generated-sources/internal-api/models/BillingCalculateTaxesResponse';
import { DELAY_MILLISECONDS } from '../../types/constants';

const selectChangeRequest = createSelector(
  userSettingsSelectors.selectFullName,
  billingSelectors.selectChangeSeats,
  billingSelectors.selectSubscriptionTerm,
  billingSelectors.selectTerm,
  (userName, seats, subscriptionTerm, plan) =>
    ({
      ...(plan != subscriptionTerm
        ? {
            scheduledChange: {
              plan,
              changeBy: userName,
              requestDate: new Date().toISOString(),
            },
          }
        : seats > 0
        ? { addSeats: seats }
        : {
            scheduledChange: {
              seats,
              changeBy: userName,
              requestDate: new Date().toISOString(),
            },
          }),
    } as updateSubscriptionRequest)
);

const selectUpdateContactsRequest = createSelector(
  billingSelectors.selectContactEmails,
  billingSelectors.selectSendBillingEmail,
  (emails, sendBillingEmail) => ({ emails, sendBillingEmail } as updateCustomerRequest)
);

const selectUpdateBillingAddressRequest = createSelector(
  billingSelectors.selectBillingAddress,
  (billingAddress) => ({ billingAddress } as updateCustomerRequest)
);

function* getBillingSaga() {
  try {
    const response: ChargeeBeeResponse = yield call(getSubscriptionDetails);

    yield put(billingActions.getBillingSuccess(response));
  } catch (error: unknown) {
    yield put(billingActions.getBillingFail(error?.toString() || ''));
    yield call(handleServiceError, error, GET_BILLING_ERROR_TOAST, true);
  }
}

function* changeSaga() {
  const request: updateSubscriptionRequest = yield select(selectChangeRequest);

  try {
    yield call(changeSeatsOrTerm, request);

    yield put(billingActions.changeSuccess());
    yield put(billingChangeModalActions.closeModal());
    yield put(billingActions.getBillingRequest());
    yield put(
      notificationsActions.showToast(
        request.addSeats
          ? getAddSeatsSuccessToast(request.addSeats)
          : request.scheduledChange?.seats
          ? getRemoveSeatsSuccessToast(request.scheduledChange?.seats)
          : CHANGE_TERM_SUCCESS_TOAST
      )
    );
    yield put(billingActions.setChangeSeats(DEFAULT_SEATS_AMOUNT));
  } catch (error: unknown) {
    yield put(billingActions.changeFail(error?.toString() || ''));
    yield call(
      handleServiceError,
      error,
      request.addSeats
        ? ADD_SEATS_ERROR_TOAST
        : request.scheduledChange?.seats
        ? REMOVE_SEATS_ERROR_TOAST
        : CHANGE_TERM_ERROR_TOAST
    );
  }
}

function* cancelSubscriptionSaga() {
  try {
    yield call(cancelSubscription);

    yield put(billingActions.cancelSubscriptionSuccess());
    yield put(billingActions.getBillingRequest());
    yield put(notificationsActions.showToast(CHANGE_TERM_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(billingActions.cancelSubscriptionFail(error?.toString() || ''));
    yield call(handleServiceError, error, CHANGE_TERM_ERROR_TOAST);
  }
}

function* reactivateSubscriptionSaga() {
  try {
    const isTenantExpired: boolean = yield select(authenticationSelectors.selectIsTenantExpired);
    yield call(reactivateSubscription);
    yield put(billingActions.reactivateSubscriptionSuccess());
    yield put(billingActions.getBillingRequest());

    if (isTenantExpired) {
      yield put(billingActions.refreshTenantRequest());
    }

    yield put(notificationsActions.showToast(REACTIVATE_SUBSCRIPTION_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(billingActions.reactivateSubscriptionFail(error?.toString() || ''));
    yield call(handleServiceError, error, REACTIVATE_SUBSCRIPTION_ERROR_TOAST);
  }
}

function* updateContactsSaga() {
  try {
    const request: updateCustomerRequest = yield select(selectUpdateContactsRequest);

    yield call(updateCustomer, request);

    yield put(billingActions.updateContactsSuccess());
    yield put(billingActions.getBillingRequest());
    yield put(notificationsActions.showToast(CHANGE_TERM_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(billingActions.updateContactsFail(error?.toString() || ''));
    yield call(handleServiceError, error, CHANGE_TERM_ERROR_TOAST);
  }
}

function* updateBillingAddressSaga() {
  try {
    const request: updateCustomerRequest = yield select(selectUpdateBillingAddressRequest);

    yield call(updateCustomer, request);

    yield put(billingActions.updateBillingAddressSuccess());
    yield put(billingChangeModalActions.closeModal());
    yield put(billingActions.getBillingRequest());
    yield put(notificationsActions.showToast(CHANGE_TERM_SUCCESS_TOAST));
  } catch (error: unknown) {
    yield put(billingActions.updateBillingAddressFail(error?.toString() || ''));
    yield call(handleServiceError, error, CHANGE_TERM_ERROR_TOAST);
  }
}

function* downloadInvoiceSaga(action: ReturnType<typeof billingActions.downloadRequest>) {
  try {
    if (action.type === BillingActionTypes.DOWNLOAD_REQUEST) {
      const response: DownloadResponse = yield call(downloadInvoice, action.payload);

      if (response.download_url) {
        window.open(response.download_url, '_blank');
      }

      yield put(billingActions.downloadSuccess());
    }
  } catch (error: unknown) {
    yield put(billingActions.downloadFail(error?.toString() || ''));
    yield call(handleServiceError, error, CHANGE_TERM_ERROR_TOAST);
  }
}

function* deleteScheduledChangeSaga(action: ReturnType<typeof billingActions.deleteScheduledChangeRequest>) {
  try {
    if (action.type === BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST) {
      yield call(deleteScheduledChange, action.payload);

      yield put(billingActions.deleteScheduledChangeSuccess());
      yield put(billingActions.getBillingRequest());
      yield put(notificationsActions.showToast(DELETE_CHANGE_SUCCESS_TOAST));
    }
  } catch (error: unknown) {
    yield put(billingActions.deleteScheduledChangeFail(error?.toString() || ''));
    yield put(billingActions.getBillingRequest()); // TODO: delete when deleteScheduledChange is fixed
    yield call(handleServiceError, error, DELETE_CHANGE_ERROR_TOAST);
  }
}

function* getItemPricesSaga() {
  try {
    const response: ItemPrices[] = yield call(getItemPrices);

    yield put(billingActions.getItemPricesSuccess(response));
  } catch (error: unknown) {
    yield put(billingActions.getBillingFail(error?.toString() || ''));
    // yield call(handleServiceError, error, GET_ITEM_TIERS_ERROR_TOAST, true);
  }
}

function* refreshTrialLicenseSaga() {
  try {
    yield put(notificationsActions.showToast(CHARGEBEE_PORTAL_CLOSE_TOAST));

    const tenantId: string = yield select(authenticationSelectors.selectTenantId);
    const attempts = 15;
    let attempt = 1;
    let isTrialLicense = true;
    let licenses: CreateLicenseInput[] = [];

    while (attempt++ <= attempts && isTrialLicense) {
      yield delay(DELAY_MILLISECONDS);
      licenses = yield call(getLicenses, tenantId);
      isTrialLicense = licenses[0].type === LicenseType.TRIAL;
    }

    yield put(billingActions.getBillingRequest());
    yield call(changeSeatsOrTerm, {}); // to update Additional Contacts
    yield put(authenticationActions.getLicenseSuccess(licenses));
    yield put(billingActions.refreshTrialLicenseSuccess());
  } catch (error: unknown) {
    yield put(authenticationActions.getLicenseFail(error?.toString()));
  }
}

function* refreshTenantSaga() {
  try {
    const tenantId: string = yield select(authenticationSelectors.selectTenantId);
    const attempts = 15;
    let attempt = 1;
    let isTenantExpired = true;
    let tenant = {} as CreateTenantInput;
    while (attempt++ <= attempts && isTenantExpired) {
      yield delay(DELAY_MILLISECONDS);
      tenant = yield call(getTenantData, tenantId);
      isTenantExpired = tenant.type !== null && tenant.status === TenantStatus.EXPIRED;
    }
    yield put(authenticationActions.getTenantSuccess(tenant));
    yield put(billingActions.refreshTenantSuccess());
  } catch (error: unknown) {
    yield put(authenticationActions.getTenantFail(error?.toString() || ''));
  }
}

function* payLaterSaga(action: ReturnType<typeof billingActions.payLaterRequest>) {
  try {
    if (action.type === BillingActionTypes.PAY_LATER_REQUEST) {
      yield call(convertToPayLater, action.payload);
      yield put(billingActions.payLaterSuccess());
      yield call(navigationService.navigateTo, Path.Users);
      yield put(billingActions.refreshTrialLicenseRequest());
    } else {
      throw new Error('Action type not matched');
    }
  } catch (error: unknown) {
    yield put(billingActions.payLaterFail(error?.toString() || ''));
    yield call(handleServiceError, error, PAY_LATER_ERROR_TOAST);
  }
}

function* calculateTaxesSaga(action: ReturnType<typeof billingActions.calculateTaxesRequest>) {
  try {
    if (action.type === BillingActionTypes.CALCULATE_TAXES_REQUEST) {
      const tenantId: string = yield select(authenticationSelectors.selectTenantId);

      const response: BillingCalculateTaxesResponse = yield call(calculateTaxes, {
        ...action.payload,
        tenantId,
      });

      if (!response?.calculation) {
        throw new Error('Calculation properties not found');
      }

      yield put(billingActions.calculateTaxesSuccess(response.calculation));
    } else {
      throw new Error('Action type not matched');
    }
  } catch (error: unknown) {
    yield put(billingActions.calculateTaxesFail(error?.toString() || ''));
    yield call(handleServiceError, error, CALCULATE_TAXES_ERROR_TOAST);
  }
}

export function* watchBillingSaga() {
  yield takeLatest(BillingActionTypes.GET_BILLING_REQUEST, getBillingSaga);
  yield takeLatest(BillingActionTypes.DOWNLOAD_REQUEST, downloadInvoiceSaga);
  yield takeLatest(BillingActionTypes.CHANGE_REQUEST, changeSaga);
  yield takeLatest(BillingActionTypes.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionSaga);
  yield takeLatest(BillingActionTypes.UPDATE_CONTACTS_REQUEST, updateContactsSaga);
  yield takeLatest(BillingActionTypes.UPDATE_BILLING_ADDRESS_REQUEST, updateBillingAddressSaga);
  yield takeLatest(BillingActionTypes.DELETE_SCHEDULED_CHANGE_REQUEST, deleteScheduledChangeSaga);
  yield takeLatest(BillingActionTypes.GET_ITEM_PRICES_REQUEST, getItemPricesSaga);
  yield takeLatest(BillingActionTypes.REFRESH_TRIAL_LICENSE_REQUEST, refreshTrialLicenseSaga);
  yield takeLatest(BillingActionTypes.REFRESH_TENANT_REQUEST, refreshTenantSaga);
  yield takeLatest(BillingActionTypes.PAY_LATER_REQUEST, payLaterSaga);
  yield takeLatest(BillingActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST, reactivateSubscriptionSaga);
  yield takeLatest(BillingActionTypes.CALCULATE_TAXES_REQUEST, calculateTaxesSaga);
}
