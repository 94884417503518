import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  newAvailabilityNameModalActions,
  availabilityActions,
  availabilitySelectors,
  AvailabilityOption,
  DEFAULT_AVAILABILITY_MODEL,
} from '../../store/availability';
import { ModalName } from '../../store/modals';
import { AvailabilityRecord } from '../../components/availability';
import labels from './labels';
import { Modal, Search } from '../../components/common';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { AppDispatch } from '../../store/rootStore';
import { MAX_LENGTH_NAME, MIN_ITEMS_FOR_SEARCH } from '../../types/constants';
import { timeHandlerService } from '../../services/TimeHandlerService';
import { userSettingsSelectors } from '../../store/userSettings';
import { notificationsActions } from '../../store/notifications';
import { useSession } from '../../hooks/useSession';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ErrorText } from '../../components/common/errorText/ErrorText';
import { StarIcon } from '@heroicons/react/24/outline';
import { Path } from '../../routing';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { locationsActions } from '../../store/locations';
import { OrgType } from '../../API';
import { authenticationSelectors, TRIAL_LIMIT_ERROR_TOAST } from '../../store/authentication';

export const Availability = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const availabilities = useSelector(availabilitySelectors.selectAvailabilities);
  const availabilitiesOptions = useSelector(availabilitySelectors.selectUserAvailabilitiesOptions);
  const selectedAvailabilityId = useSelector(availabilitySelectors.selectAvailabilityId);
  const availabilitiesName = useSelector(availabilitySelectors.selectUserAvailabilitiesNames);
  const isCreateAvail = useSelector(userSettingsSelectors.selectAvailabilityCreate);
  const tenantType = useSelector(authenticationSelectors.selectTenantType);

  const [nameForNewScheduleDialog, setNameForNewScheduleDialog] = useState('');
  const [isNameEmpty, setIsNameEmpty] = useState(true);
  const [isNameDuplicated, setIsNameDuplicated] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [visibleItems, setVisibleItems] = useState<Array<AvailabilityOption>>([]);
  const [menuItems, setMenuItems] = useState<Array<MenuItem>>([]);
  const [hiddenCount, setHiddenCount] = useState(0);
  const menu = useRef<Menu>(null);

  useEffect(() => {
    dispatch(availabilityActions.getAvailabilityRequest());
    dispatch(locationsActions.getLocationsRequest());
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    // to track schedule-tab-container resize
    containerRef.current && observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let totalWidth = 104; // offset for menu block 80px + 24px for first element start
    let lastVisibleIndex = 0;

    const tabs = Array.from(containerRef.current?.children || []).filter((tab) =>
      tab.classList.contains('schedule-tab-hidden')
    );
    tabs.forEach((tab, index) => {
      totalWidth += (tab as HTMLElement).offsetWidth + 10; // gap 10px
      if (totalWidth <= containerWidth) {
        lastVisibleIndex = index;
      }
    });
    setHiddenCount(availabilitiesOptions.length - lastVisibleIndex - 1);
    setVisibleItems(availabilitiesOptions.slice(0, lastVisibleIndex + 1));
    setMenuItems(
      availabilitiesOptions
        .slice(lastVisibleIndex + 1)
        .map((option) => ({ label: option.label, id: option.value, command: () => onAvailabilityClick(option.value) }))
    );
  }, [availabilitiesOptions, containerWidth]);

  useEffect(() => {
    if (!isInitialVisit) {
      dispatch(availabilityActions.getAvailabilityRequest());
    }

    timeHandlerService.startTimeTrack();
    return () => {
      timeHandlerService.stopTimeTrack();
    };
  }, [isInitialVisit]);

  const onAvailabilityClick = (id: string) => {
    dispatch(availabilityActions.selectAvailabilityThunk(id));
  };

  const handleNewSchedule = () => {
    if (availabilitiesOptions.length >= 40 && tenantType !== OrgType.TEST) {
      dispatch(notificationsActions.showToast(TRIAL_LIMIT_ERROR_TOAST));
    } else {
      setIsNameEmpty(true);
      setIsNameDuplicated(false);
      dispatch(newAvailabilityNameModalActions.openModal());
    }
  };

  const handleChangeNameCancelDialog = () => {
    dispatch(newAvailabilityNameModalActions.closeModal());
    setNameForNewScheduleDialog('');
  };

  const handleChangeNameSave = () => {
    dispatch(
      availabilityActions.setAvailability({
        ...DEFAULT_AVAILABILITY_MODEL,
        name: nameForNewScheduleDialog.trim(),
      })
    );
    dispatch(availabilityActions.createAvailabilityRequest());
    dispatch(newAvailabilityNameModalActions.closeModal());
    setNameForNewScheduleDialog('');
  };

  const handleChangeScheduleName = (name: string) => {
    setNameForNewScheduleDialog(name);
    const tempName = name.trim();
    setIsNameEmpty(tempName.length === 0);
    setIsNameDuplicated(availabilitiesName.includes(tempName));
  };

  return (
    <div>
      <div className="sumo-card-bg flex flex-column pt-16px">
        <div className="flex gap-6px">
          <div className="text-title-xl-med pt-12px mr-auto">{labels.title}</div>

          {availabilities.length >= MIN_ITEMS_FOR_SEARCH && (
            <Search
              placeholder={labels.search}
              paths={[Path.Availability]}
              maxTextLength={MAX_LENGTH_NAME}
              collapsible
            />
          )}

          <Button onClick={handleNewSchedule} disabled={!isCreateAvail} className="button-xl">
            <div className="h-18px">
              <PlusIcon className="icon-18px" />
            </div>
            <span className="pl-8px">{labels.newScheduleButton}</span>
          </Button>
        </div>

        <div className="schedule-tab-container p-24px -mx-24px -my-8px" ref={containerRef}>
          {visibleItems.map((option, index) => (
            <div
              key={`${index}_${option.value}`}
              className={`schedule-tab text-title-xs-med ${
                option.value === selectedAvailabilityId ? 'schedule-tab-selected' : ''
              }`}
              onClick={() => onAvailabilityClick(option.value || '')}
            >
              {option.isDefault && <StarIcon className="icon-20px" />}
              {option.label}
            </div>
          ))}
          {hiddenCount > 0 && (
            <div className="flex h-full gap-10px">
              <div className="border-left-1 border-heavy-40 my-10px"></div>
              <div
                className={`justify-content-center schedule-tab w-60px ${
                  menuItems.some((item) => item.id === selectedAvailabilityId) ? 'schedule-tab-selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  menu.current?.toggle(e);
                }}
              >
                <Menu ref={menu} model={menuItems} popup />
                {`+${hiddenCount}`}
              </div>
            </div>
          )}
          {availabilitiesOptions.map((option, index) => (
            <div key={`${index}_${option.value}`} className="schedule-tab text-title-xs-med schedule-tab-hidden">
              {option.label}
            </div>
          ))}
        </div>

        <AvailabilityRecord />
      </div>

      <Modal.Container name={ModalName.NEW_AVAILABILITY_NAME} closable={true} onClose={handleChangeNameCancelDialog}>
        <Modal.Header>
          <div>{labels.dialogNewTitleName}</div>
        </Modal.Header>
        <div className="w-440px">
          <InputText
            id="scheduleName"
            type="text"
            value={nameForNewScheduleDialog}
            onChange={(e) => {
              handleChangeScheduleName(e.target.value);
            }}
            placeholder={labels.dialogPlaceholder}
            className={`w-full ${(isNameEmpty || isNameDuplicated) && 'border-tomato-dark'}`}
            maxLength={MAX_LENGTH_NAME}
          />
          {isNameDuplicated && <ErrorText text={labels.duplicateNameMessage} className="mt-10px" />}
        </div>
        <Modal.Buttons>
          <Button onClick={handleChangeNameSave} disabled={isNameEmpty || isNameDuplicated} autoFocus>
            {labels.create}
          </Button>
          <Button onClick={handleChangeNameCancelDialog} text>
            {labels.cancel}
          </Button>
        </Modal.Buttons>
      </Modal.Container>
    </div>
  );
};
