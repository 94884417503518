import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName, modalsActions } from '../../../store/modals';
import {
  deleteWorkspaceNameModalActions,
  deleteWorkspaceNameModalSelectors,
  workspacesSelectors,
} from '../../../store/workspaces';
import { workspacesActions } from '../../../store/workspaces/actions';
import { ConfirmationModal } from '../../common';
import labels from './labels';

interface IDeleteWorkspaceModal {
  handleSearchChange?: (value: string) => void;
}

export const DeleteWorkspaceModal = (props: IDeleteWorkspaceModal) => {
  const dispatch = useDispatch();
  const selectedWorkspace = useSelector(workspacesSelectors.selectWorkspace);
  const isOpen = useSelector(deleteWorkspaceNameModalSelectors.selectIsModalOpen);

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: ModalName.DELETE_WORKSPACE_NAME }));
    return () => {
      dispatch(modalsActions.deleteModal(ModalName.DELETE_WORKSPACE_NAME));
    };
  }, []);

  const handleClose = () => {
    dispatch(deleteWorkspaceNameModalActions.closeModal());
  };

  const handleConfirm = () => {
    dispatch(workspacesActions.deleteWorkspacesRequest());
    props.handleSearchChange && props.handleSearchChange(''); // clear the search value
    handleClose();
  };

  return (
    <ConfirmationModal
      visible={isOpen}
      title={labels.delete}
      description={`${labels.deleteDesc} "${selectedWorkspace.name}"?`}
      confirmButtonLabel={labels.yes}
      cancelButtonLabel={labels.no}
      onConfirm={handleConfirm}
      onCancel={handleClose}
      onClose={handleClose}
    />
  );
};
