import { ORGS_CHART_FILTER, ORGS_CHART_VALUES, OrgsChartData } from '../../../store/opsConsole/orgs';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { Chart as ChartJS, ChartType, ScriptableContext, TooltipItem } from 'chart.js';
import { Chart } from 'primereact/chart';
import labels from './labels';
import { Tooltip } from 'primereact/tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export const STROKE_COLOR = '#006CE0';
export const HEAVY_BLUE_10 = '#EDEEEF';
export const BLUE_LIGHT = '#DEEBF9';

type OPSConsoleChartProps = {
  data: OrgsChartData[];
  title: string;
  total: string;
  totalLabel: string;
  isFethcing: boolean;
  filter: string;
  handleFilterChange: (value: ORGS_CHART_VALUES) => void;
  totalTooltip?: string;
  optionsScalesY?: object;
  tooltipLabel?: (data: TooltipItem<ChartType>) => string;
};
export const OPSConsoleChart = ({
  data,
  title,
  total,
  totalLabel,
  isFethcing,
  filter,
  handleFilterChange,
  totalTooltip,
  optionsScalesY,
  tooltipLabel,
}: OPSConsoleChartProps) => {
  const drawLineOnHoverPoint = (chart: ChartJS) => {
    const activeElements = chart.tooltip?.getActiveElements();
    if (activeElements && activeElements.length > 0) {
      const { x } = activeElements[0].element;
      const yAxis = chart.scales.y;
      const ctx = chart.ctx;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, yAxis.top);
      ctx.lineTo(x, yAxis.bottom);
      ctx.lineWidth = 1;
      ctx.strokeStyle = STROKE_COLOR;
      ctx.stroke();
      ctx.restore();
    }
  };

  return (
    <div className="h-340px w-full sumo-card py-10px px-20px mt-12px flex flex-column gap-20px mb-24px">
      <div className="text-title-s-med">{title}</div>
      <div className="flex-left-center gap-16px">
        {isFethcing ? (
          <Skeleton height="48px" width="76px" />
        ) : (
          <div className="text-display-lg-reg text-blue-main">{total}</div>
        )}
        <div>
          <div className="flex-left-center gap-4px">
            <div className="text-title-s-med">{totalLabel}</div>
            {totalTooltip && (
              <>
                <Tooltip target=".total" className="max-w-360px" />
                <InformationCircleIcon
                  className="total icon-16px text-blue-main"
      
                  data-pr-tooltip={totalTooltip}
                />
              </>
            )}
          </div>
          <div className="text-body-s-reg text-heavy-60">
            <span>{labels.for}</span>
            <Dropdown
              options={ORGS_CHART_FILTER}
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
              className="inline-dropdown text-body-s-reg text-heavy-60 hover-text-blue-dark"
              panelClassName="inline-dropdown"
            />
          </div>
        </div>
      </div>
      {isFethcing ? (
        <Skeleton height="184px" className="w-full" />
      ) : (
        <Chart
          type="line"
          className="w-full overflow-x-auto"
          height="184"
          options={{
            borderColor: STROKE_COLOR,
            borderWidth: 2,
            color: 'var(--heavy-blue-80)',
            elements: {
              point: {
                radius: 3,
                borderRadius: 3,
              },
            },
            plugins: {
              legend: { display: false },
              tooltip: {
                displayColors: false,
                backgroundColor: 'var(--heavy-blue-100)',
                titleFont: {
                  family: 'Poppins',
                  size: '12px',
                  weight: '400',
                  lineHeight: '16px',
                },
                bodyFont: {
                  family: 'Poppins',
                  size: '16px',
                  weight: '500',
                  lineHeight: '26px',
                },
                callbacks: {
                  label: tooltipLabel,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'time',
                time: {
                  minUnit: 'day',
                  tooltipFormat: 'D MMMM YYYY',
                  displayFormats: {
                    day: 'D MMM',
                    month: 'MMM YY',
                  },
                },
                grid: {
                  color: HEAVY_BLUE_10,
                },
                border: { color: HEAVY_BLUE_10 },
                ticks: {
                  maxTicksLimit: 12,
                },
              },
              y: {
                grid: {
                  color: HEAVY_BLUE_10,
                },
                beginAtZero: true,
                border: { color: HEAVY_BLUE_10 },
                ticks: {
                  maxTicksLimit: 5,
                },
                ...optionsScalesY,
              },
            },
            transitions: {
              active: {
                animation: {
                  duration: 0,
                },
              },
            },
          }}
          data={{
            datasets: [
              {
                data,
                pointBackgroundColor: (ctx: ScriptableContext<'line'>) =>
                  ctx.dataIndex === 0 || ctx.dataIndex === ctx.chart.data.datasets[0].data.length - 1
                    ? STROKE_COLOR
                    : 'transparent',
                pointBorderColor: 'transparent',
                pointHoverRadius: 6,
                pointHoverBorderWidth: 3,
                pointHoverBorderColor: BLUE_LIGHT,
                pointHoverBackgroundColor: STROKE_COLOR,
                tension: 0.3,
              },
            ],
          }}
          plugins={[
            {
              afterDraw: drawLineOnHoverPoint,
            },
          ]}
        />
      )}
    </div>
  );
};
