import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const billingChangeModalActions = modalsActionsFactory(ModalName.CHANGE_SEATS_AND_TERM_MODAL);
export const billingChangeModalSelectors = modalsSelectorsFactory(ModalName.CHANGE_SEATS_AND_TERM_MODAL);

export const billingAddressModalActions = modalsActionsFactory(ModalName.BILLING_ADDRESS_MODAL);
export const billingAddressModalSelectors = modalsSelectorsFactory(ModalName.BILLING_ADDRESS_MODAL);

export const buyLicenseModalActions = modalsActionsFactory(ModalName.BUY_LICENSE_MODAL);
export const buyLicenseModalSelectors = modalsSelectorsFactory(ModalName.BUY_LICENSE_MODAL);
