import { t } from '../../../i18n/i18n';

export default {
  title: t('OPSConsoleOrgs:TITLE'),
  search: t('OPSConsoleOrgs:SEARCH_PLACEHOLDER'),
  export: t('OPSConsoleOrgs:EXPORT'),
  status: t('OPSConsoleOrgs:STATUS_TITLE'),
  term: t('OPSConsoleOrgs:TERM_TITLE'),
  changes: t('OrgsChanges:CHANGES_TITLE'),
};
