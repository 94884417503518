export const CalendarConnections = Object.freeze({
  CALENDAR_INFO_MESSAGE: `Sie können nur 1 Kalender verbinden`, // Um dieses Limit zu erhöhen, kontaktieren Sie den Vertrieb, um Ihre Lizenzstufe zu aktualisieren.
  DESCRIPTION: `Verbinden Sie Ihren Kalender, damit SUMO weiß, wann Sie verfügbar sind und Ihren Kalender aktualisiert, wenn Termine gebucht werden.`,
  DISCONNECT_MESSAGE: `Sind Sie sicher, dass Sie Ihren Kalender trennen möchten?`,
  DISCONNECT_MESSAGE_DESCRIPTION: `Termine werden nicht gelöscht oder storniert.`,
  DISCONNECT_TITLE: `Kalender trennen`,
  EXCHANGE_CALENDAR_DESCRIPTION: `Exchange Server 2013, 2016 oder 2019`,
  EXCHANGE_CALENDAR_TITLE: `Exchange-Kalender`,
  GOOGLE_CALENDAR_DESCRIPTION: `Gmail, G Suite, Google Workspace`,
  GOOGLE_CALENDAR_TITLE: `Google-Kalender`,
  MICROSOFT_CALENDAR_DESCRIPTION: `Office 365, Outlook.com, Live.com oder Hotmail-Kalender`,
  MICROSOFT_CALENDAR_TITLE: `Office 365-Kalender`,
  SELECT_CALENDAR_TITLE: `Kalender auswählen`,
  TITLE: `Kalenderverbindungen`,
  TOOLTIP: `Verbinden Sie Ihren Google- oder Microsoft-Kalender mit SUMO`,
});

export const CookieSettings = Object.freeze({
  AGREE_CLOSE: `Zustimmen und Schließen`,
  DESCRIPTION: `Wenn Sie eine Website besuchen, hat sie die Möglichkeit, bestimmte Informationen in Ihrem Browser zu speichern oder abzurufen. Diese Informationen, oft in Form von Cookies, können sich auf Ihre Präferenzen, Ihr Gerät oder andere Details beziehen. Ihr Hauptzweck ist es, sicherzustellen, dass die Website wie erwartet funktioniert. Obwohl diese Informationen Sie in der Regel nicht direkt identifizieren, können sie Ihr Surferlebnis verbessern, indem sie Inhalte personalisieren. Wir verstehen und schätzen Ihr Recht auf Privatsphäre, weshalb wir Ihnen die Möglichkeit geben, Ihre Cookie-Präferenzen zu kontrollieren und anzupassen. Durch Klicken auf die verschiedenen Kategorieüberschriften können Sie mehr über jeden Cookie-Typ erfahren und unsere Standardeinstellungen ändern. Bitte beachten Sie, dass das Blockieren bestimmter Cookie-Typen Ihre Gesamterfahrung auf der Website beeinträchtigen und die Dienste einschränken kann, die wir Ihnen anbieten können.`,
  DESCRIPTION_FIRST_PART: `Wenn Sie eine Website besuchen, hat sie die Möglichkeit, bestimmte Informationen in Ihrem Browser zu speichern oder abzurufen. Diese Informationen, oft in Form von Cookies, können sich auf Ihre Präferenzen, Ihr Gerät oder andere Details beziehen. Ihr Hauptzweck ist es, sicherzustellen, dass die Website wie erwartet funktioniert.`,
  DESCRIPTION_SECOND_PART: `Obwohl diese Informationen Sie in der Regel nicht direkt identifizieren, können sie Ihr Surferlebnis verbessern, indem sie Inhalte personalisieren. Wir verstehen und schätzen Ihr Recht auf Privatsphäre, weshalb wir Ihnen die Möglichkeit geben, Ihre Cookie-Präferenzen zu kontrollieren und anzupassen.`,
  DESCRIPTION_THIRD_PART: `Durch Klicken auf die verschiedenen Kategorieüberschriften können Sie mehr über jeden Cookie-Typ erfahren und unsere Standardeinstellungen ändern. Bitte beachten Sie, dass das Blockieren bestimmter Cookie-Typen Ihre Gesamterfahrung auf der Website beeinträchtigen und die Dienste einschränken kann, die wir Ihnen anbieten können.`,
  FUNCTIONAL_NAME: `Funktionale Cookies`,
  MANAGE_TITLE: `Verwalten Sie Ihre Zustimmungseinstellungen`,
  NECESSARY_ONLY: `Nur unbedingt erforderliche Cookies`,
  PERFORMANCE_NAME: `Performance-Cookies`,
  STRICTLY_NAME: `Streng notwendige Cookies`,
  TARGETING_NAME: `Targeting-Cookies`,
  TITLE: `Cookie-Einstellungen`,
  SHOW_ALL: `Alle anzeigen`,
  HIDE_ALL: `Alle ausblenden`,
});

export const Login = Object.freeze({
  GOOGLE_ACCOUNT: `Google-Konto`,
  GOOGLE_ACCOUNTS_SWITCH: `Google-Konten wechseln`,
  GOOGLE_SWITCH: `Mit Google anmelden`,
  GOOGLE_SWITCH_DESC: `Wechseln Sie von der Microsoft-Authentifizierung zu Google`,
  MICROSOFT_ACCOUNT: `Microsoft-Konto`,
  MICROSOFT_ACCOUNTS_SWITCH: `Microsoft-Konten wechseln`,
  MICROSOFT_SWITCH: `Mit Microsoft anmelden`,
  MICROSOFT_SWITCH_DESC: `Wechseln Sie von der Google-Authentifizierung zu Microsoft`,
  SWITCH_ACCOUNT_MESSAGE: `Ihr SUMO1-Konto verliert den Zugriff auf Daten, die nur über Ihren ursprünglichen Anbieter zugänglich sind. Möglicherweise müssen Sie auch erneut Zugriff auf Integrationen und Kalender gewähren.`,
  SWITCH_DESCRIPTION_PART1: `Wechseln Sie von `,
  SWITCH_DESCRIPTION_PART2: ` Authentifizierung zu `,
});

export const Role = Object.freeze({
  ROLE_DESCRIPTION: `Ihre aktuelle Rolle`,
});

export const AccountSettings = Object.freeze({
  DATE_FORMAT: `Datumsformat`,
  LANGUAGE: `Sprache`,
  NAME: `Name`,
  WELCOME_MESSAGE: `Willkommensnachricht`,
  PHONE_NUMBER: `Telefonnummer`,
  PROFILE_IMAGE: `Profilbild`,
  PHONE_DETAILS: `Telefonangaben`,
  PHONE_DETAILS_PLACEHOLDER: `Fügen Sie hier zusätzliche Informationen oder Anweisungen hinzu.\r\nz. B. Ich freue mich auf unser Treffen. Senden Sie mir jederzeit eine SMS.`,
  TIME_FORMAT: `Zeitformat`,
  TIME_ZONE: `Zeitzone`,
  COUNTRY: `Land`,
});

export const Profile = Object.freeze({
  TITLE: `Profil`,
  ACCOUNT_SETTINGS: `Kontoeinstellungen`,
  ACCOUNT_DESCRIPTION: `Grundlegende Informationen und Einstellungen`,
  LOGIN: `Anmeldung`,
  LOGIN_DESCRIPTION: `Verknüpftes Konto einrichten`,
  MY_ROLE: `Meine Rolle`,
  MY_ROLE_DESCRIPTION: `Ihre aktuelle Rolle und Berechtigungen`,
  COOKIE_SETTINGS: `Cookie-Einstellungen`,
  COOKIE_DESCRIPTION: `Notwendige, Leistungs-, Funktionale, Targeting-Cookies`,
  DELETE_ACCOUNT_DESCRIPTION: `Löschen Sie Ihr Konto und alle zugehörigen Daten aus Ihrer Organisation`,
});

export const DeleteAccount = Object.freeze({
  TITLE: `Konto löschen`,
  WARNING_DELETE: `Achtung, diese Aktion ist dauerhaft und kann nicht rückgängig gemacht werden.`,
  DELETE_ACCOUNT_AND_ORG: `Konto und Organisation löschen`,
  YES_DELETE: `Ja, löschen`,
  DELETE_TO_CONFIRM: `Geben Sie <b>Löschen</b> ein, um zu bestätigen.`,
  DELETE_CONFIRM_WORD: `Löschen`,
  DELETE_ACCOUNT_MODAL_DESCRIPTION: `Löschen Sie Ihre persönlichen Daten und alle zugehörigen Daten aus Ihrer Organisation. Diese Aktion ist dauerhaft und kann nicht rückgängig gemacht werden.`,
  DELETE_ACCOUNT_AND_ORG_MODAL_DESCRIPTION: `Löschen Sie Ihr Konto und Ihre Organisation. Diese Aktion ist dauerhaft und kann nicht rückgängig gemacht werden.`,
  LIST_DELETING_ENTITIES_TITLE: `Durch das Löschen Ihres Kontos werden folgende Aktionen ausgeführt`,
  LIST_DELETING_ACCOUNT_AND_ORG_ENTITIES_TITLE: `Durch das Löschen Ihres Kontos und Ihrer Organisation werden`,
  LIST_DELETING_ENTITIES: `Sie werden auf allen Geräten abgemeldet \\p Alle persönlichen Informationen werden gelöscht \\p Alle persönlichen Integrationen werden gelöscht`,
  ADDITIONAL_LIST_DELETING_ORG_ENTITIES: `\\p Alle Daten der Organisation werden gelöscht`,
  TRANSFER_OWNERSHIP_TITLE: `Eigentum übertragen und mein Konto löschen`,
  TRANSFER_OWNERSHIP_DESCRIPTION: `Bevor Sie Ihr Konto löschen können, müssen Sie das Eigentum auf ein anderes Teammitglied übertragen, damit es die Organisation verwalten kann.`,
  DELETE_ACCOUNT_AND_ORG_TITLE: `Mein Konto löschen und Organisation auflösen`,
  DELETE_ACCOUNT_AND_ORG_DESCRIPTION: `Wenn Sie das Eigentum nicht übertragen möchten, können Sie Ihr Konto löschen und die Organisation auflösen.`,
  TRANSFER_OWNERSHIP: `Eigentum übertragen`,
  NEED_INVITE_NEW_USER_TO_ORG: `Laden Sie jemanden in Ihre Organisation ein und akzeptieren Sie die Einladung`,
});

export const UserSettingsToastsNotifications = Object.freeze({
  CONNECT_EXTERNAL_CALENDAR_ERROR_TOAST_MESSAGE: `Neue Kalenderanfrage fehlgeschlagen`,
  CONNECT_EXTERNAL_CALENDAR_SUCCESS_TOAST_MESSAGE: `Neuer Kalender erfolgreich verbunden!`,
  CONNECT_ZOOM_ERROR_TOAST_MESSAGE: `Fehler bei der Verbindung mit Zoom`,
  CONNECT_ZOOM_SUCCESS_TOAST_MESSAGE: `Erfolgreich mit Zoom verbunden!`,
  SAVE_USER_SETTINGS_ERROR_TOAST_MESSAGE: `Fehler beim Speichern der Einstellungen`,
  SAVE_USER_SETTINGS_SUCCESS_TOAST_MESSAGE: `Ihre Einstellungen wurden erfolgreich gespeichert!`,
  SWITCH_ACCOUNT_SUCCESS_TOAST_MESSAGE: `Sie haben erfolgreich das Konto gewechselt`,
  SWITCH_ACCOUNT_ERROR_TOAST_MESSAGE: `Fehler beim Konto-Wechsel. Bitte versuchen Sie es später erneut`,
  DELETE_ACCOUNT_ERROR_TOAST_MESSAGE: `Kontolöschung fehlgeschlagen`,
});

export const Guide = Object.freeze({
  GET_STARTED: `Los geht's mit SUMO1`,
  START: `Start`,

  BACK: `Zurück`,
  CLOSE: `Schließen`,
  LAST: `Fertig`,
  NEXT: `Weiter`,
  NEXT_LABEL_WITH_PROGRESS: `Weiter (Schritt {step} von {steps})`,
  OPEN_THE_DIALOG: `Dialog öffnen`,
  SKIP: `Tour beenden`,
  COMPLETE_TOUR: `Tour abschließen`,
  GO_TO: `Gehe zu`,

  PROFILE_TITLE: `Verwalten Sie Ihr Profil`,
  PROFILE_DESCRIPTION: `Sie können Ihre Benutzerprofil-Einstellungen verwalten, einschließlich Sprache, Zeitzone, Ihre Rolle und Berechtigungen, usw.`,
  INTEGRATIONS_TITLE: `Verbinden Sie Ihre Videokonferenzen`,
  INTEGRATIONS_DESCRIPTION: `SUMO 1 hat integrierte Lösungen für CRM, Videokonferenzen, Zahlungsprozesse und mehr.`,
  AVAILABILITY_TITLE: `Ändern Sie Ihre Verfügbarkeitszeiten`,
  AVAILABILITY_DESCRIPTION: `Sie können Ihre Verfügbarkeit ändern oder neue Verfügbarkeitszeiten wie "Nachmittags" hier erstellen.`,
  SUMO_INBOX_TITLE: `SUMO Inbox installieren`,
  SUMO_INBOX_DESCRIPTION: `SUMO Meeting Booker ist eine Browser-Erweiterung, die 90% der Benutzer täglich verwenden, um leistungsstarke SUMO1-Terminplanungsfunktionen in Gmail und Outlook zu bringen.`,
  SUMO_INBOX_LINK: `SUMO1-Browser-Add-On installieren`,
  BOOKING_PAGES_TITLE: `Erstellen Sie Ihre Buchungsseite`,
  BOOKING_PAGES_DESCRIPTION: `Sie können Ihre Buchungsseite erstellen, die für längere Zeit verwendet werden kann. Zum Beispiel eine Buchungsseite-Link für Ihre E-Mail-Signaturen, Ihre Website, usw.`,
  BOOKED_MEETINGS_TITLE: `Gebuchte Meetings ansehen`,
  BOOKED_MEETINGS_DESCRIPTION: `Eine Liste von zukünfigen und vergangenen Meetings ansehen, einschließlich der Option, sie zu stornieren, als nicht angetreten markieren, erneut buchen und mehr.`,
  SMART_ALERTS_TITLE: `Streamline Ihren Terminplan mit intelligenten Warnungen`,
  SMART_ALERTS_DESCRIPTION: `Buchungsseiten enthalten grundlegende Warnungen, z.B. eine SMS-Erinnerung 1 Tag vor dem Meeting. Smart Alerts helfen Benutzern, komplexere Termin-Warnungen einzurichten.`,
  BOOKING_TEMPLATES_TITLE: `Buchungs-Vorlage`,
  BOOKING_TEMPLATES_DESCRIPTION: `Sie können Buchungs-Vorlagen (auch als "Meeting-Typen" bekannt) erstellen, um es einfacher zu machen, schnell ein bestimmtes Meeting mit der richtigen Person, am richtigen Ort und am richtigen Zeitpunkt zu buchen.`,
  TEAMS_TITLE: `Verwalten Sie Ihre Teams`,
  TEAMS_DESCRIPTION: `Sie können Ihre Teams (auch als "Ressourcenpools" bekannt) erstellen, um automatisch einem Meeting zugewiesen zu werden, basierend auf einem Zuweisungsmodus, z.B. Round-Robin.`,
  USERS_TITLE: `Verwalten Sie Ihre Benutzer`,
  USERS_DESCRIPTION: `Sie können schnell Benutzer einladen, um SUMO1 zu verwenden, und aktivieren oder inaktivieren Sie Benutzer.`,
  ROLES_TITLE: `Verwalten Sie die Berechtigungen Ihrer Teammitglieder`,
  ROLES_DESCRIPTION: `Sie können Ihre Teammitglieder-Berechtigungen verwalten, um sicherzustellen, dass sie die richtigen CRUD-Zugriffe (erstellen, lesen, aktualisieren, löschen) benötigen.`,
  BILLING_TITLE: `Wählen Sie Ihren Plan aus und konfigurieren Sie Ihre Zahlung`,
  BILLING_DESCRIPTION: `Sie können Ihre SUMO1-Abonnement selbst verwalten, einschließlich der Möglichkeit, zusätzliche Lizenzen hinzuzufügen, wenn Sie diese benötigen.`,
});
