import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const inviteUsersModalActions = modalsActionsFactory(ModalName.INVITE_USERS_MODAL_NAME);
export const inviteUsersModalSelectors = modalsSelectorsFactory(ModalName.INVITE_USERS_MODAL_NAME);

export const confirmInviteUsersModalActions = modalsActionsFactory(ModalName.CONFIRM_INVITE_USERS_MODAL_NAME);
export const confirmInviteUsersModalSelectors = modalsSelectorsFactory(ModalName.CONFIRM_INVITE_USERS_MODAL_NAME);

export const editUsersModalActions = modalsActionsFactory(ModalName.EDIT_USERS_MODAL_NAME);
export const editUsersModalSelectors = modalsSelectorsFactory(ModalName.EDIT_USERS_MODAL_NAME);

export const confirmUsersModalActions = modalsActionsFactory(ModalName.CONFIRM_USERS_MODAL_NAME);
export const confirmUsersModalSelectors = modalsSelectorsFactory(ModalName.CONFIRM_USERS_MODAL_NAME);
