import { AppThunk } from '../rootStore';
import { availabilitySelectors } from './selectors';
import { CLONE_NAME_POSTFIX } from './constants';
import { changeAvailabilityNameModalActions } from './modal';
import { AvailabilityModel } from '../../generated-sources/internal-api/models/AvailabilityModel';

export enum AvailabilityActionTypes {
  GET_AVAILABILITY_REQUEST = 'availability/GET_AVAILABILITY_REQUEST',
  GET_AVAILABILITY_SUCCESS = 'availability/GET_AVAILABILITY_SUCCESS',
  GET_AVAILABILITY_FAIL = 'availability/GET_AVAILABILITY_FAIL',
  CREATE_AVAILABILITY_REQUEST = 'availability/CREATE_AVAILABILITY_REQUEST',
  CREATE_AVAILABILITY_SUCCESS = 'availability/CREATE_AVAILABILITY_SUCCESS',
  CREATE_AVAILABILITY_FAIL = 'availability/CREATE_AVAILABILITY_FAIL',
  CLONE_AVAILABILITY_REQUEST = 'availability/CLONE_AVAILABILITY_REQUEST',
  CLONE_AVAILABILITY_SUCCESS = 'availability/CLONE_AVAILABILITY_SUCCESS',
  CLONE_AVAILABILITY_FAIL = 'availability/CLONE_AVAILABILITY_FAIL',
  DELETE_AVAILABILITY_REQUEST = 'availability/DELETE_AVAILABILITY_REQUEST',
  DELETE_AVAILABILITY_SUCCESS = 'availability/DELETE_AVAILABILITY_SUCCESS',
  DELETE_AVAILABILITY_FAIL = 'availability/DELETE_AVAILABILITY_FAIL',
  SAVE_AVAILABILITY_REQUEST = 'availability/SAVE_AVAILABILITY_REQUEST',
  SAVE_AVAILABILITY_SUCCESS = 'availability/SAVE_AVAILABILITY_SUCCESS',
  SAVE_AVAILABILITY_FAIL = 'availability/SAVE_AVAILABILITY_FAIL',
  SET_AVAILABILITY = 'availability/SET_AVAILABILITY',
  UPDATE_AVAILABILITY = 'availability/UPDATE_AVAILABILITY',
  SET_CLONE_NAME = 'availability/SET_CLONE_NAME',
  SET_DEFAULT_AVAILABILITY_REQUEST = 'availability/SET_DEFAULT_AVAILABILITY_REQUEST',
  SET_DEFAULT_AVAILABILITY_SUCCESS = 'availability/SET_DEFAULT_AVAILABILITY_SUCCESS',
  SET_DEFAULT_AVAILABILITY_FAIL = 'availability/SET_DEFAULT_AVAILABILITY_FAIL',
  SET_DEFAULT_AVAILABILITY_ID_SUCCESS = 'availability/SET_DEFAULT_AVAILABILITY_ID_SUCCESS'
}

export type AvailabilityAction =
  | { type: AvailabilityActionTypes.GET_AVAILABILITY_REQUEST }
  | { type: AvailabilityActionTypes.GET_AVAILABILITY_SUCCESS; payload: {availabilities: AvailabilityModel[], defaultAvailability: string} }
  | { type: AvailabilityActionTypes.GET_AVAILABILITY_FAIL; error: unknown }
  | { type: AvailabilityActionTypes.CREATE_AVAILABILITY_REQUEST }
  | { type: AvailabilityActionTypes.CREATE_AVAILABILITY_SUCCESS; payload: AvailabilityModel }
  | { type: AvailabilityActionTypes.CREATE_AVAILABILITY_FAIL; error: unknown }
  | { type: AvailabilityActionTypes.CLONE_AVAILABILITY_REQUEST }
  | { type: AvailabilityActionTypes.CLONE_AVAILABILITY_SUCCESS; payload: AvailabilityModel }
  | { type: AvailabilityActionTypes.CLONE_AVAILABILITY_FAIL; error: unknown }
  | { type: AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST; payload: string }
  | { type: AvailabilityActionTypes.DELETE_AVAILABILITY_SUCCESS; payload: string }
  | { type: AvailabilityActionTypes.DELETE_AVAILABILITY_FAIL; error: unknown }
  | { type: AvailabilityActionTypes.SAVE_AVAILABILITY_REQUEST }
  | { type: AvailabilityActionTypes.SAVE_AVAILABILITY_SUCCESS; payload: AvailabilityModel }
  | { type: AvailabilityActionTypes.SAVE_AVAILABILITY_FAIL; error: unknown }
  | { type: AvailabilityActionTypes.SET_AVAILABILITY; payload: AvailabilityModel }
  | { type: AvailabilityActionTypes.UPDATE_AVAILABILITY; payload: Partial<AvailabilityModel> }
  | { type: AvailabilityActionTypes.SET_CLONE_NAME; payload: string }
  | { type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST }
  | { type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_SUCCESS }
  | { type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL; error: unknown }
  | { type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_ID_SUCCESS; payload: string }

const getAvailabilityRequest = (): AvailabilityAction => ({
  type: AvailabilityActionTypes.GET_AVAILABILITY_REQUEST,
});

const getAvailabilitySuccess = (payload: {
  availabilities: AvailabilityModel[]
  defaultAvailability: string
}): AvailabilityAction => ({
    type: AvailabilityActionTypes.GET_AVAILABILITY_SUCCESS,
    payload
});

const getAvailabilityFail = (error: unknown): AvailabilityAction => ({
  type: AvailabilityActionTypes.GET_AVAILABILITY_FAIL,
  error,
});

const createAvailabilityRequest = (): AvailabilityAction => ({
  type: AvailabilityActionTypes.CREATE_AVAILABILITY_REQUEST,
});

const createAvailabilitySuccess = (payload: AvailabilityModel): AvailabilityAction => ({
  type: AvailabilityActionTypes.CREATE_AVAILABILITY_SUCCESS,
  payload,
});

const createAvailabilityFail = (error: unknown): AvailabilityAction => ({
  type: AvailabilityActionTypes.CREATE_AVAILABILITY_FAIL,
  error,
});

const cloneAvailabilityRequest = (): AvailabilityAction => ({
  type: AvailabilityActionTypes.CLONE_AVAILABILITY_REQUEST,
});

const cloneAvailabilitySuccess = (payload: AvailabilityModel): AvailabilityAction => ({
  type: AvailabilityActionTypes.CLONE_AVAILABILITY_SUCCESS,
  payload,
});

const cloneAvailabilityFail = (error: unknown): AvailabilityAction => ({
  type: AvailabilityActionTypes.CLONE_AVAILABILITY_FAIL,
  error,
});

const deleteAvailabilityRequest = (payload: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.DELETE_AVAILABILITY_REQUEST,
  payload,
});

const deleteAvailabilitySuccess = (payload: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.DELETE_AVAILABILITY_SUCCESS,
  payload,
});

const deleteAvailabilityFail = (error: unknown): AvailabilityAction => ({
  type: AvailabilityActionTypes.DELETE_AVAILABILITY_FAIL,
  error,
});

const saveAvailabilityRequest = (): AvailabilityAction => ({
  type: AvailabilityActionTypes.SAVE_AVAILABILITY_REQUEST,
});

const saveAvailabilitySuccess = (payload: AvailabilityModel): AvailabilityAction => ({
  type: AvailabilityActionTypes.SAVE_AVAILABILITY_SUCCESS,
  payload,
});

const saveAvailabilityFail = (error: unknown): AvailabilityAction => ({
  type: AvailabilityActionTypes.SAVE_AVAILABILITY_FAIL,
  error,
});

const setAvailability = (payload: AvailabilityModel): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_AVAILABILITY,
  payload,
});

const updateAvailability = (payload: Partial<AvailabilityModel>): AvailabilityAction => ({
  type: AvailabilityActionTypes.UPDATE_AVAILABILITY,
  payload,
});

const setCloneName = (payload: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_CLONE_NAME,
  payload,
});

const setDefaultAvailabilityRequest = (): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_REQUEST,
});

const setDefaultAvailabilitySuccess = (): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_SUCCESS,
});

const setDefaultAvailabilityFail = (error: unknown): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_FAIL,
  error,
});

const setDefaultAvailabilityIdSuccess = (payload: string): AvailabilityAction => ({
  type: AvailabilityActionTypes.SET_DEFAULT_AVAILABILITY_ID_SUCCESS,
  payload,
});

// TODO: move to /thunks please
const cloneAvailabilityThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = availabilitySelectors.selectName(state) + CLONE_NAME_POSTFIX;
  dispatch(setCloneName(name));
  dispatch(changeAvailabilityNameModalActions.openModal());
};

const selectAvailabilityThunk =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const availability = availabilitySelectors.selectAvailabilityById(id)(state);
    availability && dispatch(setAvailability(availability));
  };

const editAvailabilityNameThunk = (): AppThunk => (dispatch, getState) => {
  const state = getState();
  const name = availabilitySelectors.selectName(state) || '';
  dispatch(setCloneName(name));
  dispatch(changeAvailabilityNameModalActions.openModal());
};

export const availabilityActions = {
  getAvailabilityRequest,
  getAvailabilitySuccess,
  getAvailabilityFail,
  createAvailabilityRequest,
  createAvailabilitySuccess,
  createAvailabilityFail,
  cloneAvailabilityRequest,
  cloneAvailabilitySuccess,
  cloneAvailabilityFail,
  deleteAvailabilityRequest,
  deleteAvailabilitySuccess,
  deleteAvailabilityFail,
  saveAvailabilityRequest,
  saveAvailabilitySuccess,
  saveAvailabilityFail,
  setAvailability,
  updateAvailability,
  setCloneName,
  setDefaultAvailabilityRequest,
  setDefaultAvailabilitySuccess,
  setDefaultAvailabilityFail,
  setDefaultAvailabilityIdSuccess,
  cloneAvailabilityThunk,
  selectAvailabilityThunk,
  editAvailabilityNameThunk,
};
