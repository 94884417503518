import {
  CodeBracketIcon,
  EllipsisVerticalIcon,
  LinkIcon,
  LockClosedIcon,
  MapPinIcon,
  PhoneIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Tooltip } from 'primereact/tooltip';
import { TooltipEvent } from 'primereact/tooltip/tooltipoptions';
import { classNames } from 'primereact/utils';
import { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocationType, UpdateBookingPageInput, UpdateBookingTemplateInput } from '../../../API';
import { Path } from '../../../routing';
import { navigationService } from '../../../services/NavigationService';
import { getUrlOrigin } from '../../../services/URLService';
import { getTimeUnitLabel } from '../../../services/utils';
import {
  addToWebsiteModalActions,
  bookingPagesActions,
  bookingPageSelectors,
  deleteBookingPagesModalActions,
} from '../../../store/bookingPages';
import { AppDispatch } from '../../../store/rootStore';
import { InformationBlock } from '../../common';
import labels from './labels';

interface IBookingPageCard {
  bookingPage: UpdateBookingPageInput;
  selectMode: boolean;
  selected: boolean;
  hostsInfo: string;
  template?: UpdateBookingTemplateInput;
  handleCheckboxChange: (id: string, checked: boolean | undefined) => void;
}

export const BookingPageCard = memo(
  ({ bookingPage, selectMode, selected, hostsInfo, template, handleCheckboxChange }: IBookingPageCard) => {
    const { id, shortLink, what, when } = bookingPage;
    const dispatch = useDispatch<AppDispatch>();
    const menu = useRef<Menu>(null);
    const tooltip = useRef<Tooltip>(null);

    const isBookingPageLocked = useSelector(bookingPageSelectors.selectIsBookingPageLocked(bookingPage));
    const isBookingPageCreate = useSelector(bookingPageSelectors.selectIsBookingPageCreate(bookingPage));
    const isBookingPageEdit = useSelector(bookingPageSelectors.selectIsBookingPageEdit(bookingPage));
    const isBookingPageDelete = useSelector(bookingPageSelectors.selectIsBookingPageDelete(bookingPage));

    let blockCardClick = false;

    const handleCardClick = () => {
      if (blockCardClick) {
        blockCardClick = false;
        return;
      }
      selectMode ? handleCheckboxChange(id, !selected) : handleEdit();
    };

    const handleEdit = () => {
      dispatch(bookingPagesActions.setBookingPage(bookingPage));
      navigationService.navigateTo(Path.EditBookingPage.replace(':bookingPageId', id));
    };

    const handleActivate = () => {
      dispatch(
        bookingPagesActions.activateBookingPageRequest({
          ...bookingPage,
          what: { ...bookingPage.what, isActive: !what?.isActive },
        })
      );
    };

    const handleActivateButton = () => {
      blockCardClick = true;
      handleActivate();
    };

    const handleClone = () => {
      dispatch(bookingPagesActions.setBookingPage(bookingPage));
      dispatch(bookingPagesActions.cloneBookingPageThunk());
    };

    const handleDelete = () => {
      dispatch(bookingPagesActions.selectBookingPage(id));
      dispatch(deleteBookingPagesModalActions.openModal());
    };

    const handleCopyLink = (event: React.SyntheticEvent) => {
      blockCardClick = true;
      const bookingPageUrl = shortLink
        ? shortLink
        : getUrlOrigin() + Path.PublicBookingPage.replace(':bookingPageId', id);
      navigator.clipboard.writeText(bookingPageUrl);
      handleShowTooltip(event);
    };

    const handleShowTooltip = (event: any) => {
      tooltip.current && tooltip.current.show(event as TooltipEvent);
      setLinkCopied(true);
    };

    const handleAddToWebsite = () => {
      blockCardClick = true;
      dispatch(bookingPagesActions.setBookingPage(bookingPage));
      dispatch(bookingPagesActions.setAddToWebsiteLink(Path.PublicBookingPage.replace(':bookingPageId', id)));
      dispatch(addToWebsiteModalActions.openModal());
    };

    const menuItems: MenuItem[] = [
      { visible: isBookingPageEdit, label: labels.edit, command: handleEdit },
      {
        visible: isBookingPageEdit && !!hostsInfo,
        label: what?.isActive ? labels.deactivate : labels.activate,
        command: handleActivate,
      },
      { visible: isBookingPageCreate && !!hostsInfo, label: labels.clone, command: handleClone },
      { visible: isBookingPageDelete, label: labels.delete, command: handleDelete },
    ];

    const generateLocationTypeIcon = (locationType: LocationType | null) => {
      switch (locationType) {
        case LocationType.IN_PERSON:
          return <MapPinIcon className="icon-16px" />;
        case LocationType.PHONE_CALL:
          return <PhoneIcon className="icon-16px" />;
        case LocationType.VIDEO_CONFERENCE:
          return <VideoCameraIcon className="icon-16px" />;
        default:
          return <></>;
      }
    };

    const [linkCopied, setLinkCopied] = useState(false);
    useEffect(() => {
      linkCopied &&
        setTimeout(() => {
          setLinkCopied(false);
          tooltip.current && tooltip.current.hide();
        }, 3000);
    }, [linkCopied]);

    return (
      <div
        className={classNames(`sumo-card action-card flex flex-column h-full`, { selected, disabled: !what?.isActive })}
        onClick={handleCardClick}
      >
        <div className="flex pt-22px px-18px">
          <div className="flex-1 flex gap-3px align-items-center text-title-lg-med">
            {isBookingPageLocked && <LockClosedIcon className="icon-16px" />}
            <span>{bookingPage.what?.customName}</span>
          </div>
          <div className="flex-center gap-4px -my-1px h-24px">
            <Checkbox
              checked={selected}
              onChange={(e) => {
                e.stopPropagation();
                handleCheckboxChange(id, !!e.checked);
              }}
              className={`card-selector ${selectMode ? '' : 'card-selector-hidden'}`}
            />
            {(isBookingPageCreate || isBookingPageEdit || isBookingPageDelete) && (
              <div
                className="-my-4px -mr-16px action-button"
                onClick={(e) => {
                  blockCardClick = true;
                  menu.current?.toggle(e);
                }}
                style={{ visibility: selectMode ? 'hidden' : 'visible' }}
              >
                <Menu ref={menu} model={menuItems} popup appendTo="self" className="scroll-menu" />
                <EllipsisVerticalIcon className="icon-20px" />
              </div>
            )}
          </div>
        </div>

        <div className="flex-left-center pt-4px px-18px text-body-s-reg text-heavy-60">
          <div>{bookingPage.displayId}</div>
          <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
          <div>{`${when?.duration?.count} ${getTimeUnitLabel(when?.duration?.timeUnit)}`}</div>
          {template?.what?.customName && (
            <>
              <div className="w-4px h-4px border-radius-2px mx-6px bg-heavy-50" />
              <div>{template?.what?.customName}</div>
            </>
          )}
        </div>

        <div className="flex flex-wrap py-12px px-18px">
          {hostsInfo ? (
            <div className="text-label-s-med text-heavy-80 bg-heavy-1 border-radius-6px py-10px px-8px">
              {hostsInfo}
            </div>
          ) : (
            <InformationBlock text={labels.unassigned} />
          )}
        </div>

        <div className="card-delimeter flex-1" />

        <div className="flex justify-content-between align-items-center p-10px pl-18px">
          <div className="flex gap-4px">
            <div className="p-8px bg-heavy-1 border-radius-6px">
              <div className="w-16px h-16px border-radius-4px" style={{ backgroundColor: what?.color || '' }} />
            </div>
            {bookingPage.where?.locationTypes?.map((locationType) => (
              <div key={locationType} className="flex-center w-32px h-32px bg-heavy-1 border-radius-6px">
                {generateLocationTypeIcon(locationType)}
              </div>
            ))}
          </div>

          <div className="flex align-items-center gap-8px h-38px">
            {!selected ? (
              <>
                {what?.isActive ? (
                  <>
                    <Button
                      className="button-icon"
                      outlined
                      onClick={handleAddToWebsite}
                      tooltip={labels.addToSite}
                      tooltipOptions={{ position: 'top' }}
                      disabled={selectMode}
                    >
                      <CodeBracketIcon className="icon-18px" />
                    </Button>
                    <Tooltip
                      ref={tooltip}
                      position="top"
                      pt={{ text: { className: 'bg-egg-blue-dark text-primary-white text-label-s-reg' } }}
                    >
                      {labels.copiedLink}
                    </Tooltip>
                    <Button
                      className="button-icon copy-link-button"
                      outlined
                      onClick={handleCopyLink}
                      disabled={selectMode}
                    >
                      <LinkIcon className="icon-18px" />
                      <span className="flex-1 pl-8px">{labels.copyLink}</span>
                    </Button>
                  </>
                ) : (
                  !what?.isActive &&
                  isBookingPageEdit &&
                  !!hostsInfo && (
                    <Button label={labels.activate} outlined onClick={handleActivateButton} disabled={selectMode} />
                  )
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);

BookingPageCard.displayName = 'BookingPageCard';
