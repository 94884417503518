import { t } from '../../i18n/i18n';

export default {
  title: t('Locations:TITLE'),
  activate: t('Common:ACTIVATE'),
  clone: t('Common:CLONE'),
  deactivate: t('Common:DEACTIVATE'),
  delete: t('Common:DELETE'),
  edit: t('Common:EDIT'),
  view: t('Common:VIEW'),
  yes: t('Common:YES'),
  no: t('Common:NO'),
  name: t('Common:NAME'),
  address: t('Common:ADDRESS'),
  zip: t('Common:ZIP'),
  state: t('Common:STATE'),
  country: t('Common:COUNTRY'),
  addLabel: t('Locations:ADD_LABEL'),
  deactivateTitle: t('Locations:DEACTIVATE_TITLE'),
  deactivateDescription: t('Locations:DEACTIVATE_DESCRIPTION'),
  deleteTitle: t('Locations:DELETE_TITLE'),
  deleteDescription: t('Locations:DELETE_DESCRIPTION'),
  importCSV: t('Locations:IMPORT_CSV'),
  type: t('Common:TYPE'),
  emptyListDesc: t('Locations:EMPTY_LIST_DESCRIPTION'),
  selectedLocations: t('Locations:SELECTED_LOCATIONS'),
  city: t('Common:CITY'),
  status: t('Locations:STATUS'),
  active: t('Locations:ACTIVE'),
  inactive: t('Locations:INACTIVE'),
};
