import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalName, modalsActions } from '../../../store/modals';
import { rolesActions, rolesSelectors } from '../../../store/roles';
import { editRoleModalActions, editRoleModalSelectors } from '../../../store/roles/modal';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { RolePermissions } from '../rolePermissions/RolePermissions';
import labels from './labels';

export const EditRoleModal = () => {
  const dispatch = useDispatch();
  const open = useSelector(editRoleModalSelectors.selectIsModalOpen);
  const selectedRole = useSelector(rolesSelectors.selectSelectedRole);
  const isFetching = useSelector(rolesSelectors.selectIsFetching);
  const [localRoleName, setLocalRoleName] = useState(selectedRole.name);

  useEffect(() => {
    dispatch(modalsActions.initializeModal({ name: ModalName.EDIT_ROLE_MODAL_NAME, options: {} }));
    return () => {
      dispatch(modalsActions.deleteModal(ModalName.EDIT_ROLE_MODAL_NAME));
    };
  }, []);

  useEffect(() => {
    setLocalRoleName(selectedRole.name);
  }, [selectedRole]);

  const handleClose = () => {
    dispatch(editRoleModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(rolesActions.saveRoleRequest());
  };

  const handleChange = (name: string, value: boolean) => {
    dispatch(rolesActions.updateSelectedRole({ [name]: value }));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalRoleName(e.target.value);
  };

  const handleOnBlurName = () => {
    dispatch(rolesActions.updateSelectedRole({ name: localRoleName }));
  };

  const confirmFooter = () => {
    return (
      <>
        <Button label={labels.save} className="min-w-120px" onClick={handleSave} disabled={isFetching} />
        <Button label={labels.cancel} text onClick={handleClose} />
      </>
    );
  };

  return (
    <Dialog
      header={
        <div className="flex-left-center gap-16px">
          <div>{selectedRole.id ? labels.editTitle : labels.newTitle}</div>
          {isFetching && <ProgressSpinner className="w-32px h-32px mx-0 -my-6px" strokeWidth="5" />}
        </div>
      }
      footer={confirmFooter}
      visible={open}
      onHide={handleClose}
      className="w-840px"
      focusOnShow={false}
      draggable={false}
    >
      <div className="flex flex-column gap-32px">
        <div className="flex flex-column gap-10px">
          <div className="text-title-xs-med">{labels.roleName}</div>
          <div className="flex-left-center gap-16px">
            <InputText
              name="name"
              className="w-300px"
              value={localRoleName}
              onChange={handleNameChange}
              onBlur={handleOnBlurName}
              maxLength={MAX_LENGTH_NAME}
            />
            <div className="flex-left-center gap-10px">
              <InputSwitch
                checked={selectedRole.isActive}
                name="isActive"
                inputId="isActive"
                onChange={(e) => handleChange(e.target.name, !!e.target.value)}
              />
              <label htmlFor="isActive" className="text-label-lg-reg text-heavy-80 cursor-pointer">
                {labels.active}
              </label>
            </div>
          </div>
        </div>

        <RolePermissions role={selectedRole} />
      </div>
    </Dialog>
  );
};
