import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { TextEditor } from '../../common';
import {InformationCircleIcon, PlusIcon, XMarkIcon} from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { Menu } from 'primereact/menu';
import {
  DEFAULT_SMS_TIMING,
  MAX_SMS_REMINDERS_COUNT,
  MERGE_FIELD_OPTIONS,
  MERGE_FIELD_SUBJECT_OPTIONS,
  SCHEDULE_BUFFER_OPTIONS,
} from '../../../store/bookingTemplates';
import { Editor } from 'primereact/editor';
import labels from './labels';
import { ScrollPanel } from 'primereact/scrollpanel';
import {
  MAX_LENGTH_EMAIL_BODY,
  MAX_LENGTH_EMAIL_SUBJECT,
  MAX_LENGTH_SMS_BODY,
} from "../../../types/constants";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {TimeInput, TimeUnit} from "../../../API";
import {Nullable} from "primereact/ts-helpers";
import {calculateLengthTextEditor} from "../../../store/smartAlerts/utils";

type SmartAlertPersonalizeProps = {
  visible: boolean;
  close: () => void;
  handleCancel: () => void;
  handleSave: (body: string, subject?: string, timing?: Array< number | null > | null) => void;
  timing?: Array< number | null > | null;
  subject: string;
  body: string;
  title: string;
  isSMS?: boolean;
  isReadOnly?: boolean;
  hasTiming?: boolean;
  hasAddTimingButton?: boolean;
  isAfterEvent?: boolean;
};

export const Personalize = ({
  visible,
  handleCancel,
  handleSave,
  timing,
  subject,
  body,
  title,
  isSMS,
  isReadOnly,
  hasTiming,
  hasAddTimingButton,
  isAfterEvent,
}: SmartAlertPersonalizeProps) => {
  const [localTiming, setLocalTiming] = useState(timing);
  const [localSubject, setLocalSubject] = useState(subject);
  const [localBody, setLocalBody] = useState(body);
  const [lengthSubject, setLengthSubject] = useState(calculateLengthTextEditor(subject));
  const [lengthBody, setLengthBody] = useState(calculateLengthTextEditor(body));
  const [isFocusedSubject, setFocusedSubject] = useState(false);
  const [isFocusedBody, setFocusedBody] = useState(false);

  const menuSubject = useRef<Menu>(null);
  const menuBody = useRef<Menu>(null);
  const editorSubjectRef = useRef<Editor | null>(null);
  const editorBodyRef = useRef<Editor | null>(null);
  const maxLengthBody = isSMS ? MAX_LENGTH_SMS_BODY : MAX_LENGTH_EMAIL_BODY

  const onSave = () => {
    handleSave(localBody, localSubject, localTiming);
  };

  const onCancel = () => {
    handleCancel();
    setLocalSubject('');
    setLocalBody('');
    setLocalTiming([]);
  };

  useEffect(() => {
    setLocalSubject(subject || '');
    setLengthSubject(calculateLengthTextEditor(subject));
  }, [subject]);

  useEffect(() => {
    setLocalBody(body);
    setLengthBody(calculateLengthTextEditor(body));
  }, [body]);

  useEffect(() => {
    setLocalTiming(timing);
  }, [timing]);

  const handleSaveSubject = (newValue: string) => {
    setLocalSubject(newValue);
    setLengthSubject(calculateLengthTextEditor(newValue))
  };

  const handleSaveBody = (newValue: string) => {
    setLocalBody(newValue);
    setLengthBody(calculateLengthTextEditor(newValue))
  };

  const addMergeVariable = (value: string, type: 'subject' | 'body') => {
    const editorRef = type === 'subject' ? editorSubjectRef : editorBodyRef;
    const selection = editorRef.current && editorRef.current?.getQuill()?.getSelection(true);
    const mergeValue = '<' + value + '>';

    if (selection && editorRef.current) {
      editorRef.current.getQuill().insertText(selection.index, mergeValue);
    }
  };

  const menuItemsSubject = MERGE_FIELD_SUBJECT_OPTIONS.map((option) => ({
    label: option.name,
    command: () => addMergeVariable(option.name, 'subject'),
  }));
  const menuItemsBody = MERGE_FIELD_OPTIONS.map((option) => ({
    label: option.name,
    command: () => addMergeVariable(option.name, 'body'),
  }));

  const getHeader = (type: 'subject' | 'body') => {
    return (
      <span className="ql-formats">
        {type === 'body' && !isSMS && (
          <>
            <button className="ql-bold" aria-label="Bold"></button>
            <button className="ql-italic" aria-label="Italic"></button>
            <button className="ql-underline" aria-label="Underline"></button>
            <button className="ql-link" aria-label="Link"></button>
            {/* <button className="ql-image" aria-label="Image"></button> */}
          </>
        )}
        <Button
          className="flex-center gap-4px text-heavy-60 hover:text-heavy-80 text-label-xs-reg w-fit"
          text
          onClick={(e) => {
            e.stopPropagation();
            (type === 'subject' ? menuSubject : menuBody).current?.toggle(e);
          }}
        >
          <PlusIcon className="icon-12px" />
          {labels.addVariable}
        </Button>
        <Menu
          ref={type === 'subject' ? menuSubject : menuBody}
          model={type === 'subject' ? menuItemsSubject : menuItemsBody}
          popup
          className="max-h-15rem overflow-y-auto"
        />
      </span>
    );
  };

  // Timing functions
  const getTimers = () =>
    localTiming?.map((time) => {
      const timeUnit = !time || time % 1440 === 0 ? TimeUnit.DAY : time % 60 === 0 ? TimeUnit.HOUR : TimeUnit.MINUTE;
      const count = !time ? 0 : timeUnit === TimeUnit.DAY ? time / 1440 : timeUnit === TimeUnit.HOUR ? time / 60 : time;
      return { count, timeUnit } as TimeInput;
    }) || [];

  const getTimerDuplicateIndexes = () =>
    localTiming?.reduce(
      (result, timer, index, timers) =>
        timers.slice(0, index).find((item) => item === timer) ? [...result, index] : result,
      [] as number[]
    ) || [];

  const convertTimeInputToTiming = (timeInput: TimeInput) =>
    !timeInput.count
      ? 0
      : timeInput.count * (timeInput.timeUnit === TimeUnit.DAY ? 1440 : timeInput.timeUnit === TimeUnit.HOUR ? 60 : 1);


  const handleTimingValueChange = (value: Nullable<number>, index: number) => {
    setLocalTiming([
        ...(localTiming || []).slice(0, index),
        convertTimeInputToTiming({ ...getTimers()[index], count: value }),
        ...(localTiming || []).slice(index + 1),
    ]);
  };

  const handleTimeUnitChange = (value: TimeUnit, index: number) => {
    setLocalTiming([
        ...(localTiming || []).slice(0, index),
        convertTimeInputToTiming({ ...getTimers()[index], timeUnit: value }),
        ...(localTiming || []).slice(index + 1),
    ]);
  };

  const handleRemoveTiming = (index: number) => {
    setLocalTiming([...(localTiming || []).slice(0, index), ...(localTiming || []).slice(index + 1)]);
  };

  const getHasAddTimingButton = () =>
    !isReadOnly && hasAddTimingButton && (localTiming?.length || 0) < 3;

  const handleAddTiming = () => {
    if ((localTiming || []).length >= MAX_SMS_REMINDERS_COUNT) {
      return;
    }
    setLocalTiming([...(localTiming || []), DEFAULT_SMS_TIMING]);
  };

  const isTooLong = lengthBody > maxLengthBody || lengthSubject > MAX_LENGTH_EMAIL_SUBJECT;

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={handleCancel}
      dismissable={false}
      showCloseIcon={false}
      className="w-500px"
      pt={{ header: { className: 'hidden' }, content: { className: 'p-0' } }}
    >
      <div className="flex flex-column h-full">
        <div className="flex-none text-sidebar-header text-heavy-100 px-32px pt-32px pb-16px">{title}</div>
        <div className="flex-none border-top-1 border-heavy-20" />
        <div className="flex-1 overflow-hidden">
          <ScrollPanel>
            <div className="flex flex-column gap-16px p-32px">
              {isSMS && (
                <div className="flex-left-center gap-6px bg-blue-soft border-radius-6px p-6px text-blue-main">
                  <InformationCircleIcon className="icon-18px" />
                  <div className="text-label-xs-reg">
                    {labels.smsLabelPart1} - {labels.smsLabelPart2}
                  </div>
                </div>
              )}
              {hasTiming && (
                <div className="flex flex-column gap-10px">
                  <div className="text-title-xs-med">{labels.timing}</div>
                  {getTimers().map((time, index, { length }) => (
                    <div key={index} className="flex-left-center gap-4px">
                      <InputNumber
                        className={getTimerDuplicateIndexes().includes(index) ? 'p-invalid' : ''}
                        inputClassName="w-60px"
                        showButtons
                        min={1}
                        max={999}
                        value={time?.count}
                        onValueChange={(e) => handleTimingValueChange(e.target.value, index)}
                        disabled={isReadOnly}
                      ></InputNumber>
                      <Dropdown
                        className={`w-120px ${getTimerDuplicateIndexes().includes(index) ? 'p-invalid' : ''}`}
                        options={SCHEDULE_BUFFER_OPTIONS}
                        optionLabel="label"
                        value={time?.timeUnit}
                        onChange={(e) => handleTimeUnitChange(e.target.value, index)}
                        disabled={isReadOnly}
                      />
                      <div className="text-label-input-reg pl-4px">
                        {isAfterEvent ? labels.afterEvent : labels.beforeEvent}
                      </div>
                      {!isReadOnly && length > 1 && (
                        <div className="action-button-sm" onClick={() => handleRemoveTiming(index)}>
                          <XMarkIcon className="icon-16px" />
                        </div>
                      )}
                    </div>
                  ))}
                  {getHasAddTimingButton() && (
                    <div className="add-button w-220px" onClick={handleAddTiming}>
                      <PlusIcon className="icon-16px" />
                    </div>
                  )}
                </div>
              )}
              {!isSMS && (
                <div className="flex flex-column gap-8px">
                  <div className="flex flex-row flex-between">
                    <div className="text-title-xs-med text-heavy-100">{labels.subject}</div>
                    {isFocusedSubject && (
                      <div
                        className={
                          'text-body-s-reg' + (lengthSubject > MAX_LENGTH_EMAIL_SUBJECT ? ' text-tomato-main' : '')
                        }
                      >
                        {labels.characters}: {lengthSubject}/{MAX_LENGTH_EMAIL_SUBJECT}
                      </div>
                    )}
                  </div>
                  <TextEditor
                    className={
                      'flex flex-column transparent-border' +
                      (lengthSubject > MAX_LENGTH_EMAIL_SUBJECT ? ' invalid-border' : '')
                    }
                    value={localSubject}
                    setValue={handleSaveSubject}
                    setEditorRef={(editor: Editor | null) => (editorSubjectRef.current = editor)}
                    header={getHeader('subject')}
                    showUndoRedo={true}
                    useTextValue={true}
                    isReadOnly={isReadOnly}
                    onFocused={(isFocused) => setFocusedSubject(isFocused)}
                  />
                </div>
              )}
              <div className="flex flex-column gap-8px">
                <div className="flex flex-row flex-between">
                  <div className="text-title-xs-med text-heavy-100">{labels.body}</div>
                  {isFocusedBody && (
                    <div className={'text-body-s-reg' + (lengthBody > maxLengthBody ? ' text-tomato-main' : '')}>
                      {labels.characters}: {lengthBody}/{maxLengthBody}
                    </div>
                  )}
                </div>
                <TextEditor
                  className={
                    'flex flex-column transparent-border' +
                    (lengthBody > maxLengthBody ? ' invalid-border' : '')
                  }
                  value={localBody}
                  setValue={handleSaveBody}
                  setEditorRef={(editor: Editor | null) => (editorBodyRef.current = editor)}
                  header={getHeader('body')}
                  showUndoRedo={true}
                  useTextValue={isSMS}
                  isReadOnly={isReadOnly}
                  onFocused={(isFocused) => setFocusedBody(isFocused)}
                />
              </div>
            </div>
          </ScrollPanel>
        </div>
        <div className="flex-none flex-left-center gap-6px border-top-1 border-heavy-20 px-32px py-24px">
          <Button className="flex-center button-xl min-w-120px" onClick={onSave} disabled={isReadOnly || isTooLong}>
            {labels.save}
          </Button>
          <Button className="button-xl" text onClick={onCancel}>
            {labels.cancel}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};
