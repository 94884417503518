import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const cloneJourneysModalActions = modalsActionsFactory(ModalName.CLONE_JOURNEYS_MODAL_NAME);
export const cloneJourneysModalSelectors = modalsSelectorsFactory(ModalName.CLONE_JOURNEYS_MODAL_NAME);

export const deleteJourneysModalActions = modalsActionsFactory(ModalName.DELETE_JOURNEYS_MODAL_NAME);
export const deleteJourneysModalSelectors = modalsSelectorsFactory(ModalName.DELETE_JOURNEYS_MODAL_NAME);

export const deactivateJourneysModalActions = modalsActionsFactory(ModalName.DEACTIVATE_JOURNEYS_MODAL_NAME);
export const deactivateJourneysModalSelectors = modalsSelectorsFactory(ModalName.DEACTIVATE_JOURNEYS_MODAL_NAME);

export const deleteJourneyStepModalActions = modalsActionsFactory(ModalName.DELETE_JOURNEY_STEP_MODAL_NAME);
export const deleteJourneyStepModalSelectors = modalsSelectorsFactory(ModalName.DELETE_JOURNEY_STEP_MODAL_NAME);
