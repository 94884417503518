import { t } from '../../../../i18n/i18n';

export default {
  annualy: t('OPSConsoleOrgs:TERM_ANNUALLY'),
  monthly: t('OPSConsoleOrgs:TERM_MONTHLY'),
  changedLabel: t('OrgsChanges:CHANGED_LABEL'),
  to: t('Common:TO'),
  reduce: t('OrgsChanges:REDUCE'),
  license: t('OrgsChanges:LICENSE'),
  licenses: t('OrgsChanges:LICENSES'),
  changesScheduledFor: t('OrgsChanges:CHANGES_SCHEDULED_FOR'),
  scheduledColumnLabel: t('Billing:SCHEDULED_COLUMN_LABEL'),
  scheduledColumnChangeBy: t('Billing:SCHEDULED_COLUMN_CHANGE_BY'),
  scheduledColumnDate: t('Billing:SCHEDULED_COLUMN_DATE'),
};
