import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { bookingPagesActions, bookingPageSelectors, cloneModalActions } from '../../../store/bookingPages';
import { ModalName } from '../../../store/modals';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Modal } from '../../common';
import labels from './labels';

export const BookingPageCloneModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingPageSelectors.selectCloneName);
  const isNameDuplicate = useSelector(bookingPageSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(bookingPageSelectors.selectIsCloneNameValid);
  const isAdminOnly = useSelector(bookingPageSelectors.selectIsAdminOnly);

  const handleNameChange = (value: string) => {
    dispatch(bookingPagesActions.setCloneName(value));
  };

  const handleCancel = () => {
    dispatch(cloneModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(bookingPagesActions.cloneBookingPageRequest());
    dispatch(cloneModalActions.closeModal());
  };

  return (
    <Modal.Container name={ModalName.BOOKING_PAGE_CLONE_MODAL} className="w-440px">
      <Modal.Header>{labels.title}</Modal.Header>
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.name}</div>
        <InputText
          type="text"
          placeholder={labels.namePlaceholder}
          value={name}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={(!isNameValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_NAME}
        />
        {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicate}</div>}
        {isAdminOnly && <div>{labels.cloneNotification}</div>}
      </div>
      <Modal.Buttons>
        <Button label={labels.save} className="min-w-120px" onClick={handleSave} autoFocus disabled={!isNameValid} />
        <Button label={labels.cancel} onClick={handleCancel} text />
      </Modal.Buttons>
    </Modal.Container>
  );
};
