import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SectionHeader } from '../../components/common';
import { usersActions, usersSelectors } from '../../store/users';
import { LicenseListSection, UsersList } from '../../components/users';
import { userSettingsSelectors } from '../../store/userSettings';
import { errorActions } from '../../store/error/actions';
import { ErrorCodes } from '../../store/error/types';
import { authenticationActions, authenticationSelectors } from '../../store/authentication';
import { Button } from 'primereact/button';
import labels from './labels';
import { Path } from '../../routing';
import { useSession } from '../../hooks/useSession';
import { workspacesActions } from '../../store/workspaces/actions';
import { rolesActions, rolesSelectors } from '../../store/roles';
import { PlusIcon } from '@heroicons/react/24/outline';
import { billingActions } from '../../store/billing';
import { inviteUsersModalActions } from '../../store/users/modal';
import { DEFAULT_FILTER, StatusOptions } from '../../store/users/constants';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { workspacesSelectors } from '../../store/workspaces';
import { useRotatingText } from '../../hooks/useRotatingText';
import { teamsActions } from '../../store/teams';

export const Users = () => {
  const dispatch = useDispatch();
  const { isInitialVisit } = useSession(); // checking if the app was initialized on current url
  const isSpinnerFetching = useSelector(usersSelectors.selectIsSpinnerFetching);
  const isInviteUsersFetching = useSelector(usersSelectors.selectIsInviteUsersFetching);
  const isReadUsers = useSelector(userSettingsSelectors.selectUsersRead);
  const isReadTeams = useSelector(userSettingsSelectors.selectTeamsRead);
  const isReadWorkspaces = useSelector(userSettingsSelectors.selectWorkspacesRead);
  const isSuperAdmin = useSelector(userSettingsSelectors.selectIsSuperAdmin);
  const users = useSelector(usersSelectors.selectUsers);
  const isDefaultFilter = useSelector(usersSelectors.selectIsDefaultFilter);
  const filterWorkspaces = useSelector(usersSelectors.selectFilterWorkspaces);
  const filterRoles = useSelector(usersSelectors.selectFilterRoles);
  const filterStatuses = useSelector(usersSelectors.selectFilterStatuses);
  const isFilterActive = useSelector(usersSelectors.selectIsFilterInUse);
  const remainingLicenses = useSelector(authenticationSelectors.selectRemainingLicenses);
  const isAddUsers = useSelector(userSettingsSelectors.selectUsersAdd);
  const workspaceId = useSelector(authenticationSelectors.selectWorkspaceId);
  const workspaceOptions = useSelector(workspacesSelectors.selectWorkspaceOptionItems);
  const roleOptions = useSelector(rolesSelectors.selectRoleOptions);
  const rotatingLoadingLabel = useRotatingText(
    [labels.loadingLabel1, labels.loadingLabel2, labels.loadingLabel3, labels.loadingLabel4],
    isInviteUsersFetching
  );

  useEffect(() => {
    roleOptions.length && isDefaultFilter && resetFilters();
  }, [roleOptions]);

  useEffect(() => {
    if (isReadUsers) {
      dispatch(usersActions.getUsersPageDataRequest());
      dispatch(rolesActions.getRolesRequest());
      if (!isInitialVisit && isReadWorkspaces) {
        dispatch(workspacesActions.getWorkspacesRequest());
      }
      if (isReadTeams) {
        dispatch(teamsActions.getTeamsRequest());
      }
      if (isSuperAdmin) {
        dispatch(authenticationActions.getLicenseRequest());
        dispatch(billingActions.getItemPricesRequest());
      }
    } else {
      dispatch(errorActions.setTheError(ErrorCodes.CODE_403));
    }
  }, [isInitialVisit, isReadUsers]);

  const resetFilters = () => {
    dispatch(
      usersActions.setFilter({
        ...DEFAULT_FILTER,
        workspaceIds: isSuperAdmin ? workspaceOptions.map((option) => option.value) : [workspaceId],
        roles: roleOptions.map((option) => option.value),
      })
    );
  };

  const handleSelectTypes = (e: MultiSelectChangeEvent) => {
    dispatch(usersActions.setFilter({ roles: e.target.value }));
  };

  const handleSelectWorkspaces = (e: MultiSelectChangeEvent) => {
    dispatch(usersActions.setFilter({ workspaceIds: e.target.value }));
  };

  const handleSelectStatuses = (e: MultiSelectChangeEvent) => {
    dispatch(usersActions.setFilter({ statuses: e.target.value }));
  };

  const handleInvite = () => {
    dispatch(usersActions.getInviteUsersOptionsRequest());
    dispatch(inviteUsersModalActions.openModal());
  };

  const inviteUsersButton = () => (
    <Button
      className="flex-none button-xl"
      disabled={remainingLicenses < 1 || !isAddUsers}
      onClick={handleInvite}
      loading={isInviteUsersFetching}
      style={isInviteUsersFetching ? { width: '196px' } : undefined}
    >
      {!isInviteUsersFetching && <PlusIcon width={18} />}
      <span className="flex-1 pl-8px">{isInviteUsersFetching ? rotatingLoadingLabel : labels.inviteTitle}</span>
    </Button>
  );

  return (
    <>
      {isSuperAdmin && <LicenseListSection />}

      <div className="sumo-card-bg flex flex-column pt-16px mb-28px">
        <SectionHeader
          loading={isSpinnerFetching}
          title={labels.users}
          itemsLength={users.length}
          searchPaths={[Path.Users]}
          buttonNode={inviteUsersButton()}
          filters={[
            { label: labels.role, value: filterRoles, options: roleOptions, onChange: handleSelectTypes },
            {
              label: labels.workspace,
              value: filterWorkspaces,
              options: workspaceOptions,
              onChange: handleSelectWorkspaces,
            },
            { label: labels.status, value: filterStatuses, options: StatusOptions, onChange: handleSelectStatuses },
          ]}
          onFilterReset={resetFilters}
          isFilterActive={isFilterActive}
        />
        <UsersList />
      </div>
    </>
  );
};
