import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';
import { UpsertSmartTypeFrom } from '../smartAlerts/types';

export const cloneModalActions = modalsActionsFactory(ModalName.BOOKING_TEMPLATE_CLONE_MODAL_NAME);
export const cloneModalSelectors = modalsSelectorsFactory(ModalName.BOOKING_TEMPLATE_CLONE_MODAL_NAME);

export const deleteBookingPagesModalActions = modalsActionsFactory(ModalName.DELETE_BOOKING_PAGES_MODAL_NAME);
export const deleteBookingPagesSelectors = modalsSelectorsFactory(ModalName.DELETE_BOOKING_PAGES_MODAL_NAME);

export const previewModalActions = modalsActionsFactory(ModalName.PREVIEW_MODAL);
export const previewModalSelectors = modalsSelectorsFactory(ModalName.PREVIEW_MODAL);

export const addToWebsiteModalActions = modalsActionsFactory(ModalName.ADD_TO_WEBSITE_MODAL);
export const addToWebsiteModalSelectors = modalsSelectorsFactory(ModalName.ADD_TO_WEBSITE_MODAL);

export const confirmOverrideModalActions = modalsActionsFactory(ModalName.CONFIRM_OVERRIDE_MODAL_NAME);
export const confirmOverrideModalSelectors = modalsSelectorsFactory(ModalName.CONFIRM_OVERRIDE_MODAL_NAME);

export const confirmOverrideSmartAlertModalActions = modalsActionsFactory<{
  upsertType: UpsertSmartTypeFrom;
}>(ModalName.CONFIRM_OVERRIDE_SMART_ALERT_MODAL);
export const confirmOverrideSmartAlertModalSelectors = modalsSelectorsFactory<{
  upsertType?: UpsertSmartTypeFrom;
}>(ModalName.CONFIRM_OVERRIDE_SMART_ALERT_MODAL);
