import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import { Accordion } from 'primereact/accordion';
import { AccordionTab } from 'primereact/accordion';
import { Dispatch, SetStateAction } from 'react';

type BillingAccordionProps = {
  isTrialLicense: boolean;
  title: string;
  description: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  content: React.ReactNode;
};

export const BillingAccordion = ({
  isTrialLicense,
  title,
  description,
  isOpen,
  setIsOpen,
  content,
}: BillingAccordionProps) => (
  <Accordion
    className="sumo-card border-none"
    activeIndex={isOpen ? 0 : null}
    onTabChange={(e) => setIsOpen(e.index === 0)}
  >
    <AccordionTab
      header={
        <div className="flex-left-center px-20px py-8px">
          <div className="flex-1 flex flex-column pb-4px">
            <div className="text-title-s-med text-heavy-100">{title}</div>
            <div className="text-body-s-reg">{description}</div>
          </div>
          <div className="flex-none">
            {isOpen ? <ChevronUpIcon className="icon-20px" /> : <ChevronDownIcon className="icon-20px" />}
          </div>
        </div>
      }
      disabled={isTrialLicense}
    >
      <div className="px-20px pt-12px pb-24px">{content}</div>
    </AccordionTab>
  </Accordion>
);
