export const OPSConsoleStaff = Object.freeze({
  ACTIVE: `Active`,
  ADD_NEW_MEMBER: `Add new member`,
  ADMIN_TITLE: `Main Admin`,
  INACTIVE: `Inactive`,
  SALES_TITLE: `Sales`,
  SUPPORT_TITLE: `Support`,
  OPERATIONS_TITLE: `Operations`,
  TITLE: `Staff`,
  ADD_STAFF_TITLE: `Create Staff Member`,
  DUPLICATE_EMAIL: `This email has already been added.`,
  LAST_LOGIN: `Last login`,
});

export const StaffToastsNotifications = Object.freeze({
  GET_STAFF_FAIL_MESSAGE: `Get staff request fail`,
  SAVE_STAFF_SUCCESS_MESSAGE: `Changed saved`,
  SAVE_STAFF_ROLE_SELECTION_FORBIDDEN_MESSAGE: `New Main Admin staff user can be invited only other Main Admin.`,
  SAVE_STAFF_FAIL_MESSAGE: `Save staff request fail`,
  CHANGE_STAFF_STATUS_FAIL_MESSAGE: `Change staff status request fail`,
  ACTIVATE_STAFF_SUCCESS_MESSAGE: `Changed saved`,
  DEACTIVATE_STAFF_SUCCESS_MESSAGE: `Changed saved`,
  DELETE_STAFF_SUCCESS_MESSAGE: `Changed saved`,
  DELETE_STAFF_FAIL_MESSAGE: `Delete staff record request fail`,
  VIEW_AS_USER_FAIL_MESSAGE: `View as user request fail`,
});
