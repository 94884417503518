import { ModalName, modalsActionsFactory, modalsSelectorsFactory } from '../modals';

export const deleteWorkspaceNameModalActions = modalsActionsFactory(ModalName.DELETE_WORKSPACE_NAME);
export const deleteWorkspaceNameModalSelectors = modalsSelectorsFactory(ModalName.DELETE_WORKSPACE_NAME);

export const cannotDeleteWorkspaceNameModalActions = modalsActionsFactory(ModalName.CANNOT_DELETE_WORKSPACE_NAME);
export const cannotDeleteWorkspaceNameModalSelectors = modalsSelectorsFactory(ModalName.CANNOT_DELETE_WORKSPACE_NAME);

export const cloneWorkspaceModalActions = modalsActionsFactory(ModalName.CLONE_WORKSPACE_NAME);
export const cloneWorkspaceModalSelectors = modalsSelectorsFactory(ModalName.CLONE_WORKSPACE_NAME);
