import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { bookingTemplatesActions, bookingTemplatesSelectors, cloneModalActions } from '../../../store/bookingTemplates';
import { ModalName } from '../../../store/modals';
import { MAX_LENGTH_NAME } from '../../../types/constants';
import { Modal } from '../../common';
import labels from './labels';

export const BookingTemplateCloneModal = () => {
  const dispatch = useDispatch();
  const name = useSelector(bookingTemplatesSelectors.selectCloneName);
  const isNameDuplicate = useSelector(bookingTemplatesSelectors.selectIsCloneNameDuplicate);
  const isNameValid = useSelector(bookingTemplatesSelectors.selectIsCloneNameValid);

  const handleNameChange = (value: string) => {
    dispatch(bookingTemplatesActions.setCloneName(value));
  };

  const handleCancel = () => {
    dispatch(cloneModalActions.closeModal());
  };

  const handleSave = () => {
    dispatch(bookingTemplatesActions.cloneBookingTemplateRequest());
    dispatch(cloneModalActions.closeModal());
  };

  return (
    <Modal.Container name={ModalName.BOOKING_TEMPLATE_CLONE_MODAL_NAME} className="w-440px">
      <Modal.Header>{labels.title}</Modal.Header>
      <div className="flex flex-column gap-10px">
        <div className="text-title-xs-med">{labels.name}</div>
        <InputText
          type="text"
          value={name}
          onChange={(e) => handleNameChange(e.target.value.trimStart())}
          className={(!isNameValid && 'p-invalid') || ''}
          maxLength={MAX_LENGTH_NAME}
        />
        {isNameDuplicate && <div className="text-body-s-reg text-tomato-main">{labels.duplicate}</div>}
      </div>
      <Modal.Buttons>
        <Button label={labels.save} className="min-w-120px" onClick={handleSave} autoFocus disabled={!isNameValid} />
        <Button label={labels.cancel} onClick={handleCancel} text />
      </Modal.Buttons>
    </Modal.Container>
  );
};
